export const emailValidator = email => {
    const re = /\S+@\S+\.\S+/;

    if (!email || email.length <= 0) return 'Email cannot be empty';
    if (!re.test(email)) return 'Ooops! We need a valid email address';

    return '';
};

export const passwordValidator = password => {
    if (!password || password.length <= 0) return 'Password cannot be empty';

    return '';
};


export const nameValidator = name => {
    if (!name || name.length <= 0) return 'Name cannot be empty';

    return '';
};


export const subjectValidetor = subject => {
    if (!subject || subject.length <= 0) return 'Subject is Required*';
    return '';
}

export const messageValidetor = usermsg => {
    if (!usermsg || usermsg.length <= 0) return 'Message is required*';
    return '';
}

export const recpValidator = recp => {
    if (!recp || recp.length <= 0) return 'Please select a Recepient';
    return '';
}

export const nhiValidator = (nhi, email) => {
    //if(!nhi || nhi.length <=0 || !email || email.length <=0) return 'NHI or Email cannot be empty';
    const re = /\S+@\S+\.\S+/;
    if (email.length > 0) {
        if (!re.test(email)) return 'Ooops! We need a valid email address';
        else return '';
    }

    if (nhi.length > email.length || email.length > nhi.length) return '';
    else return 'NHI or Email cannot be empty';
    return '';
};


export const getInitials = nameString => {
    return nameString.charAt(0).toUpperCase();
    // const regexChar = /\D\w+/
    // return nameString
    //     .trim() //remove trailing spaces
    //     .split(' ') // splits on spaces
    //     .filter(word => word.length > 0) // strip out double spaces
    //     .filter(word => regexChar.test(word)) // strip out special characters
    //     .map(word => word.substring(0, 1).toUpperCase()) // take first letter from each word and put into array
}

// return true if object is empty other wise return false
export const isEmpty=(object)=> {
    return Object.keys(object).length === 0;
}

  // retrun image/png, etc
export const detectMimeType=(b64)=> {
    const body = { profilepic: b64 };
    let mimeType = body.profilepic.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    return mimeType;
}

   // return png, jpg, pdf etc
export const detectType=(b64)=> {
    const body2 = { profilepic: b64 };
    let mimeType2 = body2.profilepic.match(/[^:/]\w+(?=;|,)/)[0];
    return mimeType2;
}
import Settings from '../config/Settings';
//import { GETTING_PROFILE_IMAGES, GETTING_PROFILE_IMAGES_SUCCESS, GETTING_PROFILE_IMAGES_FAILED } from "../constants/Constant";
import getUserId from "../core/GetToken";
import getAllRoles from "../services/RolesService";
import { GETTING_PROFILE_IMAGES, GETTING_PROFILE_IMAGES_SUCCESS, GETTING_PROFILE_IMAGES_FAILED } from "../constants/Constant";

export const fetchProfileImageFromApi = (typeid) => {
    return async (dispatch) => {

        dispatch(getProfileImage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            const queryString = '?PatientID=' + item.userid;
            //console.log(queryString)

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(Settings.apiUrl2 + "patient/LoadImage?PatientID=" + item.userid + "&DocumentKey=" + typeid, requestOptions)
                .then(response => response.text())
                .then(result => {
                    dispatch(getProfileImageSuccess(result))
                    //console.log(result)
                })
                .catch(error => {
                    dispatch(getProfileImageFailed())
                    //console.log('error', error)
                });
            // fetch(Settings.apiUrl + "AccountBalance" + queryString, requestOptions)
            //     .then(response => response.json())
            //     .then(result => {
            //      //  alert(result)
            //         dispatch(getBalanceSuccess(result))
            //         //console.log(result)
            //     })
            //     .catch(error => {
            //         dispatch(getBalanceFailed())
            //         //console.log('error', error)
            //     });
            // getAllRoles(queryString, myHeaders)
            //     .then(data => data.json())
            //     .then(json => {
            //         //console.log(json[0]);
            //         json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
            //     })
            //     .catch((err) => {
            //         //console.log(err)
            //         dispatch(getRolesFailed(err))
            //     })
        })


    }
}


const getProfileImage = () => {
    return {
        type: GETTING_PROFILE_IMAGES
    }
}

//load data
const getProfileImageSuccess = (data) => {
    return {
        type: GETTING_PROFILE_IMAGES_SUCCESS,
        data,
    }
}


//data failure
const getProfileImageFailed = () => {
    return {
        type: GETTING_PROFILE_IMAGES_FAILED,
    }
}





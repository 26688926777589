import { GET_COUNTER, GET_COUNTER_SUCCESS, GET_COUNTER_FAILED, } from "../constants/Constant";


import getPatientProfile from "../services/ProfileService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";
//import getUserInfo from "../core/GetTokenized";



export const fetchCounterFromApi = () => {
    return async (dispatch) => {


        dispatch(getCounter())

        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            // myHeaders.append("Cookie", ".Stackify.Rum=3de47563-f5a3-4a51-8ad5-efd53ff35409");

            // //console.log(queryString);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/RefreshCounter?PatientID=" + item.userid, requestOptions)
                .then(response => response.json())
                .then(result => {

                    //console.log(result)
                    dispatch(getCounterSuccess(result[0]))
                })
                .catch(error => console.log('error', error));
            // getPatientProfile(queryString, myHeaders)
            //     .then(data => data.json())
            //     .then(json => {
            //         // //console.log(json)
            //         dispatch(getCounterSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getCounterFailure(err)))
        }).catch((err) => {
            //console.log(err);
        })




    }
}




//state getting 
const getCounter = () => {
    return {
        type: GET_COUNTER
    }
}


//data success
const getCounterSuccess = (data) => {
    return {
        type: GET_COUNTER_SUCCESS,
        data,
    }
}


//data failed
const getCounterFailure = () => {
    return {
        type: GET_COUNTER_FAILED
    }

}
import * as base from '../core/BaseServicesPost';
import { postWithJson } from '../core/postWithJson';
import {PutWithJson} from '../core/PutWithJson'


//service endpoint
const serviceUri = 'Patient/ChangePassword';

//export query
// const changePasswrodService = (queryString, jsonParameter) => base.post(`${serviceUri}${queryString}`);
const changePasswrodService = (qString, jsonParameter,myHeaders) => PutWithJson(`${serviceUri}${qString}`, jsonParameter,myHeaders);
export default changePasswrodService;
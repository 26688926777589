import Settings from '../config/Settings';
import { GET_PRACTISE, GET_PRACTISE_FAILED, GET_PRACTISE_SUCCESS } from '../constants/Constant';
import getUserId from "../core/GetToken";
import getUserInfo from "../core/GetTokenized";
export const fetchPractisefromAPI = () => {
    return (dispatch) => {
        dispatch(getPractise())
        getUserInfo().then((information) => {
            const item = JSON.parse(information);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?PatientID=' + item.userid;
            fetch(Settings.apiUrl + 'Patient/Organization' + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log(json)
                    dispatch(getPractiseSuccess(json[0].entry))
                })
                .catch(err => dispatch(getPractiseFailure(err)))

        }).catch((err) => {
            //console.log(err);
        })
    }
}

//state getting 
const getPractise = () => {
    return {
        type: GET_PRACTISE
    }
}


//data success
const getPractiseSuccess = (data) => {
    return {
        type: GET_PRACTISE_SUCCESS,
        data,
    }
}


//data failed
const getPractiseFailure = () => {
    return {
        type: GET_PRACTISE_FAILED
    }

}
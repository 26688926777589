import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
import renderHTML from "react-render-html";

const DiagnosisHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="card shadow p-0 mb-3 rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={props.mystyles}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        {/* <Text style={{ fontWeight: '500', color: '#fff' }}>Recorded On</Text> */}
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.date + " " + props.month}</Text>
                    </View>
                </div>
                <div className="card-body column">
                    <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>{props.displayName}</Text>

                    <br />
                    {/* <HTML source={{ html: props.comments.split('<br>')[1] }} imagesMaxWidth={Dimensions.get('window').width} /> */}
                    {props.comments.split('<br>')[1] ? renderHTML(props.comments.split('<br>')[1]) : ""}
                </div>
            </div>
        </div>
    );
}

export default DiagnosisHandler

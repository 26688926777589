//SearchAppointment.js
import {
    START_SEARCH_APPOINTMENTN,
    START_SEARCH_APPOINTMENTN_SUCCESS,
    START_SEARCH_APPOINTMENTN_FAILURE,
    START_SEARCH_APPOINTMENTN_FINISHED,
} from "../constants/Constant";

//inital state
const initalState = {
    SearchAppointment: [],
    isGettingSearch: false,
    errorSearch: false
}

//export default state
export default function searchAppointmentReducer(state = initalState, action) {
    switch (action.type) {
        case START_SEARCH_APPOINTMENTN:
            return {
                ...state,
                isGettingSearch: true
            }

        case START_SEARCH_APPOINTMENTN_SUCCESS:
            return {
                ...state,
                isGettingSearch: false,
                SearchAppointment: action.data

            }

        case START_SEARCH_APPOINTMENTN_FAILURE:
            return {
                ...state,
                SearchAppointment: [],
                isGettingSearch: false,
                errorSearch: true
            }

        case START_SEARCH_APPOINTMENTN_FINISHED:
            return {
                ...state,
                SearchAppointment:[],
                isGettingSearch: false,
                errorSearch: false
            }

        default:
            return state
    }
}
import { GETTING_PROFILE, GETTING_PROFILE_SUCCESS, GETTING_PROFILE_FAILURE } from "../constants/Constant";
import getPatientProfile from "../services/ProfileService";
import getUserId from "../core/GetToken";
//import getUserInfo from "../core/GetTokenized";
import Settings from "../config/Settings";




export const fetchProfileFromApi = () => {
    return (dispatch) => {
        dispatch(getProfile())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            // when api is update then i will uncomment queryString
            // because currently some key is missing in the api response.

            const queryString = '?PatientID=' + item.userid;


            // fetch(Settings.apiUrl + "Patient" + queryString, requestOptions)
            fetch(Settings.apiUrl + "Patient" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log(json)
                    dispatch(getProfileSuccess(json[0].entry))
                })
                .catch(err => dispatch(getProfileFailure(err)))

            // getPatientProfile(queryString)
            //     .then(data => data.json())
            //     .then(json => {
            //         //console.log(json)
            //         dispatch(getProfileSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getProfileFailure(err)))
        }).catch((err) => {
            //console.log(err);
        })

    }
}




//state getting 
const getProfile = () => {
    return {
        type: GETTING_PROFILE
    }
}


//data success
const getProfileSuccess = (data) => {
    return {
        type: GETTING_PROFILE_SUCCESS,
        data,
    }
}


//data failed
const getProfileFailure = () => {
    return {
        type: GETTING_PROFILE_FAILURE
    }

}
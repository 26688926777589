import { GETTING_MESSAGE_FAIL, GETTING_DRAFTS_MESSAGE_MORE_SUCCESS, GETTING_DRAFTS_MESSAGE, GETTING_DRAFTS_MESSAGE_SUCCESS, GETTING_DRAFTS_MESSAGE_FAIL } from "../constants/Constant";
import getPaitentMsg from "../services/MessagingService";
import getUserId from "../core/GetToken";


export const fetchDraftsMessageFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getDraftMessage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //fetch('https://stagingindiciconnect.itsmyhealth.nz/api/?Tozken=c72bf947-4549-4d0d-b7de-141617e52b87&PatientID=2255013&PageSize=10&PageNumber=1', requestOptions)

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&Type=1&MessagingType=3'
            //console.log(queryString);
            getPaitentMsg(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    // //console.log('Hello:', json)
                    json[0].hasOwnProperty('entry') ? dispatch(getDraftMessageSuccess(json[0].entry)) : dispatch(getDraftMessageFinsihed())
                })
                .catch(err => dispatch(getDraftMessageFail(err)))
        })
    }
}


export const fetchMoreDraftsMessageFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getDraftMessage())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&Type=1&MessagingType=3'
            getPaitentMsg(queryString)
                .then(data => data.json())
                .then(json => {
                    json[0].hasOwnProperty('entry') ? dispatch(getDraftMessageMoreSuccess(json[0].entry)) : dispatch(getDraftMessageFinsihed())
                })
                .catch(err => dispatch(getDraftMessageFail(err)))
        })
    }
}




const getDraftMessage = () => {
    return {
        type: GETTING_DRAFTS_MESSAGE
    }
}

//data success
const getDraftMessageSuccess = (data) => {
    return {
        type: GETTING_DRAFTS_MESSAGE_SUCCESS,
        data,
    }
}

const getDraftMessageMoreSuccess = (data) => {
    return {
        type: GETTING_DRAFTS_MESSAGE_MORE_SUCCESS,
        data,
    }
}

//data failed
const getDraftMessageFail = () => {
    return {
        type: GETTING_DRAFTS_MESSAGE_FAIL
    }
}

const getDraftMessageFinsihed = () => {
    return "null";
}



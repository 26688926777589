import Settings from '../config/Settings';
import { GET_PHARMACY, GET_PHARMACY_SUCCESS, GET_PHARMACY_FAILED, } from "../constants/Constant";
import getUserId from "../core/GetToken";
import getAllRoles from "../services/RolesService";

export const fetchPharmcyFromApi = (typeid) => {
    return async (dispatch) => {
        dispatch(getPharmacy())
            getUserId().then((userInfo) => {
                const item = JSON.parse(userInfo);
                var myHeaders = new Headers();
                myHeaders.append("Token", item.token);
                const queryString = '?PatientID=' + item.userid+'&PageSize=1965&PageNumber=1';
                //console.log(queryString)

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(Settings.apiUrl + "Patient/Pharmacies" + queryString, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        //  alert(result)
                        dispatch(getPharmacySuccess(result[0].entry))
                        //console.log(result)
                    })
                    .catch(error => {
                        dispatch(getPharmacyFailed())
                        //console.log('error', error)
                    });
                // getAllRoles(queryString, myHeaders)
                //     .then(data => data.json())
                //     .then(json => {
                //         //console.log(json[0]);
                //         json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
                //     })
                //     .catch((err) => {
                //         //console.log(err)
                //         dispatch(getRolesFailed(err))
                //     })
            })
      

    }
}


const getPharmacy = () => {
    return {
        type: GET_PHARMACY
    }
}

//load data
const getPharmacySuccess = (data) => {
    return {
        type: GET_PHARMACY_SUCCESS,
        data,
    }
}


//data failure
const getPharmacyFailed = () => {
    return {
        type: GET_PHARMACY_FAILED,
    }
}





import { GET_AVAILABLE_PRACTITIONER, GET_AVAILABLE_PRACTITIONER_SUCCESS, GET_AVAILABLE_PRACTITIONER_FAILED, GET_AVAILABLE_PRACTITIONER_CLEAN } from "../constants/Constant";
import getAvailablePractitioner from "../services/GetAvailablePractitioner";
import getUserId from "../core/GetToken";



export const fetchAvailablePractitionerFromApi = (roleID, appType, roletype, locations) => {
    return async (dispatch) => {

        dispatch(getPractitioner())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            //  const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=2'

            const queryString = '?PatientID=' + item.userid + '&AppointmentTypeID=' + appType + '&PracticeLocationID=' + locations + '&RoleID=' + roleID + '&BookWithID=' + roletype
            // 
            // alert('Path is alright---->>>>>: ' + JSON.stringify(queryString))
            getAvailablePractitioner(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    //console.log("Practitioner", json)
                    json[0].hasOwnProperty('entry') ? dispatch(getPractitionerSuccess(json[0].entry)) : dispatch(getPractitionerFailed())
                })
                .catch(err => dispatch(getPractitionerFailed(err)))
        })


    }
}

// //featch more diagnosis pagination call
// export const fetchMoreLongTermFromApi = (pageSize, pageNumber, diagType) => {
//     return (dispatch) => {
//         dispatch(getPeopleLong())
//         getUserId().then((userInfo) => {
//             const item = JSON.parse(userInfo);
//             const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&TypeID=2'
//             //console.log('For Long: ' + queryString)
//             getAllDiagnosis(queryString)
//                 .then(data => data.json())
//                 .then(json => {
//                     ////console.log(queryString)
//                     if (json != null) {
//                         json[0].hasOwnProperty('entry') ? dispatch(getPeopleLongMoreSuccess(json[0].entry)) : dispatch(getPeopleLongFinshed())
//                     } else {
//                         dispatch(getPeopleLongFinshed())
//                     }
//                 })
//                 .catch(err => dispatch(getPeopleLongFailed(err)))
//         })
//     }
// }






//ALL LOADS HERE
const getPractitioner = () => {
    return {
        type: GET_AVAILABLE_PRACTITIONER
    }
}

const getPractitionerSuccess = (data) => {
    return {
        type: GET_AVAILABLE_PRACTITIONER_SUCCESS,
        data,
    }
}

const getPractitionerFailed = (data) => {
    return {
        type: GET_AVAILABLE_PRACTITIONER_FAILED,
        data,
    }
}

export const getPractitionerClean = () => {
    return {
        type: GET_AVAILABLE_PRACTITIONER_CLEAN,
    }
}


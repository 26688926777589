import { GETTING_PROFILE_IMAGES, GETTING_PROFILE_IMAGES_SUCCESS, GETTING_PROFILE_IMAGES_FAILED } from "../constants/Constant";


//current state
const intitalState = {
    ProfileImage: null,
    isFetching: false,
    error: false
}

//export requried state

export default function profileReducer(state = intitalState, action) {
    switch (action.type) {
        case GETTING_PROFILE_IMAGES:
            return {
                ...state,
                isFetching: true
            }

        case GETTING_PROFILE_IMAGES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                ProfileImage: action.data
            }

        case GETTING_PROFILE_IMAGES_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }
}
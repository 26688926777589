import { GETTING_DRAFTS_MESSAGE_SUCCESS, GETTING_DRAFTS_MESSAGE_MORE_SUCCESS, GETTING_DRAFTS_MESSAGE_FAIL, GETTING_DRAFTS_MESSAGE } from "../constants/Constant";

//inital state
const initalState = {
    DraftsMessaging: [],
    isGetting: false,
    error: false
}

//export default state
export default function DraftsMessage(state = initalState, action) {
    switch (action.type) {
        case GETTING_DRAFTS_MESSAGE:
            return {
                ...state,
                isGetting: true
            }

        case GETTING_DRAFTS_MESSAGE_SUCCESS:
            return {
                ...state,
                isGetting: false,
                DraftsMessaging: action.data
                //DraftsMessaging: [...state.DraftsMessaging, ...action.data]
            }

        case GETTING_DRAFTS_MESSAGE_MORE_SUCCESS:
            return {
                ...state,
                isGetting: false,
                DraftsMessaging: [...state.DraftsMessaging, ...action.data]
                //DraftsMessaging: state.DraftsMessaging.concat(action.data)
            }

        case GETTING_DRAFTS_MESSAGE_FAIL:
            return {
                ...state,
                isGetting: false,
                error: true
            }
        default:
            return state
    }
}
import React, { useEffect,useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import AppointmentHandler from "../components/AppointmentHandler";
import FutureAppointmentView from "./AppointmentTabs/FutureAppointmentView";
import CompleteAppointmentView from "./AppointmentTabs/CompleteAppointmentView";
import MissedAppointmentView from "./AppointmentTabs/MissedAppointmentView";
import CompleteAppointmentViewcopy from "./AppointmentTabs/CompleteAppointmentViewcopy";
import SearchAppointmentView from "./AppointmentTabs/SearchAppointmentView";
import { validatePatientTokenFromApi } from "../../shared/actions/TokenValidActions";
import loadjs from "loadjs";


import { View } from "react-native-web";
import { Tabs } from "antd";
import 'antd/dist/antd.css';
import NewAppointment from './AppointmentTabs/NewAppointment';
import renderHTML from 'react-render-html';
import GetAppSettings from '../../shared/core/GetAppSettings';
const { TabPane } = Tabs;
//var loadjs = require('loadjs');
const Appointments = props => {

    const [isloaded, setIsload] = React.useState(false);
    const { TokenValidator, isChecking } = props.TokenValidator;
    const [routeid, setRoute] = React.useState("1")
    const [portalSetting, setPortalSetting] = useState(null);
    useEffect(() => {
        GetAppSettings().then((info) => {
            const set = JSON.parse(info)
            setPortalSetting(set)
        })
    }, [])
    React.useEffect(() => {

        // loadjs(['http://indici-pre-production.web.app/assets/js/template.js', 'http://indici-pre-production.web.app/assets/js/dashboard.js'], 'foobar');
        // loadjs.ready('foobar', function () {
        //     setIsload(true);
        // });


        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);


    }, [])

    useEffect(() => {
        //props.validateToken();
        if (props.location.state && props.location.state.id != undefined) {
            //   alert(props.location.state.id)
            setRoute(props.location.state.id)
            //  getMessageById(props.location.state.id)
        } else {
            //  alert("Teri merbani")
            setRoute("1")
            // history.push('/emails')
        }
    }, [])
    return (
        <div>

            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Appointments</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            {/* <h2 className="grid-title">Appointments</h2> */}
                                            <div className="item-wrapper">
                                                <Tabs defaultActiveKey={props.location.state && props.location.state.id != undefined ? "2" : "1"} centered >
                                                    <TabPane tab="Book Appointment" key="1">
                                                        <NewAppointment />
                                                    </TabPane>
                                                    <TabPane tab="Upcoming Appointment" key="2" >
                                                        <FutureAppointmentView />
                                                    </TabPane>

                                                    <TabPane tab="History" key="3">
                                                        {/* <CompleteAppointmentView /> */}
                                                        <SearchAppointmentView />
                                                    </TabPane>

                                                    {/* <TabPane tab="Missed" key="3">
                                                        <MissedAppointmentView />
                                                    </TabPane>

                                                    <TabPane tab="Cancelled" key="4">
                                                        <CompleteAppointmentViewcopy />
                                                    </TabPane>

                                                    <TabPane tab="Search" key="5">
                                                        <SearchAppointmentView />
                                                    </TabPane> */}


                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
}


function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator
    }
}
function mapDispatchToProps(disptach) {
    return {
        validateToken: () => disptach(validatePatientTokenFromApi())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appointments)
// export default




import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
const ImmuneHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="card shadow p-0 mb-3 rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={props.styles}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ width: 450, textAlign: 'justify', fontWeight: '500', color: '#fff' }}>{props.heading}</Text>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.date}</Text>
                    </View>
                </div>
                <div className="card-body">
                    <h4 className="card-title"> <b>Indication: </b>{props.indication}</h4>
                    <p className="card-text"> <b>Vaccinator: </b>{props.vacc}</p>
                    <p className="card-text"><i className="mdi mdi-24px mdi-clock-fast" /> {props.datedue}</p>
                </div>
            </div>
        </div>
    );
}

export default ImmuneHandler
import {  CLEAR_SET_MESSAGE, SET_MESSAGE  } from "../constants/Constant";

// use in InboxWebScreen for modal and set the data
// now it is used for to get the id of the message and pass it to the MessageDetailById action.
// for reading the message detail against the id.

//inital state
const initalState = {
    Item: {},
    isGetting: false,
    screenName:'',
    error: false
}

//export default state
export default function SetMessageItem(state = initalState, action) {
    switch (action.type) {
        case SET_MESSAGE:
            return {
                ...state,
                Item:action.data,
                screenName:action.screenName

            }
            case CLEAR_SET_MESSAGE:
                return{
                    ...state,
                    Item:{},
                    screenName:''
                }
        default:
            return state
    }
}
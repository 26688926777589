import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, Dimensions, ActivityIndicator } from 'react-native';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';


import getUserId from "../../shared/core/GetToken"
import getRandomColor from '../../shared/core/generateRandomColor';
import Settings from '../../shared/config/Settings';
import { capitalize } from '../../shared/core/datehelper';
const ResourcesHandler = props => {

    const [visible, setVisible] = useState(false);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [show, setShow] = React.useState(false)
    const [checkFile, setCheckFile] = useState(false);

    const [file, setFile] = React.useState()
    const checkItems = resourceInfo => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        setVisible(true);
        getUserId().then((userInfo) => {
            setIsLoadingPdf(true)

            //document.getElementById("showPDF").innerHTML = '<p>kjhdakjadshkajsdhdsakhsdakjh</p>'
            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };


            // fetch(Settings.apiUrl + "Patient/MediaByID?Token=" + item.token + "&PatientID=" + item.userid + "&ResourceID=" + resourceInfo, requestOptions)
            fetch(Settings.apiUrl + "Patient/MediaByID?PatientID=" + item.userid + "&ResourceID=" + resourceInfo, requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log(json[0].entry[0].resource.content.data);

                    setShow(visible => !visible);
                    setShow((state) => {
                        return state;
                    });

                    setFile('data:application/pdf;base64,' + json[0].entry[0].resource.content.data)
                    setFile((state) => {
                        return state;
                    });
                    // //console.log('Hello:', json[0].entry[0].resource.resourceType)
                    //alert(json[0].entry[0].resource.resourceType);
                    var b64 = json[0].entry[0].resource.content.data;
                    // var bin = atob(b64);


                    // // Embed the PDF into the HTML page and show it to the user
                    var obj = document.createElement('object');
                    obj.style.width = '100%';
                    obj.style.height = '550px';
                    obj.type = 'application/pdf';
                    obj.data = 'data:application/pdf;base64,' + b64;
                    //document.body.appendChild(obj);
                    document.getElementById("showPDF").appendChild(obj)

                })
                .catch(error => setIsLoadingPdf(false))
                .finally(() => {
                    setCheckFile(false)
                    setIsLoadingPdf(false)

                });
        });

    }
    const getRandomTag = () => {
        var textArray = [
            'card-header text-white bg-danger',
            'card-header text-white bg-success',
            'card-header text-white bg-primary',
            'card-header text-white bg-info',
            'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray.length);
        return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }
    //responseType  <a href="#"><i className="fa fa-file-pdf-o text-danger" /></a>
    return (
        <div>
            <div style={{cursor: 'pointer'}}  onClick={() => props.contentType === 'URL' ? window.open(props.contentFlow, '_blank') : checkItems(props.contentFlow)}  className="drive-item module text-center">
                <div className="drive-item-inner module-inner">
                    <div className="drive-item-title" ><a href="#" style={{ color: '#000' }}>{capitalize(props.name)}</a></div>

                    <div className="drive-item-thumb">
                        <a href="#"><i className={props.responseType} /></a>
                    </div>
                </div>
                <div className="drive-item-footer module-footer">
                    <ul className="utilities list-inline">
                        <li><a href="#" data-toggle="tooltip" data-placement="top" title data-original-title="Download">View </a>{isLoadingPdf && <ActivityIndicator size="small" color="#00A1DE" />}</li>

                    </ul>
                </div>


               


            </div >

            <Modal
                    title="PDF File"
                    centered
                    visible={visible}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                >
                    <div className="PDF" id="showPDF">

                    </div>
                    <p id="hee">
                        {isLoadingPdf && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>}
                    </p>
                </Modal>


            {/* {show && (
                <DocumentPreview
                    show={show}
                    file={file}
                    onClose={() => setShow(false)}
                    toolbar={
                        <div>
                            <button className="btn btn-primary">
                                Download
                            </button>
                        </div>
                    }
                    footer={<div>my footer</div>}
                />
            )} */}
        </div>


    );
}

export default ResourcesHandler

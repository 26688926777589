import React, { useCallback, useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'

import { fetchAdministeredImmunefromApi, fetchMoreAdministeredImmunefromApi } from '../../../shared/actions/AdministeredImmuneActions';

const AdministeredTab = props => {


    const [pageSize, setPageSize] = useState(100);
    const [pageNumber, setPageNumber] = useState(1);
    const { AddImmune, isGettingImmune } = props.AddImmune;


    useEffect(() => {
        //alert('Called Future')
        props.getAddministratedImmune(pageSize, pageNumber);
    }, [])


    return (
        <div>
            {
                isGettingImmune && <View style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (AddImmune) !== 'undefined' && AddImmune.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column',
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        data={AddImmune}
                        renderItem={({ item }) => (
                            // <ImmuneWebHandler
                            //     styles={{ backgroundColor: Colors.lowAllergy }}
                            //     heading={'Adminstered on: '}
                            //     date={GetAppointmentDate(item.resource.extension[12].valueDateTime) + ' ' + GetMonthName(item.resource.extension[12].valueDateTime) + ' ' + GetYear(item.resource.extension[12].valueDateTime)}
                            //     type_diagnoes={item.resource.extension[0].valueString}
                            //     indication={item.resource.extension[5].hasOwnProperty('valueString') ? item.resource.extension[5].valueString : ''}
                            //     vacc={item.resource.extension[6].hasOwnProperty('valueString') ? item.resource.extension[6].valueString : ''}
                            //     datedue={'Due Date: ' + GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                            // />
                            <ImmuneHandler
                                styles={'card-header text-white bg-success'}
                                heading={item.resource.extension[1].valueString}//{'Adminstered on: '}
                                date={GetAppointmentDate(item.resource.date) + ' ' + GetMonthName(item.resource.date) + ' ' + GetYear(item.resource.date)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                //   date={GetAppointmentDate(item.resource.extension[12].valueDateTime) + ' ' + GetMonthName(item.resource.extension[12].valueDateTime) + ' ' + GetYear(item.resource.extension[12].valueDateTime)}
                                //type_diagnoes={item.resource.extension[0].valueString}
                                indication={item.resource.extension[5].hasOwnProperty('valueString') ? item.resource.extension[5].valueString : 'N/A'}
                                vacc={item.resource.extension[6].hasOwnProperty('valueString') ? item.resource.extension[6].valueString : 'N/A'}
                                datedue={'Due Date: ' + GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}
                    />
                ) : !isGettingImmune && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>

            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        AddImmune: state.AdministeredImmune
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAddministratedImmune: (pageSize, pageNumber) => dispatch(fetchAdministeredImmunefromApi(pageSize, pageNumber)),
        //getAddministratedImmuneMore: (pageSize, pageNumber) => dispatch(fetchMoreAdministeredImmunefromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdministeredTab)

// export default AdministeredTab

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import UserInvoice from "../../components/UserInvoice";
import StatusMessages, { useMessages } from "../../components/StatusMessages";
import { connect } from "react-redux";
import {
  GetDayName,
  GetAppointmentDate,
  GetMonthName,
  ReturnTypeIcon,
  GetYear,
} from "../../../shared/core/datehelper";
import {
  TouchableOpacity,
  View,
  FlatList,
  Text,
  ActivityIndicator,
} from "react-native-web";

import {
  fetchPendingInvoiceFromApi,
  fetchMorePendingInvoiceFromApi,
} from "../../../shared/actions/PendingInvoiceActions";
let pageNumber = 1;
const PendingInvoice = (props) => {
  const { PendingInvc, isGetting } = props.PendingInvc;
  const stripe = useStripe();
  const elements = useElements();
  const [messages, addMessage] = useMessages();
  const [pageSize, setPageSize] = useState(10); //state for the page number
  const [isReady, setIsReady] = React.useState(false); //state for check loading time

  const [isPaying, setIsPaying] = useState(false);
  // const [checked, setChecked] = React.useState('first');
  // const [notes, setNotes] = React.useState('')
  // const [otheramount, setOtherAmount] = React.useState('')
  const handleSubmit = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();


    setIsPaying(true)

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      addMessage("Stripe.js has not yet loaded.");
      return;
    }

    const { error: backendError, clientSecret } = await fetch(
      "http://localhost:4242/create-payment-intent",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethodType: "card",
          currency: "usd",
        }),
      }
    ).then((r) => r.json());

    if (backendError) {
      addMessage(backendError.message);
      return;
    }

    addMessage("Client secret returned");

    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: "Jenny Rosen",
          },
        },
      });

    if (stripeError) {
      // Show error to your customer (e.g., insufficient funds)
      addMessage(stripeError.message);
      return;
    }

    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.
    addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    setIsPaying(false);
  };
  useEffect(() => {
    props.getPendingInvoice(pageSize, pageNumber);
  }, []);
  //   useFocusEffect(
  //     useCallback(() => {
  //         const restoreState = async () => {
  //             try {
  //                 props.getPendingInvoice(pageSize, pageNumber)
  //             }
  //             finally {
  //                 setIsReady(true);
  //             }
  //         };
  //         if (!isReady) {
  //             restoreState();
  //         }
  //         return () => {
  //             // //console.log('On Exit' + people.length)
  //         };
  //     }, [isReady])
  // );
  return (
    <div className="accounts-info-container">
      {/* <div className="payment-details-container mb-4">
        <div className="total-payment-due payment-card">
          <h4 className="payment-amount">$68.5</h4>
          <p className="payment-label">Total Payment Due</p>
        </div>
        <div className="advance-payment-due payment-card ml-lg-4">
          <h4 className="payment-amount">$68.5</h4>
          <p className="payment-label">Advance Payment</p>
        </div>
        <div className="account-balance payment-card ml-lg-auto">
          <h4 className="payment-amount">$68.5</h4>
          <p className="payment-label">Account Balance</p>
        </div>
      </div> */}
      {/* <div className="payment-input-controls-container grid mb-4">
        <div className="grid-body">
          <form action className="payment-details-form">
            <div className="form-group d-inline d-lg-flex">
              <div className="form-control-container mb-2 mb-lg-0">
                <input
                  type="radio"
                  name="paymentType"
                  id="payOutstandingBalance"
                  className="pay-outstanding-balance mr-2 "
                />
                <label htmlFor="payOutstandingBalance">
                  Pay total outstanding balance
                </label>
              </div>
              <div className="form-control-container ml-0 ml-lg-5 mb-2 mb-lg-0">
                <input
                  type="radio"
                  name="paymentType"
                  id="payAnotherAmount"
                  className="pay-outstanding-balance mr-2"
                />
                <label htmlFor="payAnotherAmount">Pay another amount</label>
              </div>
            </div>
            <div className="form-group">
              <label className="mb-0">Amount</label>
              <div className="form-control-container">
                <input
                  type="number"
                  placeholder="Amount"
                  className="form-control"
                />
              </div>
              
            </div>

            <div className="form-group">
              <div id="payment-form">
                <label htmlFor="card">Card</label>
                <CardElement
                  id="card"
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#424770",
                        "::placeholder": {
                          color: "#aab7c4",
                        },
                      },
                      invalid: {
                        color: "#9e2146",
                      },
                    },
                  }}
                />
              </div>
              <StatusMessages messages={messages} />
            </div>

            <div className="form-group">
              <label className="mb-0">Notes</label>
              <div className="form-control-container">
                <textarea
                  name
                  id
                  placeholder="Notes"
                  className="form-control"
                  rows={4}
                  defaultValue={""}
                />
              </div>
            </div>

            <div className="form-group">
              <div className="form-submit-btn d-flex justify-content-end">
                <button className="btn btn-sm amount-reset-btn ml-2 ml-lg-4">
                  Reset
                </button>

                <button onClick={(e) => handleSubmit(e)} className="btn btn-sm pay-with-stripe-btn ml-2 ml-lg-4">
                  {isPaying ? <ActivityIndicator size="small" color="#fff"/> : 'Pay'}
                </button>
              </div>
            </div>
          </form>
        </div>
        <strong>
          <strong></strong>
        </strong>
      </div> */}

      {isGetting && (
        <View style={{ textAlign: "center", marginBottom: 5 }}>
          <ActivityIndicator size="small" color="#00A1DE" />
        </View>
      )}
      {typeof PendingInvc !== "undefined" && PendingInvc.length ? (
        <FlatList
          data={PendingInvc}
          renderItem={({ item }) => (
            <UserInvoice
              invoice_date={
                GetAppointmentDate(item.resource.extension[0].valueDateTime) +
                " " +
                GetMonthName(item.resource.extension[0].valueDateTime)
              }
              invoice_month={GetYear(item.resource.extension[0].valueDateTime)}
              payename={item.resource.extension[3].valueString}
              type={item.resource.extension[2].valueString}
              paitent_name={item.resource.extension[4].valueString}
              ammount={"$" + item.resource.extension[6].valueString}
              balance={item.resource.extension[7].valueString}
              notes={item.resource.extension[1].valueString}
              reference={item.resource.extension[5].valueString}
              paynow={item.resource.extension[8].valueDecimal}
            />
          )}
        />
      ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
        <Text style={{ marginTop: 10, }}>No Records Found</Text>
      </View>}

      {/* <UserInvoice />
      <UserInvoice />
      <UserInvoice /> */}
      {/* here */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    PendingInvc: state.PendingInvc,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPendingInvoice: (pageSize, pageNumber) =>
      dispatch(fetchPendingInvoiceFromApi(pageSize, pageNumber)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PendingInvoice);
// export default PendingInvoice;

import { GETTING_MEDS_REPEATED_SUCESSS, GETTING_REPEATED_MEDS_FAILED, GETTING_REPEATED_MEDS } from "../constants/Constant";



//inital state
const initalState = {
    AllRepeatedMedeications: [],
    isGettingRepeated: false,
    errorRepeated: false
}

export default function allRepeatedMedication(state = initalState, action) {
    switch (action.type) {
        case GETTING_REPEATED_MEDS:
            return {
                ...state,
                isGettingRepeated: true
            }

        case GETTING_MEDS_REPEATED_SUCESSS:
            return {
                ...state,
                isGettingRepeated: false,
                AllRepeatedMedeications: action.data  // [...state.AllRepeatedMedeications, ...action.data]

            }

        case GETTING_REPEATED_MEDS_FAILED:
            return {
                ...state,
                isGettingRepeated: false,
                errorRepeated: true
            }

        default:
            return state
    }
}


import React, { useEffect, useState } from 'react';
import UserInvoice from '../../components/UserInvoice';
import { connect } from 'react-redux'
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear } from '../../../shared/core/datehelper'
import { TouchableOpacity, View, FlatList, Text, ActivityIndicator } from "react-native-web";
import { fetchAllInvoiceFromApi } from "../../../shared/actions/AllInvoicesActions";

let pageNumber = 1;
const AllInvoice = props => {
    const { AllInvc, isGetting } = props.AllInvc;
    const [pageSize, setPageSize] = useState(10);           //state for the page number
    const [isReady, setIsReady] = React.useState(false);    //state for check loading time

    useEffect(() => {
        props.getAllInvoice(pageSize, pageNumber)
    }, [])
    return (
        <div>
            {
                isGetting && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (AllInvc) !== 'undefined' && AllInvc.length ? (
                    <FlatList
                        data={AllInvc}
                        renderItem={({ item }) => (

                            <UserInvoice
                                invoice_date={GetAppointmentDate(item.resource.extension[0].valueDateTime) + ' ' + GetMonthName(item.resource.extension[0].valueDateTime)}
                                invoice_month={GetYear(item.resource.extension[0].valueDateTime)}
                                payename={item.resource.extension[3].valueString}
                                type={item.resource.extension[2].valueString}
                                paitent_name={item.resource.extension[4].valueString}
                                ammount={item.resource.extension[6].valueString}
                                balance={item.resource.extension[7].valueString}
                                notes={item.resource.extension[1].valueString}
                                reference={item.resource.extension[5].valueString}
                                paynow={item.resource.extension[8].valueDecimal}
                            />
                            // <AccountsHandler
                            //     styles={GetTagColor(item.resource.extension[10].valueString)}
                            //     invoice_date={GetAppointmentDate(item.resource.extension[0].valueDateTime) + ' ' + GetMonthName(item.resource.extension[0].valueDateTime)}
                            //     invoice_month={GetYear(item.resource.extension[0].valueDateTime)}
                            //     payename={item.resource.extension[4].valueString}
                            //     paitent_name={'Type: ' + item.resource.extension[2].valueString}
                            //     ammount={'Ammount: $' + item.resource.extension[6].valueString}
                            //     balance={'Balance: $' + item.resource.extension[7].valueString}

                            // />
                        )}
                        keyExtractor={(item, index) => index + ""}
                        // keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}
                        onEndReachedThreshold={0.5}
                    // onEndReached={() => {
                    //     pageNumber = pageNumber + 1;
                    //     //console.log('Page is on end : ' + pageNumber)
                    //     props.getAllInvoice(pageSize, pageNumber)
                    //     //props.getPeople(pageSize, pageNumber, diagType);
                    // }}
                    />
                ) : !isGetting && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>
            }
            {/* <UserInvoice />
            <UserInvoice />
            <UserInvoice /> */}
        </div>
    );
};

function mapStateToProps(state) {
    return {
        AllInvc: state.AllInvc
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAllInvoice: (pageSize, pageNumber) => dispatch(fetchAllInvoiceFromApi(pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllInvoice)
// export default AllInvoice;
import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
const AllergiesHandler = props => {
    return (
        <div className="col-lg-12 ">
            <div className="card shadow p-0 mb-3 rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={props.styles}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text numberOfLines={1} style={{ fontWeight: '500', color: '#fff',paddingRight:90 }}>{props.algTitle}</Text>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.allergyLevel}</Text>
                    </View>
                </div>
                <div className="card-body">
                    <h4 className="card-title"><i className="mdi mdi-18px mdi-format-list-bulleted-type" /> <b>Type: </b>{props.algType}</h4>
                    <p className="card-text"><i className="mdi mdi-18px mdi-alert" /> <b>Reaction: </b>{props.symptoms}</p>
                    <p className="card-text"><i className="mdi mdi-18px mdi-comment-text" /> <b>Comments: </b>{props.comment}</p>
                </div>
            </div>
        </div>
    );
}

export default AllergiesHandler
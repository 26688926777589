import Settings from '../config/Settings';
import { GET_BALANCE_INFO, GET_BALANCE_INFO_SUCCESS, GET_BALANCE_INFO_FAILED, } from "../constants/Constant";

import getUserId from "../core/GetToken";
export const fetchBalanceFromApi = (typeid) => {
    return async (dispatch) => {
      
            dispatch(getBalance())
            getUserId().then((userInfo) => {
                const item = JSON.parse(userInfo);
                var myHeaders = new Headers();
                myHeaders.append("Token", item.token);
                const queryString = '?PatientID=' + item.userid;
                //console.log(queryString)

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                fetch(Settings.apiUrl + "Patient/AccountBalance" + queryString, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                     //  alert(result)
                        dispatch(getBalanceSuccess(result))
                        //console.log(result)
                    })
                    .catch(error => {
                        dispatch(getBalanceFailed())
                        //console.log('error', error)
                    });
                // getAllRoles(queryString, myHeaders)
                //     .then(data => data.json())
                //     .then(json => {
                //         //console.log(json[0]);
                //         json[0].hasOwnProperty('entry') ? dispatch(getRolesSuccess(json[0].entry)) : dispatch(getRolesFailed())
                //     })
                //     .catch((err) => {
                //         //console.log(err)
                //         dispatch(getRolesFailed(err))
                //     })
            })
        
    }
}


const getBalance = () => {
    return {
        type: GET_BALANCE_INFO
    }
}

//load data
const getBalanceSuccess = (data) => {
    return {
        type: GET_BALANCE_INFO_SUCCESS,
        data,
    }
}


//data failure
const getBalanceFailed = () => {
    return {
        type: GET_BALANCE_INFO_FAILED,
    }
}





import { GET_REMINDER, GET_REMINDER_SUCCESS, GET_REMINDER_MORE_SUCCESS, GET_REMINDER_FAILED, GET_REMINDER_FINISHIED } from '../constants/Constant'


//SET INITAILS STATE
const initialState = {
    Reminders: [],
    isGettingReminders: false,
    errorReminders: false
}


export default function remindersReducer(state = initialState, action) {
    switch (action.type) {

        case GET_REMINDER:
            return {
                ...state,
                isGettingReminders: true
            }

        case GET_REMINDER_SUCCESS:
            return {
                ...state,
                isGettingReminders: false,
                Reminders: action.data
            }

        case GET_REMINDER_MORE_SUCCESS:
            return {
                ...state,
                isGettingReminders: false,
                Reminders: [...state.Reminders, ...action.data]

            }

        case GET_REMINDER_FAILED:
            return {
                ...state,
                isGettingReminders: false,
                errorReminders: true
            }

        case GET_REMINDER_FINISHIED:
            return {
                ...state,
                isGettingReminders: false,
                errorReminders: false
            }
        default:
            return state
    }
}

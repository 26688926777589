import { Checkbox } from 'antd';
import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions, TouchableOpacity } from 'react-native';


const LongMedicationHandler = props => {
    const [isSelected, setIsSelected] = React.useState(false)
    return (
        <div className="col-lg-12 " >
            <View style={{ flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#f3f3f3', padding: 10 }}>
                <View style={{ width: 8, backgroundColor: '#f68647', borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>

                </View>
                <View style={{ justifyContent: 'center', alignItems: 'center', marginLeft: 10 }}>
                    <TouchableOpacity onPress={() => {
                        props.options(isSelected)
                        setIsSelected(!isSelected)
                    }}>

                        <Checkbox checked={isSelected}></Checkbox>

                    </TouchableOpacity>

                </View>
                <div className="item-wrapper" style={{ flexDirection: 'column', marginLeft: 10 }}>
                    <Text style={{ color: '#000', fontSize: 14 }}>Prescribed On: {props.medDate}</Text>
                    <p className="card-title" style={{ color: '#707070', marginTop: 5, fontSize: 14 }}>{props.medicationDetails}</p>
                    <p style={{ color: '#707070', fontsize: 14, marginTop: 10 }}>{props.directions}</p>
                </div>

            </View>


            {/* <div className="card">
            <View style={{flexDirection: 'row',  borderBottomWidth: 1, borderBottomColor: '#f3f3f3', padding: 10}}>
                    <View style={{width: 10, backgroundColor: '#ffcc04', borderTopRightRadius: 5 , borderBottomRightRadius: 5}}>

                    </View>
                    <View style={{flexDirection: 'column', marginLeft: 10}}>
                        <Text style={{  color: '#000', fontSize:15 }}>Prescribed On: {props.medDate}</Text>
                        <p  className="card-title" style={{  color: '#707070', marginTop: 5, fontSize:14}}>{props.medicationDetails}</p>
                        <p style={{ color: '#707070', fontsize: 14}}>{props.directions}</p>
                    </View>
                   
            </View>
            </div> */}
            {/* <div className="card shadow p-0 mb-3 rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={props.cardStyle}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: 'row',}}>
                        <TouchableOpacity onPress={() => {
                            props.options(isSelected)
                            setIsSelected(!isSelected)
                        }}>
                            {isSelected ?
                                <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 451.111 451.111" height="20" viewBox="0 0 451.111 451.111" width="20"><path fill="#fff" d="m361.116 183.893-45.564-45.564-122.219 122.218-57.774-57.774-45.564 45.564 103.338 103.338z" /><path fill="#fff" d="m0 0v451.111h451.111v-451.111zm386.667 386.667h-322.223v-322.223h322.222v322.223z" /></svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" enable-background="new 0 0 451.111 451.111" height="20" viewBox="0 0 451.111 451.111" width="20"><path fill="#fff" d="m451.111 451.111h-451.111v-451.111h451.111zm-386.667-64.444h322.222v-322.223h-322.222z" /></svg>
                            }
                        </TouchableOpacity>

                        <Text style={{ fontWeight: '500', color: '#fff',marginLeft: 10}}>{props.medicationDetails}</Text>
                     </View>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.medDate}</Text>
                    </View>
                </div>
                <div className="card-body">
                    <h4 className="card-title"><i className="mdi mdi-18px mdi-pharmacy" /> {props.directions}</h4>
                    <p style={{ marginTop: 5 }} className="card-text"><i className="mdi mdi-18px mdi-calendar-clock" /> Period: {props.checking} , Quantity: {props.quantity}</p>

                    <View style={{ width: '100%', marginTop: 0, borderTopWidth: 1, borderTopColor: '#DDDDDD' }}></View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <p className="card-text"><i className="mdi mdi-24px mdi-clock-in" />Start Date: {props.startDate}</p>
                        <p className="card-text"><i className="mdi mdi-24px mdi-clock-out" />End Date: {props.endDate}</p>
                    </View>

                </div>
            </div> */}
        </div>
    );
}

export default LongMedicationHandler

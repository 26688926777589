import { GET_PHARMACY, GET_PHARMACY_SUCCESS, GET_PHARMACY_FAILED, } from "../constants/Constant";


//create initial state
const initailState = {
    Pharmacy: [],
    isFetching: false,
    error: false
}


//export the defult state
export default function pharmacyReduer(state = initailState, action) {
    switch (action.type) {
        case GET_PHARMACY:
            return {
                ...state,
                isFetching: true,
            }
        case GET_PHARMACY_SUCCESS:

            return {
                ...state,
                isFetching: false,
                //people: state.people.concat(action.data)
                //  people: [...state.people, ...action.data]
                Pharmacy: action.data
            }



        case GET_PHARMACY_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }
}
import { CLEAR_SET_MESSAGE, GETTING_MESSAGE_BY_ID, GETTING_MESSAGE_BY_ID_FAILED, GETTING_MESSAGE_BY_ID_SUCCESS, GETTING_MESSAGE_BY_ID_CLEAN } from "../constants/Constant";

// use in InboxWebScreen for modal and set the data

//inital state
const initalState = {
    MessageDetail: {},
    isGettingById: false,
    errorMessage: '',
    error: false
}

//export default state
export default function SetMessageItem(state = initalState, action) {
    switch (action.type) {
        case GETTING_MESSAGE_BY_ID:
            return {
                ...state,
                isGettingById: true,
                error: false

            }
        case GETTING_MESSAGE_BY_ID_SUCCESS:

            return {
                ...state,
                MessageDetail: action.data,
                isGettingById: false,
                error: false
            }

        // use in messiginWebScreen.js
        case GETTING_MESSAGE_BY_ID_CLEAN:
            return {
                ...state,
                MessageDetail: {},
                isGettingById: false,
                errorMessage: '',
                error: false
            }
        case GETTING_MESSAGE_BY_ID_FAILED:
            return {
                ...state,
                MessageDetail: {},
                isGettingById: false,
                error: true,
                errorMessage: action.data

            }
        default:
            return state
    }
}
import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
import { capitalize } from '../../shared/core/datehelper';


const MessageItem = props => {
    const getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    const [iconColor, setIconColor] = useState(getRandomColor());
    return (
       
        <div className="email-list-item" style={{borderBottom: '1px solid #f2f4f9', height: 130}}>
            <a href="#" className="email-list-item-inner">
                 <View style={styles.personNameCircle}>
                     <View style={{
                         backgroundColor: iconColor,
                         height: 40,
                         width: 40,
                         borderRadius: 40,
                         justifyContent: "center",
                         alignItems: "center"
                     }}>
                        <Text style={styles.personNameText}>
                             {props.initails}
                        </Text>
                     </View>
                 </View>
                <div className="date" style={{width: 85}}>{props.messageDate} {props.messageTime}</div>
                <p className="user_name">{ capitalize( props.sender)}</p>
                <p className="mail-text"><strong>Subject: </strong>{props.messageSubject}</p>
                <p style={{overflow: 'hidden' , whiteSpace: 'nowrap'}} className="mail-text">{props.messageDescription}</p>
            </a>
        </div>
        // <div style={{borderBottom: '1px solid #f3f3f3'}} className="email-list-item">
        //     <a href="#" className="email-list-item-inner">

        //         <View style={styles.personNameCircle}>
        //             <View style={{
        //                 backgroundColor: iconColor,
        //                 height: 40,
        //                 width: 40,
        //                 borderRadius: 40,
        //                 justifyContent: "center",
        //                 alignItems: "center"
        //             }}>
        //                 <Text style={styles.personNameText}>
        //                     {props.initails}
        //                 </Text>
        //             </View>
        //         </View>

        //         <div className="date">{props.messageDate}</div>
        //         <p className="user_name" style={{ height: 25 }}>{props.sender}</p>
        //         <p className="mail-text"><b>Subject: </b>{props.messageSubject}</p>
        //         <p style={{overflow: 'hidden' , whiteSpace: 'nowrap'}} className="mail-text">{props.messageDescription}</p>
        //     </a>
        // </div >
    );
}

const styles = StyleSheet.create({
    personNameCircle: {
        width: 50,
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 2,
        paddingTop: 15
    },
    personNameText: {
        color: '#fff',
        fontSize: 14,
        // fontWeight:'bold'
    },

})

export default MessageItem

import { GET_APPLICATION_SETTINGS, GET_APPLICATION_SETTINGS_SUCCESS, GET_APPLICATION_SETTINGS_FAILED } from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";
import AsyncStorage from '@react-native-async-storage/async-storage';

export const fetchApplicationSettingFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getAppSetting())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const queryString = '?PatientID=' + item.userid + '&PortalSettingTypeID=1'
            //console.log(queryString);
            let sent = '';
            let reply = '';
            let Face2FaceConfirmationMessage = '';
            let IsExtendableSlotTime = '';
            let IsShowBookAppointmentOptions = '';
            let DisableAppointment = '';
            let PortalRepeatMedSetting = '';
            let EnableFamilyMemberBooking = ''
            let RepeatRxUrgentNotes = '';
            let IsPickupPharmacyMandatory = '';
            let IsRxUrgent = '';
            let IsRxFax = '';
            let PortalMessageTermsandConditions = ''
            let PortalAppointmentTermsAndConditions = '';
            let PrefPharmacy = "";
            let PharmacyId = "";
            fetch(Settings.apiUrl + "Lookup/LoadPracticeSetting" + queryString, requestOptions)
                .then(response => response.json())
                .then(json => {
                    json[0].entry.map((item, index) => {
                        if (item.resource.id == "PortalEnableReply")
                            reply = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "PortalEnableCompose")
                            sent = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false; // item.resource.extension[1].valueString
                        else if (item.resource.id == "Face2FaceConfirmationMessage")
                            Face2FaceConfirmationMessage = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "";
                        else if (item.resource.id == "IsExtendableSlotTime")
                            IsExtendableSlotTime = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false;
                        else if (item.resource.id == "IsShowBookAppointmentOptions")
                            IsShowBookAppointmentOptions = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "DisableAppointment")
                            DisableAppointment = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "PortalRepeatMedSetting" && item.resource.extension[1].valueString)
                            PortalRepeatMedSetting = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : 'If you wish to request anything different from your selected medications, either more, less or other type, please indicate so in the notes below. If you would like to have your prescription sent to a pharmacy, please indicate in the notes below the name of the pharmacy. Please note that some pharmacies have a charge to receive a faxed prescription - please check this with your pharmacy. Unless otherwise indicated, you must collect the prescription from our reception and pay for this service at the time of collection. Your prescription will be available after two working days. If this is more urgent, please contact the Reefton Health Medical Centre on 03 769 7440. Please note - repeat prescriptions are only available at your doctor’s discretion for stable medical conditions which have had medication previously prescribed by Reefton Health Medical Centre. A consultation with your doctor will be required every six months to allow a review of your medication.'
                        else if (item.resource.id == "EnableFamilyMemberBooking" && item.resource.extension[1].valueString)
                            EnableFamilyMemberBooking = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "RepeatRxUrgentNotes")
                            RepeatRxUrgentNotes = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "testing"
                        else if (item.resource.id == "IsPickupPharmacyMandatory")
                            IsPickupPharmacyMandatory = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "IsRxUrgent")
                            IsRxUrgent = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        else if (item.resource.id == "IsRxFax") {

                            IsRxFax = item.resource.extension[1].valueString && item.resource.extension[1].valueString == "1" ? true : false
                        }
                        else if (item.resource.id == "PortalMessageTermsandConditions") {

                            PortalMessageTermsandConditions = item.resource.extension[1].valueString ?
                                item.resource.extension[1].valueString : "N/A"
                        }
                        else if (item.resource.id == "PortalAppointmentTermsAndConditions") {
                            //  alert(item.resource.extension[1].valueString)
                            PortalAppointmentTermsAndConditions = item.resource.extension[1].valueString
                        }
                        else if (item.resource.id == "PrefPharmacy") {
                            PrefPharmacy = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : "N/A"
                        }
                        else if (item.resource.id == "PharmacyId") {
                            PharmacyId = item.resource.extension[1].valueString ? item.resource.extension[1].valueString : 'N/A'
                        }
                    })
                    getAppSettingSucess(json)
                    // alert(IsRxFax ? "Success" : "Error")
                    _storeSettings(reply, sent, Face2FaceConfirmationMessage, IsExtendableSlotTime, IsShowBookAppointmentOptions, DisableAppointment, PortalRepeatMedSetting, EnableFamilyMemberBooking, RepeatRxUrgentNotes, IsPickupPharmacyMandatory, IsRxUrgent, IsRxFax, PortalMessageTermsandConditions, PortalAppointmentTermsAndConditions, PrefPharmacy,PharmacyId)
                    //    json[0].hasOwnProperty('entry') ? dispatch(getAppSettingSucess(json[0].entry)) : dispatch(getAppSettingFail())
                })
                .catch(err => dispatch(getAppSettingFail(err)))
            // fetch(Settings.apiUrl + "IndiciLookup?TypeID=12&PatientID=" + item.userid + "", requestOptions)
            //     .then(response => response.json())
            //     .then(json => {
            //         //console.log(json)
            //         json[0].hasOwnProperty('entry') ? dispatch(getAppointTypesSucess(json[0].entry)) : dispatch(getAppointTypesFail())
            //     })
            //     .catch(err => dispatch(getAppointTypesFail(err)))

        })
    }
}



const _storeSettings = async (re, se, Face2FaceConfirmationMessage, IsExtendableSlotTime, IsShowBookAppointmentOptions, DisableAppointment, PortalRepeatMedSetting, EnableFamilyMemberBooking, RepeatRxUrgentNotes, IsPickupPharmacyMandatory, IsRxUrgent, IsRxFax, PortalMessageTermsandConditions, PortalAppointmentTermsAndConditions, PrefPharmacy,PharmacyId) => {
    try {//
        const jsonValue = JSON.stringify({ "send": se, "reply": re, "Face2FaceConfirmationMessage": Face2FaceConfirmationMessage, "IsExtendableSlotTime": IsExtendableSlotTime, "IsShowBookAppointmentOptions": IsShowBookAppointmentOptions, "DisableAppointment": DisableAppointment, "PortalRepeatMedSetting": PortalRepeatMedSetting, "EnableFamilyMemberBooking": EnableFamilyMemberBooking, "RepeatRxUrgentNotes": RepeatRxUrgentNotes, "IsPickupPharmacyMandatory": IsPickupPharmacyMandatory, "IsRxUrgent": IsRxUrgent, "IsRxFax": IsRxFax, "PortalMessageTermsandConditions": PortalMessageTermsandConditions, PortalAppointmentTermsAndConditions, "PrefPharmacy": PrefPharmacy ,"PharmacyId":PharmacyId});
         //  alert(jsonValue)

        await AsyncStorage.setItem('@portalSettings', jsonValue)

    } catch (error) {
        // Error saving data
    }
};



const getAppSetting = () => {
    return {
        type: GET_APPLICATION_SETTINGS
    }
}

//data success
const getAppSettingSucess = (data) => {
    return {
        type: GET_APPLICATION_SETTINGS_SUCCESS,
        data,
    }
}




//data failed
const getAppSettingFail = () => {
    return {
        type: GET_APPLICATION_SETTINGS_FAILED
    }
}
import { GETTING_TREATMENTOFCHOICE_MEDS, GETTING_LONG_TREATMENTOFCHOICE_SUCCESS, GETTING_LONG_TREATMENTOFCHOICE_FAILED, GETTING_LONG_TREATMENTOFCHOICE_MORE, GETTING_LONG_TREATMENTOFCHOICE_FINISHED } from "../constants/Constant";

//inital state
const initalState = {
    TreatmentOfChoices: [],
    isGettingTreatmentOfChoices: false,
    error: false
}

//export default state
export default function treatmentOfChoice(state = initalState, action) {
    switch (action.type) {
        case GETTING_TREATMENTOFCHOICE_MEDS:
            return {
                ...state,
                isGettingTreatmentOfChoices: true
            }

        case GETTING_LONG_TREATMENTOFCHOICE_SUCCESS:
            return {
                ...state,
                isGettingTreatmentOfChoices: false,
                // TreatmentOfChoices: [...state.TreatmentOfChoices, ...action.data]
                TreatmentOfChoices:action.data

            }

        case GETTING_LONG_TREATMENTOFCHOICE_FAILED:
            return {
                ...state,
                isGettingTreatmentOfChoices: false,
                error: true
            }
        case GETTING_LONG_TREATMENTOFCHOICE_MORE:
            return {
                ...state,
                TreatmentOfChoices: [...state.TreatmentOfChoices, ...action.data]
            }
        case GETTING_LONG_TREATMENTOFCHOICE_FINISHED:
            return {
                ...state,
                isGettingTreatmentOfChoices: false,
                error: false
            }
        default:
            return state
    }
}
//CancelledAppointments.js
import {
    GETTING_CANCEL_APPOINTMENT,
    GETTING_CANCEL_APPOINTMENT_SUCCESS,
    GETTING_CANCEL_APPOINTMENTT_FAILURE,
    GETTING_CANCEL_MORE_APPOINTMENT_SUCCESS,
    GETTING_CANCEL_MORE_APPOINTMENT_FINISHED
} from "../constants/Constant";

//inital state
const initalState = {
    CancelledAppointments: [],
    isGetting: false,
    error: false
}

//export default state
export default function fappointmentReducer(state = initalState, action) {
    switch (action.type) {
        case GETTING_CANCEL_APPOINTMENT:
            return {
                ...state,
                isGetting: true
            }

        case GETTING_CANCEL_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isGetting: false,
                CancelledAppointments: action.data // [...state.CancelledAppointments, ...action.data]
                //Medication: action.data
            }

        case GETTING_CANCEL_MORE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                isGetting: false,
                // CancelledAppointments: action.data // [...state.CancelledAppointments, ...action.data]
                CancelledAppointments: [...state.CancelledAppointments, ...action.data]
                //Medication: action.data
            }
            
        case GETTING_CANCEL_APPOINTMENTT_FAILURE:
            return {
                ...state,
                isGetting: false,
                error: true
            }

        case GETTING_CANCEL_MORE_APPOINTMENT_FINISHED:
            return {
                ...state,
                isGetting: false,
                error: false
            }

        default:
            return state
    }
}
import {
    START_SEND_SERVICE,
    START_SEND_SERVICE_SUCCESS,
    START_SEND_SERVICE_REPLY_SUCCESS,
    START_SEND_SERVICE_CLEANUP,
    START_SEND_SERVICE_FAILED
} from "../constants/Constant";

{/**
*******************************************************************************************************************
********************************************* Action is New MessageAction.js *******************************************
*******************************************************************************************************************
*/}
//inital state
const initalState = {
    isSending: false,
    SendMessage: '',
    error: false,
    isSendMessageSuccess: false, // use for to handle the condition in ComposeMessageWebHandler
    isReplyMessageSuccess: false
}

//export default state
export default function newMessageReducer(state = initalState, action) {
    switch (action.type) {
        case START_SEND_SERVICE:
            return {
                ...state,
                SendMessage: '',
                isSending: true,
                isSendMessageSuccess: false,

            }
        case START_SEND_SERVICE_SUCCESS:
            return {
                ...state,
                isSending: false,
                SendMessage: action.data,
                isSendMessageSuccess: true

            }


        case START_SEND_SERVICE_REPLY_SUCCESS:
            return {
                ...state,
                isSending: false,
                SendMessage: action.data,
                isReplyMessageSuccess: true

            }

        case START_SEND_SERVICE_FAILED:
            return {
                ...state,
                isSending: false,
                SendMessage: action.data,
                error: true,
                isSendMessageSuccess: false,

            }

        case START_SEND_SERVICE_CLEANUP:
            return {
                ...state,
                isSending: false,
                SendMessage: '',
                error: false,
                isSendMessageSuccess: false
            }
        default:
            return state
    }
}
import { START_LETTERS, START_LETTERS_SUCCESS, START_LETTERS_MORE_SUCCESS, SEARCH_START_LETTERS_SUCCESS, START_LETTERS_FAILED, START_LETTERS_FINISHIED } from '../constants/Constant'
import getUserId from "../core/GetToken";
import getAllLetters from "../services/LettersService";

export const fetchLettersFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getLetters())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
            //console.log(queryString)
            getAllLetters(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json[0]);
                    json[0].hasOwnProperty('entry') ? dispatch(getLettersSuccess(json[0].entry)) : dispatch(getLetterFinshed())
                })
                .catch((err) => {
                    //console.log(err)
                    dispatch(getLettersFailed(err))
                })
        })
    }
}


export const fetchMoreLettersFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getLetters())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
            //console.log(queryString)
            getAllLetters(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json[0]);
                    json[0].hasOwnProperty('entry') ? dispatch(getLettersSuccessMore(json[0].entry)) : dispatch(getLetterFinshed())
                })
                .catch((err) => {
                    //console.log(err)
                    dispatch(getLettersFailed(err))
                })
        })
    }
}



export const searchLettersFromApi = (fromDate, toDate) => {
    return (dispatch) => {
        dispatch(getLetters())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&StartDate=' + fromDate + '&EndDate=' + toDate
          //console.log('query:',queryString)
            getAllLetters(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json);
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getLettersSearchSuccess(json[0].entry)) : dispatch(getLetterFinshed())
                    } else {
                        dispatch(getLetterFinshed());
                    }
                })
                .catch((err) => {
                    // //console.log(err)
                    dispatch(getLettersFailed(err))
                })
        })
    }
}


//call api
const getLetters = () => {
    return {
        type: START_LETTERS
    }
}

//load data
const getLettersSuccess = (data) => {
    return {
        type: START_LETTERS_SUCCESS,
        data,
    }
}

//load data
const getLettersSearchSuccess = (data) => {
    return {
        type: SEARCH_START_LETTERS_SUCCESS,
        data,
    }
}

//more
const getLettersSuccessMore = (data) => {
    return {
        type: START_LETTERS_MORE_SUCCESS,
        data
    }
}

//data failure
const getLettersFailed = () => {
    return {
        type: START_LETTERS_FAILED,
    }
}

//finishing
const getLetterFinshed = () => {
    return {
        type: START_LETTERS_FINISHIED
    }
}



import { GETTING_RESOURCES, GETTING_RESOURCES_SUCCESS, GETTING_RESOURCES_FAILED, GETTING_RESOURCES_FINISHED, GETTING_RESOURCES_MOR_SUCCESS } from "../constants/Constant";
import getPaitentResources from "../services/ResourcesService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";
//function will return data
export const fetchResourcesFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getResources())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // //console.log(data);
            // token = data

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;

            fetch(Settings.apiUrl + "Patient/Media" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log(json)
                    dispatch(getResourcesSuccess(json[0].entry))
                })
                .catch(err => dispatch(getResourcesFailed(err)))



            // const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
            // getPaitentResources(queryString)
            //     .then(data => data.json())
            //     .then(json => {
            //         //console.log(json)
            //         dispatch(getResourcesSuccess(json[0].entry))
            //     })
            //     .catch(err => dispatch(getResourcesFailed(err)))
        })


    }
}



//function will return more data
export const fetchMoreResourcesFromApi = (token, pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getResources())

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // //console.log(data);
            // token = data
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
            getPaitentResources(queryString)
                .then(data => data.json())
                .then(json => {
                    if (json != null) {
                        json[0].hasOwnProperty('entry') ? dispatch(getMoreResourcesSuccess(json[0].entry)) : dispatch(getResourcesFinished())
                    } else {
                        dispatch(getResourcesFinished())
                    }
                })
                .catch(err => dispatch(getResourcesFailed(err)))
        })


    }
}
const getResources = () => {
    return {
        type: GETTING_RESOURCES
    }
}

const getResourcesSuccess = (data) => {
    return {
        type: GETTING_RESOURCES_SUCCESS,
        data,
    }
}
const getMoreResourcesSuccess = (data) => {
    return {
        type: GETTING_RESOURCES_MOR_SUCCESS,
        data,
    }
}

const getResourcesFailed = () => {
    return {
        type: GETTING_RESOURCES_FAILED
    }
}

const getResourcesFinished = () => {
    return {
        type: GETTING_RESOURCES_FINISHED
    }
}
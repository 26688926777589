import { ADDING_APPOINT, ADDING_APPOINT_SUCCESS, ADDING_APPOINT_FAIL, ADDING_APPOINT_CLEANUP } from "../constants/Constant";

//inital state
const initalState = {
    isAdding: false,
    AddAppointment: '',
    error: false,
    isAvailable: false,
    AddingFailed: false,
}

//export default state
export default function addAppointmentReducer(state = initalState, action) {
    switch (action.type) {
        case ADDING_APPOINT:
            return {
                ...state,
                AddAppointment: '',
                isAdding: true,
                AddingFailed: false,

            }

        case ADDING_APPOINT_SUCCESS:

          
                return {
                    ...state,
                    isAdding: false,
                    AddAppointment: action.data,
                    isAvailable: true,
                    AddingFailed: false,
                }
            


        case ADDING_APPOINT_FAIL:
            return {
                ...state,
                isAdding: false,
                AddingFailed: true,
                AddAppointment: action.data,
                error: true
            }

        case ADDING_APPOINT_CLEANUP:
            return {
                ...state,
                isAdding: false,
                AddingFailed: false,
                AddAppointment: []
            }
        default:
            return state
    }
}
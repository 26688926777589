import { GET_PROXY_MENU, GET_PROXY_MENU_SUCCESS, GET_PROXY_MENU_FAILED } from "../constants/Constant";
import getProxyMenu from "../services/ProxyMenuService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


export const getProxyAccessMenu = () => {
    return (disptach) => {
        disptach(getProxyMeun())
        getUserId().then((userInfo) => {
            //console.log("called")
            const item = JSON.parse(userInfo);
            // //console.log("From Proxy Menu" + userInfo)

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "UserLoggedID": item.parentid,
                "ProxyID": item.proxyid
            });


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/PatientMenu", requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log('Hello:', json)
                    json[0].hasOwnProperty('entry') ? disptach(getProxyMeunSuccess(json[0].entry)) : disptach(null)
                })
                .catch(err => disptach(getProxyMeunFailed(err)))





            // const queryString = '?Token=' + item.token;  //this is token and proxy id
            // //console.log("This is called login" + item.userid)                   //this is user id
            // getProxyMenu(queryString, raw)
            //     .then(data => data.json())
            //     .then(json => {
            //         //console.log('Hello:', json)
            //         json[0].hasOwnProperty('entry') ? disptach(getProxyMeunSuccess(json[0].entry)) : disptach(null)
            //     })
            //     .catch(err => disptach(getProxyMeunFailed(err)))
        })

    }
}



const getProxyMeun = () => {
    return {
        type: GET_PROXY_MENU
    }
}


const getProxyMeunSuccess = (data) => {
    return {
        type: GET_PROXY_MENU_SUCCESS,
        data
    }
}

const getProxyMeunFailed = () => {
    return {
        type: GET_PROXY_MENU_FAILED
    }
}

// const getProxyMeunEnd = () => {
//     return {
//         type: GET_PROXY_ACCOUNTS_FINISHED
//     }
// }
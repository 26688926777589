import React, { useEffect, useState } from 'react';
import { connect, } from "react-redux";
import { Link } from "react-router-dom";
import { TouchableOpacity, FlatList, View, Text } from 'react-native-web';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import $, { type } from "jquery";
import indicilogo from "../assets/indicilogo.png";
import getUserId from '../../shared/core/GetToken';
import { AuthContext } from "../../shared/core/context";
import { LogoutUser } from "../../shared/actions/AuthActions";
import { getProxyAccessMenu } from "../../shared/actions/ProxyMenuActions";
import { fetchProfileImageFromApi } from "../../shared/actions/GetProfileImageAction";
import Settings from "../../shared/config/Settings";
import { getProxyListApi } from '../../shared/actions/ProxyActions';
import { fetchProfileFromApi } from "../../shared/actions/ProfileActions"
import profileplace from "../assets/profileplace.png";
import { fetchPractisefromAPI } from "../../shared/actions/PractiseAction";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { FormatAMPM, FormateUTCTime, convertUTCToLocalTime, GetAppointmentDate, convertLocalTimeToUTCTime, displayTime } from '../../shared/core/datehelper';
const Header = props => {
    // const openMenu = () => {
    //     var element = document.getElementById("myphonemenu");
    //     //element.classList.add("sidebar-collpased");
    // }



    const { signOut } = React.useContext(AuthContext);
    const { ProxyMenu, isLoadingMenu } = props.ProxyMenu;
    const { Practise } = props.Practise;
    const { Proxy, isGettlistPrpxy } = props.Proxy;
    const { Profile, isFetching } = props.Profile;
    const { ProfileImage } = props.ProfileImage;
    const [currentUser, setCurrentUser] = React.useState('');
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');

    const [menuView, setMenuView] = useState(true);

    let his = useHistory()

    const logoutUser = () => {

       
        // alert("hello")
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token

            // //console.log(queryString);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {

                method: 'POST',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Logout" + queryString, requestOptions)
                .then(response => response.json())
                .then(result => {
                    // //console.log(result)
                    // alert(JSON.stringify(result))
                    if (result.StatusCode == 1) {
                        // alert(result.ResponseMessage)
                        props.endUserSession();
                        signOut();
                        his.replace('/')
                        window.location.reload();
                    } else {
                        props.endUserSession();
                        signOut();
                        his.replace('/')
                        window.location.reload();
                    }
                })
                .catch(error => {
                    //   //console.log('error', error)
                    alert("Check your network connection.")
                });

        })


        /// window.location.href = '/';
    }

    useEffect(() => {
        props.getProxyAccessMenu()
        getCurrentUserId()
        getProfileInfo()
        props.getProxy();
        getPatientName();
        props.getProfile();
        props.getPracticeCenterDetail()
        // // props.getProxy();
    }, [])



    const getProfileInfo = () => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //  //console.log(JSON.stringify(item))
            // name = item.parentname;

            // setName(item.parentname)
            setName(item.username)
            setEmail(item.email)
            setName((state) => {
                return state;
            });
            setEmail((state) => {
                return state;
            });
        })
        return name;
    }


    const getCurrentUserId = () => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // //console.log("THis is from inside user" + JSON.stringify(item))
            // name = item.parentname;

            setCurrentUser(item.userid)
            setCurrentUser((state) => {
                return state;
            });
        })
        return currentUser;
    }



    const _setDataState = async (proxyUserId, proxyUserName, proxyUpdateId) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            _saveData(item.token, proxyUserId, item.email, item.password, "Yes", proxyUserName, item.parentid, proxyUpdateId, item.parentname)
        })

    };

    const _saveData = async (token, proxyUserId, pemail, ppassword, isProxy, proxyUserName, parentid, proxyid, parentname) => {
        await AsyncStorage.clear();
        try {
            const jsonValue = JSON.stringify({ "token": token, "userid": proxyUserId, "email": pemail, "password": ppassword, "proxy": isProxy, "username": proxyUserName, "parentid": parentid, "proxyid": proxyid, "parentname": parentname });
            // //console.log(jsonValue)
            await AsyncStorage.setItem('@userToken', jsonValue)
            loadNext();
        } catch (error) {
            // Error saving data
        }
    }


    const loadNext = () => {
        window.location.href = '/';
    }
    function getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age + " years";
    }
    let patientName = ''

    const [proxyName, setProxyName] = useState();
    const getPatientName = () => {

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            // //console.log(item.username)
            if (item.userid === item.parentid) {
                patientName = item.username// "Self"
                setProxyName(item.username);
                setProxyName((state) => {
                    return state;
                });
            }
            else {
                patientName = item.username

                setProxyName(item.username);
                setProxyName((state) => {
                    return state;
                });
            }

        })

        return patientName;
    }

    const changeClasses = () => {
        if (menuView) {
            //hide themenu
            $('.sidebar').css('display', 'none');
            $('.page-content-wrapper').css('margin-left', '0rem')
            setMenuView(false)
        } else {
            //show the menu
            setMenuView(true)
            $('.sidebar').css('display', 'block');
            $('.page-content-wrapper').css('margin-left', '17rem')
        }
    }
    return (
        <nav className="t-header">
            <div className="t-header-brand-wrapper bg-white">
                <a href="/">
                    <img className="logo" src={indicilogo} alt="Logo" />
                    <img className="logo-mini" src={indicilogo} alt="Logo" />
                </a>
                <button onClick={() => changeClasses()} className="t-header-toggler t-header-desk-toggler d-none d-lg-block">
                    <svg className="logo" viewBox="0 0 200 200">
                        <path className="top" d="
                            M 40, 80
                            C 40, 80 120, 80 140, 80
                            C180, 80 180, 20  90, 80
                            C 60,100  30,120  30,120
                            " />
                        <path className="middle" d="
                            M 40,100
                            L140,100
                            " />
                        <path className="bottom" d="
                            M 40,120
                            C 40,120 120,120 140,120
                            C180,120 180,180  90,120
                            C 60,100  30, 80  30, 80
                            " />
                    </svg>
                </button>
            </div>
            <div className="t-header-content-wrapper bg-white">
                <div className="t-header-content">
                    <button onClick={props.onClick} className="t-header-toggler t-header-mobile-toggler d-block d-lg-none">
                        <i className="mdi mdi-menu" />
                    </button>

                    {/* <div className="input-group">
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <i className="mdi mdi-magnify" />
                                </div>
                            </div>
                            <input type="text" className="form-control" id="inlineFormInputGroup" placeholder="Search" autoComplete="off" />
                        </div> */}

                    <ul className="nav">
                        {/*                        
                        <li className="nav-item d-none d-md-block">
                            <a class="nav-link d-flex align-items-center">
                                <i class="mdi mdi-24px mdi-deskphone"></i>
                                {Profile && Profile.length ? Profile[0].resource.telecom.map((item, index) => {
                                    if (index == 1)
                                        return (
                                            <>
                                                <span class="ml-2">{item.use}</span>
                                            </>
                                        )
                                    //setEmail({ value: item.value, error: '' })
                                    // document.getElementById('mEmail').value = item.value;
                                })
                                    : null}
                               
                            </a>

                        </li>
                       */}


                        <li className="nav-item d-none d-md-block">


                            <a style={{ flexDirection: 'row' }} class="nav-link d-flex align-items-center mt-2">
                                <Avatar size={40} icon={<UserOutlined />} />
                                <div style={{ flexDirection: 'column' }} class="d-flex">
                                    <span class="ml-3">{name}</span>
                                    {Profile && Profile.length && Profile[0].resource.extension ?

                                        <span class="ml-3">NHI:{Profile[0].resource.extension[3].valueString ? Profile[0].resource.extension[3].valueString : ""} </span>

                                        : null}
                                    {/* <span class="ml-3">NHI: ZZZ0016</span> */}
                                </div>
                            </a>


                        </li>

                        {/* <li className="nav-item d-none d-md-block">
                            <a class="nav-link d-flex align-items-center">
                                <i class="mdi mdi-24px mdi-cellphone-iphone"></i>
                              
                                {Profile && Profile.length && Profile[0].resource.telecom ? Profile[0].resource.telecom.map((item, index) => {
                                    // if (index == )
                                    return (
                                        <>
                                            {item.system == "phone" &&
                                                <span class="ml-2">{item.value}</span>}

                                           
                                        </>
                                    )
                                  
                                })
                                    : null}
                            </a>
                        </li> */}
                        {/* <li className="nav-item d-none d-lg-block patient-view">
                            <a style={{ flexDirection: 'column' }} class="nav-link d-flex ">
                                {Profile && Profile.length && Profile[0].resource.extension ?
                                    <span class="ml-2 "> <i class="mdi mdi-18px mdi-calendar-check mr-2"></i>DOB: {Profile[0].resource.birthDate}</span>
                                    : <span class="ml-2 "> <i class="mdi mdi-18px mdi-calendar-check mr-2"></i>DOB: N/A</span>}
                                {Profile && Profile.length && Profile[0].resource.extension ?
                                    <span class="ml-2 "> <i class="mdi mdi-18px mdi-email mr-2"></i>Age: {getAge(Profile[0].resource.birthDate)}</span>
                                    : <span class="ml-2 "> <i class="mdi mdi-18px mdi-email mr-2"></i>Age: N/A</span>}
                            </a>
                        </li> */}






                        <li className="nav-item d-none d-lg-block patient-view">
                            <a style={{ flexDirection: 'column' }} class="nav-link d-flex ">
                                <span class="ml-2 align-items-center"> <i class="mdi mdi-18px  mdi-cellphone-iphone mr-2"></i>
                                    {Profile && Profile.length && Profile[0].resource.telecom ? Profile[0].resource.telecom.map((item, index) => {
                                        // if (index == )
                                        return (
                                            <>
                                                {item.use == "mobile" &&
                                                    <>  {item.value}</>}

                                            </>
                                        )

                                    })
                                        : <> </>}
                                </span>
                                <span class="ml-2 align-items-center"> <i class="mdi mdi-18px mdi-email mr-2"></i>{email}</span>
                            </a>
                        </li>
                        <li className="nav-item d-none d-lg-block patient-view">


                            <a style={{ flexDirection: 'row' }} class="nav-link d-flex align-items-center">
                                <i class="mdi mdi-36px mdi-home-map-marker"></i>
                                <div style={{ flexDirection: 'column' }} class="d-flex">
                                    {
                                        typeof (Practise) !== 'undefined' && Practise.length ?
                                            <span class="ml-3">{Practise[0].resource.name}</span>

                                            : <></>}
                                    {
                                        typeof (Practise) !== 'undefined' && Practise.length ?
                                            //     setTypeId<span class="ml-3">{displayTime(new Date(Practise[0].resource.extension[0].valueDateTime))} - {displayTime(new Date(Practise[0].resource.extension[1].valueDateTime))}</span>
                                            <span class="ml-3">{FormatAMPM(Practise[0].resource.extension[0].valueDateTime.split('+')[0])} - {FormatAMPM (Practise[0].resource.extension[1].valueDateTime.split('+')[0])}</span>
                                           
                                           : <></>}

                                </div>
                            </a>


                        </li>
                        <li className="nav-item dropdown  d-none ml-auto d-md-flex align-item-center">
                            <a class="nav-link nav-link-switch" href="#" id="messageDropdown" data-toggle="dropdown" aria-expanded="false">
                                <i class="mdi mdi-24px mdi-account-switch"></i>

                            </a>
                            <div className="dropdown-menu navbar-dropdown dropdown-menu-right" aria-labelledby="messageDropdown">
                                <div className="dropdown-body pt-0">

                                    {
                                        typeof (Proxy) !== 'undefined' && Proxy.length ? (
                                            <FlatList
                                                data={Proxy}
                                                renderItem={({ item }) => (
                                                    <>
                                                        {
                                                            currentUser != item.resource.id &&
                                                            <TouchableOpacity onPress={() => _setDataState(item.resource.id, item.resource.name[0].text, item.resource.extension[0].valueString)}>

                                                                <div className="dropdown-list">
                                                                    <div className="image-wrapper">

                                                                        <Avatar size={40} icon={<UserOutlined />} />
                                                                        {/* <div className="status-indicator rounded-indicator bg-success" /> */}
                                                                    </div>
                                                                    <div className="content-wrapper">
                                                                        <small><strong className="name">{item.resource.name[0].text} </strong></small>
                                                                    </div>

                                                                </div>
                                                            </TouchableOpacity>
                                                        }

                                                        {
                                                            currentUser == item.resource.id &&
                                                            <Link to='/profile'>
                                                                <div className="dropdown-list">
                                                                    <div className="image-wrapper">
                                                                        {/* <img className="profile-img" src={profileplace} alt="profile image" /> */}
                                                                        <Avatar size={40} icon={<UserOutlined />} />
                                                                        {/* <div className="status-indicator rounded-indicator bg-success" /> */}
                                                                    </div>
                                                                    <div className="content-wrapper">
                                                                        <small><strong className="name">{item.resource.name[0].text} </strong></small>
                                                                    </div>

                                                                </div>
                                                            </Link>
                                                        }



                                                    </>

                                                )}
                                                keyExtractor={item => item.resource.id}
                                            />
                                        ) : !isGettlistPrpxy && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                        </View>
                                    }
                                    {/* <div className="dropdown-list">
                                        <div className="image-wrapper">
                                            <img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />
                                            <div className="status-indicator rounded-indicator bg-success" />
                                        </div>
                                        <TouchableOpacity onPress={() => window.open('https://www.dropbox.com/s/usl4993vi0cznza/Portal%20App%20Video.mp4?dl=0', '_blank')}>
                                            <div className="content-wrapper">
                                                <small><strong className="name">User Account </strong></small>
                                            </div>
                                        </TouchableOpacity>
                                    </div>
                                    <div className="dropdown-list">
                                        <div className="image-wrapper">
                                            <img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />
                                            <div className="status-indicator rounded-indicator bg-success" />
                                        </div>
                                        <TouchableOpacity onPress={() => window.open('https://www.dropbox.com/s/usl4993vi0cznza/Portal%20App%20Video.mp4?dl=0', '_blank')}>
                                            <div className="content-wrapper">
                                                <small> <strong className="name">User Account 2</strong></small>
                                            </div>
                                        </TouchableOpacity>
                                    </div>
                                */}
                                </div>
                            </div>

                        </li>
                        <li className="nav-item dropdown d-flex align-item-center">
                            <a class="nav-link d-flex align-items-center" href="#" id="messageDropdown" data-toggle="dropdown" aria-expanded="false">
                                <i class="mdi mdi-24px mdi-account-circle"></i>
                                <span class="ml-2">My Account</span>
                            </a>
                            <div className="dropdown-menu navbar-dropdown dropdown-menu-right" aria-labelledby="messageDropdown">
                                <div className="dropdown-body pt-0">
                                    <Link to='/profile'>
                                        <div className="dropdown-list">
                                            <div className="image-wrapper">
                                                <i class="mdi mdi-24px mdi-account"></i>
                                            </div>
                                            <div className="content-wrapper">
                                                <small className="name">Profile</small>
                                            </div>

                                        </div>
                                    </Link>

                                    <TouchableOpacity onPress={() => logoutUser()}>
                                        <div className="dropdown-list">

                                            <div className="image-wrapper">
                                                <i class="mdi mdi-24px mdi-logout"></i>
                                            </div>

                                            <div className="content-wrapper">
                                                <small className="name">Log Out</small>
                                            </div>

                                        </div>
                                    </TouchableOpacity>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        ProfileImage: state.ProfileImage,
        Proxy: state.Proxy,
        ProxyMenu: state.ProxyMenu,

        Practise: state.Practise,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getProfile: () => dispatch(fetchProfileFromApi()),
        endUserSession: () => dispatch(LogoutUser()),
        getProxyAccessMenu: () => dispatch(getProxyAccessMenu()),
        getProxy: () => dispatch(getProxyListApi()),
        getProfileImage: (uid) => dispatch(fetchProfileImageFromApi(uid)),
        getPracticeCenterDetail: () => dispatch(fetchPractisefromAPI()),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header)
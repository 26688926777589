import Settings from '../config/Settings'


const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

//var myHeaders = new Headers();
//myHeaders.append("Content-Type", "application/json");

//expor the dulft get fetch api call

export const PutWithJson = (endpoint,  myHeaders) => (
    ////console.log("myHeaders", myHeaders)
    fetch(`${Settings.apiUrl}${endpoint}`, {

        method: 'PUT',
        headers: myHeaders || defaultHeaders,
     
    })
);


import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
    StyleSheet,
    View,
    FlatList,
    Text,
    ActivityIndicator,
    Dimensions
} from 'react-native-web';
import { connect } from 'react-redux'
import ImmuneHandler from "../../components/ImmuneHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchDueImmunefromApi, fetchMoreDueImmunefromApi } from '../../../shared/actions/DueImmuneActions';
import { ImmuneHomeContext } from '../ImmuneHome';


const DueTab = props => {
    const [pageSize, setPageSize] = useState(30);
    const [pageNumber, setPageNumber] = useState(1);
    const { DueImmune, isGettingImmune } = props.DueImmune;

    const { dispatch, state } = useContext(ImmuneHomeContext)

    useEffect(() => {
        //alert('Called Future')
        props.getDueImmune(pageSize, pageNumber);

    }, [])
    useEffect(() => {
        if (isGettingImmune) {
            //console.log("if statment==>" + isGettingImmune)
            dispatch({
                type: "ISIMMUNELOADED",

            })

        } else {
            // item.resource.extension[1].valueString
            typeof (DueImmune) !== 'undefined' && DueImmune.length &&
                dispatch({
                    type: "IMMUNELOAD",
                    immuneMg: DueImmune[0].resource.extension[13].valueString

                })
            //console.log("else statment==>" + isGettingImmune)
        }

    }, [isGettingImmune])

    return (
        <div>
            {
                isGettingImmune && <View style={{ textAlign: 'center', marginTop: 5, marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }

            {
                typeof (DueImmune) !== 'undefined' && DueImmune.length ? (
                    <FlatList

                        data={DueImmune}
                        renderItem={({ item }) => (
                            <ImmuneHandler
                                styles={'card-header text-white bg-warning'}
                                date={GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}//date={GetAppointmentDate(item.resource.extension[12].valueDateTime) + ' ' + GetMonthName(item.resource.extension[12].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                                month={GetYear(item.resource.extension[12].valueDateTime)}
                                // type_diagnoes={item.resource.extension[0].valueString}
                                heading={item.resource.extension[1].valueString}
                                indication={(item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : "N/A")}
                                vacc={(item.resource.extension[6].valueString != undefined ? item.resource.extension[6].valueString : "N/A")}
                                datedue={'Due Date: ' + GetAppointmentDate(item.resource.extension[7].valueDateTime) + ' ' + GetMonthName(item.resource.extension[7].valueDateTime) + ' ' + GetYear(item.resource.extension[7].valueDateTime)}
                            />
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={10}
                        windowSize={7}
                    />
                ) : !isGettingImmune && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>

            }
        </div>
    );
}


function mapStateToProps(state) {
    return {
        DueImmune: state.DueImmune
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDueImmune: (pageSize, pageNumber) => dispatch(fetchDueImmunefromApi(pageSize, pageNumber)),
        //getDueImmuneMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreDueImmunefromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DueTab)
// export default DueTab

import React from "react";

const Route = require("react-router-dom").Route;
const Switch = require("react-router-dom").Switch;
const Router = require("react-router-dom").BrowserRouter;

const Redirect = require("react-router-dom").Redirect;


const PublicRoute = ({ component: Component, authed, ...rest }) => {

    // return (
    //     <Route {...rest} render={props => (
    //         authed ? (<Component {...props} />
    //         ) : (
    //                 <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    //             )
    //     )} />
    // )
    //console.log('Path is alright---->>>>>: ' + JSON.stringify(rest.location))
    //  console.log('Path is alright---->>>>>: ' + JSON.stringify(rest.location.state.from.pathname))
    return (
        <Route
            {...rest}
            render={(props) => authed === false
                ? <Component {...props} />
                :
                authed ? <Redirect to={{ pathname: props.location.state !== undefined ? props.location.state.from.pathname : '/', state: { from: props.location } }} />
                    :
                    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
        />
    )

}

export default PublicRoute


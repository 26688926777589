import React from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './index.css';
import App from './Entry';
import reportWebVitals from './reportWebVitals';

import { Provider } from "react-redux";
import configureStore from "./configureStore";


const store = configureStore();
// document.addEventListener('DOMContentLoaded', async () => {
 // const { publishableKey } = await fetch('http://localhost:4242/config').then((r) => r.json());
  const stripePromise = loadStripe('pk_test_jVIOf3mGtJXT1TCLaL3Eqf9s');
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

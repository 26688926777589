import { CLEAR_SET_MESSAGE, SET_MESSAGE } from "../constants/Constant";
import getPaitentMsg from "../services/MessagingService";
import getUserId from "../core/GetToken";


// item is the object 
// screenName use for to handle conditionaly the data in component
// screenName must be "Inbox" or "Sent" or "Draft"
export const setItemMessage = (item,screenName) => {
    return (dispatch) => {
        dispatch(settMessageItem(item,screenName))
    }
}

export const clearSetItemMessage = (item) => {
    return (dispatch) => {
        dispatch(removeSetItemMessage())
    }
}



const settMessageItem = (data,screenName) => {
    return {
        type: SET_MESSAGE,
        data,
        screenName
    }
}
const removeSetItemMessage = () => {
    return {
        type: CLEAR_SET_MESSAGE
    }
}





import React from 'react'
import { capitalize } from '../../shared/core/datehelper'



const getByTitle = item => {

    if (item.system === "email" && item.use === "home") {
        return "Primary Email"
    }
    else if (item.system === "email" && item.use === "temp") {
        return "Secondary Email"
    }
    else {
        return capitalize(item.use)
    }
    //  item.system === "email" && item.use ==="home" ?   capitalize(item.use)
}

const Item = ({ titleName, titleValue }) => {
    return (
        <div className="detail-container">
            <label className="detail-label">{titleName}</label>
            <span className="detail-value">{titleValue}</span>
        </div>
    )
}
const ProfileContactDetails = ({ Profile }) => {
    return (
        <div>
            <div className="contact-detail-card-body">
                {
                    typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.telecom ? Profile[0].resource.telecom.map((item) => {
                        return (
                            <Item
                                titleName={getByTitle(item)}
                                titleValue={item.value} />
                            // <ProfileItemSingle contactTile={getByTitle(item)} contactValue={item.value} />

                        )
                    }) : <p>Contact detail not available.</p>
                }

                {/* <Item
                    titleName={"Work Phone Number"}
                    titleValue={"Miss"} />
                <Item
                    titleName={"Home Phone Number"}
                    titleValue={"Miss"} />
                <Item
                    titleName={"Cell Phone Number"}
                    titleValue={"Miss"} />

                <Item
                    titleName={"Email"}
                    titleValue={"Miss"} />
                <Item
                    titleName={"Contact Method"}
                    titleValue={"Miss"} /> */}
            </div>

        </div>
    )
}
export default ProfileContactDetails;
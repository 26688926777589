import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, Button, StyleSheet, StatusBar, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FormatAMPM, GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchMessageFromApi } from "../../../shared/actions/MessagingActions";
import { fetchMessageById } from '../../../shared/actions/MessageDetailByIdAction';
import { clearSetItemMessage, setItemMessage } from '../../../shared/actions/SetMessageItemAction';
import Dots from "react-activity/lib/Dots";
import 'react-activity/lib/Dots/Dots.css';

import MessageItem from "../../components/MessageItem";
import { getInitials } from '../../../shared/core/utils';
import MessageView from '../../components/MessageView';
import getUserId from '../../../shared/core/GetToken';
const Received = props => {

    //  const { Messaging, isGetting } = props.Messaging;         
    const { Messaging, isGetting } = props.Messaging;                   //loading main message
    const { MessageDetail, isGettingById } = props.MessageDetail;       //get message details by id
    const [pageSize, setPageSize] = useState(100);                      //state for the page number
    const [pageNumber, setPageNumber] = useState(1);                    //state for the page number
    useEffect(() => {
        //alert('Called Future')
        props.getMessage(pageSize, pageNumber)
    }, [])


    const getMessageId = params => {
        //console.log("for Hassan====>  " + params)
        props.setMessageItem(params, 'Received')
    }



    return (
        <>
            {
                isGetting && <View style={{ textAlign: 'center', marginTop: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (Messaging) !== 'undefined' && Messaging.length ? (
                    <FlatList
                        // style={{ marginTop: 0, height: 760 }}
                        data={Messaging}
                        renderItem={({ item }) => (
                            // <TouchableOpacity onPress={() => {
                            //     getMessageId(item.resource.id)
                            // }}>
                            // <Link to={'/read/'+item.resource.id}>
                            <Link to={{
                                pathname: '/read',
                                state: { "id": item.resource.id, "reply": true }
                            }}>
                                <MessageItem
                                    initails={getInitials(item.resource.extension[2].valueString)}
                                    messageDate={GetAppointmentDate(item.resource.extension[6].valueDateTime) + ' ' + GetMonthName(item.resource.extension[6].valueDateTime) + " " + GetYear(item.resource.extension[6].valueDateTime)}
                                    messageTime={FormatAMPM(item.resource.extension[6].valueDateTime.split('+')[0])} 
                                    sender={item.resource.extension[2].valueString}
                                    messageSubject={item.resource.note[0].text}
                                    messageDescription={item.resource.extension[0].valueString}
                                />
                            </Link>
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        maxToRenderPerBatch={2}
                        windowSize={7}

                    />

                ) : !isGetting && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>
            }
        </>
    );
}


function mapStateToProps(state) {
    return {
        Messaging: state.Messaging,
        MessageDetail: state.MessageDetail,
        // SetMessageItem: state.SetMessageItem,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getMessage: (pageSize, pageNumber) => dispatch(fetchMessageFromApi(pageSize, pageNumber)),
        getMessageByid: (messageId) => dispatch(fetchMessageById(messageId)),
        setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Received);

// export default Received

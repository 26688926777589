import { FETCHING_PEOPLE_FAILURE, FETCHING_PEOPLE, FETCHING_PEOPLE_SUCCESS } from '../constants/Constant'


//SET INITAILS STATE
const initialState = {
    RecentDiag: [],
    isFetching: false,
    error: false
}

//export the defult state

export default function RecentDiagnosisReducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_PEOPLE:
            return {
                ...state,
                isFetching: true
            }

        case FETCHING_PEOPLE_SUCCESS:

            return {
                ...state,
                isFetching: false,
                //LongDiag: state.LongDiag.concat(action.payload)
                RecentDiag: action.data
            }

        // case FETCHING_LONGTERM_PEOPLE_SUCCESS_MORE:
        //     return {
        //         ...state,
        //         isFetching: false,
        //         //LongDiag: state.LongDiag.concat(action.payload)
        //         LongDiag: [...state.LongDiag, ...action.payload]
        //         //LongDiag: action.payload
        //     }

        case FETCHING_PEOPLE_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        // case FETCHING_LONGTERM_PEOPLE_FINISHED:
        //     return {
        //         ...state,
        //         isFetching: false,
        //         error: false
        //     }
        default:
            return state
    }
}
import {
    GETTING_MEDS,
    GETTING_MEDS_SUCCESS,
    GETTING_MEDS_MORE_SUCCESS,
    GETTING_MEDS_FAILED,
    GETTING_RECENT_MEDS,
    GETTING_MEDS_RECENT_SUCESSS,
    GETTING_RECENT_MEDS_FAILED,

    GETTING_REPEATED_MEDS,
    GETTING_REPEATED_MEDS_FAILED,
    GETTING_REPEATED_MEDS_MORE_SUCCESS,
    GETTING_MEDS_REPEATED_SUCESSS
} from "../constants/Constant";
import getAllMedications from "../services/MedicationService";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";



export const fetchRepeatedMedsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getMed())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            const queryString = '?PatientID=' + item.userid + '&PageSize=10&PageNumber=1&TypeID=2'
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(Settings.apiUrl + "Messaging" + queryString, requestOptions)
                .then(response => response.json())
                .then(result => {
                    //console.log(result)
                    result[0].hasOwnProperty('entry') ? dispatch(getMedSuccess(result[0].entry)) : dispatch(getMedFinished())
                })
                .catch(error => {
                    getMedFailed(error)
                    //console.log('error', error)
                });
            // const queryString = '?PatientID=' + item.userid + '&PageSize=10&PageNumber=' + pageNumber + '&TypeID=2'
            // //console.log(queryString);

            // getAllMedications(queryString, myHeaders)
            //     .then(data => data.json())
            //     .then(json => {
            //         //console.log(json[0].entry[0].resource.id);
            //         json[0].hasOwnProperty('entry') ? dispatch(getMedSuccess(json[0].entry)) : dispatch(getMedFinished())
            //     })
            //     .catch(err => dispatch(getMedFailed(err)))
        })
    }
}


//start getting data
const getMed = () => {
    return {
        type: GETTING_REPEATED_MEDS
    }
}

//data is success
const getMedSuccess = (data) => {
    return {
        type: GETTING_MEDS_REPEATED_SUCESSS,
        data
    }
}
//data failed
const getMedFailed = () => {
    return {
        // type: GETTING_RECENT_MEDS_FAILED
        type: GETTING_REPEATED_MEDS_FAILED
    }
}

const getMedFinished = () => {
    return null;
}
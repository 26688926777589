import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../components/Header";
import ReactDOM from "react-dom";

import Sidebar from "../components/Sidebar";
import { View, Text, StyleSheet } from "react-native-web";
import { ActivityIndicator, TouchableOpacity } from "react-native-web";
import { connect } from "react-redux";
import { Tabs } from "antd";
import FileBase64 from "react-file-base64";
import { Select } from "antd";
import { Alert } from "antd";
import { _ } from "underscore";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";

import { Modal, Button } from "antd";
import { notification, Space } from "antd";

import "antd/dist/antd.css";
import Received from "./Inbox/Received";
import Sent from "./Inbox/Sent";
import Drafts from "./Inbox/Drafts";
import Settings from "../../shared/config/Settings";
import MessageView from "../components/MessageView";
import getUserId from "../../shared/core/GetToken";
import {
  clearSetItemMessage,
  setItemMessage,
} from "../../shared/actions/SetMessageItemAction";
import {
  capitalize,
  GetAppointmentDate,
  GetMonthName,
  GetYear,
} from "../../shared/core/datehelper";
import { getInitials } from "../../shared/core/utils";
import {
  msgClenuplatData,
  sendNewMessagetoAPI,
} from "../../shared/actions/NewMessageActions";
import GetAppSettings from "../../shared/core/GetAppSettings";
const { TabPane } = Tabs;

const ReadMessage = (props) => {
  let history = useHistory();
  const [isloaded, setIsload] = React.useState(false);
  const [show, setShow] = useState(false);
  const [showPDF, setShowPDF] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [myfile, setmyfile] = useState(false);
  const [fileType, setFileTpye] = useState("");
  const [fileConent, setFileContent] = useState("");
  const [files, setFiles] = useState([]);
  // const [filecontent, setFilecontent] = useState('')
  const [senderName, setSenderName] = useState("");
  const [newMsgModal, setNewMsgModal] = useState(false);

  const [centerName, setCenterName] = useState("");
  const [centerRef, setCenterRef] = useState("");
  const [centersArray, setCentersArray] = useState([]);
  const [providerArray, setProviderArray] = useState([]);
  const [resourceType, setResourceType] = useState("");
  const [recpName, setRecpName] = useState("");
  const [subject, setSubject] = useState("");

  const [message, setMessage] = useState("");
  const [shown, setShown] = useState(false);
  const [testUrl, setTestUrl] = useState();

  const [fileSize, setFileSize] = useState("");
  const [portalSetting, setPortalSetting] = useState(null);
  // const [fileType, setfileType] = useState('');
  const [fileBase, setFileBase] = useState("");
  const [filesList, setFilesList] = useState([]);
  const { SendMessage, isSending, isSendMessageSuccess, error } =
    props.SendMessage;
  const [successMessage, setSuccessMessage] = useState(
    "Your Message has been sent Successfully"
  );
  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Message Sent",
      description: successMessage,
    });
  };
  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://indici-pre-production.web.app/assets/js/template.js";
    document.body.appendChild(script);
    // d.div.appendChild(script);
  }, []);

  React.useEffect(() => {
    // \

    GetAppSettings().then((info) => {
      const set = JSON.parse(info);
      setPortalSetting(set);
    });
    if (props.location.state.id != undefined) {
      // alert( props.location.state.id)
      getMessageById(props.location.state.id);
    } else {
      //   alert("Teri merbani")
      history.push("/emails");
    }
  }, []);

  const getMessageById = (parms) => {
    setIsLoading(true);
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      const queryString = "?PatientID=" + item.userid + "&MessageID=" + parms;
      //console.log(queryString)
      fetch(
        `${Settings.apiUrl}` + "Patient/MessageByID" + queryString,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          setIsLoading(false);
          let mydata = JSON.parse(result);
          //console.log("DATA FOUND: " + mydata)

          //console.log("The data===>>>" + mydata[0].entry[0].resource.extension[0].valueString)

          setSenderName(mydata[0].entry[0].resource.sender.display);
          // document.getElementById('userImage').innerHTML = '<img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />'
          document.getElementById("senderName").innerHTML = capitalize(
            mydata[0].entry[0].resource.sender.display
          );
          //  document.getElementById('centerName').innerHTML = mydata[0].entry[0].resource.extension[7].valueReference.display
          document.getElementById("messageDate").innerHTML =
            GetAppointmentDate(mydata[0].entry[0].resource.sent) +
            " " +
            GetMonthName(mydata[0].entry[0].resource.sent) +
            " " +
            GetYear(mydata[0].entry[0].resource.sent);
          document.getElementById("messageDetails").innerHTML =
            mydata[0].entry[0].resource.extension[0].valueString;

          //    alert(mydata[0].entry[0].resource.extension[7].valueReference.display)
          setCenterName("bluePod");
          setCenterRef("42");
          // alert(mydata[0].entry[0].resource.sender.display)
          setRecpName(mydata[0].entry[0].resource.sender.display);
          setResourceType(mydata[0].entry[0].resource.sender.reference);
          setSubject("Re:" + mydata[0].entry[0].resource.note[0].text);
          if (props.screen === "Received") {
            //only for reply purpose

            document.getElementById("center_ref").value =
              mydata[0].entry[0].resource.extension[7].valueReference.reference;
            document.getElementById("provider_ref").value =
              mydata[0].entry[0].resource.recipient[0].reference;
            document.getElementById("mCenter").value =
              mydata[0].entry[0].resource.extension[7].valueReference.display;
            document.getElementById("provider_name").value =
              mydata[0].entry[0].resource.extension[3].valueString;
            //  document.getElementById('message').value = mydata[0].entry[0].resource.extension[0].valueString
            document.getElementById("subject").value =
              mydata[0].entry[0].resource.note[0].text;
            //only for reply end
          }

          if (mydata[0].entry[0].resource.hasOwnProperty("payload")) {
            setFiles(mydata[0].entry[0].resource.payload);
            // setFilesArray(mydata[0].entry[0].resource.payload)
            //console.log("The file Type is" + mydata[0].entry[0].resource.payload[0].contentAttachment.contentType)
            // setFilesArray(mydata[0].entry[0].resource.payload)
            let filesArray = mydata[0].entry[0].resource.payload;
            filesArray.map((value, index) => {
              //console.log(value.contentAttachment.contentType)
            });

            setFileTpye(
              mydata[0].entry[0].resource.payload[0].contentAttachment
                .contentType
            );
            setFileTpye((state) => {
              return state;
            });

            if (
              mydata[0].entry[0].resource.payload[0].contentAttachment
                .contentType === "PDF"
            ) {
              setmyfile(true);
              setmyfile((state) => {
                return state;
              });

              //document.getElementById("messagePDF").removeChild('object')
              //     var obj = document.createElement('object');
              //     obj.style.width = '100%';
              //     obj.style.height = '550px';
              //     obj.type = 'application/pdf';
              //     obj.data = 'data:application/pdf;base64,' + mydata[0].entry[0].resource.payload[0].contentAttachment.data;
              //     //
              //     //console.log({ obj })
              //  //   setFilecontent(obj)
              //         document.getElementById("messagePDF").appendChild(obj)
              //     return
            } else {
              //console.log("Image")
              setmyfile(true);
              setmyfile((state) => {
                return state;
              });
              setFileContent(
                "data:image/" +
                mydata[0].entry[0].resource.payload[0].contentAttachment
                  .contentType +
                ";base64, " +
                mydata[0].entry[0].resource.payload[0].contentAttachment.data
              );
              setFileContent((state) => {
                return state;
              });
            }
          } else {
            setmyfile(false);
            setIsLoading(false);
            setmyfile((state) => {
              return state;
            });
            //console.log("File Not Found")
          }
        })
        .catch((err) => {
          //console.log(err)
          setIsLoading(false);
          setmyfile(false);
          setmyfile((state) => {
            return state;
          });
        });
    });
  };
  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr(
      "data:application/pdf;base64,".length
    );

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };

  const displayFile = (item) => {
    document.getElementById("messagePDF");
    console.log("File:" + item.contentAttachment.contentType);
    if (item.contentAttachment.contentType == "PDF") {
      setShowPDF(true);
      // setShown(true)

      // `base64String` is the given base 64 data

      const blob = base64toBlob(
        "data:application/pdf;base64," + item.contentAttachment.data
      );
      const url = URL.createObjectURL(blob);

      setTestUrl(url);

      //document.getElementById("messagePDF").removeChild('object')
      var obj = document.createElement("object");
      obj.style.width = "100%";
      obj.style.height = "550px";
      obj.type = "application/pdf";
      obj.data = "data:application/pdf;base64," + item.contentAttachment.data;

      //console.log({ obj })
      //   setFilecontent(obj)
      var test = document.querySelector("#messagePDF");
      if (test != null) {
        // test.appendChild(obj)
        // document.getElementById("messagePDF").appendChild(obj)
      }
    } else {
      //contentAttachment.contentType
      setFileContent(
        "data:image/" +
        item.contentAttachment.contentType +
        ";base64, " +
        item.contentAttachment.data
      );
      setFileContent((state) => {
        return state;
      });
      setShow(true);
    }
  };

  const sendMessage = () => {
    // if (centersArray.length === 0) {
    //     document.getElementById('error').innerHTML = 'Please select Center';
    //     alert("Please select Center");
    //     return
    // }

    if (resourceType === "") {
      document.getElementById("errorProvider").innerHTML =
        "Please select Provider";
      alert("Please select Provider");
      return;
    }

    let sub = document.getElementById("userSubject").value;
    if (sub === "") {
      document.getElementById("errorSub").innerHTML = "Please enter subject";
      alert("Please select subject");
      return;
    }

    let msg = document.getElementById("userMsg").value;
    if (msg === "") {
      document.getElementById("errorMsg").innerHTML =
        "Please type your message";
      alert("Please select message");
      return;
    }
    //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

    props.sendData(
      null,
      centerName,
      centerRef,
      sub,
      msg,
      fileType,
      fileBase,
      filesList,
      resourceType,
      recpName,
      "Send Mail"
    );
  };
  const checkFile = (file) => {
    if (file) {
      for (var i = 0; i < file.length; i++) {
        setFileSize(file[i].size);
        setFileTpye(file[i].name.split(".").pop());
        setFileBase(file[i].base64);
        let ob = {
          type: file[i].name.split(".").pop(),
          size: file[i].size.split(" ")[0] * 1024,
          base64: file[i].base64.split(",").pop(), //reader.result.split(',').pop(),
        };
        filesList.push(ob);
      }
      //console.log({ filesList })
    }
  };
  React.useEffect(() => {
    if (isSendMessageSuccess) {
      setNewMsgModal(false);
      props.cleandata();
      openNotificationWithIcon("success");
    }
  }, [isSendMessageSuccess]);
  const replyMessage = () => {
    // alert(centerName)
    setNewMsgModal(true);
  };
  ///
  return (
    <div>
      <Header />
      <div id="myphonemenu" className="page-body">
        <Sidebar />
        <div className="page-content-wrapper">
          <div className="page-content-wrapper-inner">
            <div className="viewport-header">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb has-arrow">
                  <li className="breadcrumb-item">
                    {/* <a href=>Dashboard</a> */}
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to="/emails">Inbox</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Read Message
                  </li>
                </ol>
              </nav>
            </div>
            <div className="content-viewport">
              {/* {
                                isSendMessageSuccess && <Alert
                                    message="Success"
                                    description={successMessage}
                                    type="success"
                                    showIcon
                                />

                            } */}

              <div className="email-wrapper grid">
                <div>
                  {isLoading ? (
                    <View style={{ textAlign: "center", marginTop: 5 }}>
                      <ActivityIndicator size="small" color="#00A1DE" />
                    </View>
                  ) : (
                    <div className="email-preview-wrapper">
                      <div className="preview-header">
                        {/* <img className="profile-img" src="../../../assets/images/profile/male/image_1.png" alt="profile image" /> */}
                        <View style={styles.personNameCircle}>
                          <View
                            style={{
                              backgroundColor: "blue",
                              height: 40,
                              width: 40,
                              borderRadius: 40,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text style={styles.personNameText}>
                              {getInitials(senderName)}
                            </Text>
                          </View>
                        </View>
                        <p id="senderName" className="user_name"></p>
                        <p className="user-email" style={{ color: '#8d8d8d' }}>Subject: {subject}</p>
                        {portalSetting &&
                          portalSetting.reply &&
                          props.location.state.reply && (
                            <button
                              onClick={() => {
                                replyMessage();
                              }}
                              className="btn btn-white btn-xs component-flat p-0"
                            >
                              <i className="mdi mdi-reply" />
                            </button>
                          )}
                        <p id="messageDate" className="date"></p>
                      </div>
                      <div className="email-container">
                        <div className="email-content">
                          {/* <p><b>Hello John,</b></p> */}
                          <p
                            style={{ whiteSpace: 'pre-line' , lineHeight: '15px' }}
                            id="messageDetails"
                          ></p>
                          {/*
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Distinctio expedita ea fugit provident aliquid natus eveniet nemo nobis nihil exercitationem accusamus dolores id, corrupti quaerat, sapiente alias ipsum. Autem vel nulla harum error, dolore nemo quis quod? Alias, eaque repudiandae officia error consectetur tenetur pariatur, nemo numquam non modi tempore.</p>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio ipsum neque quidem quam architecto quas dolorum commodi nemo minus nihil.</p>
                                                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Consectetur soluta accusamus consequatur voluptas temporibus placeat animi ipsum asperiores quas rerum nam amet est vero perspiciatis, facere similique, quis odit porro dignissimos sequi tempore ipsa doloribus. Obcaecati fuga accusamus nam magni.</p>
                                                 */}
                        </div>
                        <div className="email-attachments">
                          {files && files.length ? <p>Attachments</p> : null}

                          <div className="attachment-wrapper">
                            {files && files.length
                              ? files.map((item, key) => {
                                return (
                                  <div
                                    onClick={() => displayFile(item)}
                                    className={item.contentAttachment.contentType == "PDF"
                                      ? "attachment attachment-warning"
                                      : "attachment attachment-primary"
                                    }>

                                    <p>
                                      Document.
                                      {item.contentAttachment.contentType}
                                    </p>
                                    <span>Attachment {key + 1}</span>
                                    <div className="icon-wrapper">
                                      {item.contentAttachment.contentType ==
                                        "PDF" ? (
                                        <i className="mdi mdi mdi-file-pdf" />
                                      ) : (
                                        <i className="mdi mdi mdi-file-image" />
                                      )}
                                    </div>
                                    {/* </TouchableOpacity> */}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>

                        {/* <div className="email-attachments">
                                                        <p>Attachments</p>
                                                        <div className="attachment-wrapper">
                                                            <div className="attachment attachment-danger">
                                                                <p>Document.pdf</p><span>17.35mb</span>
                                                                <div className="icon-wrapper"><i className="mdi mdi mdi-file-pdf" /></div>
                                                            </div>
                                                            <div className="attachment attachment-primary">
                                                                <p>Project.docx</p><span>03.15mb</span>
                                                                <div className="icon-wrapper"><i className="mdi mdi-file-outline" /></div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                        {/* <div className="email-reply">
                                                        <p>Reply</p>
                                                        <div className="btn-group email-actions mr-n0 mr-md-n4" data-toggle="buttons">
                                                            <button className="btn btn-white btn-xs component-flat p-0"><i className="mdi mdi-reply" /></button>
                                                            <button className="btn btn-white btn-xs component-flat p-0"><i className="mdi mdi-reply-all" /></button>
                                                            <button className="btn btn-white btn-xs component-flat p-0"><i className="mdi mdi-forward" /></button>
                                                            <button className="btn btn-white btn-xs component-flat p-0"><i className="mdi mdi-delete" /></button>
                                                        </div>
                                                        <div className="textarea-wrapper">
                                                            <form action="#">
                                                                <textarea className="form-control" rows={5} defaultValue={""} />
                                                                <button className="btn btn-primary" type="submit"><i className="mdi mdi-send" /></button>
                                                            </form>
                                                        </div>
                                                    </div> */}
                      </div>
                    </div>
                  )}

                  <Modal
                    title="PDF File"
                    centered
                    visible={showPDF}
                    onOk={() => setShowPDF(false)}
                    onCancel={() => setShowPDF(false)}
                    afterClose={() =>
                      (document.getElementById("messagePDF").innerHTML = "")
                    }
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                  >
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.3)",
                        height: "750px",
                      }}
                    >
                      <Viewer fileUrl={testUrl} />
                    </div>

                    <div className="PDF" id="messagePDF"></div>
                  </Modal>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer"></footer>
        </div>
      </div>
      <Modal
        title="File Content"
        centered
        visible={show}
        onOk={() => setShow(false)}
        onCancel={() => setShow(false)}
        width={1000}
      >
        <img
          style={{ height: 450 }}
          class="img-responsive"
          src={fileConent}
          alt="Red dot"
        />
      </Modal>

      <Modal
        title="Reply Message"
        centered
        visible={newMsgModal}
        // onOk={() => {
        //     setNewMsgModal(false);

        // }}loading={loading}
        // onCancel={() => setNewMsgModal(false)}
        style={{ top: 10, bottom: 90, overflow: "hidden" }}
        width={1000}
        footer={[
          <Button key="back" onClick={() => setNewMsgModal(false)}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isSending}
            onClick={() => sendMessage()}
          >
            Send
          </Button>,
          // <Button
          //     key="link"
          //     href="https://google.com"
          //     type="primary"
          //     // loading={loading}
          //     onClick={()=>alert("hello")}
          // >
          //     Search
          // </Button>,
        ]}
      >
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div style={{}} className="form-group row mb-0">
              <div className="col-md-12">
                <div
                  className="form-group input-recipient"
                  style={{ paddingBottom: 4 }}
                >
                  <label className="label" style={{ width: 68 }}>
                    Provider
                  </label>
                  <div class="showcase_content_area">
                    <input
                      type="text"
                      value={recpName}
                      className="form-control "
                      placeholder="Subject"
                      id="mProvider"
                      onClick={() =>
                        (document.getElementById("errorSub").innerHTML = "")
                      }
                    />
                    {/* <Select
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder="Select a person"
                                            optionFilterProp="children"
                                            onChange={onChangeProvider}
                                            onFocus={onFocusProvider}
                                            onBlur={onBlurProvider}
                                            value={selected}


                                        >
                                            {providerArray !== undefined && providerArray.map((item, index) => <Select.Option key={index}>{item.resource.name[0].text}</Select.Option>)}

                                        </Select> */}
                    <p id="errorProvider" style={{ color: "red" }}></p>
                  </div>
                </div>
              </div>
            </div>

            <div style={{}} className="form-group row mb-0">
              <div className="form-group col-md-12">
                <label className="label" style={{ width: 68 }}>
                  Subject
                </label>
                <input
                  type="text"
                  className="form-control "
                  value={subject}
                  placeholder="Subject"
                  id="userSubject"
                  editable="false"
                  onClick={() =>
                    (document.getElementById("errorSub").innerHTML = "")
                  }
                />
                <p id="errorSub" style={{ color: "red" }}></p>
              </div>
            </div>
            <div style={{}} className="form-group row mb-0">
              <div className="form-group col-md-12">
                <textarea
                  id="userMsg"
                  className="form-control"
                  rows={8}
                  placeholder="Write your message here"
                  defaultValue={""}
                  onClick={() =>
                    (document.getElementById("errorMsg").innerHTML = "")
                  }
                />
                <p id="errorMsg" style={{ color: "red" }}></p>
              </div>
            </div>
            <FileBase64
              className="action-buttons"
              multiple={true}
              onDone={(files) => checkFile(files)}
            />
            <div
              style={{ alignItems: "flex-end", backgroundColor: "red" }}
              className="form-group row mb-0"
            ></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const styles = StyleSheet.create({
  textDetails: {
    textAlign: "justify",
    color: "#95989A",
    fontSize: 15,
  },
  textDetailsInner: {
    textAlign: "justify",
    color: "#333333",
    fontSize: 14,
  },
  personNameCircle: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 2,
    paddingTop: 15,
  },
  personNameText: {
    color: "#fff",
    fontSize: 14,
    // fontWeight:'bold'
  },
});

function mapStateToProps(state) {
  return {
    // SetMessageItem: state.SetMessageItem,
    SendMessage: state.SendMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendData: (
      draftMessageId,
      centerName,
      centerReference,
      subject,
      emailText,
      fileType,
      fileBase,
      fileSize,
      resourceType,
      msgRecp,
      msgType
    ) =>
      dispatch(
        sendNewMessagetoAPI(
          draftMessageId,
          centerName,
          centerReference,
          subject,
          emailText,
          fileType,
          fileBase,
          fileSize,
          resourceType,
          msgRecp,
          msgType
        )
      ),

    cleandata: () => dispatch(msgClenuplatData()), //  setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReadMessage);

import { START_LETTERS, START_LETTERS_SUCCESS, START_LETTERS_MORE_SUCCESS, SEARCH_START_LETTERS_SUCCESS, START_LETTERS_FAILED, START_LETTERS_FINISHIED } from '../constants/Constant'


//SET INITAILS STATE
const initialState = {
    Letters: [],
    isGettingLetters: false,
    errorLetters: false
}


export default function lettersReducer(state = initialState, action) {
    switch (action.type) {

        case START_LETTERS:
            return {
                ...state,
                isGettingLetters: true
            }

        case START_LETTERS_SUCCESS:
            return {
                ...state,
                isGettingLetters: false,
                Letters: action.data
            }

        case START_LETTERS_MORE_SUCCESS:
            return {
                ...state,
                isGettingLetters: false,
                Letters: [...state.Letters, ...action.data]

            }

        case SEARCH_START_LETTERS_SUCCESS:
            return {
                ...state,
                isGettingLetters: false,
                Letters: action.data
            }

        case START_LETTERS_FAILED:
            return {
                ...state,
                isGettingLetters: false,
                errorLetters: true
            }

        case START_LETTERS_FINISHIED:
            return {
                ...state,
                isGettingLetters: false,
                Letters: [],
                errorLetters: false
            }

        default:
            return state
    }
}

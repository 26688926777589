import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, Text, View, Image, Dimensions, ActivityIndicator } from 'react-native';
import { Modal, Button } from 'antd';
import $ from "jquery";
//import { Modal, Button } from "react-bootstrap";
import 'antd/dist/antd.css';

import './css/watermark.css'
import getUserId from "../../shared/core/GetToken"
import Iframe from './Iframe';
const LetterHandler = props => {

    const [visible, setVisible] = useState(false);
    const [pdfVisible, setPdfVisible] = useState(false);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [checkFile, setCheckFile] = useState(false);


    const [dimension, setDimension] = useState(Dimensions.get('window'));




    const [username, setusername] = useState('');

    const [htmlLetter, setHtmlLetter] = useState('');
    const [pdfLetter, setPdfLetter] = useState('');


    const [show, setShow] = React.useState(false)
    //const [checkFile, setCheckFile] = useState(false);

    const [file, setFile] = React.useState()


    // const [visible, setVisible] = useState(false);
    // const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    const displayLetter = letterId => {
        //alert(letterId)
        setIsLoadingPdf(true);
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            setusername(item.username)
            fetch("https://nhldevconnect.indici.ie/api/Patient/LetterAndDocumentByID?Token=" + item.token + "&PatientID=" + item.userid + "&ResultID=" + letterId, requestOptions)
                .then(data => data.json())
                .then(json => {
                    // //console.log(JSON.stringify(json))

                    if (json[0].entry[0].resource.content[0].attachment.contentType == "PDF") {

                        setPdfVisible(true)

                        setFile('data:application/pdf;base64,' + json[0].entry[0].resource.content[0].attachment.data)
                        setFile((state) => {
                            return state;
                        });
                        // //console.log('Hello:', json[0].entry[0].resource.resourceType)
                        //alert(json[0].entry[0].resource.resourceType);
                        var b64 = json[0].entry[0].resource.content[0].attachment.data;
                        // var bin = atob(b64);


                        // // Embed the PDF into the HTML page and show it to the user
                        var obj = document.createElement('object');
                        obj.style.width = '100%';
                        obj.style.height = '550px';
                        obj.type = 'application/pdf';
                        obj.data = 'data:application/pdf;base64,' + b64;
                        //document.body.appendChild(obj);
                        document.getElementById("showPDF").appendChild(obj)
                        setIsLoadingPdf(false);


                        return;
                    } else {
                        var decodedHTML = atob(json[0].entry[0].resource.content[0].attachment.data)
                        // //console.log("NewVaritan" + decodedHTML)
                        setVisible(true)
                        setHtmlLetter(decodedHTML)
                        setHtmlLetter((state) => {
                            return state;
                        });
                        //document.getElementById("showPDFLetters").innerHTML = decodedHTML

                        setIsLoadingPdf(false);
                    }

                })
                .catch(error => {console.log('error', error)})
                .finally(() => {
                    setCheckFile(false)
                    setIsLoadingPdf(false)

                });
        });
    }

    useEffect(() => {

        const onDimensionsChange = ({ window }) => {
            let wrapperWidth = $('.grid-items-wrapper').width();
            let maxCards = Number((wrapperWidth / 210).toFixed(0));
            let cardWidth = (100 / maxCards).toFixed(2);
            $('.drive-item').css('width', 'calc(' + cardWidth + '% - 30px)');
        }

        Dimensions.addEventListener('change', onDimensionsChange)

        return () => Dimensions.removeEventListener('change', onDimensionsChange)

    }, [])

    // function printDoc() {



    // }

    // function handlePrint() {



    //     //var divContents = document.getElementById("showPDFLetters").innerHTML;
    //     //alert(divContents)
    //     var a = window.open();
    //     // a.document.write('<html>');
    //     // a.document.write('<body > <h1>Div contents are <br>');
    //     a.document.write(htmlLetter);
    //     // a.document.write('</body></html>');
    //     a.document.close();
    //     //a.print();
    //     //  a.close();
    //     //setTimeout(a.close, 0);

    //     setTimeout(function () { a.print(); }, 500);
    //     a.onfocus = function () { setTimeout(function () { a.close(); }, 500); }
    // }



    function handlePrint() {


        var bodyHtml = /<body.*?>([\s\S]*)<\/body>/.exec(htmlLetter)[1];
        //  //console.log(bodyHtml)
        //alert(bodyHtml);

        var a = window.open();
        a.document.write('<html>');

        a.document.write('<head>');
        a.document.write('<link href="https://devnhl.indici.ie/Content/style.css" rel="stylesheet"> <link href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" rel="stylesheet" type="text/css">  <link href="https://devnhl.indici.ie/Content/CustomCss/MailMerge/MailMergePrint.css" rel="stylesheet"> <script src="https://devnhl.indici.ie/Scripts/jquery-2.1.4.min.js"></script>');
        a.document.write('<style>.watermark{height:100%;display:block;position:relative}.watermark::after{content:"";background:url(https://banner2.cleanpng.com/20180512/oxe/kisspng-paper-rubber-stamp-stock-photography-royalty-free-5af72d03738f71.0212011215261483554733.jpg) no-repeat;opacity:0.2;top:0;left:0;bottom:0;right:0;position:absolute;z-index:-1} @media print{*{-webkit-print-color-adjust:exact}} *{-webkit-print-color-adjust: exact !important; color-adjust: exact !important;}@media screen{div.divFooter{display: none;}}@media print{div.divFooter{position: fixed; bottom: 0;}}</style>')
        a.document.write('</head>');

        a.document.write('<body>');

        a.document.write('<div class="watermark"><div class="col-md-12">' + bodyHtml + '</div>')
        a.document.write('<div class="divFooter"><p id="hello"></p></div></div>');
        a.document.write('<div class="divFooter">' + username + '</div></div>');


        //a.document.write(htmlLetter);


        a.document.write('<script>var currentdate=new Date();var datetime="Printed: " + currentdate.getDate() + "/" + (currentdate.getMonth()+1) + "/" + currentdate.getFullYear() + " @ " + currentdate.getHours() + ":" + currentdate.getMinutes() + ":" + currentdate.getSeconds();document.getElementById("hello").innerHTML=datetime;</script>')
        a.document.write('</body></html>');




        a.document.close();


        setTimeout(function () { a.print(); }, 500);
        a.onfocus = function () { setTimeout(function () { a.close(); }, 500); }
    }

    //print configrations
    const componentRef = useRef();
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });

    return (
        <div className="drive-item module text-center">
            <div className="drive-item-inner module-inner">
                <div className="drive-item-title">
                    <a href="#">{props.letterDetail}</a>
                    <p><small>Added: {props.letterDate}</small></p>
                </div>
                <div className="drive-item-thumb">
                    <a href="#"><i className="fa fa-file-pdf-o text-danger" /></a>
                    {isLoadingPdf && <View style={{ marginTop: 10 }}><ActivityIndicator size="small" color="#00A1DE" /></View>}
                </div>
            </div>
            <div className="drive-item-footer module-footer">
                <ul className="utilities list-inline">
                    <li><a href="#" onClick={() => displayLetter(props.letterId)} data-toggle="tooltip" data-placement="top" title data-original-title="Download">View </a></li>
                </ul>
            </div>


            {/* {
                visible && <Iframe content={htmlLetter} />
            } */}
            <Modal
                title="Letter Details"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                // afterClose={() => document.getElementById("showPDFLetters").innerHTML = ""}
                //style={{ top: 10, bottom: 90, paddingBottom: 30 }}
                width={1000}
                footer={[
                    <Button key="back" onClick={() => setVisible(false)}>
                        Close
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => handlePrint()}>
                        Print
                    </Button>
                ]}
            >
                <div style={{ height: '100%', width: '100%' }}>
                    <div ref={componentRef} style={{ padding: 30, marginRight: 25 }} className="PDF" id="showPDFLetters">
                        {/* <OuterHtml commingHtml={htmlLetter} /> */}
                        <div className="watermark">
                            <Iframe printRef={componentRef} content={htmlLetter} />
                        </div>

                    </div>
                </div >

            </Modal >


            <Modal
                title="PDF File"
                centered
                visible={pdfVisible}
                onOk={() => setPdfVisible(false)}
                onCancel={() => setPdfVisible(false)}
                afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
            >
                <div className="PDF" id="showPDF">
                </div>
                <p id="hee">
                    {isLoadingPdf && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>}
                </p>
            </Modal>




        </div>
    );
}
export default LetterHandler

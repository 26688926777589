// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';


const getUserId = async () => {
    // let userId = '';
    // try {
    //     userId = await AsyncStorage.getItem('secureToken') || 'none';

    // } catch (error) {
    //     // Error retrieving data
    //     //console.log(error.message);
    // }
    // ////console.log(userId)
    // return userId;


    let userId = '';
    try {
        userId = await AsyncStorage.getItem('@userToken') || 'none';

    } catch (error) {
        // Error retrieving data
        //console.log(error.message);
    }
    ////console.log(userId)
    return userId;
}

export default getUserId
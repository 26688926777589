import Settings from "../config/Settings";
import { GET_PROVIDER_LOCATIONS, GET_PROVIDER_LOCATIONS_SUCCESS, GET_PROVIDER_LOCATIONS_FAILED, } from "../constants/Constant";

import getUserId from "../core/GetToken";
import getAllRoles from "../services/RolesService";

export const fetchProviderLocationFromApi = () => {
    return async (dispatch) => {

        dispatch(getProviderLocation())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(Settings.apiUrl + "/IndiciLookup?TypeID=11&PatientID=" + item.userid, requestOptions)
                .then(response => response.json())
                .then(json => {
                    let arrayofProviderLocation = []
                    let listofids=[]
                    let namesLocation= []
                    if (json[0].hasOwnProperty('entry'))
                        json[0].entry.map((item, index) => {
                            let ob = {
                                id: item.resource.extension[0].valueString,
                                title: item.resource.extension[1].valueString
                            }
                            let obj = {
                                id:item.resource.extension[0].valueString,
                                colorcode: getRandomColor(),
                                name:item.resource.extension[1].valueString
                            }
                            listofids.push(item.resource.extension[0].valueString)
                            arrayofProviderLocation.push(obj)
                            namesLocation.push(item.resource.extension[0].valueString)
                        })

                    dispatch(getProviderLocationSuccess(json[0].entry,listofids,arrayofProviderLocation))

                })
                .catch(error => getProviderLocationFailed());

        })

    }
}


const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const getProviderLocation = () => {
    return {
        type: GET_PROVIDER_LOCATIONS
    }
}

//load data
const getProviderLocationSuccess = (data, locations,arrayofProviderLocation) => {
    return {
        type: GET_PROVIDER_LOCATIONS_SUCCESS,
        data,
        locations,
        arrayofProviderLocation
    }
}


//data failure
const getProviderLocationFailed = () => {
    return {
        type: GET_PROVIDER_LOCATIONS_FAILED,
    }
}





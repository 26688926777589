import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, Button, StyleSheet, StatusBar, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";
import { FormatAMPM, GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchSentMessageFromApi } from "../../../shared/actions/SentMessageActions";
import { clearSetItemMessage, setItemMessage } from '../../../shared/actions/SetMessageItemAction';
import MessageItem from "../../components/MessageItem";
import { getInitials } from '../../../shared/core/utils';
import { Link } from "react-router-dom";

const Sent = props => {

    const { SentItems, isGetting } = props.SentItems;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    useEffect(() => {
        props.getsSentMessage(pageSize, pageNumber)
    }, [])


    const getMessageId = params => {
        //console.log("for Hassan====>  " + params)
        props.setMessageItem(params, 'Sent')
    }


    return (
        <>
            {
                isGetting && <View style={{ textAlign: 'center', marginTop: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (SentItems) !== 'undefined' && SentItems.length ? (
                    <FlatList

                        data={SentItems}
                        renderItem={({ item }) => (
                            <Link to={{
                                pathname: '/read',
                                state: { "id": item.resource.id, "reply": false }
                            }}>
                                <MessageItem
                                    initails={getInitials(item.resource.extension[2].valueString)}
                                    messageDate={GetAppointmentDate(item.resource.extension[6].valueDateTime) + ' ' + GetMonthName(item.resource.extension[6].valueDateTime) + " " + GetYear(item.resource.extension[6].valueDateTime)}
                                    messageTime={FormatAMPM(item.resource.extension[6].valueDateTime.split('+')[0]) }
                                    sender={item.resource.extension[3].valueString}
                                    messageSubject={item.resource.note[0].text}
                                    messageDescription={item.resource.extension[0].valueString}
                                />
                            </Link>
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        windowSize={7}

                    />

                ) : !isGetting && <View style={{
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>
            }
        </>
    );
}


function mapStateToProps(state) {
    return {
        SentItems: state.SentItems,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        getsSentMessage: (pageSize, pageNumber) => dispatch(fetchSentMessageFromApi(pageSize, pageNumber)),
        setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sent);

// export default Received

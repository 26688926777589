import React, { useState } from 'react';
import { ActivityIndicator, View } from "react-native-web";
import { connect } from 'react-redux'
import { Link } from "react-router-dom";

import { Tabs } from "antd";
import { Select } from 'antd';
import { Alert } from 'antd';
import 'antd/dist/antd.css';

import { Modal, Button } from 'antd';
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Received from './Inbox/Received';
import Sent from './Inbox/Sent';
import Drafts from './Inbox/Drafts';
import MessageView from "../components/MessageView";
import FileBase64 from 'react-file-base64';
import getUserId from '../../shared/core/GetToken';
import { msgClenuplatData, sendNewMessagetoAPI } from '../../shared/actions/NewMessageActions';
import { clearSetItemMessage, setItemMessage } from '../../shared/actions/SetMessageItemAction';
import Settings from '../../shared/config/Settings';
import { _ } from 'underscore'
import { checkToken } from '../../shared/actions/TokenValidActions';
const useHistory = require("react-router-dom").useHistory;
const { TabPane } = Tabs;
const { Option } = Select;
const Messaing = props => {

    const history = useHistory()

    const [centersArray, setCentersArray] = useState([]);
    const [providerArray, setProviderArray] = useState([]);


    //sending items
    const [centerName, setCenterName] = useState('');
    const [centerRef, setCenterRef] = useState('');

    const [resourceType, setResourceType] = useState('');
    const [recpName, setRecpName] = useState('');



    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');


    const [fileSize, setFileSize] = useState('');
    const [fileType, setfileType] = useState('');
    const [fileBase, setFileBase] = useState('');
    const [newMsgModal, setNewMsgModal] = useState(false);
    const { SendMessage, isSending, isSendMessageSuccess, error } = props.SendMessage

    const [filesList, setFilesList] = useState([]);
    const [isReady, setIsReady] = useState([])

    const { Item, screenName } = props.SetMessageItem;


    const [selected, setSelected] = useState()

    const clearSelected = () => {
        // this line will clear the select
        // when you click on the button
        setSelected(null)
    }


    const [isloaded, setIsload] = React.useState(false);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])

    React.useEffect(() => {
        props.tokenVal(history);
    }, [])

    function onChange(value) {
        setResourceType('')
        clearSelected();

        //console.log(centersArray[value]);
        let id = centersArray[value].resource.extension[0].valueString;
        // let resourceType = centersArray[value].resource.resourceType;
        setCenterRef(id);
        // setCenterName(centersArray[value].resource.name)
        CallProviderList(id)
    }

    function onBlur() {
        //console.log('blur');
    }

    function onFocus() {
        CallcenterList()
        //console.log('focus');
    }



    function onChangeProvider(value) {
        setSelected(providerArray[value].resource.name[0].text)
        document.getElementById('errorProvider').innerHTML = ''
        setRecpName(providerArray[value].resource.name[0].text);
        setResourceType(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);


        //console.log(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);
        //console.log(providerArray[value].resource.name[0].text)
    }

    function onBlurProvider() {
        //console.log('blur');
    }

    function onFocusProvider() {
        //CallcenterList()
        //console.log('focus provider');
    }


    const CallcenterList = () => {
        document.getElementById('error').innerHTML = ''
        setProviderArray([]);
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?PatientID=' + item.userid + '&TypeID=1';
            //console.log(queryString)
            //console.log(item)
            fetch(Settings.apiUrl + "Patient/LoadRoles" + queryString, requestOptions)
                // .then(response => response.text())
                // .then(result => //console.log(result))
                // .catch(error => //console.log('error', error));
                // fetch(Settings.apiUrl + "Patient/Location" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    setCentersArray(json[0].entry)
                })
                .catch(err => {
                    //console.log(err)
                })
        })
    }


    const CallProviderList = (index) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = "?PatientID=" + item.userid + "&RoleID=" + index + "&CallType=0";
            // const queryString = '?PatientID=' + item.userid + '&PracticeLocationID=' + index;
            //console.log(queryString)
            fetch(Settings.apiUrl + "Patient/MessagingRecipients" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log(json[0].entry)
                    setProviderArray(json[0].entry);
                })
                .catch(err => {
                    //console.log(err)
                })
        })

    }


    const checkFile = (file) => {



        //alert('There is a file')
        alert(file)

        if (file) {
            for (var i = 0; i < file.length; i++) {
                setFileSize(file[i].size);
                setfileType(file[i].name.split('.').pop())
                setFileBase(file[i].base64)
                let ob = {
                    type: file[i].name.split('.').pop(),
                    size: file[i].size.split(' ')[0] * 1024,
                    base64: file[i].base64.split(',').pop(), //reader.result.split(',').pop(),
                };
                filesList.push(ob);
            }
            //console.log({ filesList })

        }

        // let file = document.getElementById("myFile").files;
        // if (file) {


        //     for (var i = 0; i < file.length; i++) {
        //         setFileSize(file[i].size);
        //         setfileType(file[i].name.split('.').pop())
        //         var reader = new FileReader();
        //         //console.log('file[i]: ', file[i]);
        //         // reader.readAsDataURL(file);
        //         reader.readAsDataURL(file[i]);
        //         reader.onload = function () {
        //             //console.log('result[' + i + ']: ', reader.result);
        //             //   setFileBase(reader.result.split(',').pop())
        //             //     let ob = {
        //             //         // type: file[i].name.split('.').pop(),
        //             //         // size: file[i].size,
        //             //        base64: reader.result.split(',').pop(),
        //             //     };
        //             //     filesList.push(ob);
        //         }
        //         reader.onerror = function (error) {
        //             //console.log('Error: ', error);
        //         };


        //     }
        //     //     //getting base64
        //     //     var reader = new FileReader();
        //     // reader.readAsDataURL(file);
        //     // reader.onload = function () {
        //     //     setFileBase(reader.result.split(',').pop())
        //     //     // let ob = {
        //     //     //     type: file.name.split('.').pop(),
        //     //     //     size: file.size,
        //     //     //     base64: reader.result.split(',').pop(),
        //     //     // };
        //     //     // filesList.push(ob);

        //     //     // //console.log(reader.result.split(',').pop());
        //     // };
        //     // reader.onerror = function (error) {
        //     //     //console.log('Error: ', error);
        //     // };

        // } else {
        //     // alert("You have not selected any file");
        // }


    }


    function getBase64(file) {

    }


    const [msgText, setMsgText] = useState('');
    const sendMessage = () => {


        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            alert("Please select Center");
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            alert("Please select Provider");
            return
        }



        let sub = document.getElementById('userSubject').value
        if (sub === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            alert("Please select subject");
            return
        }

        let msg = document.getElementById('userMsg').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            alert("Please select message");
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(null, centerName, centerRef, sub, msg, fileType, fileBase, filesList, resourceType, recpName, 'Send Mail')
    }

    const saveDraft = () => {
        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            return
        }



        let sub = document.getElementById('userSubject').value
        if (sub === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            return
        }

        let msg = document.getElementById('userMsg').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(null, centerName, centerRef, sub, msg, fileType, fileBase, fileSize, resourceType, recpName, 'Drafts')

    }
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Messaging</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="email-header">
                                <button className="btn email-aside-list-toggler">
                                    <i className="mdi mdi-menu" />
                                </button>
                                <button onClick={() => setNewMsgModal(true)}
                                    className="btn btn-primary has-icon ">
                                    {/* email-composer */}
                                    <i className="mdi mdi-plus" />Compose </button>
                            </div>
                            {
                                isSendMessageSuccess && <Alert
                                    message="Success"
                                    description="Message has been sent Succesfuly"
                                    type="success"
                                    showIcon
                                />

                            }
                            {
                                isSendMessageSuccess && window.location.reload()
                            }
                            <div className="email-wrapper grid">
                                <div className="email-aside-list">
                                    <Tabs defaultActiveKey="1" centered onTabClick={() => {
                                        props.setMessageItem(0, 'Received')
                                    }} >
                                        <TabPane tabBarStyle={{ height: 100 }} tab="Inbox" key="1" >

                                            <Received />


                                        </TabPane>

                                        <TabPane tab="Sent" key="2">
                                            <Sent />
                                        </TabPane>

                                        <TabPane tab="Draft" key="3">
                                            <Drafts />
                                        </TabPane>
                                    </Tabs>
                                </div>


                                <div className="email-preview-wrapper">
                                    {
                                        typeof (Item) !== 'undefined' && Item.length ? (
                                            ////console.log("Item===>" + Item + ' Scree===>' + screenName)
                                            <MessageView example={Item} screen={screenName} />
                                        ) : null
                                    }

                                </div>


                                <div className="email-compose-wrapper">
                                    <div className="email-compose-header">
                                        <p className="header-title">New Message</p>
                                        <div className="header-controls">
                                            <a href="#" className="compose-minimize"><i className="mdi mdi-close" /></a>
                                        </div>
                                    </div>
                                    <div className="email-compose-body">

                                        {
                                            isSending && <ActivityIndicator size={"large"} />
                                        }
                                        {/* {
                                            !isSending && 
                                            <div id="email-compose">
                                                <div className="form-group input-recipient">
                                                    <label className="label" style={{ width: 68 }}>Center</label>
                                                    <div class="col-md-9 showcase_content_area">
                                                        <Select
                                                            showSearch
                                                            style={{ width: 245 }}
                                                            placeholder="Select a person"
                                                            optionFilterProp="children"
                                                            onChange={onChange}
                                                            onFocus={onFocus}
                                                            onBlur={onBlur}
                                                        >
                                                            {
                                                                centersArray.map((item, index) => <Select.Option key={index}>{item.resource.title}</Select.Option>)
                                                            }

                                                        </Select>
                                                        <p id="error" style={{ color: 'red' }}></p>
                                                    </div>
                                                </div>


                                                <div className="form-group input-recipient" style={{ marginTop: 4, paddingBottom: 4 }}>
                                                    <label className="label" style={{ width: 68 }}>Provider</label>
                                                    <div class="col-md-9 showcase_content_area">
                                                        <Select
                                                            showSearch
                                                            style={{ width: 245 }}
                                                            placeholder="Select a person"
                                                            optionFilterProp="children"
                                                            onChange={onChangeProvider}
                                                            onFocus={onFocusProvider}
                                                            onBlur={onBlurProvider}
                                                            value={selected}


                                                        >
                                                            {providerArray !== undefined && providerArray.map((item, index) => <Select.Option key={index}>{item.resource.name[0].text}</Select.Option>)}

                                                        </Select>
                                                        <p id="errorProvider" style={{ color: 'red' }}></p>

                                                    </div>
                                                </div>


                                                <div className="form-group input-recipient">
                                                    <label className="label" style={{ width: 68 }}>Subject</label>
                                                    <input type="text" className="form-control" placeholder="Subject" id="userSubject" onClick={() => document.getElementById('errorSub').innerHTML = ''} />
                                                    <p id="errorSub" style={{ color: 'red' }}></p>
                                                </div>
                                                <div className="form-group">
                                                    <textarea id="userMsg" className="form-control" rows={8} placeholder="Write your message here" defaultValue={""} onClick={() => document.getElementById('errorMsg').innerHTML = ''} />
                                                    <p id="errorMsg" style={{ color: 'red' }}></p>
                                                </div>
                                                <div className="email-compose-toolbar">
                                                    <ul className="toolbar-menu" style={{ width: 190 }}>
                                                        <li style={{ width: 190 }} className="action-buttons"><input onChange={() => checkFile()} type="file" id="myFile" name="filename" accept="image/* , application/pdf" /></li>

                                                    </ul>
                                                    <button onClick={() => saveDraft()} className="btn btn-primary btn-sm component-flat send-button">Draft</button>

                                                    <button onClick={() => sendMessage()} className="btn btn-success btn-sm component-flat send-button">Send</button>

                                                </div>
                                            </div>
                                        } */}

                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>
                <Modal
                    title="New Message"
                    centered
                    visible={newMsgModal}
                    // onOk={() => {
                    //     setNewMsgModal(false);

                    // }}loading={loading}
                    // onCancel={() => setNewMsgModal(false)}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                    footer={[
                        <Button key="back" onClick={() => setNewMsgModal(false)}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" loading={isSending} onClick={() => sendMessage()}>
                            Send
                        </Button>,
                        // <Button
                        //     key="link"
                        //     href="https://google.com"
                        //     type="primary"
                        //     // loading={loading}
                        //     onClick={()=>alert("hello")}
                        // >
                        //     Search 
                        // </Button>,
                    ]}
                >
                    <div className="row" >
                        <div className="col-md-12 mx-auto">


                            <div style={{}} className="form-group row mb-0">
                                <div className="col-md-6">
                                    {/* <div className="form-group input-recipient"> showcase_content_area */}
                                    <label className="label" style={{ width: 68 }}>Center</label>
                                    {/* <div class="col-md-6 "> */}
                                    <Select
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    >
                                        {
                                            centersArray.map((item, index) => <Select.Option key={index}>{item.resource.title}</Select.Option>)
                                        }

                                    </Select>
                                    <p id="error" style={{ color: 'red' }}></p>
                                    {/* </div> */}
                                    {/* </div> */}
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group input-recipient" style={{ paddingBottom: 4 }}>
                                        <label className="label" style={{ width: 68 }}>Provider</label>
                                        <div class="showcase_content_area">
                                            <Select
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Select a person"
                                                optionFilterProp="children"
                                                onChange={onChangeProvider}
                                                onFocus={onFocusProvider}
                                                onBlur={onBlurProvider}
                                                value={selected}


                                            >
                                                {providerArray !== undefined && providerArray.map((item, index) => <Select.Option key={index}>{item.resource.name[0].text}</Select.Option>)}

                                            </Select>
                                            <p id="errorProvider" style={{ color: 'red' }}></p>

                                        </div>
                                    </div>


                                </div>

                            </div>



                            <div style={{}} className="form-group row mb-0">
                                {/* <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Cell Phone Number:</label>
                                    <div className="input-group">
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Email:</label>
                                    <div className="input-group">

                                    </div>
                                </div> */}
                                <div className="form-group col-md-12">
                                    <label className="label" style={{ width: 68 }}>Subject</label>
                                    <input type="text" className="form-control "
                                        placeholder="Subject" id="userSubject"
                                        onClick={() => document.getElementById('errorSub').innerHTML = ''} />
                                    <p id="errorSub" style={{ color: 'red' }}></p>
                                </div>
                            </div>
                            <div style={{}} className="form-group row mb-0">
                                <div className="form-group col-md-12">

                                    <textarea id="userMsg" className="form-control" rows={8} placeholder="Write your message here" defaultValue={""} onClick={() => document.getElementById('errorMsg').innerHTML = ''} />
                                    <p id="errorMsg" style={{ color: 'red' }}></p>
                                </div>


                            </div>
                            <FileBase64
                                className="action-buttons"
                                multiple={true}
                                type="file" accept=".jpg,.png,.pdf"
                                onDone={(files) => checkFile(files)} />
                            <div style={{ alignItems: 'flex-end', backgroundColor: 'red' }} className="form-group row mb-0">
                                {/* className="email-compose-toolbar col-md-12" */}

                                {/* <div className="col-md-12">
                                    <ul className="toolbar-menu" style={{ width: 190 }}>
                                        <FileBase64
                                            className="action-buttons"
                                            multiple={true}
                                            onDone={(files) => checkFile(files)} />
                                       
                                    </ul>
                                </div> */}
                                {/* {
                                    isSending ? <ActivityIndicator size={"large"} />
                                        :
                                        <div className="col-md-3 row">
                                            <div style={{ marginLeft: 10, marginRight: 10 }} >
                                                <button onClick={() => saveDraft()} className="btn btn-primary btn-sm component-flat send-button">Draft</button>

                                            </div>
                                            <div >
                                                <button onClick={() => sendMessage()} className="btn btn-success btn-sm component-flat send-button ">Send</button>

                                            </div>
                                        </div>

                                } */}
                                {/* {
                                    isSendMessageSuccess && setNewMsgModal(false)
                                } */}

                            </div>

                        </div>


                    </div>

                </Modal>

            </div>

        </div >
    )
}



function mapStateToProps(state) {
    return {

        SetMessageItem: state.SetMessageItem,
        SendMessage: state.SendMessage,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendData: (draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType) => dispatch(sendNewMessagetoAPI(draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType)),
        setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
        tokenVal: (history) => dispatch(checkToken(history))
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Messaing)


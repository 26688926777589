// import { AsyncStorage } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';


const GetAppSettings = async () => {
    // let userId = '';
    // try {
    //     userId = await AsyncStorage.getItem('secureToken') || 'none';

    // } catch (error) {
    //     // Error retrieving data
    //     //console.log(error.message);
    // }
    // ////console.log(userId)
    // return userId;


    let portalSetting = '';
    try {
        portalSetting = await AsyncStorage.getItem('@portalSettings') || 'none';

    } catch (error) {
        // Error retrieving data
        //console.log(error.message);
    }
    ////console.log(userId)
    return portalSetting;
}

export default GetAppSettings
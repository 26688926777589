import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, StyleSheet } from "react-native-web";
import { ActivityIndicator } from "react-native-web";
import { connect } from 'react-redux'
import { Tabs } from "antd";
import { Select } from 'antd';
import { Alert } from 'antd';
import { _ } from 'underscore'
import { Modal, Button } from 'antd';
import "antd/dist/antd.css";
import Received from './Inbox/Received';
import Sent from './Inbox/Sent';
import Drafts from './Inbox/Drafts';
import Settings from '../../shared/config/Settings';
import MessageView from "../components/MessageView";

import { notification, Space } from 'antd';
import FileBase64 from 'react-file-base64';
import getUserId from '../../shared/core/GetToken';
import { msgClenuplatData, sendNewMessagetoAPI } from '../../shared/actions/NewMessageActions';
import { clearSetItemMessage, setItemMessage } from '../../shared/actions/SetMessageItemAction';
import GetAppSettings from '../../shared/core/GetAppSettings';
import renderHTML from "react-render-html";
import { checkToken } from '../../shared/actions/TokenValidActions';

const useHistory = require("react-router-dom").useHistory;

const { TabPane } = Tabs;

const Med = (props) => {
    const history = useHistory()

    const [isloaded, setIsload] = React.useState(false);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script);
        // d.div.appendChild(script);
    }, []);



    //sending items
    const [centerName, setCenterName] = useState('');
    const [centerRef, setCenterRef] = useState('');
    const [centersArray, setCentersArray] = useState([]);
    const [providerArray, setProviderArray] = useState([]);
    const [resourceType, setResourceType] = useState('');
    const [recpName, setRecpName] = useState('');
    const [successMessage, setSuccessMessage] = useState('Your Message has been sent Successfully');


    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');


    const [fileSize, setFileSize] = useState('');
    const [fileType, setfileType] = useState('');
    const [fileBase, setFileBase] = useState('');
    const [newMsgModal, setNewMsgModal] = useState(false);
    const { SendMessage, isSending, isSendMessageSuccess, error } = props.SendMessage
    const [portalSetting, setPortalSetting] = useState(null);
    const { Item, screenName } = props.SetMessageItem;


    const [selected, setSelected] = useState()
    const [selectedCenter, setSelectedCenter] = useState()

    const [filesList, setFilesList] = useState([]);
    const clearSelected = () => {
        // this line will clear the select
        // when you click on the button
        setSelected(null)
    }
    const openNotificationWithIcon = type => {
        notification[type]({
            //message: 'Message Sent',
            description:
                SendMessage,
        });
    };
    React.useEffect(() => {
        if (isSendMessageSuccess) {

            setNewMsgModal(false)
            document.getElementById('userSubject').value = ''
            document.getElementById('userMsg').value = ''
            clearSelected()
            setSelectedCenter(null)

            // setCenterRef('');
            // setRecpName('');
            // setResourceType('');
            // setSubject(null)
            // setMessage(null)
            openNotificationWithIcon('success')
            props.cleandata()
            window.location.reload()
        }
    }, [isSendMessageSuccess])
    React.useEffect(() => {
        props.tokenVal(history);
        CallcenterList()
    }, [])

    useEffect(() => {
        GetAppSettings().then((info) => {
            const set = JSON.parse(info)
            setPortalSetting(set)
        })
    }, [])
    function onChange(value) {
        setResourceType('')
        clearSelected();
        // alert(value)
        setSelectedCenter(centersArray[value].resource.extension[1].valueString)
        //console.log(centersArray[value]);
        let id = centersArray[value].resource.extension[0].valueString;
        // let resourceType = centersArray[value].resource.resourceType;
        setCenterRef(id);
        // setCenterName(centersArray[value].resource.name)
        CallProviderList(id)
    }

    function onBlur() {
        //console.log('blur');
    }

    function onFocus() {
        //    CallcenterList()
        //console.log('focus');
    }

    useEffect(() => {
        if (providerArray && providerArray.length) {
            setSelected(providerArray[0].resource.name[0].text)
            setRecpName(providerArray[0].resource.name[0].text);
            setResourceType(providerArray[0].resource.resourceType + '/' + providerArray[0].resource.id);


        }

    }, [providerArray])
    function onChangeProvider(value) {
        setSelected(providerArray[value].resource.name[0].text)
        document.getElementById('errorProvider').innerHTML = ''
        setRecpName(providerArray[value].resource.name[0].text);
        setResourceType(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);


        //console.log(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);
        //console.log(providerArray[value].resource.name[0].text)
    }

    function onBlurProvider() {
        //console.log('blur');
    }

    function onFocusProvider() {
        //CallcenterList()
        //console.log('focus provider');
    }

    const [centerLoading, setCenterLoading] = useState(false)
    const CallcenterList = () => {

        setProviderArray([]);
        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setCenterLoading(true)
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?PatientID=' + item.userid + '&TypeID=1';
            //console.log(queryString)
            //console.log(item)
            // document.getElementById('error').innerHTML = ''
            fetch(Settings.apiUrl + "Patient/LoadRoles" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    setCentersArray(json[0].entry)
                    setCenterLoading(false)
                })
                .catch(err => {
                    //console.log(err)
                    setCenterLoading(false)
                })
        })
    }
    React.useEffect(() => {
        if (centersArray && centersArray.length) {

            CallProviderList(centersArray[0].resource.extension[0].valueString)
            setSelectedCenter(centersArray[0].resource.extension[1].valueString)
            //console.log(centersArray[value]);
            let id = centersArray[0].resource.extension[0].valueString;
            // let resourceType = centersArray[value].resource.resourceType;
            setCenterRef(id);
        }

    }, [centersArray])

    const [providerLoading, setProviderLoading] = useState(false)
    const CallProviderList = (index) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setProviderLoading(true)
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = "?PatientID=" + item.userid + "&RoleID=" + index + "&CallType=0";
            // const queryString = '?PatientID=' + item.userid + '&PracticeLocationID=' + index;
            //console.log(queryString)
            fetch(Settings.apiUrl + "Patient/MessagingRecipients" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    setProviderLoading(false);
                    //console.log(json[0].entry)
                    setProviderArray(json[0].entry);
                })
                .catch(err => {
                    setProviderLoading(false)
                    //console.log(err)
                })
        })

    }


    const checkFile = (file) => {
        if (file) {
            for (var i = 0; i < file.length; i++) {
                setFileSize(file[i].size);
                setfileType(file[i].name.split('.').pop())
                setFileBase(file[i].base64)
                let ob = {
                    type: file[i].name.split('.').pop(),
                    size: file[i].size.split(' ')[0] * 1024,
                    base64: file[i].base64.split(',').pop(), //reader.result.split(',').pop(),
                };
                filesList.push(ob);
            }
            //console.log({ filesList })

        }



    }


    const [msgText, setMsgText] = useState('');
    const sendMessage = () => {


        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            alert("Please select Center");
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            alert("Please select Provider");
            return
        }



        let sub = document.getElementById('userSubject').value
        if (sub === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            alert("Please select subject");
            return
        }

        let msg = document.getElementById('userMsg').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            alert("Please select message");
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(null, centerName, centerRef, sub, msg, fileType, fileBase, filesList, resourceType, recpName, 'Send Mail')
    }

    const saveDraft = () => {
        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            return
        }



        let sub = document.getElementById('userSubject').value
        if (sub === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            return
        }

        let msg = document.getElementById('userMsg').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(null, centerName, centerRef, sub, msg, fileType, fileBase, filesList, resourceType, recpName, 'Drafts')

    }
    ///
    return (
        <div>
            <Header />

            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        {/* <a href=>Dashboard</a> */}
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Messages
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            {/* <div class="row mb-2">
                                <div class="col-md-12 mx-auto">
                                    <div class="alert alert-warning" role="alert">

                                        <h6 class="alert-heading">Disclaimer:</h6>
                                        {portalSetting &&
                                            portalSetting.PortalMessageTermsandConditions &&
                                            renderHTML(portalSetting.PortalMessageTermsandConditions)

                                        }

                                    </div>
                                </div>
                            </div> */}
                            {portalSetting && portalSetting.send ?
                                <div className="email-header">
                                    <button className="btn email-aside-list-toggler">
                                        <i className="mdi mdi-menu" />
                                    </button>


                                    <button onClick={() => setNewMsgModal(true)}
                                        className="btn btn-primary has-icon ">
                                        {/* email-composer */}
                                        <i className="mdi mdi-plus" />Compose </button>
                                </div> : null
                            }

                            {/* {
                                isSendMessageSuccess && <Alert
                                    message="Success"
                                    description="Message has been sent Succesfuly"
                                    type="success"
                                    showIcon
                                />

                            }
                            {
                                isSendMessageSuccess && window.location.reload()
                            } */}

                            <div className="email-wrapper grid">
                                <div className="email-aside-list">
                                    <Tabs defaultActiveKey="1" centered >
                                        <TabPane tab="Inbox" key="1" >
                                            <Received />
                                        </TabPane>

                                        <TabPane tab="Sent" key="2">
                                            <Sent />
                                        </TabPane>

                                        <TabPane tab="Draft" key="3">
                                            <Drafts />
                                        </TabPane>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer"></footer>
                </div>
            </div>
            <Modal
                title="New Message"
                centered
                visible={newMsgModal}
                closable={false}
                // onOk={() => {
                //     setNewMsgModal(false);

                // }}loading={loading}
                // onCancel={() => setNewMsgModal(false)}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
                footer={[
                    isSending ?
                        <ActivityIndicator size="small" color="#00A1DE" />

                        :
                        <>
                            <Button key="back" onClick={() => setNewMsgModal(false)}>
                                Cancel
                            </Button>
                            <Button key="submit" type="secondary" onClick={() => saveDraft()}>
                                Draft
                            </Button>
                            <Button key="submit" type="primary" onClick={() => sendMessage()}>
                                Send
                            </Button>
                        </>


                ]}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">
                        <div class="col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">

                                <h6 class="alert-heading">Disclaimer:</h6>
                                {portalSetting &&
                                    portalSetting.PortalMessageTermsandConditions &&
                                    renderHTML(portalSetting.PortalMessageTermsandConditions)

                                }

                            </div>
                        </div>

                        <div style={{}} className="form-group row mb-0">
                            <div className="col-md-6">
                                {/* <div className="form-group input-recipient"> showcase_content_area */}
                                <label className="label" style={{ width: 68 }}>To</label>
                                {/* <div class="col-md-6 "> */}
                                {centerLoading && <ActivityIndicator size="small" color="#00A1DE" />}
                                {centersArray && centersArray.length ?
                                    <Select
                                        defaultValue={centersArray[0].resource.extension[1].valueString}
                                        showSearch
                                        style={{ width: '100%' }}
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        value={selectedCenter}

                                    >
                                        {
                                            centersArray.map((item, index) => <Select.Option key={index}>{item.resource.title}</Select.Option>)
                                        }

                                    </Select>
                                    : !centerLoading &&
                                    <p>No registered Role Available.</p>
                                }

                                <p id="error" style={{ color: 'red' }}></p>
                                {/* </div> */}
                                {/* </div> */}
                            </div>
                            <div className="col-md-6">
                                <div className="form-group input-recipient" style={{ paddingBottom: 4 }}>
                                    <label className="label" style={{}}>Select Provider</label>
                                    <div class="showcase_content_area">

                                        {providerLoading ? <ActivityIndicator size="small" color="#00A1DE" />
                                            :
                                            providerArray && providerArray.length ?
                                                <Select
                                                    showSearch
                                                    defaultValue={providerArray[0].resource.name[0].text}
                                                    style={{ width: '100%' }}
                                                    placeholder="Select Provider"
                                                    optionFilterProp="children"
                                                    onChange={onChangeProvider}
                                                    onFocus={onFocusProvider}
                                                    onBlur={onBlurProvider}
                                                    value={selected}


                                                >
                                                    {providerArray !== undefined && providerArray.map((item, index) => <Select.Option key={index}>{item.resource.name[0].text}</Select.Option>)}

                                                </Select>
                                                : !providerLoading && <p>No registered provider available.</p>

                                        }
                                        <p id="errorProvider" style={{ color: 'red' }}></p>

                                    </div>
                                </div>


                            </div>

                        </div>


                        <div style={{}} className="form-group row mb-0">

                            <div className="form-group col-md-12">
                                <label className="label" style={{ width: 68 }}>Subject</label>
                                <input type="text" className="form-control "
                                    placeholder="Subject" id="userSubject" //
                                    onClick={() => document.getElementById('errorSub').innerHTML = ''} />
                                <p id="errorSub" style={{ color: 'red' }}></p>
                            </div>
                        </div>
                        <div style={{}} className="form-group row mb-0">
                            <div className="form-group col-md-12">

                                <textarea id="userMsg" className="form-control" rows={8} placeholder="Write your message here" defaultValue={""} onClick={() => document.getElementById('errorMsg').innerHTML = ''} />
                                <p id="errorMsg" style={{ color: 'red' }}></p>
                            </div>


                        </div>
                        <FileBase64
                            className="action-buttons"
                            multiple={true}
                            onDone={(files) => checkFile(files)} />
                        <div style={{ alignItems: 'flex-end', backgroundColor: 'red' }} className="form-group row mb-0">

                        </div>

                    </div>


                </div>

            </Modal>


        </div>

    );
};

const styles = StyleSheet.create({
    textDetails: {
        textAlign: "justify",
        color: "#95989A",
        fontSize: 15,
    },
    textDetailsInner: {
        textAlign: "justify",
        color: "#333333",
        fontSize: 14,
    },
});
function mapStateToProps(state) {
    return {

        SetMessageItem: state.SetMessageItem,
        SendMessage: state.SendMessage,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendData: (draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType) => dispatch(sendNewMessagetoAPI(draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType)),
        setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
        cleandata: () => dispatch(msgClenuplatData()),
        tokenVal: (history) => dispatch(checkToken(history))
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Med);

import { GET_COUNTER, GET_COUNTER_SUCCESS, GET_COUNTER_FAILED, } from "../constants/Constant";


//current state
const intitalState = {
    Counter: '',
    isFetching: false,
    error: false
}

//export requried state

export default function countereducer(state = intitalState, action) {
    switch (action.type) {
        case GET_COUNTER:
            return {
                ...state,
                isFetching: true
            }

        case GET_COUNTER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                Counter: action.data
            }

        case GET_COUNTER_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }
}
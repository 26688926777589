import { GET_ROLES, GET_ROLES_SUCCESS, GET_ROLES_FAILED } from "../constants/Constant";


//current state
const intitalState = {
    Roles: [],
    isFetching: false,
    error: false
}

//export requried state
export default function rolesReducer(state = intitalState, action) {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                isFetching: true
            }

        case GET_ROLES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                Roles: action.data
            }

        case GET_ROLES_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }
}
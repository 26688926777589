const Settings = {
	//apiUrl: 'https://patientportalapi.indici.ie/api/'
	//

	apiUrl: process.env.REACT_APP_API_URL 		//"https://devdhbconnect.itsmyhealth.nz/api/",
	//apiUrl: 'https://uatmyindiciapi.itsmyhealth.nz/api/'
	// apiUrl: "https://wcdhbapi.myindici.co.nz/api/",
};
export default Settings;

// apiUrl : 'https://stagingindiciconnect.itsmyhealth.nz/api/' https://erxindiciconnect.indici.nz/api/

// 'https://nhldevconnect.indici.ie/api/'

import { USER_LOGIN, USER_LOGIN_SUCCESS, USER_LOGIN_FAILED, USER_LOGIN_END, USER_RESET_PASSWORD_STATE } from "../constants/Constant";
import AsyncStorage from '@react-native-async-storage/async-storage';
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


export function fetchUserInformation(username, password) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(
        {
            "UserName": username,
            "Password": password
        }
    );

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };


    return (dispatch) => {
        dispatch(getuserToken())
        fetch(Settings.apiUrl + "Patient/Authentication", requestOptions)
            .then(data => data.json())
            .then(json => {
                let password_statu = null;
                if (json.hasOwnProperty('StatusCode')) {
                    dispatch(getUserTokenFailed(json.ResponseMessage));
                }
                else if (json[0].hasOwnProperty('resourceType')) {

                    json[0].hasOwnProperty('resourceType') && json[0].extension[1].valueBoolean != undefined && json[0].extension[1].valueBoolean == true ? password_statu = true : password_statu = false

                    // if passwrod staus is true then it will be redirect the user to reset their passwrod.
                    if (password_statu) {
                        // print some thing
                        setPassordResetInfo(json[0].extension[0].valueString, json[0].id, json[0].id)
                        _storeData(json[0].extension[0].valueString, json[0].id, username, password, 'No', json[0].name[0].text)
                    } else {
                        //alert(json[0].name[0].text)

                        _storeData(json[0].extension[0].valueString, json[0].id, username, password, 'No', json[0].name[0].text)
                        // getUserId().then((data) => {
                        //     const item = JSON.parse(data);
                        //     // //console.log(item)
                        // })
                    }
                    dispatch(getUserTokenSuccess(json, password_statu));


                    // sir code ...................
                    // dispatch(getUserTokenSuccess(json));
                }
                else {
                    dispatch(getUserTokenFailed("Login from Inside Api"));
                    alert('Your login details are incorrect.')
                }
            })
        // .catch(err => {
        //     // //console.log("respons ==>"+JSON.stringify(err))
        //     dispatch(getUserTokenFailed(JSON.stringify(json)))
        // })
    }
}

export const LogoutUser = () => {
    clearStorage();
    return (dispatch) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token

            //console.log(queryString);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {

                method: 'POST',
                headers: myHeaders,

                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Logout" + queryString, requestOptions)
                .then(response => response.json())
                .then(result => {
                    //console.log(result)
                    alert(JSON.stringify(result))
                    if (result.StatusCode == 1) {
                        // aslert(result.ResponseMessage)
                    }
                })
                .catch(error => console.log('error', error));

        })
        dispatch(getUserLogout());
    }
}



//clear storage
const clearStorage = async () => {
    try {
        await AsyncStorage.clear()
        //alert('Storage successfully cleared!')
    } catch (e) {
        //alert('Failed to clear the async storage.')
    }
};

const _storeData = async (usertoken, userId, userEmail, userAuth, isProxy, name) => {
    try {
        const jsonValue = JSON.stringify({ "token": usertoken, "userid": userId, "email": userEmail, "password": userAuth, "proxy": isProxy, "username": name, "parentid": userId, "proxyid": "", "parentname": name });
        await AsyncStorage.setItem('@userToken', jsonValue)

    } catch (error) {
        // Error saving data
    }
};

const setPassordResetInfo = async (usertoken, userId) => {
    try {
        const jsonValue = JSON.stringify({ "token": usertoken, "userid": userId });
        await AsyncStorage.setItem('@userPassordReset', jsonValue)

    } catch (error) {
        // Error saving data
    }
};


export const clearUserState = () => {
    return (dispatch) => {
        dispatch(clearUserResetState())
    }
}

const getuserToken = () => {
    return {
        type: USER_LOGIN
    }
}

const clearUserResetState = () => {
    return {
        type: USER_RESET_PASSWORD_STATE
    }
}

const getUserTokenSuccess = (data, status_Pass) => {
    return {
        type: USER_LOGIN_SUCCESS,
        passwordStatus: status_Pass,
        data,
    }
}

const getUserTokenFailed = (msg) => {
    return {
        type: USER_LOGIN_FAILED,
        msg,
    }
}

const getUserLogout = () => {
    return {
        type: USER_LOGIN_END
    }
}
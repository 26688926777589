import React from "react";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { View, Text, StyleSheet } from "react-native-web";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import AllMedications from "./MedicationsTabs/AllMedications";
import LongTermMed from "./MedicationsTabs/LongTermMed";
import RepeatedMedication from "./MedicationsTabs/RepeatedMedication";
const { TabPane } = Tabs;

const RepeatedMedcation = (props) => {
    const [isloaded, setIsload] = React.useState(false);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script);
        // d.div.appendChild(script);
    }, []);
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <a href="#">Dashboard</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Letter & Documents</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Letter & Documents</h2>
                                            <div className="item-wrapper">
                                                <p>hello 2</p>
                                                <div className="drive-wrapper drive-grid-view">
                                                    <div className="grid-items-wrapper">
                                                        <p>hello</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
};

const styles = StyleSheet.create({
    textDetails: {
        textAlign: "justify",
        color: "#95989A",
        fontSize: 15,
    },
    textDetailsInner: {
        textAlign: "justify",
        color: "#333333",
        fontSize: 14,
    },
});

export default RepeatedMedcation;

import React, { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { ActivityIndicator, View, FlatList, Text } from "react-native";
import AppointmentHandler from '../../components/AppointmentHandler';
import { fetchfAppointmentsFromApi } from "../../../shared/actions/FutureAppointActions";
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime, dateFormatWithOutTime } from '../../../shared/core/datehelper';
import { Modal } from 'antd';
import getUserId from '../../../shared/core/GetToken';
import Settings from '../../../shared/config/Settings';

import { notification } from 'antd';

const FutureAppointmentView = props => {

    const { FutureAppointments, isGettingFut } = props.FutureAppointments;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [cancelvisible, setcancelVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [modalText, setModalText] = React.useState('Content of the modal');
    const [deleteitemid, setDeleteItemId] = React.useState('')

    useEffect(() => {
        //alert('Called Future')
        props.getAppoint(pageSize, pageNumber);
    }, [])
    const AppointmentCancelHandler = (item) => {
        setcancelVisible(true)
        setDeleteItemId(item.resource.id)
    }
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description: message,
        });
    };
    const handleOk = () => {
        let reason = document.getElementById('mReason1').value
        setConfirmLoading(true);
        //    alert(reason)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/Appointment?PatientID=" + item.userid + "&AppointmentID=" + deleteitemid + "&Reason=" + reason, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result == "Appointment cancelled successfully.") {
                        openNotificationWithIcon('success', "Appointment cancelled successfully.")
                        props.getAppoint(pageSize, pageNumber);
                        setcancelVisible(false);
                        setConfirmLoading(false);
                        // props.getAppoint(pageSize, pageNumber);
                    }
                    else {
                        openNotificationWithIcon('waring', result)
                        setcancelVisible(false);
                        setConfirmLoading(false);
                    }

                })
                .catch(error => console.log('error', error));
        })



    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setcancelVisible(false);
    };
    return (
        <div>
            {
                isGettingFut && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (FutureAppointments) !== 'undefined' && FutureAppointments.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column', paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        //contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-evenly' }}
                        data={FutureAppointments}
                        renderItem={({ item }) => (
                            <AppointmentHandler
                                styleName={'card-header text-white bg-primary'}
                                type={"Future"}
                                appType={item.resource.extension[0].valueString ? item.resource.extension[0].valueString : 'N/A'}
                                providerName={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                                appointmentDate={GetAppointmentDate(item.resource.period.start.split('T')[0]) + ' ' + GetMonthName(item.resource.period.start.split('T')[0]) + ' ' + GetYear(item.resource.period.start.split('T')[0])}
                                praticeAddress={item.resource.extension[3].hasOwnProperty('valueString') ? item.resource.extension[3].valueString : '(N/A)'}
                                firstitem={item.resource.extension[5].valueString ? item.resource.extension[5].valueString : 'N/A'}
                                secondItem={GetDayName(item.resource.period.start.split('T')[0]) + ', ' + FormateUTCTime(item.resource.period.start)}
                                patientDetails={item.resource.extension[1].valueString} //+ ', ' + item.resource.extension[0].valueString}}
                                location={item.resource.extension[4].hasOwnProperty('valueString') ? item.resource.extension[4].valueString : '(N/A)'}
                                reasonForCancel={null}
                                canelHandler={() => AppointmentCancelHandler(item)}

                            />
                        )}
                        horizontal={false}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={30}

                    />
                ) : !isGettingFut && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>
            }
            <Modal
                title="Cancel Appointment"
                visible={cancelvisible}
                onOk={handleOk}
                okText={'Yes'}
                cancelText={'No'}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >

                <div className="form-group row ">
                    <div className="col">
                        <h6>Are you sure you want to cancel appointment?</h6>
                        <textarea id="mReason1" className="form-control" rows={3} placeholder="Type Reason here..." />

                    </div>

                </div>

            </Modal>

        </div>

    );
}



function mapStateToProps(state) {
    return {
        FutureAppointments: state.FutureAppointments
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getAppoint: (pageSize, pageNumber) => dispatch(fetchfAppointmentsFromApi(pageSize, pageNumber))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FutureAppointmentView)

//export default FutureAppointmentView

import React, { useState } from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';

import Svg, { G, Path, Circle } from "react-native-svg"
const SVGlist = [
    {
        "icon":
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={34.345}
                height={44.402}
                viewBox="0 0 34.345 44.402" >
                <G data-name="doctor (2)">
                    <Path
                        data-name="Path 3762"
                        d="M13.688 18.762h6.968l.038.476-3.405 5.162-3.658-5.213.057-.425z"
                        fill="#b3b3b3"
                    />
                    <Path
                        data-name="Path 3763"
                        d="M13.733 17.467l-.06 2.912-4.94 2.7v9.9h16.629v-9.9l-4.591-2.854-.252-2.911-6.788.152z"
                        fill="#f0ba7d"
                    />
                    <Path
                        data-name="Path 3764"
                        d="M11.455 8.975a.756.756 0 00-1.044-.314c-.761.308.034 3.384.325 3.813s.758.157.928.14c.242 1.833.264 3.1 1 3.87a22.782 22.782 0 003.125 2.744 4.675 4.675 0 002.78 0 22.758 22.758 0 003.119-2.741c.734-.773.756-2.037 1-3.87.17.017.638.289.928-.14s1.086-3.5.325-3.813a.756.756 0 00-1.044.314 6.6 6.6 0 00-.585-4.793c-.916-1.629-3-2.474-5.138-2.536-2.133.062-4.222.907-5.138 2.536a6.6 6.6 0 00-.585 4.793z"
                        fill="#fed298"
                    />
                    <Path
                        data-name="Path 3765"
                        d="M11.25 21.236l1.183-.466.953-.857 3.366 2.553h.838l3.367-2.556 2.136 1.326 3.712 6.309-2.94 15.949a25.147 25.147 0 01-12.2.3L6.396 28.051l4.851-6.814z"
                        fill="#15bdb2"
                    />
                    <G data-name="Group 2687" fill="#21d0c3">
                        <Path
                            data-name="Path 3766"
                            d="M22.014 20.433l-1.359-1.676-3.064 3.7 2.63 2.923z"
                        />
                        <Path
                            data-name="Path 3767"
                            d="M12.327 20.408l1.361-1.646 3.065 3.7-2.735 2.893z"
                        />
                    </G>
                    <Path
                        data-name="Path 3768"
                        d="M12.327 20.408l-9.008 2.929a1.016 1.016 0 00-.671.808L0 37.569a25.056 25.056 0 006.036 4.219l1.317-4.3a.106.106 0 01.206.025l.19 5.047a24.915 24.915 0 009.423 1.839v-1.676l-4.845-22.318z"
                        fill="#fff"
                    />
                    <G data-name="Group 2688" fill="#eef3f7">
                        <Path
                            data-name="Path 3769"
                            d="M12.332 20.408l-1.032.317-2.733 4.664 3.45.108-2.118 1.206 7.278 16.023-4.845-22.318z"
                        />
                        <Path
                            data-name="Path 3770"
                            d="M22.015 20.438l9.01 2.9a1.014 1.014 0 01.671.808l2.649 13.423a25.063 25.063 0 01-6.036 4.22l-1.317-4.3a.105.105 0 00-.206.025l-.19 5.047a24.915 24.915 0 01-9.423 1.841v-1.676l4.842-22.288z"
                        />
                    </G>
                    <Path
                        data-name="Path 3771"
                        d="M22.014 20.438l.573.174 3.193 4.806-3.45.108 2.119 1.206-7.277 15.994 4.842-22.288z"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 3772"
                        d="M18.193 42.621a.549.549 0 10.55.548.549.549 0 00-.55-.548z"
                        fill="#d9dada"
                    />
                    <Path
                        data-name="Path 3773"
                        d="M22.238 9.694l.53-.019.171-.6.5-.5c.33-2.483.427-6.348-2.038-6.236 0 0-.222-2.818-4.668-2.265s-6.455 3.422-6.215 5.817a11.928 11.928 0 01.143 2.684l.5.5.075.688.436-.028a11.079 11.079 0 01.272-1.33 4.485 4.485 0 00.094-1.225s5.251.335 6.67-1.471c.876-1.115 2.889-3.046 2.991-.371.03.793-.265 1.982.3 2.847a1.566 1.566 0 01.239 1.508z"
                        fill="#666"
                    />
                    <Path
                        data-name="Path 3774"
                        d="M17.168 42.726l1.419-6.533-.965-12.878.526-.224-.562-.624h-.838l-.576.61.541.239-.94 12.982 1.4 6.429z"
                        fill="#0f7986"
                    />
                    <Path
                        data-name="Path 3775"
                        d="M18.144 42.621a.5.5 0 10.5.5.5.5 0 00-.5-.5z"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 3776"
                        d="M26 34.35a1.793 1.793 0 112.071-1.461A1.793 1.793 0 0126 34.35z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3777"
                        d="M26.017 34.248a1.688 1.688 0 111.951-1.376 1.688 1.688 0 01-1.951 1.376z"
                        fill="#0f7986"
                    />
                    <Path
                        data-name="Path 3778"
                        d="M26.146 33.504a.934.934 0 111.079-.762.934.934 0 01-1.079.762z"
                        fill="#ebecec"
                    />
                    <Path
                        data-name="Path 3779"
                        d="M26.255 31.764l.41.071.221-1.278-.41-.071z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3780"
                        d="M12.509 34.705l.769-.153a1.214 1.214 0 00.952-1.424l-.9-4.557a3.493 3.493 0 00-3.224-2.791 5.8 5.8 0 00-3.5.694 3.488 3.488 0 00-1.906 3.81l.9 4.557a1.209 1.209 0 00.516.77 1.207 1.207 0 00.908.182l.769-.153-.063-.317-.769.153a.887.887 0 01-.666-.133.887.887 0 01-.378-.565l-.9-4.557a3.18 3.18 0 011.748-3.467 5.47 5.47 0 013.3-.655 3.186 3.186 0 012.947 2.535l.9 4.557a.891.891 0 01-.7 1.044l-.769.153.063.317z"
                        fill="#21d0c3"
                    />
                    <Path
                        data-name="Path 3781"
                        d="M13.57 29.247l-.138-.7a3.6 3.6 0 00-3.316-2.877 5.906 5.906 0 00-3.566.707 3.591 3.591 0 00-1.959 3.925l.138.7.528-.105-.138-.7a3.079 3.079 0 011.7-3.353 5.369 5.369 0 013.236-.641 3.084 3.084 0 012.855 2.449l.138.7.528-.1z"
                        fill="#4d4d4d"
                    />
                    <G data-name="Group 2689" fill="#076673">
                        <Path
                            data-name="Path 3782"
                            d="M7.184 35.831l.411-.082a.452.452 0 10-.089-.448l-.411.082.089.448z"
                        />
                        <Path
                            data-name="Path 3783"
                            d="M12.836 34.709l-.411.082a.452.452 0 11-.089-.448l.411-.082z"
                        />
                    </G>
                    <Path
                        data-name="Path 3784"
                        d="M8.527 25.705l-.157-.791-.312.062-.312.062.159.8q.178-.052.361-.088c.086-.019.173-.034.261-.045z"
                        fill="#666"
                    />
                    <G data-name="Group 2690" fill="#0f7986">
                        <Path
                            data-name="Path 3785"
                            d="M7.853 35.392a.137.137 0 10-.161-.108.137.137 0 00.161.108z"
                        />
                        <Path
                            data-name="Path 3786"
                            d="M11.92 34.583a.137.137 0 10-.161-.107.137.137 0 00.161.107z"
                        />
                    </G>
                    <Path
                        data-name="Path 3787"
                        d="M26.092 33.496a.883.883 0 111.021-.72.884.884 0 01-1.021.72z"
                        fill="#fefefe"
                    />
                    <G data-name="Group 2691" fill="#4d4d4d">
                        <Path
                            data-name="Path 3788"
                            d="M12.331 20.646c-.014 0-4.869.04-4.049 4.288l-.452.086c-.924-4.789 4.48-4.833 4.495-4.833z"
                        />
                        <Path
                            data-name="Path 3789"
                            d="M22.019 20.209a4.6 4.6 0 013.5 1.671c1.153 1.4 1.962 3.979 1.357 8.7l-.455-.057c.585-4.572-.171-7.034-1.256-8.354a4.144 4.144 0 00-3.158-1.5l.011-.459z"
                        />
                    </G>
                </G>
            </Svg>
    },
    {
        "icon":
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={36.014}
                height={44.045}
                viewBox="0 0 36.014 44.045"  >
                <G data-name="doctor (4)" transform="translate(-6.993 -5.955)">
                    <Path
                        data-name="Path 3813"
                        d="M30.874 35.51H19.105l1.884-3.235 4.01-.524 4.01.524z"
                        fill="#3fbbd8"
                    />
                    <Path
                        data-name="Path 3814"
                        d="M25 50a24.94 24.94 0 0018.007-7.658 36.438 36.438 0 00-.836-3.769c-.857-2.751-8.182-4.968-17.211-4.968S8.686 35.822 7.83 38.573c-.262.857-.554 2.237-.836 3.769A24.94 24.94 0 0025 50z"
                        fill="#e9f2f4"
                    />
                    <Path
                        data-name="Path 3815"
                        d="M24.96 33.595h-.937a40 40 0 00-5.331.413s.4 3.819.4 5.9l5.895 6.953 5.875-6.943c0-4.293-.01-5.935-.01-5.935a39.837 39.837 0 00-5.892-.388z"
                        fill="#78ddf4"
                    />
                    <Path
                        data-name="Path 3816"
                        d="M28.92 28.426h-7.84c1.219 3.688.1 5.371.1 5.371l2.711.615h2.217l2.711-.615s-1.119-1.683.101-5.371z"
                        fill="#e2a379"
                    />
                    <G data-name="Group 2696" fill="#9b1818">
                        <Path
                            data-name="Path 3817"
                            d="M25 34.28l-1.582.474s.544 2.58 1.582 2.58 1.582-2.58 1.582-2.58z"
                        />
                        <Path
                            data-name="Path 3818"
                            d="M25 47.248s1.622-2.963 2.388-4.615l-1.985-6.62h-.816l-1.965 6.59C23.388 44.266 25 47.248 25 47.248z"
                        />
                    </G>
                    <Path
                        data-name="Path 3819"
                        d="M33.902 17.654c0-9.311-3.98-11.447-8.9-11.447s-8.9 2.136-8.9 11.447a16.87 16.87 0 001.562 7.527c2.056 4.192 5.613 5.653 7.336 5.653s5.28-1.461 7.336-5.653a16.869 16.869 0 001.566-7.527z"
                        fill="#f4b382"
                    />
                    <G data-name="Group 2697">
                        <Path data-name="Path 3820" d="M24.95 29.514z" fill="#191919" />
                    </G>
                    <G data-name="Group 2698" fill="#f4b382">
                        <Path
                            data-name="Path 3822"
                            d="M17.17 24.052c-.846.141-1.451.01-1.925-2.791s.171-3.174 1.018-3.315z"
                        />
                        <Path
                            data-name="Path 3823"
                            d="M32.839 24.052c.846.141 1.441.01 1.925-2.791s-.171-3.174-1.018-3.315z"
                        />
                    </G>
                    <Path
                        data-name="Path 3824"
                        d="M27.69 25.897c0 .262-1.2.957-2.69.957s-2.69-.705-2.69-.957c0-.161.857-.615 1.421-.7a4.112 4.112 0 011.26.292 4.029 4.029 0 011.249-.292 4.508 4.508 0 011.45.7z"
                        fill="#e5a173"
                    />
                    <Path
                        data-name="Path 3825"
                        d="M27.69 25.897a3.521 3.521 0 01-5.381 0 27.7 27.7 0 005.381 0z"
                        fill="#d89364"
                    />
                    <G data-name="Group 2699" fill="#e6e7e8">
                        <Path
                            data-name="Path 3826"
                            d="M33.902 17.655c0-9.311-3.053-11.7-8.9-11.7s-8.9 2.388-8.9 11.7l4.01-3.335h4.89c2.217 0 4.655-3.557 4.655-3.557z"
                        />
                        <Path
                            data-name="Path 3827"
                            d="M16.676 14.188a10.855 10.855 0 00-.564 3.779s.887-.191.887.262l.282 2.781.393-.191c-.02-2.318.494-6.852.494-6.852z"
                        />
                        <Path
                            data-name="Path 3828"
                            d="M33.333 14.187a10.855 10.855 0 01.564 3.779s-.887-.191-.887.262l-.282 2.781-.393-.191c.02-2.318-.494-6.671-.494-6.671z"
                        />
                    </G>
                    <Path
                        data-name="Path 3829"
                        d="M20.852 17.09c-1.925 0-3.5.333-3.5 2.741 0 1.522.867 3.033 3.5 3.033a3.41 3.41 0 003.5-3.033c-.007-1.512-1.569-2.741-3.5-2.741zm0 5.179c-2.086 0-2.771-1.209-2.771-2.408 0-1.915 1.239-2.177 2.771-2.177a2.53 2.53 0 012.771 2.177 2.7 2.7 0 01-2.771 2.408z"
                        fill="#261d17"
                    />
                    <Path
                        data-name="Path 3830"
                        d="M20.848 17.684c-1.532 0-2.771.272-2.771 2.177 0 1.2.685 2.408 2.771 2.408a2.7 2.7 0 002.771-2.408 2.53 2.53 0 00-2.771-2.177z"
                        fill="#f4c4a4"
                    />
                    <Path
                        data-name="Path 3831"
                        d="M20.848 17.684c-1.532 0-2.771.272-2.771 2.177a2.218 2.218 0 001.864 2.318l1.663-4.414a3.7 3.7 0 00-.756-.081z"
                        fill="#f4ccb0"
                    />
                    <Path
                        data-name="Path 3832"
                        d="M23.62 19.865a2.339 2.339 0 00-2.015-2.1l-1.653 4.414a4.059 4.059 0 00.907.091 2.7 2.7 0 002.761-2.405z"
                        fill="#f4c4a4"
                    />
                    <G data-name="Group 2700" fill="#261d17">
                        <Path
                            data-name="Path 3833"
                            d="M17.776 19.015a.361.361 0 01-.252-.111l-1.34-1.461a.342.342 0 11.5-.464l1.34 1.461a.338.338 0 01-.02.484.319.319 0 01-.228.091z"
                        />
                        <Path
                            data-name="Path 3834"
                            d="M32.286 19.015a.361.361 0 00.252-.111l1.34-1.461a.342.342 0 00-.5-.464l-1.34 1.461a.338.338 0 00.02.484.355.355 0 00.228.091z"
                        />
                        <Path
                            data-name="Path 3835"
                            d="M25.645 19.831a3.4 3.4 0 003.5 3.033c2.63 0 3.5-1.522 3.5-3.033 0-2.408-1.562-2.741-3.5-2.741s-3.5 1.229-3.5 2.741zm.726.03a2.53 2.53 0 012.774-2.176c1.532 0 2.771.272 2.771 2.177 0 1.2-.685 2.408-2.771 2.408a2.7 2.7 0 01-2.774-2.409z"
                        />
                    </G>
                    <Path
                        data-name="Path 3836"
                        d="M29.141 17.684c1.532 0 2.771.272 2.771 2.177 0 1.2-.685 2.408-2.771 2.408a2.7 2.7 0 01-2.771-2.408 2.53 2.53 0 012.771-2.177z"
                        fill="#f4c4a4"
                    />
                    <Path
                        data-name="Path 3837"
                        d="M29.141 17.684a3.7 3.7 0 00-.756.081 2.339 2.339 0 00-2.015 2.1 2.594 2.594 0 002.005 2.308l1.663-4.434a6.416 6.416 0 00-.897-.055z"
                        fill="#f4ccb0"
                    />
                    <Path
                        data-name="Path 3838"
                        d="M30.039 22.179a2.218 2.218 0 001.864-2.318 1.859 1.859 0 00-1.864-2.126l-1.663 4.434a2.705 2.705 0 00.766.1 4.029 4.029 0 00.897-.09z"
                        fill="#f4c4a4"
                    />
                    <G data-name="Group 2701" fill="#261d17">
                        <Path
                            data-name="Path 3839"
                            d="M26.229 20.214h-2.187a12.97 12.97 0 01-.574-1.2h3.043a10.322 10.322 0 01-.282 1.2z"
                        />
                        <Path
                            data-name="Path 3840"
                            d="M26.037 20.223a1.924 1.924 0 00-1.058-.423 1.968 1.968 0 00-.826.282c-.393.2-.393 1.28-.393 1.28a1.359 1.359 0 011.229-.776 1.382 1.382 0 011.229.776c-.009.01.112-.937-.181-1.139z"
                        />
                    </G>
                    <Path
                        data-name="Path 3843"
                        d="M30.885 33.917l-1.875-1.632-4.01 2s5.854 3.305 5.885 3.285z"
                        fill="#4ecff2"
                    />
                    <Path
                        data-name="Path 3844"
                        d="M36.074 34.855l-6.52-2.116L25 47.269l8.253-9.673-1.108-1.108h1.663z"
                        fill="#c9dee2"
                    />
                    <Path
                        data-name="Path 3845"
                        d="M19.115 33.917l1.874-1.632 4.01 2.005s-5.854 3.305-5.875 3.285v-3.658z"
                        fill="#4ecff2"
                    />
                    <Path
                        data-name="Path 3846"
                        d="M13.916 34.855l6.55-2.116L25 47.269l-8.263-9.673 1.108-1.108h-1.663z"
                        fill="#c9dee2"
                    />
                    <G data-name="Group 2703" fill="#3f3f3f">
                        <Path
                            data-name="Path 3847"
                            d="M21.645 31.439c-3.517.867-6.026 3.043-6.026 5.582v8.6h.5v-8.6c0-2.287 2.287-4.252 5.512-5.068a2.88 2.88 0 00.014-.514z"
                        />
                        <Path
                            data-name="Path 3848"
                            d="M28.356 31.439c0 .181 0 .353.01.514 3.235.816 5.512 2.781 5.512 5.068v1.582h.5v-1.572c.004-2.549-2.505-4.725-6.022-5.592z"
                        />
                    </G>
                    <Circle
                        data-name="Ellipse 1196"
                        cx={1.683}
                        cy={1.683}
                        r={1.683}
                        transform="translate(14.198 43.057)"
                        fill="#5e5e5e"
                    />
                    <Circle
                        data-name="Ellipse 1197"
                        cx={1.471}
                        cy={1.471}
                        r={1.471}
                        transform="translate(14.409 43.269)"
                        fill="#828282"
                    />
                    <Circle
                        data-name="Ellipse 1198"
                        cx={1.038}
                        cy={1.038}
                        r={1.038}
                        transform="translate(14.843 43.702)"
                        fill="#5e5e5e"
                    />
                    <Circle
                        data-name="Ellipse 1199"
                        cx={0.746}
                        cy={0.746}
                        r={0.746}
                        transform="translate(15.135 43.994)"
                        fill="#828282"
                    />
                    <G data-name="Group 2704" fill="#69696b">
                        <Path
                            data-name="Path 3849"
                            d="M35.212 46.282a4.29 4.29 0 01-2.025-.363 2.677 2.677 0 01-1.27-2.59v-2.176h.4v2.177a2.276 2.276 0 001.048 2.237 4.07 4.07 0 001.9.322h.06v.4h-.06a.128.128 0 00-.053-.007z"
                        />
                        <Path
                            data-name="Path 3850"
                            d="M35.761 46.081a.426.426 0 01-.423.423.426.426 0 01-.423-.423.426.426 0 01.423-.423.419.419 0 01.423.423z"
                        />
                    </G>
                    <G data-name="Group 2705" fill="#8d8d8e">
                        <Path
                            data-name="Path 3851"
                            d="M33.031 46.271h-.121v-.4h.06a4.09 4.09 0 001.9-.322 2.276 2.276 0 001.048-2.237v-2.18h.4v2.177a2.649 2.649 0 01-1.27 2.59 4.185 4.185 0 01-2.017.372z"
                        />
                        <Path
                            data-name="Path 3852"
                            d="M32.487 46.07a.426.426 0 00.423.423.426.426 0 00.423-.423.426.426 0 00-.423-.423.413.413 0 00-.423.423z"
                        />
                    </G>
                    <Path
                        data-name="Path 3853"
                        d="M36.124 41.394a.25.25 0 01-.252-.252v-.665a1.754 1.754 0 00-3.507 0v.665a.252.252 0 11-.5 0v-.665a2.257 2.257 0 014.514 0v.665a.256.256 0 01-.255.252z"
                        fill="#3f3f3f"
                    />
                </G>
            </Svg>
    },
    {
        "icon":
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={28.34}
                height={44.052}
                viewBox="0 0 28.34 44.052" >
                <G data-name="doctor (3)">
                    <Path
                        data-name="Path 3790"
                        d="M11.488 17.567l-.674 3.387-2.258.777 1.336 21.081 8.592-.049.4-21.43-1.442-.454-.735-3.455-5.22.143z"
                        fill="#f0ba7d"
                    />
                    <Path
                        data-name="Path 3791"
                        d="M8.044 27.613c3.759 1.823 7.626 2.205 11.7.1l-.139 15.734a24.971 24.971 0 01-11.7-.19z"
                        fill="#21d0c3"
                    />
                    <Path
                        data-name="Path 3792"
                        d="M18.168 21.137l6.548 1.786c1.7.524 3.215 1.528 3.264 3.333l.359 13.336a24.758 24.758 0 01-4.812 2.624l-.418-7.375-1.147 7.954a24.752 24.752 0 01-7.815 1.258h-.213v-6.761z"
                        fill="#eef3f7"
                    />
                    <G data-name="Group 2692" fill="#fff">
                        <Path
                            data-name="Path 3793"
                            d="M18.106 21.091l.863.348 2.944 4.419-3.45-.055 2.139 1.144-6.669 10.342 4.174-16.2z"
                        />
                        <Path
                            data-name="Path 3794"
                            d="M10.508 21.07l-6.579 1.67c-1.707.494-3.242 1.471-3.322 3.275L0 39.622a24.762 24.762 0 004.766 2.593l.56-7.531 1.025 8.117a24.751 24.751 0 007.585 1.249v-6.761L10.512 21.07z"
                        />
                    </G>
                    <Path
                        data-name="Path 3795"
                        d="M10.571 21.024l-1.029.394-2.861 4.305 3.451.005-2.159 1.106 5.959 10.453-3.361-16.264z"
                        fill="#eef3f7"
                    />
                    <Path
                        data-name="Path 3796"
                        d="M7.035 9.446c-.11 1.11.112 3.574 1.459 3.48 0 0 1.938 5.648 5.669 6.287 3.221-.589 5.18-5.7 5.329-6.19.444.152 1.685-1.914.733-3.6-.273-.485-1.046-.092-1.046-.092L19.12 7.92c-5.09.494-8.264-4.429-8.264-4.429a8.161 8.161 0 00-2.615 5.94 1.728 1.728 0 00-.769-.235.755.755 0 00-.437.253z"
                        fill="#fed298"
                    />
                    <Path
                        data-name="Path 3797"
                        d="M10.814 20.954l.659-3.311a14.158 14.158 0 01-2.906-4.717c-1.3.145-1.824-3.441-.948-3.567a1.272 1.272 0 01.668.068c.2-2.772.731-3.614 2.568-5.94 0 0 3.209 5.4 8.294 4.9l.073 1.164a.6.6 0 011.046.144c.6.98-.184 3.207-.905 3.343a12.333 12.333 0 01-2.606 4.613l.686 3.224c1.908.618 3.749 1.1 5.6 1.586-.938-5.72-1.476-11.909-2.6-17.192C19.368.199 13.49-1.219 10.11 1.033a6.3 6.3 0 00-3.447 5.068c-.532 3.894-.328 4.27-.328 4.27l-.939 11.992c1.741-.442 3.757-.842 5.418-1.413z"
                        fill="#666"
                    />
                    <Path
                        data-name="Path 3798"
                        d="M23.112 35.039a1.779 1.779 0 112.055-1.45 1.779 1.779 0 01-2.055 1.45z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3799"
                        d="M23.129 34.932a1.675 1.675 0 111.936-1.365 1.675 1.675 0 01-1.936 1.365z"
                        fill="#0f7986"
                    />
                    <Path
                        data-name="Path 3800"
                        d="M23.257 34.195a.927.927 0 111.071-.756.927.927 0 01-1.071.756z"
                        fill="#ebecec"
                    />
                    <Path
                        data-name="Path 3801"
                        d="M23.366 32.465l.407.07.219-1.268-.407-.07-.219 1.268z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3802"
                        d="M9.727 35.386l.763-.151a1.2 1.2 0 00.945-1.413l-.9-4.521a3.466 3.466 0 00-3.2-2.769 5.753 5.753 0 00-3.472.689 3.461 3.461 0 00-1.891 3.78l.9 4.521a1.2 1.2 0 00.512.764 1.2 1.2 0 00.9.181l.763-.151-.062-.314-.763.151a.88.88 0 01-.661-.132.881.881 0 01-.375-.56l-.9-4.521A3.155 3.155 0 014.02 27.5a5.427 5.427 0 013.275-.65 3.161 3.161 0 012.924 2.515l.9 4.521a.884.884 0 01-.693 1.036l-.763.151.062.314z"
                        fill="#21d0c3"
                    />
                    <Path
                        data-name="Path 3803"
                        d="M10.784 29.97l-.137-.691a3.567 3.567 0 00-3.29-2.854 5.859 5.859 0 00-3.537.7 3.563 3.563 0 00-1.944 3.894l.137.691.524-.1-.137-.691a3.055 3.055 0 011.682-3.327 5.326 5.326 0 013.202-.636 3.06 3.06 0 012.832 2.43l.137.691.524-.1z"
                        fill="#4d4d4d"
                    />
                    <G data-name="Group 2693" fill="#076673">
                        <Path
                            data-name="Path 3804"
                            d="M4.444 36.503l.408-.081a.448.448 0 10-.088-.445l-.408.081z"
                        />
                        <Path
                            data-name="Path 3805"
                            d="M10.052 35.392l-.408.081a.448.448 0 11-.088-.445l.408-.081.088.445z"
                        />
                    </G>
                    <Path
                        data-name="Path 3806"
                        d="M5.776 26.456l-.156-.784-.31.062-.309.061.157.792q.177-.052.358-.087.13-.026.26-.044z"
                        fill="#666"
                    />
                    <G data-name="Group 2694" fill="#0f7986">
                        <Path
                            data-name="Path 3807"
                            d="M5.108 36.067a.136.136 0 10-.159-.107.136.136 0 00.159.107z"
                        />
                        <Path
                            data-name="Path 3808"
                            d="M9.144 35.265a.136.136 0 10-.16-.106.135.135 0 00.16.106z"
                        />
                    </G>
                    <Path
                        data-name="Path 3809"
                        d="M23.207 34.186a.876.876 0 111.013-.715.877.877 0 01-1.013.715z"
                        fill="#fefefe"
                    />
                    <G data-name="Group 2695" fill="#4d4d4d">
                        <Path
                            data-name="Path 3810"
                            d="M9.553 21.437c-.014 0-4.831.04-4.017 4.254l-.448.086c-.917-4.751 4.444-4.795 4.46-4.795l.005.455z"
                        />
                        <Path
                            data-name="Path 3811"
                            d="M19.165 21.004a4.558 4.558 0 013.474 1.658c1.144 1.392 1.946 3.948 1.347 8.635l-.452-.057c.581-4.536-.17-6.978-1.246-8.289a4.112 4.112 0 00-3.134-1.492l.011-.455z"
                        />
                    </G>
                </G>
            </Svg>
    },
    {
        "icon":
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={34.345}
                height={44.402}
                viewBox="0 0 34.345 44.402" >
                <G data-name="doctor (2)">
                    <Path
                        data-name="Path 3762"
                        d="M13.688 18.762h6.968l.038.476-3.405 5.162-3.658-5.213.057-.425z"
                        fill="#b3b3b3"
                    />
                    <Path
                        data-name="Path 3763"
                        d="M13.733 17.467l-.06 2.912-4.94 2.7v9.9h16.629v-9.9l-4.591-2.854-.252-2.911-6.788.152z"
                        fill="#f0ba7d"
                    />
                    <Path
                        data-name="Path 3764"
                        d="M11.455 8.975a.756.756 0 00-1.044-.314c-.761.308.034 3.384.325 3.813s.758.157.928.14c.242 1.833.264 3.1 1 3.87a22.782 22.782 0 003.125 2.744 4.675 4.675 0 002.78 0 22.758 22.758 0 003.119-2.741c.734-.773.756-2.037 1-3.87.17.017.638.289.928-.14s1.086-3.5.325-3.813a.756.756 0 00-1.044.314 6.6 6.6 0 00-.585-4.793c-.916-1.629-3-2.474-5.138-2.536-2.133.062-4.222.907-5.138 2.536a6.6 6.6 0 00-.585 4.793z"
                        fill="#fed298"
                    />
                    <Path
                        data-name="Path 3765"
                        d="M11.25 21.236l1.183-.466.953-.857 3.366 2.553h.838l3.367-2.556 2.136 1.326 3.712 6.309-2.94 15.949a25.147 25.147 0 01-12.2.3L6.396 28.051l4.851-6.814z"
                        fill="#15bdb2"
                    />
                    <G data-name="Group 2687" fill="#21d0c3">
                        <Path
                            data-name="Path 3766"
                            d="M22.014 20.433l-1.359-1.676-3.064 3.7 2.63 2.923z"
                        />
                        <Path
                            data-name="Path 3767"
                            d="M12.327 20.408l1.361-1.646 3.065 3.7-2.735 2.893z"
                        />
                    </G>
                    <Path
                        data-name="Path 3768"
                        d="M12.327 20.408l-9.008 2.929a1.016 1.016 0 00-.671.808L0 37.569a25.056 25.056 0 006.036 4.219l1.317-4.3a.106.106 0 01.206.025l.19 5.047a24.915 24.915 0 009.423 1.839v-1.676l-4.845-22.318z"
                        fill="#fff"
                    />
                    <G data-name="Group 2688" fill="#eef3f7">
                        <Path
                            data-name="Path 3769"
                            d="M12.332 20.408l-1.032.317-2.733 4.664 3.45.108-2.118 1.206 7.278 16.023-4.845-22.318z"
                        />
                        <Path
                            data-name="Path 3770"
                            d="M22.015 20.438l9.01 2.9a1.014 1.014 0 01.671.808l2.649 13.423a25.063 25.063 0 01-6.036 4.22l-1.317-4.3a.105.105 0 00-.206.025l-.19 5.047a24.915 24.915 0 01-9.423 1.841v-1.676l4.842-22.288z"
                        />
                    </G>
                    <Path
                        data-name="Path 3771"
                        d="M22.014 20.438l.573.174 3.193 4.806-3.45.108 2.119 1.206-7.277 15.994 4.842-22.288z"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 3772"
                        d="M18.193 42.621a.549.549 0 10.55.548.549.549 0 00-.55-.548z"
                        fill="#d9dada"
                    />
                    <Path
                        data-name="Path 3773"
                        d="M22.238 9.694l.53-.019.171-.6.5-.5c.33-2.483.427-6.348-2.038-6.236 0 0-.222-2.818-4.668-2.265s-6.455 3.422-6.215 5.817a11.928 11.928 0 01.143 2.684l.5.5.075.688.436-.028a11.079 11.079 0 01.272-1.33 4.485 4.485 0 00.094-1.225s5.251.335 6.67-1.471c.876-1.115 2.889-3.046 2.991-.371.03.793-.265 1.982.3 2.847a1.566 1.566 0 01.239 1.508z"
                        fill="#666"
                    />
                    <Path
                        data-name="Path 3774"
                        d="M17.168 42.726l1.419-6.533-.965-12.878.526-.224-.562-.624h-.838l-.576.61.541.239-.94 12.982 1.4 6.429z"
                        fill="#0f7986"
                    />
                    <Path
                        data-name="Path 3775"
                        d="M18.144 42.621a.5.5 0 10.5.5.5.5 0 00-.5-.5z"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 3776"
                        d="M26 34.35a1.793 1.793 0 112.071-1.461A1.793 1.793 0 0126 34.35z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3777"
                        d="M26.017 34.248a1.688 1.688 0 111.951-1.376 1.688 1.688 0 01-1.951 1.376z"
                        fill="#0f7986"
                    />
                    <Path
                        data-name="Path 3778"
                        d="M26.146 33.504a.934.934 0 111.079-.762.934.934 0 01-1.079.762z"
                        fill="#ebecec"
                    />
                    <Path
                        data-name="Path 3779"
                        d="M26.255 31.764l.41.071.221-1.278-.41-.071z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3780"
                        d="M12.509 34.705l.769-.153a1.214 1.214 0 00.952-1.424l-.9-4.557a3.493 3.493 0 00-3.224-2.791 5.8 5.8 0 00-3.5.694 3.488 3.488 0 00-1.906 3.81l.9 4.557a1.209 1.209 0 00.516.77 1.207 1.207 0 00.908.182l.769-.153-.063-.317-.769.153a.887.887 0 01-.666-.133.887.887 0 01-.378-.565l-.9-4.557a3.18 3.18 0 011.748-3.467 5.47 5.47 0 013.3-.655 3.186 3.186 0 012.947 2.535l.9 4.557a.891.891 0 01-.7 1.044l-.769.153.063.317z"
                        fill="#21d0c3"
                    />
                    <Path
                        data-name="Path 3781"
                        d="M13.57 29.247l-.138-.7a3.6 3.6 0 00-3.316-2.877 5.906 5.906 0 00-3.566.707 3.591 3.591 0 00-1.959 3.925l.138.7.528-.105-.138-.7a3.079 3.079 0 011.7-3.353 5.369 5.369 0 013.236-.641 3.084 3.084 0 012.855 2.449l.138.7.528-.1z"
                        fill="#4d4d4d"
                    />
                    <G data-name="Group 2689" fill="#076673">
                        <Path
                            data-name="Path 3782"
                            d="M7.184 35.831l.411-.082a.452.452 0 10-.089-.448l-.411.082.089.448z"
                        />
                        <Path
                            data-name="Path 3783"
                            d="M12.836 34.709l-.411.082a.452.452 0 11-.089-.448l.411-.082z"
                        />
                    </G>
                    <Path
                        data-name="Path 3784"
                        d="M8.527 25.705l-.157-.791-.312.062-.312.062.159.8q.178-.052.361-.088c.086-.019.173-.034.261-.045z"
                        fill="#666"
                    />
                    <G data-name="Group 2690" fill="#0f7986">
                        <Path
                            data-name="Path 3785"
                            d="M7.853 35.392a.137.137 0 10-.161-.108.137.137 0 00.161.108z"
                        />
                        <Path
                            data-name="Path 3786"
                            d="M11.92 34.583a.137.137 0 10-.161-.107.137.137 0 00.161.107z"
                        />
                    </G>
                    <Path
                        data-name="Path 3787"
                        d="M26.092 33.496a.883.883 0 111.021-.72.884.884 0 01-1.021.72z"
                        fill="#fefefe"
                    />
                    <G data-name="Group 2691" fill="#4d4d4d">
                        <Path
                            data-name="Path 3788"
                            d="M12.331 20.646c-.014 0-4.869.04-4.049 4.288l-.452.086c-.924-4.789 4.48-4.833 4.495-4.833z"
                        />
                        <Path
                            data-name="Path 3789"
                            d="M22.019 20.209a4.6 4.6 0 013.5 1.671c1.153 1.4 1.962 3.979 1.357 8.7l-.455-.057c.585-4.572-.171-7.034-1.256-8.354a4.144 4.144 0 00-3.158-1.5l.011-.459z"
                        />
                    </G>
                </G>
            </Svg>
    },
    {
        "icon":
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={34.345}
                height={44.402}
                viewBox="0 0 34.345 44.402" >
                <G data-name="doctor (2)">
                    <Path
                        data-name="Path 3762"
                        d="M13.688 18.762h6.968l.038.476-3.405 5.162-3.658-5.213.057-.425z"
                        fill="#b3b3b3"
                    />
                    <Path
                        data-name="Path 3763"
                        d="M13.733 17.467l-.06 2.912-4.94 2.7v9.9h16.629v-9.9l-4.591-2.854-.252-2.911-6.788.152z"
                        fill="#f0ba7d"
                    />
                    <Path
                        data-name="Path 3764"
                        d="M11.455 8.975a.756.756 0 00-1.044-.314c-.761.308.034 3.384.325 3.813s.758.157.928.14c.242 1.833.264 3.1 1 3.87a22.782 22.782 0 003.125 2.744 4.675 4.675 0 002.78 0 22.758 22.758 0 003.119-2.741c.734-.773.756-2.037 1-3.87.17.017.638.289.928-.14s1.086-3.5.325-3.813a.756.756 0 00-1.044.314 6.6 6.6 0 00-.585-4.793c-.916-1.629-3-2.474-5.138-2.536-2.133.062-4.222.907-5.138 2.536a6.6 6.6 0 00-.585 4.793z"
                        fill="#fed298"
                    />
                    <Path
                        data-name="Path 3765"
                        d="M11.25 21.236l1.183-.466.953-.857 3.366 2.553h.838l3.367-2.556 2.136 1.326 3.712 6.309-2.94 15.949a25.147 25.147 0 01-12.2.3L6.396 28.051l4.851-6.814z"
                        fill="#15bdb2"
                    />
                    <G data-name="Group 2687" fill="#21d0c3">
                        <Path
                            data-name="Path 3766"
                            d="M22.014 20.433l-1.359-1.676-3.064 3.7 2.63 2.923z"
                        />
                        <Path
                            data-name="Path 3767"
                            d="M12.327 20.408l1.361-1.646 3.065 3.7-2.735 2.893z"
                        />
                    </G>
                    <Path
                        data-name="Path 3768"
                        d="M12.327 20.408l-9.008 2.929a1.016 1.016 0 00-.671.808L0 37.569a25.056 25.056 0 006.036 4.219l1.317-4.3a.106.106 0 01.206.025l.19 5.047a24.915 24.915 0 009.423 1.839v-1.676l-4.845-22.318z"
                        fill="#fff"
                    />
                    <G data-name="Group 2688" fill="#eef3f7">
                        <Path
                            data-name="Path 3769"
                            d="M12.332 20.408l-1.032.317-2.733 4.664 3.45.108-2.118 1.206 7.278 16.023-4.845-22.318z"
                        />
                        <Path
                            data-name="Path 3770"
                            d="M22.015 20.438l9.01 2.9a1.014 1.014 0 01.671.808l2.649 13.423a25.063 25.063 0 01-6.036 4.22l-1.317-4.3a.105.105 0 00-.206.025l-.19 5.047a24.915 24.915 0 01-9.423 1.841v-1.676l4.842-22.288z"
                        />
                    </G>
                    <Path
                        data-name="Path 3771"
                        d="M22.014 20.438l.573.174 3.193 4.806-3.45.108 2.119 1.206-7.277 15.994 4.842-22.288z"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 3772"
                        d="M18.193 42.621a.549.549 0 10.55.548.549.549 0 00-.55-.548z"
                        fill="#d9dada"
                    />
                    <Path
                        data-name="Path 3773"
                        d="M22.238 9.694l.53-.019.171-.6.5-.5c.33-2.483.427-6.348-2.038-6.236 0 0-.222-2.818-4.668-2.265s-6.455 3.422-6.215 5.817a11.928 11.928 0 01.143 2.684l.5.5.075.688.436-.028a11.079 11.079 0 01.272-1.33 4.485 4.485 0 00.094-1.225s5.251.335 6.67-1.471c.876-1.115 2.889-3.046 2.991-.371.03.793-.265 1.982.3 2.847a1.566 1.566 0 01.239 1.508z"
                        fill="#666"
                    />
                    <Path
                        data-name="Path 3774"
                        d="M17.168 42.726l1.419-6.533-.965-12.878.526-.224-.562-.624h-.838l-.576.61.541.239-.94 12.982 1.4 6.429z"
                        fill="#0f7986"
                    />
                    <Path
                        data-name="Path 3775"
                        d="M18.144 42.621a.5.5 0 10.5.5.5.5 0 00-.5-.5z"
                        fill="#fff"
                    />
                    <Path
                        data-name="Path 3776"
                        d="M26 34.35a1.793 1.793 0 112.071-1.461A1.793 1.793 0 0126 34.35z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3777"
                        d="M26.017 34.248a1.688 1.688 0 111.951-1.376 1.688 1.688 0 01-1.951 1.376z"
                        fill="#0f7986"
                    />
                    <Path
                        data-name="Path 3778"
                        d="M26.146 33.504a.934.934 0 111.079-.762.934.934 0 01-1.079.762z"
                        fill="#ebecec"
                    />
                    <Path
                        data-name="Path 3779"
                        d="M26.255 31.764l.41.071.221-1.278-.41-.071z"
                        fill="#fefefe"
                    />
                    <Path
                        data-name="Path 3780"
                        d="M12.509 34.705l.769-.153a1.214 1.214 0 00.952-1.424l-.9-4.557a3.493 3.493 0 00-3.224-2.791 5.8 5.8 0 00-3.5.694 3.488 3.488 0 00-1.906 3.81l.9 4.557a1.209 1.209 0 00.516.77 1.207 1.207 0 00.908.182l.769-.153-.063-.317-.769.153a.887.887 0 01-.666-.133.887.887 0 01-.378-.565l-.9-4.557a3.18 3.18 0 011.748-3.467 5.47 5.47 0 013.3-.655 3.186 3.186 0 012.947 2.535l.9 4.557a.891.891 0 01-.7 1.044l-.769.153.063.317z"
                        fill="#21d0c3"
                    />
                    <Path
                        data-name="Path 3781"
                        d="M13.57 29.247l-.138-.7a3.6 3.6 0 00-3.316-2.877 5.906 5.906 0 00-3.566.707 3.591 3.591 0 00-1.959 3.925l.138.7.528-.105-.138-.7a3.079 3.079 0 011.7-3.353 5.369 5.369 0 013.236-.641 3.084 3.084 0 012.855 2.449l.138.7.528-.1z"
                        fill="#4d4d4d"
                    />
                    <G data-name="Group 2689" fill="#076673">
                        <Path
                            data-name="Path 3782"
                            d="M7.184 35.831l.411-.082a.452.452 0 10-.089-.448l-.411.082.089.448z"
                        />
                        <Path
                            data-name="Path 3783"
                            d="M12.836 34.709l-.411.082a.452.452 0 11-.089-.448l.411-.082z"
                        />
                    </G>
                    <Path
                        data-name="Path 3784"
                        d="M8.527 25.705l-.157-.791-.312.062-.312.062.159.8q.178-.052.361-.088c.086-.019.173-.034.261-.045z"
                        fill="#666"
                    />
                    <G data-name="Group 2690" fill="#0f7986">
                        <Path
                            data-name="Path 3785"
                            d="M7.853 35.392a.137.137 0 10-.161-.108.137.137 0 00.161.108z"
                        />
                        <Path
                            data-name="Path 3786"
                            d="M11.92 34.583a.137.137 0 10-.161-.107.137.137 0 00.161.107z"
                        />
                    </G>
                    <Path
                        data-name="Path 3787"
                        d="M26.092 33.496a.883.883 0 111.021-.72.884.884 0 01-1.021.72z"
                        fill="#fefefe"
                    />
                    <G data-name="Group 2691" fill="#4d4d4d">
                        <Path
                            data-name="Path 3788"
                            d="M12.331 20.646c-.014 0-4.869.04-4.049 4.288l-.452.086c-.924-4.789 4.48-4.833 4.495-4.833z"
                        />
                        <Path
                            data-name="Path 3789"
                            d="M22.019 20.209a4.6 4.6 0 013.5 1.671c1.153 1.4 1.962 3.979 1.357 8.7l-.455-.057c.585-4.572-.171-7.034-1.256-8.354a4.144 4.144 0 00-3.158-1.5l.011-.459z"
                        />
                    </G>
                </G>
            </Svg>
    },
];
const AppointmentSlotHandler = props => {
    const [index, setIndex] = useState(Math.floor(Math.random() * 3) + 1);

    return (
        <div className="emergency-card">
            <div className="emergency-card-inner-div">
                <div className="contact-image-container">
                    <View style={{ width: 50, height: 50, borderRadius: 50, backgroundColor: "#759DCD", alignItems: 'center', paddingTop: 6, }}>
                        {SVGlist[index].icon}
                    </View>
                    {/* <img src="https://thumbs.dreamstime.com/b/default-female-avatar-profile-picture-icon-grey-woman-photo-placeholder-vector-illustration-88413637.jpg" alt className="contact-image img-fluid rounded-circle" /> */}
                </div>
                <div className="card-content">
                    <div className="card-heading">
                        <h5>{props.providerName}</h5>
                    </div>
                    <div className="card-partner">
                        <span>{props.type}</span>
                    </div>
                </div>
            </div>
            <hr className="w-100" />
            <div className="card-details-div">
                <div className="card-detail">
                    <strong>Date and time </strong>
                    <span className="datetime-span">{props.time}</span>
                </div>
                <div className="card-detail ml-auto">
                    <strong>Duration</strong>
                    <span className="span-duration">{props.duration}</span>
                </div>
            </div>
        </div>


    );
}

export default AppointmentSlotHandler
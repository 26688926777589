import React,{useEffect} from 'react';
import { View, Text, ActivityIndicator, FlatList } from "react-native-web";
import { DatePicker } from "antd";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'

import AppointmentHandler from '../../components/AppointmentHandler';
import { searchAppointmentFromApi } from "../../../shared/actions/SearchAppointmentActions";
import { GetDayName, GetAppointmentDate, GetMonthName, ReturnTypeIcon, GetYear, FormateUTCTime, GetMonthNumber } from '../../../shared/core/datehelper'

import moment from "moment";

const { RangePicker } = DatePicker;
const SearchAppointmentView = props => {


    useEffect(() => {
        props.searchAppointment('2017-01-01' ,'2021-12-31')

          
    }, [])
    const { SearchAppointment, isGettingSearch } = props.SearchAppointment;
    const dateFormat = 'DD-MM-YYYY';
    //const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const dateChange = (date, dateString) => {
        if (date === null) {
            props.searchAppointment('2017-01-01' ,'2021-12-31')

        }
        else {

            //console.log(date)
            //console.log(JSON.stringify(date[0]))




            // // alert(dateString)
            // var str = dateString.toString();
            // //alert(str)
            // str = str.split(",");

            // let sDate = GetMonthNumber(str[0])// GetYear(str[0]) + '-' + GetMonthNumber(str[0]) + '-' + GetAppointmentDate(str[0])
            props.searchAppointment(JSON.stringify(date[0]).replace(/['"]+/g, ''), JSON.stringify(date[1]).replace(/['"]+/g, ''))

            // //console.log(str);


            // //console.log(moment(str[0]).format())

            // //var d = new Date(str[0]);

            // ////console.log(d.getDate())
            // // var D = new Date(str[0]).toString().split("-");
            // ////console.log(D[2] + "-" + D[1] + "-" + D[3]);
            // //alert(str[0] + '-----' + d.getFullYear())
        }

    }

    const getTagStyles = (type) => {
        if (type === "Cancelled") {
            return 'card-header text-white bg-warning'
        }
        if (type === "Missed") {
            return 'card-header text-white bg-danger'
        }
        if (type === "Future") {
            return 'card-header text-white bg-primary'
        }
        if (type === "Completed") {
            return 'card-header text-white bg-success'
        }
    }
    return (
        <div>
            <div className="row">
                <div className="col-12">
                    <div style={{ marginLeft: 10, alignItems: 'center' }}>
                        {/* <span className="badge badge-primary" style={{ marginLeft: 15, marginTop: 10 }}>Future</span> */}
                        <span className="badge badge-success" style={{ marginLeft: 15, marginTop: 10 }}>Completed</span>
                        <span className="badge badge-danger" style={{ marginLeft: 15, marginTop: 10 }}>Missed</span>
                        <span className="badge badge-warning" style={{ marginLeft: 15, marginTop: 10 }}>Cancelled </span>
                    </div>

                </div>
            </div>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                <Text>Search Appointments: </Text>
                <RangePicker
                    format={dateFormat}
                    
                    onChange={dateChange} />
            </View>


            {
                isGettingSearch && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (SearchAppointment) !== 'undefined' && SearchAppointment.length ? (
                    <FlatList
                        style={{
                            flexDirection: 'column', paddingLeft: 10,
                            paddingRight: 10,
                            paddingBottom: 10
                        }}
                        //contentContainerStyle={{ flexDirection: "row", flexWrap: "wrap", justifyContent: 'space-evenly' }}
                        data={SearchAppointment}
                        renderItem={({ item }) => (
                            item.resource.extension[6].valueString != "Future" &&
                            <AppointmentHandler
                                providerName={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                                styleName={getTagStyles(item.resource.extension[6].valueString)}
                                appType={item.resource.extension[0].valueString ? item.resource.extension[0].valueString : 'N/A'}
                                providerName={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                               // appointmentDate={GetAppointmentDate(item.resource.period.start) + ' ' + GetMonthName(item.resource.period.start) + ' ' + GetYear(item.resource.period.start)}
                                appointmentDate={GetAppointmentDate(item.resource.period.start.split('T')[0]) + ' ' + GetMonthName(item.resource.period.start.split('T')[0]) + ' ' + GetYear(item.resource.period.start.split('T')[0])}
                                praticeAddress={item.resource.extension[3].hasOwnProperty('valueString') ? item.resource.extension[3].valueString : '(N/A)'}
                                firstitem={item.resource.extension[5].valueString ? item.resource.extension[5].valueString : 'N/A'}
                                secondItem={GetDayName(item.resource.period.start.split('T')[0]) + ', ' + FormateUTCTime(item.resource.period.start)}
                                patientDetails={item.resource.extension[1].valueString} //+ ', ' + item.resource.extension[0].valueString}}
                                location={item.resource.extension[4].hasOwnProperty('valueString') ? item.resource.extension[4].valueString : '(N/A)'}
                                icTpye={'mdi mdi-18px mdi-alert-circle'} //this icons for cancel reason
                                reason={"Appointment Status: " + item.resource.extension[6].valueString}
                                type={item.resource.extension[6].valueString}
                                icTpyeCancel={'mdi mdi-18px mdi-close-circle'}
                                reasonForCancel={item.resource.extension[6].valueString === "Cancelled" ? item.resource.extension[9].valueString !== undefined || item.resource.extension[9].valueString !== " " ? 'Reason: ' + item.resource.extension[9].valueString : 'Reason: N/A' : null}


                            />
                        )}
                        horizontal={false}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={30}

                    />
                ) : !isGettingSearch && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ marginTop: 10, }}>No Records Found.</Text>
                </View>
            }

            {/* <AppointmentHandler styleName={'card-header text-white bg-warning'} />
            <AppointmentHandler styleName={'card-header text-white bg-danger'} />
            <AppointmentHandler styleName={'card-header text-white bg-warning'} />
            <AppointmentHandler styleName={'card-header text-white bg-primary'} />
            <AppointmentHandler styleName={'card-header text-white bg-primary'} />
            <AppointmentHandler styleName={'card-header text-white bg-success'} /> */}
        </div>
    );
}


function mapStateToProps(state) {
    return {
        SearchAppointment: state.SearchAppointment
    }
}

function mapDispatchToProps(dispatch) {
    return {
        searchAppointment: (fromDate, toDate) => dispatch(searchAppointmentFromApi(fromDate, toDate)),
        //getMoreMissedAppoint: (pageSize, pageNumber) => dispatch(fetchMoreAppointmentsFromApi(pageSize, pageNumber))
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(SearchAppointmentView)

//export default SearchAppointmentView

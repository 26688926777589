import { GET_VITAL, GET_VITAL_SUCCESS, GET_VITAL_FAILED, GET_VITAL_WHOLE_ARRAY } from "../constants/Constant";

//inital state
const initalState = {
    isLoading: false,
    GetVital: [],
    wholeArray: {},
    error: false,
    errorMessage:'',
    isWholeSuccess: false
}

//export default state
export default function GetVitalReducer(state = initalState, action) {
    switch (action.type) {
        case GET_VITAL:
            return {
                ...state,
                isLoading: true,
                isWholeSuccess: false

            }
        case GET_VITAL_SUCCESS:
            {
                return {
                    ...state,
                    GetVital: action.data,
                    isLoading: false,
                    isWholeSuccess: false

                }
            }
        case GET_VITAL_FAILED:
            {
                return {
                    ...state,
                    isLoading: false,
                    errorMessage:action.err,
                    // isAdding: false,
                    error: true,
                    isWholeSuccess: false

                }
            }
        case GET_VITAL_WHOLE_ARRAY:
            {
                return {
                    ...state,
                    wholeArray: action.data,
                    isWholeSuccess: true

                }
            }
        default:
            return state
    }
}
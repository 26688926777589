import React from "react";
import {
	StyleSheet,
	Text,
	View,
	Image,
	Dimensions,
	TouchableOpacity,
} from "react-native";
import { Tooltip } from "antd";

//export default function AppointmentHandler() {
const AppointmentHandler = (props) => {
	return (
		<div className="col-lg-12 ">
			<div
				className="card shadow p-0 mb-3 rounded"
				style={{ marginTop: 15, marginBottom: 10 }}>
				<div className={props.styleName}>
					<View
						style={{ flexDirection: "row", justifyContent: "space-between" }}>
						<Text style={{ fontWeight: "500", color: "#fff" }}>
							{props.providerName !== null ? props.providerName : "N/A"}
						</Text>

				<View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
						<Text
									style={{
										fontWeight: "500",
										color: "#fff",
										marginRight: 10,
									}}>
									{props.appointmentDate !== null
										? props.appointmentDate
										: "N/A"}
								</Text>
						{props.type == "Future" ? (
							<TouchableOpacity
								style={{ flexDirection: "row", alignItems: "center" }}
								onPress={() => {
									props.canelHandler();
								}}>
								

								<Tooltip placement="topLeft" title={"Cancel Appointment"}>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="30"
										viewBox="0 0 511.76 511.76">
										<g>
											<g>
												<path
													fill="#fff"
													d="M436.896,74.869c-99.84-99.819-262.208-99.819-362.048,0c-99.797,99.819-99.797,262.229,0,362.048    c49.92,49.899,115.477,74.837,181.035,74.837s131.093-24.939,181.013-74.837C536.715,337.099,536.715,174.688,436.896,74.869z     M361.461,331.317c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251    l-75.413-75.435l-75.392,75.413c-4.181,4.16-9.643,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251    c-8.341-8.341-8.341-21.845,0-30.165l75.392-75.413l-75.413-75.413c-8.341-8.341-8.341-21.845,0-30.165    c8.32-8.341,21.824-8.341,30.165,0l75.413,75.413l75.413-75.413c8.341-8.341,21.824-8.341,30.165,0    c8.341,8.32,8.341,21.824,0,30.165l-75.413,75.413L361.461,331.317z"
												/>
											</g>
										</g>
									</svg>
								</Tooltip>
							</TouchableOpacity>
						) : null}
						</View>
					</View>
				</div>
				<div className="card-body">
					{/* <h4 className="card-title"><i className="mdi mdi-18px mdi-home-map-marker" /> {props.praticeAddress}</h4> */}

					<div className="row">
						<div className="col-md-4">
							<h4 className="card-title">
								<i className="mdi mdi-18px mdi-home-map-marker" />{" "}
								{props.firstitem}
							</h4>
						</div>

						<div className="col-md-4">
							<p className="card-text">
								<i
									style={{ marginRight: 4 }}
									className="mdi mdi-18px mdi-calendar-clock"
								/>
								{props.secondItem}
							</p>
						</div>

						<div className="col-md-4">
							<p className="card-text">
								Appointment Type:
								<strong>{props.appType}</strong>
							</p>
						</div>
					</div>

					<p className="card-text">
						<i className="mdi mdi-18px mdi-hospital-marker" />
						{props.location}
					</p>

					{props.reason !== undefined && (
						<div>
							<View
								style={{
									width: "100%",
									marginTop: 0,
									borderTopWidth: 1,
									borderTopColor: "#DDDDDD",
								}}></View>
							<p className="card-text">
								<i style={{ marginRight: 4 }} className={props.icTpye} />
								{props.reason}
							</p>
							{props.reasonForCancel !== null && (
								<p className="card-text">
									<i
										style={{ marginRight: 4 }}
										className={props.icTpyeCancel}
									/>
									{props.reasonForCancel}
								</p>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const styles = StyleSheet.create({
	listItems: {
		flex: 0.5,
		//    alignSelf:'baseline',
		//s  alignItems: 'space-between',
	},
	listRow: {
		flexDirection: "row",
		justifyContent: "flex-start",
		padding: 5,
		marginTop: 10,
		marginLeft: 5,
		marginBottom: 3,
		marginRight: 5,
		borderRadius: 10,
		borderWidth: 0,
		borderColor: "#D1D1D6",
		backgroundColor: "white",
	},
	doctorTilteFont: {
		color: "#212121",
		fontSize: 17,
	},
	patientFont: {
		marginTop: 3,
		fontSize: 16,
		color: "#455A64",
	},
	dateSectionMainStyle: {
		opacity: 1,
		position: "relative",
		backgroundColor: "transparent",
		width: 50,
		height: 75,
		marginTop: 10,
		marginBottom: 10,
		justifyContent: "center",
		alignItems: "center",
	},
	dateSectionBackground: {
		opacity: 1,
		backgroundColor: "#1c4490",
		borderRadius: 5,
		width: 55,
		height: 65,
	},
	dateSectionTextPlacement: {
		opacity: 1,
		position: "absolute",
		height: "100%",
		width: "100%",
		borderRadius: 10,
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},
	appointmentTypeStyle: {
		marginTop: 5,
		marginBottom: 5,
		marginRight: 35,
		flex: 1,
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
	},

	appointmentTypeBox: {
		backgroundColor: "#C5C5C5",
		borderRadius: 5,
		marginBottom: 5,
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
	},
	appointmentTypeStyleText: {
		textAlign: "center",
		width: 80,
		marginBottom: 5,
		color: "#00A500",
		fontWeight: "400",
		fontSize: 16,
	},
	toptextDate: {
		fontSize: 14,
		fontWeight: "400",
		color: "#fff",
	},
});

export default AppointmentHandler;

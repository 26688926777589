import React from "react";

const UserInvoice = (props) => {
  return (
    <div className="invoice-grid-container" style={{ marginTop: 10 }}>
      <div className="invoice-grid">
        <div className="invoice-grid-card shadow">
          <div className="invoice-card-header">
            <span className="invoice-date">{props.invoice_date} {props.invoice_month}</span>
            <span className="incovice-amount">
              Amount : <span>{props.ammount}</span>
            </span>
            <a href className="invoice-download-link">
              <i className="mdi mdi-download" />
            </a>
          </div>
          <hr />
          <div className="invoice-card-body">
            <div className="cell">
              <h4>Person Name</h4>
              <p>{props.paitent_name}</p>
            </div>
            <div className="cell">
              <h4>Notes</h4>
              <p>{props.notes}</p>
            </div>

            <div className="cell">
              <h4>Type</h4>
              <p>{props.type}</p>
            </div>
            <div className="cell">
              <h4>Payee</h4>
              <p>{props.payename}</p>
            </div>
            <div className="cell">
              <h4>Reference No.</h4>
              <p>{props.reference}</p>
            </div>
            <div className="cell">
              <h4>Pay Now</h4>
              <p>{props.paynow}</p>
            </div>
            <div className="cell">
              <h4>Balance</h4>
              <p>{props.balance}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInvoice;

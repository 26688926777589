import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, Button, StyleSheet, StatusBar, Dimensions, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";
import { message, Modal } from 'antd';
import { fetchPharmcyFromApi } from '../../../shared/actions/GetPharmacyActions'
import { fetchAllMedsFromApi } from "../../../shared/actions/AllMedicationActions";
import LongMedicationHandler from "../../components/LongMedicationHandler";
import { GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchRecentMedsFromApi } from '../../../shared/actions/RecentMedAction';
import getUserId from '../../../shared/core/GetToken';
import Settings from '../../../shared/config/Settings';
import { Select, notification, Checkbox } from 'antd';

import GetAppSettings from '../../../shared/core/GetAppSettings';
let array = [];
const { Option, OptGroup } = Select;
const LongTermMed = props => {

    const { AllMedeications, isGetting } = props.AllMedeications;
    const { Pharmacy } = props.Pharmacy
    const { Practise } = props.Practise;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);           //state for the page number
    const [list, setList] = useState([])
    const [repeadtedmodalVisible, setrepeadtedmodalVisible] = useState(false);
    const [portalSetting, setPortalSetting] = useState(null);
    /////////////////////
    const [isSelected, setIsSelected] = useState(false);
    const [Pharmacyname, setPharmacyName] = React.useState({ id: '', name: 'Select Pharmacy' })
    const [isPickupSelected, setIsPickupSelected] = useState(true);
    const [isPharmacySelected, setIsPharmacySelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [ordertype, setOrderType] = useState('Pharmacy');
    const [ready, setReady] = useState(false);

    const openNotificationWithIcon = (type, value) => {
        notification[type]({
            message: 'Repeat Medication Request',
            description:
                value,
        });
    };
    useEffect(() => {
        GetAppSettings().then((info) => {
            const items = JSON.parse(info)
            let ob = {
                id: items.PharmacyId,
                name: items.PrefPharmacy,
            }
            setPharmacyName({ id: items.PharmacyId, name:items.PrefPharmacy });
            // alert(JSON.stringify(ob))
         //   selectedItems.push(items.PharmacyId)
        })
    }, [])
    ////////////////////////
    function capitalize(word) {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    useEffect(() => {
        //alert('Called Future')
        props.getMed(pageSize, pageNumber);
        props.getPharmacy()
        array = []
    }, [])
    const hanldeSlectedList = (value) => {
        /*
            ye code basically selected item ki list ko update kr rha yahan pe start pe pehli condioton chlay gi 
            us k bd pehly map chaly ga test ho ga k ye item pehly add tu ni agr hoga tu abc ki value true kr us item ko remove kr dain gy
            other wise map k end pe if ki conditionme ja k item list mei add ho jy ga
        */


        let abc = false;
        if (array.length == 0) {
            array = [...array, value]
        } else {
            array.map((item, index) => {
                if (item == value) {
                    array.splice(index, 1)
                    abc = true
                }
            })
            if (!abc) {
                array = [...array, value]
            }
        }
        //console.log({ array })
        setIsReady(!isReady)
    }
    useEffect(() => {
        GetAppSettings().then((info) => {
            const set = JSON.parse(info)
            setPortalSetting(set)
            if (!set.IsRxFax) {
                setOrderType("Pick Up")
                setIsPickupSelected(true)
                setIsPharmacySelected(false)
            }

        })
    }, [])
    const repeadtedmedicationHandler = () => {

        let notes = document.getElementById("mNotes").value;

        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            let obj = [];
            let medcnlist = ''
            array.map((item, index) => {
                medcnlist = medcnlist + item.resource.medicationCodeableConcept.coding[0].display + ','
                let ob = {
                    "MedicationRepeatRequestID": 0,
                    "MessageID": 0,
                    "MedicationID": item.resource.id,
                    "SCTID": null,
                    "Type": null,
                    "PracticeName": null,
                    "FullySpecifiedName": item.resource.medicationCodeableConcept.coding[0].display,
                    "MedicineID": 0,
                    "Form": null,
                    "MedicineName": null,
                    "Take": null,
                    "Duration": null,
                    "StartDate": null,
                    "ProviderID": 0,
                    "Directions": item.resource.dosageInstruction[0].text,
                    "IsLongTerm": item.resource.extension[0].valueBoolean,
                    "IsActive": false,
                    "IsDeleted": false,
                    "InsertedBy": 2232675,
                    "UpdatedBy": 0,
                    "InsertedAt": null,
                    "UpdatedAt": null,
                    "TotalRecords": 0,
                    "PracticeID": 0,
                    "PatientID": 0,
                    "Comments": notes,
                    "Quantity": null,
                    "ProviderName": null,
                    "PharmacyId": Pharmacyname.id,
                    "PickupTypeId": 2,
                    "OtherPharmacy": null,
                    "PrefPharmacyId": Pharmacyname.id,
                    "IsUrgent": isSelected,
                    "PharmacyLocationId": 0
                };
                obj.push(ob)
            })

            let raw = {
                "extension": [
                    {
                        "url": "MessageBody",
                        "valueString": isPharmacySelected ? "Notes:Type: Pharmacy Pharmacy on :" + Pharmacyname.name + "Requested Medicine:" + medcnlist : "Notes:Type: Pickup Pharmacy on :" + Pharmacyname.name + "Requested Medicine:" + medcnlist
                        // "valueString": "Notes:Type: Pharmacy \n Pharmacy on :AKL Pharmacys \n Requested Medicine:Depo-Testosterone (testosterone cipionate 1 g/10 mL) injection: solution, vial"
                    },
                    {
                        "url": "MessageType",
                        "valueString": "Send Mail"
                    },
                    {
                        "url": "MedicationList",
                        "valueString": JSON.stringify(obj),

                    }
                ],
                "note": [
                    {
                        "text": "Repeat Rx Request"
                    }
                ],
                "recipient": [
                    {
                        "display": Practise && Practise.length ? Practise[0].resource.extension[2].valueString : "N/A",
                        "reference": Practise && Practise.length ? "Practitioner/" + Practise[0].resource.extension[3].valueString : "N/A",//"/284389"
                    }
                ],
                "resourceType": "Communication",
                "sender": {
                    "display": item.username,
                    "reference": "Patient/" + item.userid
                },
                "status": "completed"
            }

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(raw),
                redirect: 'follow'
            };

            console.log({ raw })
            fetch(Settings.apiUrl + "Patient/Message?RoleID=1&CallType=3", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setrepeadtedmodalVisible(false)
                    // Alert.alert(
                    //     "",
                    //     result,
                    //     [

                    //         {
                    //             text: "OK", onPress: () => {
                    //                 setLoading(false)
                    //                 props.navigation.navigate('Medications')
                    //                 //console.log("OK Pressed")
                    //             }
                    //         }
                    //     ]
                    // );
                    array = [];
                    // window.location.reload()
                    console.log(result)
                    setLoading(false)
                    openNotificationWithIcon('success')

                    setTimeout(() => {
                        window.location.reload()
                    }, 1500);

                })
                .catch(error => {
                    setLoading(false)
                    //console.log('error', error)
                });
        })

    }

    const [response, setResponse] = useState(false);


    const RepeatedMedicationAdding = () => {
        let idString = ''
        let notes = document.getElementById("mNotes").value;
        getUserId().then(userInfo => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setLoading(true)
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            // console.log(props.route.params.med)
            array.map((item, i) => {
                idString = idString + item.resource.id + ","
            })
            var raw;
            {
                isPickupSelected ?
                    raw = JSON.stringify({
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 1,
                        "PharmacyID": 0,
                        "PharmacyName": "",
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": false,
                        "Notes": notes,
                        "PharmacyLocationId": 0
                    })
                    :
                    raw = JSON.stringify({
                        "PatientID": item.userid,
                        "MedicationID": idString,
                        "PickUpTypeID": 2,
                        "PharmacyID": Pharmacyname.id,
                        "PharmacyName": Pharmacyname.name,
                        "IsUrgent": isSelected ? true : false,
                        "IsPreferredPharmacy": true,
                        "Notes": notes,
                        "PharmacyLocationId": 0
                    })

            }
            // = JSON.stringify({
            //     "PatientID": item.userid,
            //     "MedicationID": idString,
            //     "PickUpTypeID": 2,
            //     "PharmacyID": Pharmacyname.id,
            //     "PharmacyName": Pharmacyname.name,
            //     "IsUrgent": isSelected ? true : false,
            //     "IsPreferredPharmacy": true,
            //     "Notes": notes,
            //     "PharmacyLocationId": 0
            // });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            console.log({ raw })
            fetch(Settings.apiUrl + "Patient/SendRepeatMedicationRequest", requestOptions)
                .then(response => response.json())
                .then(result => {
                    openNotificationWithIcon('success', result)
                    setrepeadtedmodalVisible(false)
                    // setResponse(true)
                    props.getMed(pageSize, pageNumber);
                    setLoading(false)
                    setTimeout(() => {
                        window.location.reload(false);
                    }, 1500);
                    // window.location.reload()
                    //   openNotificationWithIcon('success', result)
                    array = [];
                    //   setReady(!ready)
                    // Alert.alert(
                    //     "",
                    //     result,
                    //     [
                    //         {
                    //             text: "OK", onPress: () => {
                    //                 setLoading(false)
                    //                 props.navigation.navigate('Medications')
                    //                 console.log("OK Pressed")
                    //             }
                    //         }
                    //     ]
                    // );
                    //  alert(result)

                })
                .catch(error => {
                    setLoading(false)
                    //  console.log('error', error)
                });
        })



    }

    function handleChange(value) {
        console.log(`selected ${value}`);
        setPharmacyName({ id: value.split(',')[0], name: value.split(',')[1] });
    }
    function handleChangetype(value) {
        console.log(`selected ${value}`);
        // alert(value)
        setOrderType(value)
    }
    // useEffect(() => {

    // },[ready])
    return (
        <div>
            {
                isGetting && <View style={{ textAlign: 'center', justifyContent: 'center', marginBottom: 1, marginTop: 2 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }


            {
                typeof (AllMedeications) !== 'undefined' && AllMedeications.length ? (
                    <>
                        <TouchableOpacity
                            disabled={!array.length}
                            style={{ height: 40, width: 250, marginTop: 20, marginLeft: 20, marginBottom: 10, borderRadius: 5, justifyContent: 'center', alignItems: 'center', backgroundColor: array && array.length >= 1 ? '#195faa' : '#Dedede' }}
                            onPress={() => { setrepeadtedmodalVisible(true) }}>
                            <Text style={{ color: 'white', fontSize: 15 }}>Repeat Selected Medication</Text>
                        </TouchableOpacity>
                        <FlatList
                            style={{
                                flexDirection: 'column',
                                paddingLeft: 10,
                                paddingRight: 10,
                                paddingBottom: 10
                            }}
                            data={AllMedeications}
                            renderItem={({ item }) => (


                                <LongMedicationHandler
                                    options={() => hanldeSlectedList(item)}
                                    cardStyle={'card-header text-white bg-danger'}
                                    checkvalue={false}
                                    medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    //medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                    medicationDetails={item.resource.hasOwnProperty('medicationCodeableConcept') ? capitalize(item.resource.medicationCodeableConcept.coding[0].display) : 'N/A'} //
                                    directions={item.resource.hasOwnProperty('dosageInstruction') ? item.resource.dosageInstruction[0].text : 'No'} // item.resource.dosageInstruction[0].text
                                    checking={item.resource.extension[1].valueString}
                                    quantity={item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                    endDate={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                    startDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                />
                                // <MedicationWebHandler
                                //     medDate={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     medMonth={GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                //     medicationDetails={capitalize(item.resource.medicationCodeableConcept.coding[0].display)}
                                //     directions={item.resource.dosageInstruction[0].text}
                                //     checking={'Period: ' + item.resource.extension[1].valueString}
                                //     quantity={'Quantity: ' + item.resource.dispenseRequest.quantity.value + " " + (item.resource.extension[5] != undefined && item.resource.extension[5].valueString != undefined ? item.resource.extension[5].valueString : '')}
                                //     presby={item.resource.extension[4] != undefined ? (GetAppointmentDate(item.resource.extension[4].valueDateTime) + ' ' + GetMonthName(item.resource.extension[4].valueDateTime) + ' ' + GetYear(item.resource.extension[4].valueDateTime)) : "-"}
                                //     pratname={GetAppointmentDate(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetMonthName(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null) + ' ' + GetYear(item.resource.hasOwnProperty('authoredOn') ? item.resource.authoredOn : null)}
                                // />
                            )}
                            keyExtractor={(item, index) => index}
                            //    extraData={ready}
                            initialNumToRender={10}
                            maxToRenderPerBatch={10}
                            windowSize={7}

                        />
                    </>
                ) : !isGetting && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                    <Text style={{ marginTop: 10, }}>No Records Found</Text>
                </View>

            }

            {/* Edit start */}
            <Modal
                title="Repeat Medication"
                centered
                visible={repeadtedmodalVisible}
                onOk={() => {

                    if (ordertype == "Pharmacy" && (Pharmacyname.id == "" || Pharmacyname.name == "Select Pharmacy")) {
                        openNotificationWithIcon('warning', "Please select pharmacy")
                    } else {

                        setLoading(true);
                        RepeatedMedicationAdding()
                    }


                }}
                confirmLoading={loading}
                onCancel={() => setrepeadtedmodalVisible(false)}
                //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">

                        <div className="form-group mb-0">
                            <p style={{ textAlign: "justify" }}><strong>Provider Name:
                                {Practise && Practise.length ? Practise[0].resource.extension[2].valueString : "N/A"}</strong> </p>

                        </div>

                        <div className="form-group mb-0">
                            {portalSetting && portalSetting.PortalRepeatMedSetting &&
                                <p style={{ textAlign: "justify" }}>{portalSetting.PortalRepeatMedSetting}</p>}

                        </div>

                        <div className="form-group row mb-0">
                            <div className="col-md-12">
                                <label htmlFor="validate-internet-ip-field">Notes</label>
                                <textarea id="mNotes" type="text" style={{ backgroundColor: '#fff' }} className="form-control" line="3" placeholder="Notes" ></textarea>

                            </div>

                        </div>

                        <div style={{ marginTop: 20 }} className="form-group row mb-0">
                            <div className="col-md-12">
                                <label htmlFor="validate-internet-ip-field">Select Type </label>

                                {portalSetting && portalSetting.IsRxFax ? <Select defaultValue="Pick up" value={ordertype} className="col-md-12 row mb-0" onChange={handleChangetype}>
                                    <Option value="Pick up">Pick up</Option>
                                    <Option value="Pharmacy">Pharmacy</Option>
                                </Select> :
                                    <Select defaultValue="Pick up" value={"Pick up"} className="col-md-12 row mb-0" onChange={handleChangetype}>
                                        <Option value="Pick up">Pick up</Option>

                                    </Select>}




                                {/* <Select defaultValue="Pick up" value={ordertype} className="col-md-12 row mb-0" onChange={handleChangetype}>
                                    {portalSetting && portalSetting.isRxFax ?
                                  <Option value="Pick up">Pick up</Option>
                                  <Option value="Pharmacy">Pharmacy</Option>
                                        :  <Option value="Pick up">Pick up</Option>
                                  <Option value="Pharmacy">Pharmacy</Option>

                                    }



                                </Select> */}

                            </div>
                        </div>


                        {portalSetting && portalSetting.IsRxFax &&
                            <>
                                {ordertype == 'Pharmacy' &&
                                    <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                        <div className="col-md-12">
                                            <label htmlFor="validate-internet-ip-field">Pharmacies</label>
                                            <Select showSearch  defaultValue={Pharmacyname.name}
                                            style={{ width: '100%' }} id="mPharmacy" className="col-md-12 row mb-0" onChange={handleChange}>
                                                <OptGroup style={{ fontsize: 30 }} label="Favorite Pharmacy" >
                                                    {
                                                        Pharmacy && Pharmacy.length && Pharmacy.map((value, key) => {

                                                            if (value.resource.extension[2].valueBoolean)
                                                                return (
                                                                    <Option value={value.resource.extension[0].valueString + "," + value.resource.extension[1].valueString}>{value.resource.extension[1].valueString}</Option>
                                                                )
                                                        })
                                                    }
                                                </OptGroup>
                                                <OptGroup label="Other Pharmacy">
                                                    {
                                                        Pharmacy && Pharmacy.length && Pharmacy.map((value, key) => {

                                                            if (!value.resource.extension[2].valueBoolean)
                                                                return (
                                                                    <Option value={value.resource.extension[0].valueString + "," + value.resource.extension[1].valueString}>{value.resource.extension[1].valueString}</Option>
                                                                )
                                                        })
                                                    }
                                                </OptGroup>
                                            </Select>

                                        </div>
                                    </div>

                                }
                            </>}


                    </div>



                    {portalSetting && portalSetting.IsRxUrgent &&
                        <div>
                            <div className="col-md-12 mb-0 mt-2">
                                <Checkbox checked={isSelected} onChange={() => setIsSelected(!isSelected)}>Urgent</Checkbox>
                                {isSelected && <p style={{ textAlign: "justify", marginTop: 10 }}>{portalSetting.RepeatRxUrgentNotes}</p>}


                            </div>
                        </div>
                    }



                </div>
                {/* </div> */}
            </Modal>

            {/* End Modal*/}
        </div>
    );
}


function mapStateToProps(state) {
    return {
        AllMedeications: state.AllMedeications,
        Pharmacy: state.Pharmacy,

        Practise: state.Practise,
    }
}

function mapDispatchToProps(disptach) {
    return {
        getMed: (pageSize, pageNumber) => disptach(fetchAllMedsFromApi(pageSize, pageNumber)),
        getPharmacy: () => disptach(fetchPharmcyFromApi())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LongTermMed);

// export default AllMedications

import { GET_PRACTISE, GET_PRACTISE_FAILED, GET_PRACTISE_SUCCESS } from '../constants/Constant';

const intitalState = {
    Practise: [],
    isFetching: false,
    error: false
}

export default function practiseReducer(state = intitalState, action) {
    switch (action.type) {
        case GET_PRACTISE:
            return {
                ...state,
                isFetching: true
            }

        case GET_PRACTISE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                Practise: action.data
            }

        case GET_PRACTISE_FAILED:
            return {
                ...state,
                isFetching: false,
                error: true
            }

        default:
            return state
    }
}
import { combineReducers } from "redux";
import AuthUser from "./AuthUser";
import TokenValidator from "./TokenValidator";
import Proxy from "./Proxy";
import ProxyMenu from "./ProxyMenu";
import Profile from "./Profile";
import FutureAppointments from "./FutureAppointments";
import CompletedAppointment from "./CompletedAppointment";
import MissedAppointments from "./MissedAppointments";
import CancelledAppointments from "./CancelledAppointments";
import SearchAppointment from "./SearchAppointment";
import LongDiag from "./LongDiag";
import TreatmentOfChoices from "./TreatmentOfChoices";
import AllMedeications from "./AllMedeications";
import Reports from "./Reports";
import Allergies from "./Allergies";
import DueImmune from "./DueImmune";
import OverdueImmune from "./OverdueImmune";
import AdministeredImmune from "./AdministeredImmune";
import Immune from "./Immune";
import PatientTimeline from "./PatientTimeline";
import Reminder from "./Reminder";
import Resources from "./Resources";
import Letters from "./Letters";
import VitalReducer from "./VitalReducer";
import Messaging from "./Messaging";
import SentItems from "./SentItems";
import MessageDetail from "./MessageDetail";
import SetMessageItem from "./SetMessageItem";
import SendMessage from "./SendMessage";
import DraftsMessaging from "./DraftsMessaging";
import Practise from "./Practise";
import RecentDiag from './RecentDiag'
import RecentMed from "./RecentMed";
import RepeatedMed from "./RepeatedMed";
import Roles from "./Roles";
import AvailablePractitioner from "./AvailablePractitioner";
import BookApp from "./BookApp";
import AddAppointment from "./AddAppointment";
import PendingInvc from "./PendingInvc";
import AllInvc from "./AllInvc";
import Balance from "./Balance";
import Counter from "./Counter";
import ProfileImage from "./ProfileImage";
import Pharmacy from "./Pharmacy";
import AppointmentTypes from './AppointmentTypes'
import ApplicationSettings from "./ApplicationSettings";
import ProviderLocation from "./ProviderLocation";
const rootReducer = combineReducers({
    AuthUser,
    TokenValidator,
    Proxy,
    ProxyMenu,
    Profile,
    FutureAppointments,
    CompletedAppointment,
    MissedAppointments,
    CancelledAppointments,
    SearchAppointment,
    LongDiag,
    RecentDiag,
    TreatmentOfChoices,
    AllMedeications,
    Reports,
    Allergies,
    DueImmune,
    OverdueImmune,
    AdministeredImmune,
    Immune,
    PatientTimeline,
    Reminder,
    Resources,
    Letters,
    VitalReducer,
    Messaging,
    SentItems,
    MessageDetail,
    SetMessageItem,
    SendMessage,
    DraftsMessaging,
    Practise,
    RecentMed,
    RepeatedMed,
    Roles,
    AvailablePractitioner,
    BookApp,
    AddAppointment,
    PendingInvc,
    AllInvc,
    Balance,
    Counter,
    ProfileImage, Pharmacy,
    AppointmentTypes,
    ApplicationSettings,
    ProviderLocation

});

//export default rootReducer;
export default (state, action) =>
    rootReducer(action.type === 'USER_LOGIN_END' ? undefined : state, action);

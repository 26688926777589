import { GET_PROXY_MENU, GET_PROXY_MENU_SUCCESS, GET_PROXY_MENU_FAILED } from "../constants/Constant";
//inital state
const initalState = {
    ProxyMenu: [],
    isLoadingMenu: false,
    errorMenu: false
}

//export default state
export default function getProxyReduer(state = initalState, action) {
    switch (action.type) {
        case GET_PROXY_MENU:
            return {
                ...state,
                isLoadingMenu: true
            }

        case GET_PROXY_MENU_SUCCESS:
            return {
                ...state,
                isLoadingMenu: false,
                ProxyMenu: action.data

            }

        case GET_PROXY_MENU_FAILED:
            return {
                ...state,
                isLoadingMenu: false,
                errorMenu: true
            }

        // case GET_PROXY_ACCOUNTS_FINISHED:
        //     return {
        //         ...state,
        //         isLoadingMenu: false,
        //         errorMenu: false
        //     }

        default:
            return state
    }
}
import { GET_APPOINTMENT_TYPE, GET_APPOINTMENT_TYPE_SUCCESS, GET_APPOINTMENT_TYPE_FAILED } from "../constants/Constant";

//inital state
const initalState = {
    isLoadingAPPType: false,
    AppointmentTypes: [],
    error: false,
    isAvailable: false
}

//export default state
export default function AppointmentTypeReducer(state = initalState, action) {
    switch (action.type) {
        case GET_APPOINTMENT_TYPE:
            return {
                ...state,
                AppointmentTypes: [],
                isLoadingAPPType: true,

            }

        case GET_APPOINTMENT_TYPE_SUCCESS:

                return {
                    ...state,
                    isLoadingAPPType: false,
                    AppointmentTypes: action.data,
                    isAvailable: true
                }
           

        case GET_APPOINTMENT_TYPE_FAILED:
            return {
                ...state,
                isLoadingAPPType: false,
                AppointmentTypes:[],
                error: true
            }

     
        default:
            return state
    }
}
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { View, Text, Dimensions, StyleSheet, FlatList, ActivityIndicator, TouchableOpacity } from 'react-native-web';
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

import { capitalize, GetAppointmentDate, GetMonthName, GetYear } from '../../shared/core/datehelper'
import { fetchReportsFromApi, fetchMoreReportsFromApi } from "../../shared/actions/ReportsActions";
import ReportsHandler from "../components/ReportsHandler";
import { Table, Tag, Space } from 'antd';
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import ReportViewHandler from '../components/ReportViewHandler';
import getUserId from "../../shared/core/GetToken";
import { Spin, Switch, Alert } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


import renderHTML from "react-render-html";
import { useReactToPrint } from 'react-to-print';
import Settings from '../../shared/config/Settings';
import { checkToken } from '../../shared/actions/TokenValidActions';
const useHistory = require("react-router-dom").useHistory;


const { Column, ColumnGroup } = Table;
const data = [
    {
        key: '1',
        firstName: 'John',
        lastName: 'Brown',
        age: 32,
        address: 'New York No. 1 Lake Park',
        tags: ['nice', 'developer'],
    },
    {
        key: '2',
        firstName: 'Jim',
        lastName: 'Green',
        age: 42,
        address: 'London No. 1 Lake Park',
        tags: ['loser'],
    },
    {
        key: '3',
        firstName: 'Joe',
        lastName: 'Black',
        age: 32,
        address: 'Sidney No. 1 Lake Park',
        tags: ['cool', 'teacher'],
    },
];
let tabledata = []
const PatientResults = props => {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const history = useHistory()
    const [isloaded, setIsload] = React.useState(false);
    const { Reports, isFetching } = props.Reports;
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const [sloading, setSLoading] = useState(false);

    const [visible, setVisible] = useState(false);  //for report pdf view 
    const [tableModalVisible, setTableModalVisible] = useState(false); //for
    //for report virws
    const [authorName, setAuthorName] = useState('');
    const [labrotatyName, setlabrotatyName] = useState('');
    const [InsertedDate, setInsertedDate] = useState('');
    const [Observationtext, setObservationtext] = useState('');
    const [AbnormalFlagDesc, setAbnormalFlagDesc] = useState('');
    const [pdfViewerLoading, setPdfViewerLoading] = useState(false);
    const [isReady, setIsReady] = useState(true);
    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])

    const ActivityIndicatorHanlder = () => {
        setSLoading(!sloading)
    }

    useEffect(() => {
        props.getReports(pageSize, pageNumber);
    }, [])


    React.useEffect(() => {
        props.tokenVal(history);
    }, [])


    const loadReport = (reportId) => {
        // setVisible(true)


        getUserId().then((userInfo) => {
            setPdfViewerLoading(true)
            //    

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/LabResultByID?PatientID=" + item.userid + "&ResultID=" + reportId, requestOptions)
                .then(data => data.json())
                .then(json => {
                    setSLoading(false);
                    if (json[0].entry[0].resource.content[0].attachment.contentType == "PDF" || json[0].entry[0].resource.content[0].attachment.contentType == "pdf") {
                        setVisible(true)
                        setTimeout(() => {

                        }, 3000);
                        document.getElementById("showPDF").innerHTML = ""
                        //    alert("pdf here")
                        var b64 = json[0].entry[0].resource.content[0].attachment.data;
                        var obj = document.createElement('object');
                        obj.style.width = '100%';
                        obj.style.height = '550px';
                        obj.type = 'application/pdf';
                        obj.data = 'data:application/pdf;base64,' + b64;
                        //document.body.appendChild(obj);
                        document.getElementById("showPDF").appendChild(obj)
                        setPdfViewerLoading(false)
                    }
                    else {
                        //showTable

                        let ObservationText = '';
                        let ObservationValue = "";
                        let AbnormalFlagDesc = ""
                        json[0].entry.map((item, index) => {
                            item.resource.extension.map((value, key) => {
                                if (item.resource.extension[key].url == "ObservationText") {
                                    ObservationText = capitalize(item.resource.extension[key].valueString)
                                } else if (item.resource.extension[key].url == "ObservationValue") {
                                    ObservationValue = capitalize(item.resource.extension[key].valueString)
                                }
                                else if (item.resource.extension[key].url == "AbnormalFlagDesc") {
                                    AbnormalFlagDesc = capitalize(item.resource.extension[key].valueString)
                                }
                            })
                            let ob = {
                                "ObservationText": ObservationText,
                                "ObservationValue": ObservationValue,
                                "AbnormalFlagDesc": AbnormalFlagDesc //capitalize(item.resource.extension[3].valueString) // ObservationValue,
                                // age: 32,
                                // address: 'New York No. 1 Lake Park',
                                // tags: ['nice', 'developer'],
                            }
                            tabledata.push(ob)


                        })
                        setTableModalVisible(true)
                        setIsReady(!isReady)
                        console.log({ tabledata })
                        setTimeout(() => {

                        }, 3000);
                        // var obj = document.createElement('object');
                        // obj.style.width = '100%';
                        // obj.style.height = '550px';
                        // // obj.type = 'application/pdf';
                        // obj.data = 'dataapplicationpdfbase64 + b64';
                        // //document.body.appendChild(obj);
                        // document.getElementById("showTable").appendChild(obj)

                        //  alert("Table here")
                    }
                    //console.log(json[0].entry[0].resource.content[0].attachment.data)

                    // setAuthorName(json[0].entry[0].resource.author[0].display);
                    // setlabrotatyName(json[0].entry[0].resource.extension[0].valueString);
                    // setInsertedDate(json[0].entry[0].resource.extension[1].valueString);
                    // setObservationtext(json[0].entry[0].resource.extension[2].valueString);
                    // setAbnormalFlagDesc(json[0].entry[0].resource.extension[3].valueString);




                    // setFile('data:application/pdf;base64,' + json[0].entry[0].resource.content.data)
                    // setFile((state) => {
                    //     return state;
                    // });
                    // //console.log('Hello:', json[0].entry[0].resource.resourceType)
                    //alert(json[0].entry[0].resource.resourceType);
                    // var bin = atob(b64);


                    //console.log(b64)
                    // // Embed the PDF into the HTML page and show it to the user

                    // var b64 = json[0].entry[0].resource.content[0].attachment.data;
                    // var obj = document.createElement('object');
                    // obj.style.width = '100%';
                    // obj.style.height = '550px';
                    // obj.type = 'application/pdf';
                    // obj.data = 'data:application/pdf;base64,' + b64;
                    // //document.body.appendChild(obj);
                    // document.getElementById("showPDF").appendChild(obj)
                    // setPdfViewerLoading(false)

                    // setVisible(true)




                })
                .catch(error => {
                    // setIsLoadingPdf(false)
                    //console.log(error)
                    setPdfViewerLoading(false)
                })
                .finally(() => {
                    // setCheckFile(false)
                    // setIsLoadingPdf(false)
                    setPdfViewerLoading(false)


                });
        });
    }



    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const columnsLetter = [
        {
            title: "Observation Text",
            dataIndex: "ObservationText",
            key: "ObservationText",
        },
        {
            title: "Observation Value",
            dataIndex: "ObservationValue",
            key: "ObservationValue",
            render: (text, record, index) => (
				<p>
					 Hello
				</p>
			),
        },
        {
            title: "Abnormal Flag Desc",
            dataIndex: "AbnormalFlagDesc",
            key: "AbnormalFlagDesc",
        },
    ];
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />
                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Test Results</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            <div className="row">
                                <div className="col-12">
                                    <div className="grid">
                                        <div className="grid-body">
                                            <h2 className="grid-title">Results & Reports</h2>
                                            <div className="item-wrapper">
                                                {/* <Spin indicator={antIcon} tip="Loading..." spinning={sloading}> */}
                                                {
                                                    isFetching && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                                }
                                                {
                                                    typeof (Reports) !== 'undefined' && Reports.length ? (

                                                        <FlatList
                                                            data={Reports}
                                                            style={{
                                                                flexDirection: 'column',
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                paddingBottom: 10
                                                            }}
                                                            renderItem={({ item }) => (

                                                                <ReportsHandler
                                                                    labid={item.resource.id}
                                                                    // indicatorHandler={() => ActivityIndicatorHanlder()}
                                                                    rptDate={GetAppointmentDate(item.resource.extension[1].valueDateTime) + ' ' + GetMonthName(item.resource.extension[1].valueDateTime) + ' ' + GetYear(item.resource.extension[1].valueDateTime)}
                                                                    testName={item.resource.description}
                                                                    author={'Provider: ' + item.resource.author[0].display}
                                                                    labName={item.resource.extension[0].valueString ? item.resource.extension[0].valueString : 'N/A'}
                                                                    folderName={item.resource.extension[4].valueString ? item.resource.extension[4].valueString : 'N/A'}
                                   
                                                                    comments={item.resource.extension[2].valueString ? item.resource.extension[2].valueString : 'N/A'}
                                                                />
                                                            )}
                                                            keyExtractor={item => item.resource.id}
                                                            initialNumToRender={10}
                                                            maxToRenderPerBatch={10}
                                                            windowSize={7}
                                                        />

                                                    ) : !isFetching && <View style={{ flex: 1, flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                                                        <Text style={{ marginTop: 10, }}>No Records Found</Text>
                                                    </View>

                                                }
                                                {/* </Spin> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal
                        title="PDF File"
                        centered
                        visible={visible}
                        onOk={() => setVisible(false)}
                        onCancel={() => setVisible(false)}
                        afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                        style={{ top: 10, bottom: 90, overflow: "hidden" }}
                        width={1000}
                    >
                        <div className="PDF" id="showPDF">

                        </div>
                        <p id="hee">
                            {pdfViewerLoading && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>}
                        </p>
                    </Modal>
                    <Modal
                        title="Lab Result dhdskjhdsksjdhkdsjhs"
                        centered
                        visible={tableModalVisible}
                        onOk={() => setTableModalVisible(false)}
                        onCancel={() => setTableModalVisible(false)}
                        afterClose={() => {
                            tabledata = []
                        }}
                        style={{ top: 10, bottom: 90, overflow: "hidden" }}
                        width={1000}
                    >
                        <div className="Table" id="showTable">

                            {tabledata && tabledata.length ?
                                <>
                                    {/* <Table dataSource={tabledata} columns={columnsLetter} />; */}
                                    {/* <Table dataSource={tabledata}>
                                        <Column
                                            title="Observation Text"
                                            dataIndex="ObservationText"
                                            key="ObservationText"
                                            render={(text, record, index) => {
                                                    <p>Hello World</p>
                                            }}
                                           
                                        />
                                        <Column 
                                        title="Observation Value" 
                                        dataIndex="ObservationValue" 
                                        key="ObservationValue" 
                                        />
                                        <Column 
                                        title="Abnormal Flag Desc" 
                                        dataIndex="AbnormalFlagDesc" 
                                        key="AbnormalFlagDesc" 
                                        />
                                    </Table> */}
                                </>
                                : <p>no data is available</p>
                            }
                            {/* <Table dataSource={tabledata}>
                                <Column title="Inserted Date" dataIndex="InsertedDate" key="InsertedDate" />
                                <Column title="Observation Text" dataIndex="ObservationText" key="ObservationText" />
                                <Column title="Observation Value" dataIndex="ObservationValue" key="ObservationValue" /> */}
                            {/* <Column
                                    title="ObservationValue"
                                    dataIndex="tags"
                                    key="tags"
                                    render={tags => (
                                        <>
                                            {tags.map(tag => (
                                                <Tag color="blue" key={tag}>
                                                    {tag}
                                                </Tag>
                                            ))}
                                        </>
                                    )}
                                /> */}
                            {/* </Table> */}
                        </div>
                        {/* <p id="hee"> hell here is tabke */}
                        {/* {pdfViewerLoading && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>} */}
                        {/* </p> */}
                    </Modal>



                    {/* <Modal
                        title="Result Details"
                        centered
                        visible={visible}
                        onOk={() => setVisible(false)}
                        onCancel={() => setVisible(false)}
                        width={1000}
                        footer={[
                            <Button key="back" onClick={() => setVisible(false)}>
                                Close
                            </Button>,
                            <Button key="submit" type="primary" onClick={() => handlePrint()}>
                                Print
                            </Button>,
                        ]}
                    >

                        <div ref={componentRef} className="email-preview-wrapper">
                            <div className="preview-header">
                                <img className="profile-img" src="http://www.placehold.it/50x50" alt="profile image" />
                                <p classname="user_name" style={{ overflow: 'visible !important' }}> {authorName}</p>

                                <a className="user-email" href="#">{labrotatyName}</a>
                                <p className="date"> {InsertedDate}</p>
                            </div>

                            <div className="email-container">
                                <div className="email-content">
                                    <View >
                                        <Text>Title</Text>
                                        <Text>{Observationtext + ' ' + AbnormalFlagDesc}</Text>
                                    </View>
                                </div>
                            </div>
                        </div>
                    </Modal> */}

                    <footer className="footer">

                    </footer>
                </div>

            </div>

        </div >
    )
}


function mapStateToProps(state) {
    return {
        Reports: state.Reports
    }
}

function mapDispatchToProps(disptach) {
    return {
        getReports: (pageSize, pageNumber) => disptach(fetchReportsFromApi(pageSize, pageNumber)),
        tokenVal: (history) => disptach(checkToken(history))
        //  getMoreReports: (token, pageSize, pageNumber) => disptach(fetchMoreReportsFromApi(token, pageSize, pageNumber))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PatientResults)
// export default PatientResults

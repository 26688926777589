import React, { useCallback, useState, useEffect } from 'react';
import { fetchPractisefromAPI } from '../../shared/actions/PractiseAction';
import { fetchProfileFromApi } from '../../shared/actions/ProfileActions';
import { connect } from "react-redux";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { notification } from 'antd';

import PersonalDetail from '../components/PersonalDetail';
import ProfileAddress from '../components/ProfileAddress';
import ProfileContactDetails from '../components/ProfileContactDetails';
import ProfilePracticeDetail from '../components/ProfilePracticeDetail';
import Sidebar from "../components/Sidebar";
import { ActivityIndicator, View, Text, TouchableOpacity } from 'react-native';
import { passwordValidator } from '../../shared/core/utils';
import getUserId from '../../shared/core/GetToken';
import changePasswrodService from '../../shared/services/ChangePasswrod';
import { FlatList } from 'react-native';
import Svg, { Path, G } from "react-native-svg"
import { Modal, Button } from 'antd';
import 'antd/dist/antd.css';
import ProfileItem from '../components/ProfileItem';
import Settings from '../../shared/config/Settings';
import { fetchBalanceFromApi } from "../../shared/actions/GetBalanceAction";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import { capitalize } from '../../shared/core/datehelper';
const ProfileScreen = props => {
    const { Profile, isFetching } = props.Profile;
    const { Practise } = props.Practise;
    const { Balance, isLoadoing } = props.Balance;
    const [isloading, setIsLoading] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false)

    const [isAddingEmergency, setIsAddingEmergency] = useState(false)

    const [titleArray, setTitleArray] = useState([])
    const [relationShipArray, setRelationShipArray] = useState([])
    const [cityArray, setCityArray] = useState([])
    const [townArray, setTownArray] = useState([])
    const [genderlist, setGenderlist] = useState([])

    const [addContactVisible, setAddContactVisible] = useState(false);
    /////********************************Profile Modal   */
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [contactid, setContactId] = React.useState('');

    const [editProfileModalVisible, setEditProfileModalVisible] = useState(false);

    const [editContactModalVisible, setContactModalVisible] = useState(false);
    const [editAddressesModalVisible, setAddressesModalVisible] = useState(false);
    const [editPostalAddressModalVisible, setPostalAddressModalVisible] = useState(false);
    /////******************************** */
    const showEmeregencyContact = () => {
        setAddContactVisible(true);
    }
    const [passwordStartMessage, setPasswordStartMessage] = useState({
        error: false,
        message: ''
    })

    useEffect(() => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = "?TypeID=2&PatientID=" + item.userid
            fetch(Settings.apiUrl + "IndiciLookup" + queryString, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let response = []
                    result[0].entry.map((item, index) => {
                        let tmp = {}
                        tmp.id = item.resource.extension[0].valueString
                        tmp.name = item.resource.extension[1].valueString
                        response.push(tmp)

                    })
                    // SetCountryList(response)
                    setGenderlist(response)
                    // console.log(result)
                })
                .catch(error => console.log('error', error));
        })
    }, [])
    const [gender, setGender] = useState({ id: 0, name: '' })
    // useEffect(() => {
    //     genderlist.map((item, index) => {
    //         console.log("gender", { item })
    //         if (item.name.toLowerCase() === props.route.params.item.gender.toLowerCase()) {
    //             setGender(item)
    //         }
    //     })
    // }, [genderlist])

    React.useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://indici-pre-production.web.app/assets/js/template.js";
        document.body.appendChild(script)
        // d.div.appendChild(script);
    }, [])
    useEffect(() => {
        // Profile.length && Profile[0].resource.telecom.map((item, index) => {
        //     if (item.system === "email")
        //         alert(item.value)
        //     //console.log(item.value)
        //     //setEmail({ value: item.value, error: '' })
        //     // document.getElementById('mEmail').value = item.value;
        // })
        // // props.route.params.item.telecom.map((item, index) => {
        // //     if (item.system === "email")
        // //         //setEmail({ value: item.value, error: '' })
        // //         document.getElementById('mEmail').value=item.value;
        // // })
    }, [])

    useEffect(() => {
        props.getProfile();
        props.getBalance()
        props.getPracticeCenterDetail()

        // 1 = Title
        // 2 = Gender
        // 3 = Martial Status
        // 4 = Ethnicity
        // 5 = Country
        // 6 = City
        // 7 = Suburb
        // 8 = Contact Method
        // 9 = Relationship
        // 10 = Blood Group
        // 11 = Role / Pods

        getLookUpdata(1)
        getLookUpdata(9)
        getLookUpdata(6)
        getLookUpdata(7)

        // //console.log(Profile)

        // props.getProxy();
    }, []);


    const getLookUpdata = async (type) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '?Token=' + item.token;
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Cookie", ".Stackify.Rum=4415f100-6b95-4aa2-b4a1-28361d2b345c");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "IndiciLookup?TypeID=" + type + "&PatientID=" + item.parentid, requestOptions)
                .then(response => response.json())
                .then(result => {
                    //console.log(result)
                    switch (type) {
                        case 1:
                            setTitleArray(result[0].entry)
                            break;
                        case 9:
                            setRelationShipArray(result[0].entry)
                            break;
                        case 7:
                            setTownArray(result[0].entry)
                            break;
                        case 6:
                            setCityArray(result[0].entry)

                            break;


                        default:
                            break;
                    }
                })
                .catch(error => console.log('error', error));

        })
    }
    const updateProfile = () => {
        setIsAddingEmergency(true)
        let title = document.getElementById("mTitlte");
        // let relationShip = document.getElementById("mRelationship");
        // let town = document.getElementById("mTown");
        // let city = document.getElementById("mCity");
        let firstName = document.getElementById("mFirstName").value;
        let familyName = document.getElementById("mFamilyName").value;
        let preferedName = document.getElementById("mPreferedName").value;
        let email = document.getElementById("mEmail").value;
        let ethnicity = document.getElementById("mEthnicity").value;
        let dob = document.getElementById("mDOB").value;
        //   let bloodgroupsid = document.getElementById("mBloodGroup").value;



        var e = document.getElementById("mGender");
        var genderId = e.options[e.selectedIndex].value;
        var genderText = e.options[e.selectedIndex].text;



        // let homePhone = document.getElementById("mHomePhone").value;
        // let workPhone = document.getElementById("mWorkPhone").value;
        // let house = document.getElementById("mHouse").value;
        // let floor = document.getElementById("mFloor").value;
        // let street = document.getElementById("mStreet").value;
        // let residence = document.getElementById("mResidence").value;
        // let postcode = document.getElementById("mPostcode").value;

        var titleId = title.value; // 2
        var titleValue = title.options[title.selectedIndex].text; //Dr
        // var relationShipId = relationShip.value; // 2
        // var relationValue = relationShip.options[relationShip.selectedIndex].text; //Dr

        // var cityId = city.value; // 2
        // var cityValue = city.options[city.selectedIndex].text; //Dr

        // var townId = town.value; // 2
        // var townValue = town.options[town.selectedIndex].text; //Dr

        //console.log({ titleId, titleValue, firstName, familyName, preferedName, email, ethnicity, dob, bloodgroups })

        getUserId().then((userInfo) => {
            const item = userInfo !== "none" && JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "PatientID": item.userid,
                "ChartNumber": "string",
                "TitleID": title.id,
                "Title": title.name,
                "FirstName": firstName,
                "FamilyName": familyName,
                "PreferredName": preferedName,
                "GenderID": gender.id,
                "GenderName": gender.name,
                "Ethnicity": ethnicity,
                "DOB": dob,
                "BloodGroupID": Profile[0].resource.extension[3].valueString ? Profile[0].resource.extension[3].valueString : "0",
                "BloodGroupName": "0",
                "NHINumber": "N/A",
                "UserEmail": email,
                "PracticeID": 0,
                "Balance": 0,
                "RelationshipID": 0,
                "RelationshipName": "string",
                "InCaseOfEmergency": true
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            console.log(raw)

            fetch(Settings.apiUrl + "Patient/UpdatePatientDemographics", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoading(false)
                    setEditProfileModalVisible(false)
                    openNotificationWithIcon('error', "Profile update successfully.")


                })
                .catch(error => {
                    setEditProfileModalVisible(false)

                    setLoading(false)
                    openNotificationWithIcon('error', "Profile issue update soon.")
                    //console.log('error', error)
                });


        })
    }

    const deleteEmergencyContact = (id) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'DELETE',
                headers: myHeaders,
                redirect: 'follow'
            };
            let queryString = "?PatientID=" + item.userid + "&EmergencyContactID=" + id + ""
            //console.log(queryString)
            fetch(Settings.apiUrl + "Patient/EmergencyContact" + queryString, requestOptions)
                .then(response => response.json())
                .then(result => {
                    //  alert(result)
                    // //console.log(result)
                    setVisible(false)
                    setConfirmLoading(false);
                    props.getProfile();
                })
                .catch(error => console.log('error', error));

        })
    }

    const saveApi = () => {

        let title = document.getElementById("mTitlte");
        let relationShip = document.getElementById("mRelationship");
        let town = document.getElementById("mTown");
        let city = document.getElementById("mCity");
        let firstName = document.getElementById("mFirstName").value;
        let familyName = document.getElementById("mFamilyName").value;
        let homePhone = document.getElementById("mHomePhone").value;
        let workPhone = document.getElementById("mWorkPhone").value;
        let house = document.getElementById("mHouse").value;
        let floor = document.getElementById("mFloor").value;
        let street = document.getElementById("mStreet").value;
        let residence = document.getElementById("mResidence").value;
        let postcode = document.getElementById("mPostcode").value;

        var titleId = title.value; // 2
        var titleValue = title.options[title.selectedIndex].text; //Dr

        var relationShipId = relationShip.value; // 2
        var relationValue = relationShip.options[relationShip.selectedIndex].text; //Dr

        var cityId = city.value; // 2
        var cityValue = city.options[city.selectedIndex].text; //Dr

        var townId = town.value; // 2
        var townValue = town.options[town.selectedIndex].text; //Dr
        //////////////////////////
        if (titleId === '' && titleValue == '') {
            //     alert("Please select Title")

            return
        }
        else{
            document.getElementById("mfirstnameerror").innerText = ""
        }
        if (firstName === '') {
            //    alert("Please Enter Title")
            document.getElementById("mfirstnameerror").innerText = "Please enter first name"
            return
        } else{
            document.getElementById("mfirstnameerror").innerText = ""
        }
        if (familyName == '') {
            //  alert("Please Enter Family Name")
            document.getElementById("mfaimlynameerror").innerText = "Please enter family name"
            return;
        } else{
            document.getElementById("mfaimlynameerror").innerText = ""
        }
         if (homePhone == '') {

            document.getElementById("mhomephone").innerText = "Please enter home phone number"
            return;
        } else{
            document.getElementById("mhomephone").innerText = ""
        }
        if (workPhone == '') {
            document.getElementById("mworkphone").innerText = "Please enter work phone number"
            return;
        }
        else{
            document.getElementById("mworkphone").innerText = ""
        }
        setIsAddingEmergency(true)
        // alert("title Id==>" + titleId+",,"+titleValue + "\n" + "relationship Id==>" + relationShipId + "\n" + "city id==>" + cityId + "\n" + "Town id==>" + townId
        //     + "\n" + "firstName==>" + firstName
        //     + "\n" + "familyName==>" + familyName
        //     + "\n" + "Home==>" + homePhone
        //     + "\n" + "WorkPhone==>" + workPhone
        //     + "\n" + "floor==>" + floor
        //     + "\n" + "Street==>" + street
        //     + "\n" + "residence==>" + residence
        //     + "\n" + "post code==>" + postcode
        // )
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo)

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cookie", ".Stackify.Rum=6b3115ba-187b-4481-94fa-38d0c9542fff");

            var raw = JSON.stringify({
                "PatientID": item.userid,
                "ChartNumber": "SSW3",
                "TitleID": titleId,
                "Title": titleValue,
                "FirstName": firstName,
                "FamilyName": familyName,
                "PreferredName": "NickName",
                "GenderID": 2,
                "GenderName": "Female",
                "Ethnicity": "x ethnicity",
                "DOB": "2021-07-14T19:32:42.274Z",
                "BloodGroupID": 2,
                "BloodGroupName": "b+",
                "NHINumber": "nhi441",
                "UserEmail": "myemail@address.com",
                "PracticeID": 128,
                "Balance": 200,
                "RelationshipID": relationShipId,
                "RelationshipName": relationValue,
                "InCaseOfEmergency": true,
                "PatientAddress": [
                    {
                        "AddressID": 0,
                        "PaientID": item.userid,
                        "AddressTypeID": 1,
                        "HouseNumber": house,
                        "BuildingNumber": floor,
                        "StreetNumber": street,
                        "SuburbTownName": townValue,
                        "SuburbTownID": townId,
                        "CityAreaName": cityValue,
                        "CityAreaID": cityId,
                        "PostCodeID": postcode
                    }
                ],
                "PatientContact": {
                    "CellPhoneNumber": homePhone,
                    "HomePhoneNumber": homePhone,
                    "WorkPhoneNumber": workPhone,
                    "EmailAddress": "email@eee.com",
                    "PrefferedContactMethodID": 2,
                    "PrefferedContactMethodName": "string"
                },
                "lstContactMethods": [
                    {
                        "ProfileContactMethodID": 0,
                        "ProfileID": 0,
                        "ContactMethodID": 0,
                        "IsActive": true,
                        "IsDeleted": true,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": {
                            "Ticks": 0,
                            "Year": 0,
                            "Month": 0,
                            "Day": 0,
                            "Hour": 0,
                            "Minute": 0,
                            "Second": 0
                        },
                        "UpdatedAt": {
                            "Ticks": 0,
                            "Year": 0,
                            "Month": 0,
                            "Day": 0,
                            "Hour": 0,
                            "Minute": 0,
                            "Second": 0
                        },
                        "ContactMethod": "string"
                    }
                ]
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            //console.log(raw);
            fetch(Settings.apiUrl + "patient/AddEmergencyContact?PatientID=" + item.userid, requestOptions)
                .then(response => response.json())
                .then(result => {
                    //console.log(result)
                    setIsAddingEmergency(false)
                    props.getProfile();
                    setAddContactVisible(false)
                })
                .catch(error => {

                    setIsAddingEmergency(false)
                    setAddContactVisible(false)
                    //console.log('error', error)
                });
        })

    }
    const handleCloseReset = () => {
        setShowReset(false)
        // testFunction(AuthUser[0].extension[0].valueString, AuthUser[0].id)

    };

    const [showincon, setshowicon] = useState('mdi mdi-eye')
    const showPassword = () => {
        var x = document.getElementById("password");
        if (x.type === "password") {
            setshowicon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowicon('mdi mdi-eye')
            x.type = "password";
        }
    }

    const [showinconConf, setshowConficon] = useState('mdi mdi-eye')

    const showPasswordConfirm = () => {
        var x = document.getElementById("confirmPassword");
        if (x.type === "password") {
            setshowConficon('mdi mdi-eye-off')
            x.type = "text";
        } else {
            setshowConficon('mdi mdi-eye')
            x.type = "password";
        }
    }
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: 'Notification',
            description:
                message,
        });
    };

    const resetPasswordFunc = async () => {

        let password = document.getElementById("password").value;
        let confirmPassword = document.getElementById("confirmPassword").value;

        const passwordError = passwordValidator(password);
        const cnfrmPaswrd = passwordValidator(confirmPassword);
        if (passwordError) {
            document.getElementById("errorMessageReset").innerHTML = "Password cannot be empty "
            return;
        }
        if (cnfrmPaswrd) {
            document.getElementById("errorMessageReset").innerHTML = "Confirm Password cannot be empty "
            return;
        }
        if (password !== confirmPassword) {
            document.getElementById("errorMessageReset").innerHTML = "Passwords did not matched. Please try again"
            return
        }
        //check for lower case
        if (!password.match(/[a-z]/)) {
            document.getElementById("errorMessageReset").innerHTML = "Password must contain at least one lower case letter.";
            // passForm.passInput.focus();
            return;
        }

        //Validating length
        if (password.length < 8) {
            document.getElementById("errorMessageReset").innerHTML = "Your password has less than 8 characters.";
            return;
        }

        //check for upper ase
        if (!password.match(/[A-Z]/)) {
            document.getElementById("errorMessageReset").innerHTML = "Password must contain at least one upper case letter.";
            // passForm.passInput.focus();
            return;
        }
        var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
        //check for number
        if (!patt.test(password)) {
            document.getElementById("errorMessageReset").innerHTML = "Password must contain at least two number.";
            // passForm.passInput.focus();
            return;
        }
        setIsResetPassword(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            const queryString = '';
            //console.log("ResetPasswrod===>" + password)
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");
            var jsonPa = JSON.stringify({ "PatientID": item.parentid, "Password": password })
            var requestOptions = {
                method: 'Post',
                headers: myHeaders,
                body: jsonPa,
                redirect: 'follow'
            };
            //changePasswrodService(queryString, jsonPa,myHeaders)

            fetch(Settings.apiUrl + "/Patient/ChangePassword", requestOptions)
                .then(data => data.json())
                .then(json => {
                    //console.log(json)
                    // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
                    if (json.StatusCode == "1") {
                        // for activity indicatorr
                        setIsResetPassword(false)
                        setPasswordStartMessage(prevState => ({ ...prevState, error: false, message: json.StatusMessage }))
                        // alert(json.StatusMessage)
                        openNotificationWithIcon('success', json.StatusMessage)
                        handleCloseReset()

                        //console.log("statscode==1==>" + JSON.stringify(json))
                        // handleCloseReset()
                    }
                    // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
                    if (json.StatusCode == "3") {
                        setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: json.StatusMessage }))
                        setIsResetPassword(false)
                        openNotificationWithIcon('error', json.StatusMessage)
                        //console.log("statscode==2==>" + JSON.stringify(json))

                    }
                })
                .catch(err => {
                    setPasswordStartMessage(prevState => ({ ...prevState, error: true, message: "Some thing went wrong" }))

                    //console.log("resetPasswrodError==>" + err)
                })
        })
        // check remining confirm and passweordk

    }

    /////////////////////

    const [workphone, setWorkPhone] = useState("");
    const [homephone, setHomePhone] = useState("");
    const [cellPhone, setCellPhone] = useState("");
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState();//email
    const [workupdated, setWorkUpdateed] = useState(false);
    const [cellUpdeated, setCellUpdeated] = useState(false);
    const [homeUpdated, setHomeUpdated] = useState(false);
    const handleOnChangeWork = (value, data, event, formattedValue) => {
        //console.log(formattedValue);
        setWorkPhone(formattedValue);
        setWorkUpdateed(true)
        // this.setState({ phone: value }, () => {
        //     //console.log(this.state.phone);
        // });
    };
    const handleOnChangeCell = (value, data, event, formattedValue) => {
        //console.log(value);
        setCellPhone(formattedValue);
        setCellUpdeated(true)
        // this.setState({ phone: value }, () => {
        //     //console.log(this.state.phone);
        // });
    };
    const handleOnChangeHome = (value, data, event, formattedValue) => {
        //console.log(value);
        setHomePhone(formattedValue);
        setHomeUpdated(true)
        // this.setState({ phone: value }, () => {
        //     //console.log(this.state.phone);
        // });
    };

    const Contact = () => {
        let cell;
        let home;
        let work;
        let cell1;
        let home1;
        let work1;
        let email;

        Profile.forEach(person => {
            person.resource.telecom.forEach(element => {
                if (element.system == 'phone') {
                    if (element.use == 'home') {
                        //   const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

                        //   // Parse number with country code and keep raw input.
                        //   const number = phoneUtil.parseAndKeepRawInput(element.value, 'US');
                        //   //console.log(number.getRawInput());
                        //   let ab = number.getCountryCode();
                        //   home = element.value.slice(ab.toString().length + 1)
                        //   //   alert(home)
                        home1 = element.value
                        setHomePhone(element.value)
                        //   //console.log(ab.toString().length + 1);
                    }
                    if (element.use == 'mobile') {
                        //   const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
                        //   const number = phoneUtil.parseAndKeepRawInput(element.value, 'US');
                        //   //console.log(number.getRawInput());
                        //   let ab = number.getCountryCode();
                        //   cell = element.value.slice(ab.toString().length + 1)
                        cell1 = element.value
                        setCellPhone(element.value)
                        //  alert(cell)
                    }
                    if (element.use == 'work') {
                        //   const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
                        //   const number = phoneUtil.parseAndKeepRawInput(element.value, 'US');
                        //   //console.log(number.getRawInput());
                        //   let ab = number.getCountryCode();
                        //   work = element.value.slice(ab.toString().length + 1)
                        work1 = element.value
                        setWorkPhone(element.value)
                        //alert(work)
                    }
                }
                else if (element.system == 'email') {
                    if (element.use == 'home') {
                        email = element.value
                        setEmail(element.value)
                    }
                }


            });
        })
        setContactModalVisible(true)
    }

    const ContactUpdateHandler = () => {
        //  //console.log(workupdated ? "+64" + workphone : { workphone }, homeUpdated ? "+" + homephone : { homephone }, cellUpdeated ? "+" + cellPhone : { cellPhone }, email);
        setLoading(true)
        getUserId().then((userInfo) => {
            const item = userInfo !== "none" && JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "PatientID": item.userid,
                "CellPhoneNumber": cellPhone,
                "HomePhoneNumber": homephone,
                "WorkPhoneNumber": workphone,
                "EmailAddress": document.getElementById("mEmail").value,
                "PrefferedContactMethodID": 1,
                "PrefferedContactMethodName": "Cell Phone",
                "SelectedContacttMethods": [
                    {
                        "ProfileContactMethodID": 1,
                        "ProfileID": 0,
                        "ContactMethodID": 1,
                        "IsActive": true,
                        "IsDeleted": true,
                        "InsertedBy": 0,
                        "UpdatedBy": 0,
                        "InsertedAt": "2021-07-29T07:50:52.694Z",
                        "UpdatedAt": "2021-07-29T07:50:52.694Z"
                    }
                ]
            });
            //console.log(raw)
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/UpdatePatientContactMethods", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setLoading(false)
                    window.location.reload()
                    //console.log(result)
                })
                .catch(error => {
                    setLoading(false)
                    //console.log('error', error)
                });
        })


    }

    const handleOk = () => {
        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        deleteEmergencyContact(contactid)
        // setTimeout(() => {

        //   setConfirmLoading(false);
        // }, 2000);
    };

    const handleCancel = () => {
        //console.log('Clicked cancel button');
        setVisible(false);
    };
    const openModalPorfileUpdate = () => {
        genderlist.map((item, index) => {
            if (Profile[0].resource.gender.toLowerCase() == item.name.toLowerCase()) {
                setGender(item)
            }
        })

        setEditProfileModalVisible(true)
    }
    //////////
    return (
        <div>
            <Header />
            <div id="myphonemenu" className="page-body">
                <Sidebar />

                <div className="page-content-wrapper">
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">User Profile</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">

                            <div className="letter-and-documentation-container">
                                <div className="grid">
                                    <div className="grid-body">
                                        <div className="row no-gutters">
                                            <div className="col col-12 col-md-4 mb-3">
                                                <div className="letter-profile-container">
                                                    <div className="letter-image-container">

                                                        {
                                                            typeof Profile !== "undefined" && Profile.length && Profile[0].resource.gender.toLowerCase() !== "female" ? (

                                                                <Svg
                                                                    height={50}
                                                                    viewBox="0 0 512 512"
                                                                    width={50}
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    {...props}
                                                                >
                                                                    <Path
                                                                        d="M256.025 483.334l101.429-25.614c57.895-48.074 94.771-120.586 94.771-201.719 0-125.144-87.711-229.801-205.012-255.852C109.897 4.78 0 117.556 0 256c0 71.112 29 135.446 75.812 181.836z"
                                                                        fill="#cbe2ff"
                                                                    />
                                                                    <Path
                                                                        d="M446.914 256c0 83.915-40.381 158.391-102.765 205.079l92.031-23.241C482.995 391.448 512 327.114 512 256 512 114.615 397.385 0 256 0c-11.024 0-21.886.698-32.543 2.05C349.476 18.038 446.914 125.64 446.914 256z"
                                                                        fill="#bed8fb"
                                                                    />
                                                                    <Path
                                                                        d="M319.621 96.952c0-13.075-10.599-23.674-23.674-23.674h-81.582c-30.091 0-54.485 24.394-54.485 54.485v60.493h192.209v-59.635c0-13.075-10.599-23.674-23.674-23.674h-.798a7.995 7.995 0 01-7.996-7.995z"
                                                                        fill="#365e7d"
                                                                    />
                                                                    <Path
                                                                        d="M328.415 104.947h-.798a7.996 7.996 0 01-7.996-7.996c0-13.075-10.599-23.674-23.674-23.674h-8.945v114.978h65.086V128.62c.001-13.073-10.599-23.673-23.673-23.673z"
                                                                        fill="#2b4d66"
                                                                    />
                                                                    <Path
                                                                        d="M425.045 372.355a56.757 56.757 0 00-22.79-13.745l-69.891-22.128-76.348-2.683-76.38 2.683-69.891 22.128c-23.644 7.486-39.713 29.428-39.713 54.229v19.094c44.789 47.328 107.451 77.568 177.183 79.92 78.128-17.353 143.129-69.576 177.83-139.498z"
                                                                        fill="#4a80aa"
                                                                    />
                                                                    <Path
                                                                        d="M441.968 431.932v-19.094c0-17.536-8.04-33.635-21.105-44.213-37.111 75.626-110.422 130.268-197.346 141.317a258.334 258.334 0 0032.026 2.057 273.717 273.717 0 0030.824-1.782c61.031-7.212 115.485-35.894 155.601-78.285z"
                                                                        fill="#407093"
                                                                    />
                                                                    <Path
                                                                        d="M261.796 508.168c15.489-30.751 55.822-118.067 44.321-172.609l-50.101-19.499-50.148 19.5c-11.856 56.225 31.37 147.277 45.681 175.29a259.242 259.242 0 0010.247-2.682z"
                                                                        fill="#e4f6ff"
                                                                    />
                                                                    <Path
                                                                        d="M288.197 483.789l-20.314-79.917h-23.767l-20.264 79.699 25.058 27.897a260.054 260.054 0 0018.81-5.057z"
                                                                        fill="#e28086"
                                                                    />
                                                                    <Path
                                                                        d="M249.302 511.905c2.075.054 4.154.091 6.241.095 2.415-.004 4.822-.046 7.222-.113l12.907-14.259a254.021 254.021 0 01-31.309 8.779z"
                                                                        fill="#dd636e"
                                                                    />
                                                                    <Path
                                                                        d="M298.774 328.183v-45.066h-85.58v45.066c0 23.632 42.79 49.446 42.79 49.446s42.79-25.814 42.79-49.446z"
                                                                        fill="#ffddce"
                                                                    />
                                                                    <Path
                                                                        d="M352.089 180.318H335.73c-9.098 0-16.473-7.375-16.473-16.473v-9.015c0-11.851-11.595-20.23-22.847-16.511a128.797 128.797 0 01-80.823.006l-.031-.01c-11.252-3.717-22.845 4.662-22.845 16.512v9.019c0 9.098-7.375 16.473-16.473 16.473H159.88v26.938c0 6.883 5.58 12.464 12.464 12.464 2.172 0 3.939 1.701 4.076 3.869 2.628 41.668 37.235 74.654 79.565 74.654s76.937-32.986 79.565-74.654c.137-2.167 1.904-3.869 4.076-3.869 6.883 0 12.464-5.58 12.464-12.464v-26.939z"
                                                                        fill="#ffddce"
                                                                    />
                                                                    <Path
                                                                        d="M335.73 180.318c-9.098 0-16.473-7.375-16.473-16.473v-9.015c0-11.851-11.595-20.23-22.847-16.511a128.886 128.886 0 01-9.407 2.707v88.972c-.438 28.948-16.3 54.142-39.725 67.758a80.49 80.49 0 008.706.486c42.33 0 76.937-32.986 79.565-74.654.137-2.167 1.904-3.869 4.076-3.869 6.883 0 12.464-5.58 12.464-12.464v-26.938H335.73z"
                                                                        fill="#ffcbbe"
                                                                    />
                                                                    <G fill="#f4fbff">
                                                                        <Path d="M213.194 316.06l-33.558 27.267 35.192 43.513a11.657 11.657 0 0015.605.594l26.465-22.107zM298.79 316.06l-41.892 49.267 24.874 21.268a11.658 11.658 0 0015.651-.453l34.94-42.815z" />
                                                                    </G>
                                                                    <Path
                                                                        d="M213.194 316.06l-49.256 24.199a7.461 7.461 0 00-3.341 10.117l9.65 18.71a12.528 12.528 0 01-2.282 14.61 43639.5 43639.5 0 00-6.004 5.993c-3.338 3.332-4.537 8.255-3.067 12.737 11.651 35.517 67.725 89.828 88.946 109.478 1.427.038 2.857.064 4.29.08-15.389-29.933-69.922-143.655-38.936-195.924zM344.019 383.695a12.527 12.527 0 01-2.282-14.61l9.65-18.71a7.461 7.461 0 00-3.341-10.117L298.79 316.06c30.978 52.255-23.517 165.929-38.923 195.9 1.448-.025 2.893-.061 4.335-.109 21.265-19.695 77.248-73.94 88.888-109.424 1.47-4.482.271-9.405-3.067-12.737l-6.004-5.995z"
                                                                        fill="#365e7d"
                                                                    />
                                                                    <Path
                                                                        d="M256.898 365.327l-26.06 21.764 13.278 16.781h23.767l13.279-17.771z"
                                                                        fill="#dd636e"
                                                                    />
                                                                </Svg>
                                                            )
                                                                :
                                                                <Svg
                                                                    height={50}
                                                                    viewBox="0 0 512 512"
                                                                    width={50}
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <Path
                                                                        d="M256.522 496.333l96.354-27.793c61.613-48.365 101.202-123.51 101.202-207.915 0-129.719-93.489-237.588-216.761-259.943C104.655 10.253 0 120.9 0 256c0 71.244 28.569 135.165 75.536 181.573l10.563 9.899z"
                                                                        fill="#cbe2ff"
                                                                    />
                                                                    <Path
                                                                        d="M256 0c-11.024 0-21.886.698-32.543 2.05C349.476 18.038 446.914 125.64 446.914 256c0 95.61-52.415 178.974-130.076 222.935l109.058-31.458C478.709 400.582 512 332.184 512 256 512 114.615 397.385 0 256 0z"
                                                                        fill="#bed8fb"
                                                                    />
                                                                    <Path
                                                                        d="M334.886 306.987a55.659 55.659 0 0027.497-48.027l-.028-87.988c0-58.738-47.616-106.354-106.354-106.354s-106.354 47.616-106.354 106.354l-.028 87.988a55.657 55.657 0 0027.497 48.027l11.997 7.036a132.221 132.221 0 00133.778 0z"
                                                                        fill="#efc984"
                                                                    />
                                                                    <Path
                                                                        d="M362.354 170.972c0-44.092-26.832-81.916-65.058-98.038v252.623a132.162 132.162 0 0025.592-11.534l11.997-7.036a55.659 55.659 0 0027.497-48.027l-.028-87.988z"
                                                                        fill="#eabc6b"
                                                                    />
                                                                    <Path
                                                                        d="M256 418.104l67.48-47.486-27.239-32.992v-75.207h-80.482v75.207l-27.239 32.992z"
                                                                        fill="#ffddce"
                                                                    />
                                                                    <Path
                                                                        d="M387.573 355.203l-74.522-14.342c-11.766 19.162-32.916 31.943-57.051 31.943s-45.285-12.781-57.051-31.943l-74.522 14.342c-28.38 5.462-48.89 30.296-48.89 59.196v23.174c39.91 39.667 92.827 66.253 151.788 72.824 4.422.493 8.897.222 13.242-.736l18.955-4.18a29.12 29.12 0 0113.624.26 29.176 29.176 0 0018.624-1.318c58.042-24.285 105.575-68.671 133.875-124.479-8.737-12.533-22.168-21.68-38.072-24.741z"
                                                                        fill="#4a80aa"
                                                                    />
                                                                    <Path
                                                                        d="M436.463 414.399c0-16.318-6.541-31.337-17.343-42.307-37.636 73.834-110.035 126.985-195.638 137.854a258.447 258.447 0 0031.666 2.052h.026c.333.001 1.179.002 1.65 0h.026c70.073-.228 133.516-28.61 179.612-74.425v-23.174z"
                                                                        fill="#407093"
                                                                    />
                                                                    <Path
                                                                        d="M261.178 505.117c9.536-27.607 29.332-90.05 34.041-145.019-11.02 7.989-24.567 12.706-39.219 12.706s-28.199-4.717-39.219-12.706c4.833 56.421 25.564 120.725 34.777 147.14z"
                                                                        fill="#e28086"
                                                                    />
                                                                    <Path
                                                                        d="M250.768 504.961a730.16 730.16 0 002.458 7.007c5.249.055 3.424.022 5.548 0a737.884 737.884 0 003.502-10.051 252.509 252.509 0 01-11.508 3.044z"
                                                                        fill="#dd636e"
                                                                    />
                                                                    <Path
                                                                        d="M327.324 163.855c-28.418-30.607-33.694-58.413-33.694-58.413-14.116 37.04-49.967 63.355-91.964 63.355-1.285 0-2.564-.025-3.837-.073-9.664-.371-17.69 7.375-17.69 17.046v31.07c.625 41.373 34.338 74.725 75.861 74.725 41.522 0 75.235-33.351 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                        fill="#ffddce"
                                                                    />
                                                                    <Path
                                                                        d="M327.324 163.855c-17.072-18.387-25.786-35.754-30.028-46.698v96.257c-.575 38.004-29.074 69.218-65.892 74.053A75.713 75.713 0 00256 291.565c41.522 0 75.235-33.352 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                        fill="#ffcbbe"
                                                                    />
                                                                    <G fill="#365e7d">
                                                                        <Path d="M171.879 343.452c-3.239 1.985-3.883 6.423-1.341 9.246l15.779 17.527a6.076 6.076 0 01-.48 8.608l-10.404 9.244c-2.185 1.941-3.265 4.873-2.75 7.749 5.813 32.476 57.771 94.503 76.568 116.09 1.325.032 2.652.055 3.982.069-9.093-25.471-37.475-110.116-37.475-174.36v-21.064zM340.121 343.452l-43.878-26.89v21.064c0 64.244-28.382 148.889-37.475 174.36 1.33-.013 2.657-.036 3.982-.069 18.797-21.587 70.755-83.614 76.568-116.09.515-2.877-.566-5.808-2.75-7.749l-10.404-9.244a6.076 6.076 0 01-.48-8.608l15.779-17.527c2.541-2.824 1.897-7.262-1.342-9.247z" />
                                                                    </G>
                                                                    <Path
                                                                        d="M343.362 175.56H331.86v38h11.502c10.496 0 19.003-8.51 19-19.006-.003-10.49-8.509-18.994-19-18.994z"
                                                                        fill="#ffddce"
                                                                    />
                                                                </Svg>

                                                        }
                                                    </div>
                                                    <div className="letter-profile-name">
                                                        <h4 className="letter-profile-name-heading">

                                                            {/* {isFetching && <ActivityIndicator color='blue' size='small' />} */}
                                                            {
                                                                typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                                    <p>{Profile[0].resource.name[0].text}</p>
                                                                ) : null
                                                            }

                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-8 mb-3">
                                                <div className="row no-gutters">
                                                    <div className="col-12 col-md-6">
                                                        <div className="personal-info-container mr-lg-4">
                                                            <div className="personal-info d-flex mb-3">
                                                                <div className="personal-balance">
                                                                    <p className="personal-balance-para">Balance<span>{Balance}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="personal-nhi">
                                                                    <p className="personal-nhi-para">NHI<span>N/A</span></p>
                                                                </div>
                                                            </div>
                                                            <div className="personal-paypal-payment mb-3 mb-md-0">
                                                                <Link to="/accounts">   <button className="btn paypal-payment-btn">View Invoices</button> </Link>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="letter-action-container h-100">
                                                            <a href="https://firebasestorage.googleapis.com/v0/b/project-h-de8a7.appspot.com/o/Documents%2FmyIndici%20Terms%20and%20Conditions.pdf?alt=media&token=0bb38548-17ec-4511-9a38-a60c5da7836c" target="_blank">  <button className="btn terms-and-conditions-btn">
                                                                <i className="mdi mdi-file-document d-none d-md-flex" />
                                                                <span>Terms and Conditions</span>
                                                            </button>
                                                            </a>
                                                            <button onClick={() => { setShowReset(true) }} className="btn change-password-btn">
                                                                <i className="mdi mdi-lock d-none d-md-flex" />
                                                                <span>Change Password</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {isFetching &&
                                                    <div style={{
                                                        marginTop: 20,
                                                        display: 'flex',

                                                        alignItems: "flex-start"
                                                    }}>
                                                        <ActivityIndicator color='blue' size='small' />
                                                    </div>}
                                            </div>

                                            <div className="col  col-12 col-md-4 mb-3">
                                                <div className="mb-4">
                                                    <div style={{ justifyContent: 'space-between' }} className="row">
                                                        <div className="letter-emergency-detail-card-heading">
                                                            <h5>Personal details</h5>
                                                        </div>
                                                        <div className="letter-emergency-detail-card-heading">
                                                            <TouchableOpacity onPress={() =>
                                                                openModalPorfileUpdate()
                                                            }>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill={"#000"} height="12pt" viewBox="0 -1 401.52289 401" width="12pt"><path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" /><path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" /></svg>

                                                            </TouchableOpacity>
                                                        </div>
                                                    </div>
                                                    {/* <div className="letter-detail-card-heading">
                                                        <h5>Personal details</h5>
                                                    </div> */}
                                                    {typeof Profile !== "undefined" && Profile.length ?
                                                        <PersonalDetail
                                                            nameArray={typeof Profile !== "undefined" && Profile.length ? Profile : []} />
                                                        : <p></p>}

                                                </div>
                                                <div>
                                                    <div className="contact-detail-card-heading">
                                                        {/* <h5>Contact details</h5> */}
                                                        <div style={{ justifyContent: 'space-between' }} className="row">
                                                            <div className="letter-emergency-detail-card-heading">
                                                                <h5>Contact details</h5>
                                                            </div>

                                                            <div className="letter-emergency-detail-card-heading">
                                                                {typeof Profile !== "undefined" && Profile.length && Profile[0].resource.telecom ?
                                                                    <TouchableOpacity onPress={() => Contact()}>

                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill={"#000"} height="12pt" viewBox="0 -1 401.52289 401" width="12pt"><path d="m370.589844 250.972656c-5.523438 0-10 4.476563-10 10v88.789063c-.019532 16.5625-13.4375 29.984375-30 30h-280.589844c-16.5625-.015625-29.980469-13.4375-30-30v-260.589844c.019531-16.558594 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.519531-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.59375c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.792969c0-5.523437-4.476563-10-10-10zm0 0" /><path d="m376.628906 13.441406c-17.574218-17.574218-46.066406-17.574218-63.640625 0l-178.40625 178.40625c-1.222656 1.222656-2.105469 2.738282-2.566406 4.402344l-23.460937 84.699219c-.964844 3.472656.015624 7.191406 2.5625 9.742187 2.550781 2.546875 6.269531 3.527344 9.742187 2.566406l84.699219-23.464843c1.664062-.460938 3.179687-1.34375 4.402344-2.566407l178.402343-178.410156c17.546875-17.585937 17.546875-46.054687 0-63.640625zm-220.257812 184.90625 146.011718-146.015625 47.089844 47.089844-146.015625 146.015625zm-9.40625 18.875 37.621094 37.625-52.039063 14.417969zm227.257812-142.546875-10.605468 10.605469-47.09375-47.09375 10.609374-10.605469c9.761719-9.761719 25.589844-9.761719 35.351563 0l11.738281 11.734375c9.746094 9.773438 9.746094 25.589844 0 35.359375zm0 0" /></svg>

                                                                    </TouchableOpacity>
                                                                    : null}

                                                            </div>
                                                            {/* <div className="letter-emergency-detail-card-heading"> */}
                                                            {/* <TouchableOpacity onPress={() =>alert("hhh")}>  <h5>Edit Contact</h5> </TouchableOpacity> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>
                                                    {typeof Profile !== "undefined" && Profile.length ?
                                                        <ProfileContactDetails
                                                            Profile={Profile} /> :
                                                        <p></p>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col col-12 col-md-8 mb-3">
                                                <div className=" mb-4">
                                                    <div style={{ justifyContent: 'space-between' }} className="row">
                                                        <div className="letter-emergency-detail-card-heading">
                                                            <h5>Emergency Contact</h5>
                                                        </div>
                                                        <div className="letter-emergency-detail-card-heading">
                                                            <TouchableOpacity onPress={() => setAddContactVisible(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" height="20pt" viewBox="0 0 512 512" width="20">
                                                                    <path d="m256 0c-141.164062 0-256 114.835938-256 256s114.835938 256 256 256 256-114.835938 256-256-114.835938-256-256-256zm0 0" fill="green" />
                                                                    <path d="m368 277.332031h-90.667969v90.667969c0 11.777344-9.554687 21.332031-21.332031 21.332031s-21.332031-9.554687-21.332031-21.332031v-90.667969h-90.667969c-11.777344 0-21.332031-9.554687-21.332031-21.332031s9.554687-21.332031 21.332031-21.332031h90.667969v-90.667969c0-11.777344 9.554687-21.332031 21.332031-21.332031s21.332031 9.554687 21.332031 21.332031v90.667969h90.667969c11.777344 0 21.332031 9.554687 21.332031 21.332031s-9.554687 21.332031-21.332031 21.332031zm0 0" fill="#fff" /></svg>
                                                            </TouchableOpacity>
                                                        </div>
                                                    </div>

                                                    <div className="letter-emergency-detail-card-body mr-n2 ml-n2">
                                                        <div className="row no-gutters">
                                                            {/* <div className="col-12 col-md-4"> */}
                                                            {typeof Profile !== "undefined" && Profile.length ?
                                                                Profile.map((person, index) => {
                                                                    return (
                                                                        <FlatList
                                                                            horizontal={true}
                                                                            showsHorizontalScrollIndicator={true}
                                                                            data={person.resource.contact}
                                                                            renderItem={({ item, index }) => (

                                                                                <div className="emergency-card">
                                                                                    <TouchableOpacity onPress={() => {
                                                                                        setContactId(item.id)
                                                                                        setVisible(true)
                                                                                    }
                                                                                    }
                                                                                        style={{ backgroundColor: 'red', alignItems: 'center', width: 20, borderRadius: 20, alignContent: 'center', height: 20, right: 0, top: 0, position: 'absolute' }}>
                                                                                        <Text style={{ color: 'white', }}>X</Text>
                                                                                    </TouchableOpacity>
                                                                                    <div className="w-100 d-flex">
                                                                                        <div className="contact-image-container">

                                                                                            <Svg
                                                                                                height={40}
                                                                                                viewBox="0 0 512 512"
                                                                                                width={40}
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                {...props}
                                                                                            >
                                                                                                <Path
                                                                                                    d="M256.522 496.333l96.354-27.793c61.613-48.365 101.202-123.51 101.202-207.915 0-129.719-93.489-237.588-216.761-259.943C104.655 10.253 0 120.9 0 256c0 71.244 28.569 135.165 75.536 181.573l10.563 9.899z"
                                                                                                    fill="#cbe2ff"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M256 0c-11.024 0-21.886.698-32.543 2.05C349.476 18.038 446.914 125.64 446.914 256c0 95.61-52.415 178.974-130.076 222.935l109.058-31.458C478.709 400.582 512 332.184 512 256 512 114.615 397.385 0 256 0z"
                                                                                                    fill="#bed8fb"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M334.886 306.987a55.659 55.659 0 0027.497-48.027l-.028-87.988c0-58.738-47.616-106.354-106.354-106.354s-106.354 47.616-106.354 106.354l-.028 87.988a55.657 55.657 0 0027.497 48.027l11.997 7.036a132.221 132.221 0 00133.778 0z"
                                                                                                    fill="#efc984"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M362.354 170.972c0-44.092-26.832-81.916-65.058-98.038v252.623a132.162 132.162 0 0025.592-11.534l11.997-7.036a55.659 55.659 0 0027.497-48.027l-.028-87.988z"
                                                                                                    fill="#eabc6b"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M256 418.104l67.48-47.486-27.239-32.992v-75.207h-80.482v75.207l-27.239 32.992z"
                                                                                                    fill="#ffddce"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M387.573 355.203l-74.522-14.342c-11.766 19.162-32.916 31.943-57.051 31.943s-45.285-12.781-57.051-31.943l-74.522 14.342c-28.38 5.462-48.89 30.296-48.89 59.196v23.174c39.91 39.667 92.827 66.253 151.788 72.824 4.422.493 8.897.222 13.242-.736l18.955-4.18a29.12 29.12 0 0113.624.26 29.176 29.176 0 0018.624-1.318c58.042-24.285 105.575-68.671 133.875-124.479-8.737-12.533-22.168-21.68-38.072-24.741z"
                                                                                                    fill="#4a80aa"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M436.463 414.399c0-16.318-6.541-31.337-17.343-42.307-37.636 73.834-110.035 126.985-195.638 137.854a258.447 258.447 0 0031.666 2.052h.026c.333.001 1.179.002 1.65 0h.026c70.073-.228 133.516-28.61 179.612-74.425v-23.174z"
                                                                                                    fill="#407093"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M261.178 505.117c9.536-27.607 29.332-90.05 34.041-145.019-11.02 7.989-24.567 12.706-39.219 12.706s-28.199-4.717-39.219-12.706c4.833 56.421 25.564 120.725 34.777 147.14z"
                                                                                                    fill="#e28086"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M250.768 504.961a730.16 730.16 0 002.458 7.007c5.249.055 3.424.022 5.548 0a737.884 737.884 0 003.502-10.051 252.509 252.509 0 01-11.508 3.044z"
                                                                                                    fill="#dd636e"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M327.324 163.855c-28.418-30.607-33.694-58.413-33.694-58.413-14.116 37.04-49.967 63.355-91.964 63.355-1.285 0-2.564-.025-3.837-.073-9.664-.371-17.69 7.375-17.69 17.046v31.07c.625 41.373 34.338 74.725 75.861 74.725 41.522 0 75.235-33.351 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                                                    fill="#ffddce"
                                                                                                />
                                                                                                <Path
                                                                                                    d="M327.324 163.855c-17.072-18.387-25.786-35.754-30.028-46.698v96.257c-.575 38.004-29.074 69.218-65.892 74.053A75.713 75.713 0 00256 291.565c41.522 0 75.235-33.352 75.861-74.725v-41.405c0-4.292-1.617-8.435-4.537-11.58z"
                                                                                                    fill="#ffcbbe"
                                                                                                />
                                                                                                <G fill="#365e7d">
                                                                                                    <Path d="M171.879 343.452c-3.239 1.985-3.883 6.423-1.341 9.246l15.779 17.527a6.076 6.076 0 01-.48 8.608l-10.404 9.244c-2.185 1.941-3.265 4.873-2.75 7.749 5.813 32.476 57.771 94.503 76.568 116.09 1.325.032 2.652.055 3.982.069-9.093-25.471-37.475-110.116-37.475-174.36v-21.064zM340.121 343.452l-43.878-26.89v21.064c0 64.244-28.382 148.889-37.475 174.36 1.33-.013 2.657-.036 3.982-.069 18.797-21.587 70.755-83.614 76.568-116.09.515-2.877-.566-5.808-2.75-7.749l-10.404-9.244a6.076 6.076 0 01-.48-8.608l15.779-17.527c2.541-2.824 1.897-7.262-1.342-9.247z" />
                                                                                                </G>
                                                                                                <Path
                                                                                                    d="M343.362 175.56H331.86v38h11.502c10.496 0 19.003-8.51 19-19.006-.003-10.49-8.509-18.994-19-18.994z"
                                                                                                    fill="#ffddce"
                                                                                                />
                                                                                            </Svg>
                                                                                        </div>
                                                                                        <div className="card-content">
                                                                                            {/* added minWidth for card data handling */}
                                                                                            <div className="card-heading" style={{ minWidth: 150 }}>
                                                                                                <h5>{item.name.given[0]}</h5>
                                                                                            </div>
                                                                                            <div className="card-partner">
                                                                                                <span>{item.relationship[0].coding[0].display}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            )}
                                                                            keyExtractor={(item, index) => index + ""}

                                                                        />
                                                                    )
                                                                })
                                                                : null

                                                            }
                                                            {/* </div> */}
                                                            {/* <div className="col-12 col-md-4">
                                                                <div className="emergency-card">
                                                                    <div className="contact-image-container">
                                                                        <img src="https://thumbs.dreamstime.com/b/default-female-avatar-profile-picture-icon-grey-woman-photo-placeholder-vector-illustration-88413637.jpg" alt className="contact-image img-fluid rounded-circle" />
                                                                    </div>
                                                                    <div className="card-content">
                                                                        <div className="card-heading">
                                                                            <h5>Han Hyo-Joo</h5>
                                                                        </div>
                                                                        <div className="card-partner">
                                                                            <span>Partner</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <div className="emergency-card">
                                                                    <div className="contact-image-container">
                                                                        <img src="https://thumbs.dreamstime.com/b/default-female-avatar-profile-picture-icon-grey-woman-photo-placeholder-vector-illustration-88413637.jpg" alt className="contact-image img-fluid rounded-circle" />
                                                                    </div>
                                                                    <div className="card-content">
                                                                        <div className="card-heading">
                                                                            <h5>Han Hyo-Joo</h5>
                                                                        </div>
                                                                        <div className="card-partner">
                                                                            <span>Partner</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-4">
                                                                <div className="emergency-card">
                                                                    <div className="contact-image-container">
                                                                        <img src="https://thumbs.dreamstime.com/b/default-female-avatar-profile-picture-icon-grey-woman-photo-placeholder-vector-illustration-88413637.jpg" alt className="contact-image img-fluid rounded-circle" />
                                                                    </div>
                                                                    <div className="card-content">
                                                                        <div className="card-heading">
                                                                            <h5>Han Hyo-Joo</h5>
                                                                        </div>
                                                                        <div className="card-partner">
                                                                            <span>Partner</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                       */}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-4">
                                                    <div className="practice-detail-card">
                                                        <div style={{ justifyContent: 'space-between' }} className="row">
                                                            <div className="letter-emergency-detail-card-heading">
                                                                <h5>Practice Details</h5>
                                                            </div>
                                                            {/* <div className="letter-emergency-detail-card-heading">
                                                                <TouchableOpacity onPress={() => showEmeregencyContact()}>  <h5>Edit Practice Details</h5> </TouchableOpacity>
                                                            </div> */}
                                                        </div>

                                                        {/* <div className="practice-detail-card-heading">
                                                            <h5>Practice Details</h5>
                                                        </div> */}


                                                        {typeof Practise !== "undefined" && Practise.length &&
                                                            <ProfilePracticeDetail Practise={Practise} />
                                                        }
                                                    </div>

                                                </div>
                                                <div>
                                                    <div className="address-detail-card-heading">
                                                        <div style={{ justifyContent: 'space-between' }} className="row">
                                                            <div className="letter-emergency-detail-card-heading">
                                                                <h5>Address</h5>
                                                            </div>
                                                            <div className="letter-emergency-detail-card-heading">
                                                                {/* <TouchableOpacity onPress={() => { }}> 
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill={"green"} height="15pt" viewBox="0 0 492.49284 492" width="15pt">
                                                                    <path d="m304.140625 82.472656-270.976563 270.996094c-1.363281 1.367188-2.347656 3.09375-2.816406 4.949219l-30.035156 120.554687c-.898438 3.628906.167969 7.488282 2.816406 10.136719 2.003906 2.003906 4.734375 3.113281 7.527344 3.113281.855469 0 1.730469-.105468 2.582031-.320312l120.554688-30.039063c1.878906-.46875 3.585937-1.449219 4.949219-2.8125l271-270.976562zm0 0"/><path d="m476.875 45.523438-30.164062-30.164063c-20.160157-20.160156-55.296876-20.140625-75.433594 0l-36.949219 36.949219 105.597656 105.597656 36.949219-36.949219c10.070312-10.066406 15.617188-23.464843 15.617188-37.714843s-5.546876-27.648438-15.617188-37.71875zm0 0"/></svg>
                                 
                                                                  </TouchableOpacity> */}
                                                            </div>
                                                        </div>
                                                        {/* <h5>Address</h5> */}
                                                    </div>
                                                    {typeof Profile !== "undefined" && Profile.length &&
                                                        <ProfileAddress
                                                            Profile={Profile}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                    </footer>
                </div>

                {/* {Add Emergency contact Modal} */}
                <Modal
                    title="Add Emergency Contact"
                    centered
                    visible={addContactVisible}
                    onOk={() => {
                        saveApi()
                        // setAddContactVisible(false)
                    }}
                    onCancel={() => setAddContactVisible(false)}
                    //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                >
                    <div className="row" >
                        <div className="col-md-12 mx-auto">

                            {/* Name Section */}
                            <div className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Title</label>
                                    <select id="mTitlte" class="form-control" name="country">
                                        {
                                            titleArray.length > 0 && titleArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="1234">Hassan Jan</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Belize">Belize</option> */}
                                    </select>
                                    <label htmlFor="validate-internet-ip-field" id="mTitleerror"></label>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">First Name</label>
                                    <div className="input-group">
                                        <input id="mFirstName" type="text" className="form-control" placeholder="First Name" />

                                    </div>
                                    <label htmlFor="validate-internet-ip-field" style={{ color: "red" }} id="mfirstnameerror"></label>
                                </div>
                            </div>

                            {/* Family Section */}
                            <div style={{ }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Family Name</label>
                                    <div className="input-group">
                                        <input id="mFamilyName" type="text" className="form-control" placeholder="Family Name" />

                                    </div>
                                    <label htmlFor="validate-internet-ip-field" style={{ color: "red" }} id="mfaimlynameerror"></label>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Releationship</label>
                                    <div className="input-group">
                                        <select id="mRelationship" class="form-control" name="country">
                                            {
                                                relationShipArray.length > 0 && relationShipArray.map((value, key) => {
                                                    return (
                                                        <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                    )
                                                })
                                            }
                                            {/* <option value="Togo">Togo</option>
                                            <option value="Guinea-Bissau">Guinea-Bissau</option>
                                            <option value="Laos">Laos</option>
                                            <option value="Andorra">Andorra</option>
                                            <option value="Cayman Islands">Cayman Islands</option>
                                            <option value="Falkland Islands">Falkland Islands</option>
                                            <option value="Norway">Norway</option>
                                            <option value="American Samoa">American Samoa</option>
                                            <option value="Palau">Palau</option>
                                            <option value="Pakistan">Pakistan</option>
                                            <option value="Malta">Malta</option>
                                            <option value="Belize">Belize</option> */}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Contact Section */}
                            <div style={{ }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Cell Phone Number</label>
                                    <div className="input-group">
                                        <input id="mHomePhone" type="text" className="form-control" placeholder="Home Phone" />

                                    </div>
                                    <label htmlFor="validate-internet-ip-field" style={{ color: "red" }} id="mhomephone"></label>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Work Phone Number</label>
                                    <div className="input-group">
                                        <input id="mWorkPhone" type="text" className="form-control" placeholder="Work Phone" />

                                    </div>
                                    <label htmlFor="validate-internet-ip-field" style={{ color: "red" }} id="mworkphone"></label>
                                </div>
                            </div>

                            <br></br>
                            <hr></hr>
                            <div className="letter-emergency-detail-card-heading">
                                <h5>Address Information</h5>
                            </div>

                            {/* House infomraiton */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">House Number</label>
                                    <div className="input-group">
                                        <input id="mHouse" type="text" className="form-control" placeholder="House Number" />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Floor/Building</label>
                                    <div className="input-group">
                                        <input id="mFloor" type="text" className="form-control" placeholder="Floor" />

                                    </div>
                                </div>
                            </div>

                            {/* Street information */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Street</label>
                                    <div className="input-group">
                                        <input id="mStreet" type="text" className="form-control" placeholder="Street" />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Suburb/Town</label>
                                    <select id="mTown" class="form-control" name="country">
                                        {
                                            townArray.length > 0 && townArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="Togo">Togo</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Belize">Belize</option> */}
                                    </select>
                                </div>
                            </div>

                            {/* City information */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">City</label>
                                    <select id="mCity" class="form-control" name="country">
                                        {
                                            cityArray.length > 0 && cityArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }
                                        {/* <option value="Togo">Togo</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Falkland Islands">Falkland Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Belize">Belize</option> */}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Residence</label>
                                    <div className="input-group">
                                        <input id="mResidence" type="text" className="form-control" placeholder="Residence" />

                                    </div>
                                </div>
                            </div>

                            {/* Postal Code INfromation */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-12">
                                    <label htmlFor="validate-internet-ip-field">Postal Code</label>
                                    <div className="input-group">
                                        <input id="mPostcode" type="text" className="form-control" placeholder="Postal Code" />

                                    </div>
                                </div>

                            </div>
                            {
                                isAddingEmergency && <ActivityIndicator style={{ marginTop: 30 }} size="small" />
                            }
                        </div>
                    </div>

                </Modal>


                <Modal
                    // show={showReset}
                    visible={showReset}
                    onHide={() => handleCloseReset()}
                    backdrop="static"

                    title="Update your password to continue."
                    centered
                    visible={showReset}
                    onOk={() => {
                        // saveApi()
                        resetPasswordFunc()
                        // setAddContactVisible(false)
                    }}
                    onCancel={() => handleCloseReset()}
                    //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}



                    keyboard={false}>
                    {/* <Modal.Header className="modal-header bg-primary" closeButton>
                        <Modal.Title className="modal-title text-white">Update your password to continue.</Modal.Title>
                    </Modal.Header> */}
                    {/* <Modal.Body> */}
                    <div>
                        <View style={{ backgroundColor: "#d1ecf1", padding: 10, marginBottom: 5 }}>
                            <Text style={{ fontWeight: 'bold' }}>Password Rules</Text>
                            <Text style={{}}>Password must be at least 8 characters.</Text>
                            <Text style={{}}>Password must contain at least one alphabet and two digits.</Text>
                            <Text style={{}}>Password must not contain first, middle or family name.</Text>
                            <Text style={{}}>Example: abc12345 or Abc@1234</Text>
                        </View>
                        {/* <div className="form-group input-rounded">
                                <input id="password" type="text" className="form-control" placeholder="Enter password." />
                            </div>
                            <div className="form-group input-rounded">
                                <input id="confirmPassword" type="text" className="form-control" placeholder="Confirm password." />
                            </div>
                            <div className="form-group input-rounded">
                                <p id="errorMessageReset" style={{ color: "red" }}></p>
                            </div> */}

                        <div className="form-group">
                            <div className="input-group">
                                <input id="password" type="password" className="form-control" placeholder="Password" />
                                <div className="input-group-append">
                                    <div onClick={() => showPassword()} className="input-group-text"><i className={showincon}></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <input id="confirmPassword" type="password" className="form-control" placeholder="Confirm Password" />
                                <div className="input-group-append">
                                    <div onClick={() => showPasswordConfirm()} className="input-group-text"><i className={showinconConf}></i></div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group input-rounded">
                            <p id="errorMessageReset" style={{ color: "red" }}></p>
                        </div>
                        {
                            isResetPassword && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                        }
                        {
                            passwordStartMessage.error && <View style={{ textAlign: 'center', marginBottom: 5 }}><Text style={{ color: "red" }}>{passwordStartMessage.message}</Text></View>
                        }
                        {/* <button onClick={() => resetPasswordFunc()} className="btn btn-primary btn-block">Set Password </button> */}

                    </div>
                    {/* </Modal.Body> */}

                </Modal>

                {/* Edit Address Modal start */}
                <Modal
                    title="Edit Address Information"
                    centered
                    visible={editAddressesModalVisible}
                    onOk={() => {
                        // saveApi()
                        // setAddContactVisible(false)
                    }}
                    onCancel={() => setAddressesModalVisible(false)}
                    //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                >
                    <div className="row" >
                        <div className="col-md-12 mx-auto">

                            <br></br>
                            <hr></hr>
                            <div className="letter-emergency-detail-card-heading">
                                <h5>Address Information</h5>
                            </div>

                            {/* House infomraiton */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Find Address</label>
                                    <div className="input-group">
                                        <input id="mHouse" type="text" className="form-control" placeholder="Find Address" />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">House Number</label>
                                    <div className="input-group">
                                        <input id="mHouse" type="text" className="form-control" placeholder="House Number" />

                                    </div>
                                </div>

                            </div>

                            {/* Street information */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Floor/Building</label>
                                    <div className="input-group">
                                        <input id="mFloor" type="text" className="form-control" placeholder="Floor" />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Street</label>
                                    <div className="input-group">
                                        <input id="mStreet" type="text" className="form-control" placeholder="Street" />

                                    </div>
                                </div>
                            </div>

                            {/* City information */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Suburb/Town</label>
                                    <select id="mTown" class="form-control" name="country">
                                        {
                                            townArray.length > 0 && townArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">City</label>
                                    <select id="mCity" class="form-control" name="country">
                                        {
                                            cityArray.length > 0 && cityArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>

                            </div>

                            {/* Postal Code INfromation */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                {/* <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Residence</label>
                                    <div className="input-group">
                                        <input id="mResidence" type="text" className="form-control" placeholder="Residence" />

                                    </div>
                                </div> */}
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Postal Code</label>
                                    <div className="input-group">
                                        <input id="mPostcode" type="text" className="form-control" placeholder="Postal Code" />

                                    </div>
                                </div>

                            </div>
                            {
                                isAddingEmergency && <ActivityIndicator style={{ marginTop: 30 }} size="small" />
                            }


                        </div>


                    </div>

                </Modal>

                {/* End Edit Address Modal*/}

                {/* Edit Contact Modal start */}
                <Modal
                    title="Edit Contact Details"
                    centered
                    visible={editContactModalVisible}
                    onOk={() => {
                        ContactUpdateHandler()
                        // saveApi()
                        // setAddContactVisible(false)
                    }}
                    onCancel={() => setContactModalVisible(false)}
                    //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                >
                    <div className="row" >
                        <div className="col-md-12 mx-auto">

                            {/* <br></br>
                            <hr></hr> */}
                            {/* <div className="letter-emergency-detail-card-heading">
                                <h5>Contact Information</h5>
                            </div> */}
                            {/* Work Phone Number infomraiton */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Work Phone Number:</label>
                                    <div className="input-group">
                                        <div>
                                            <ReactPhoneInput
                                                inputExtraProps={{
                                                    name: "Work Phone Number:",
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                areaCodes={{ nz: ['+64'] }}
                                                placeholder="Work Phone Number"
                                                //onlyCountries={['nz']}
                                                defaultCountry={"NZ"}
                                                value={workphone}
                                                inputStyle={{
                                                    width: 465,
                                                    backgroundColor: '#f6f7f9',
                                                    height: 34
                                                }}
                                                // containerStyle={{
                                                //     width: '100%',
                                                // }}
                                                onChange={handleOnChangeWork}
                                            />
                                        </div>

                                        {/* <input id="mHouse" type="text" className="form-control" placeholder="Work phone number" /> */}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Home Phone Number:</label>
                                    <div className="input-group">
                                        {/* <input id="mHouse" type="text" className="form-control" placeholder="Home Phone Number" />
                                         */}
                                        <div>
                                            <ReactPhoneInput
                                                inputExtraProps={{
                                                    name: "Home Phone Number",
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                // onlyCountries={['nz']}
                                                defaultCountry={"NZ"}
                                                value={homephone}
                                                //containerClass="form-control"
                                                inputStyle={{
                                                    width: 465,
                                                    backgroundColor: '#f6f7f9',
                                                    height: 34
                                                }}
                                                onChange={handleOnChangeHome}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>


                            {/* Cell Phone Number infomraiton */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Cell Phone Number:</label>
                                    <div className="input-group">
                                        {/* <input id="mHouse" type="text" className="form-control" placeholder="Cell Phone Number" /> */}
                                        <div>
                                            <ReactPhoneInput
                                                inputExtraProps={{
                                                    name: "Home Phone Number",
                                                    required: true,
                                                    autoFocus: true
                                                }}
                                                // onlyCountries={['nz']}
                                                defaultCountry={"NZ"}
                                                value={cellPhone}
                                                inputStyle={{
                                                    width: 465,
                                                    backgroundColor: '#f6f7f9',
                                                    height: 34
                                                }}
                                                onChange={handleOnChangeCell}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Email:</label>
                                    <div className="input-group">

                                        <input id="mEmail" value={email} type="text" className="form-control" placeholder="Email" />

                                    </div>
                                </div>

                            </div>


                            {/* House infomraiton */}
                            {/* <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Contact Methods:</label>
                                    <div className="input-group">
                                        <input id="mHouse" type="text" className="form-control" placeholder="Contact Methods" />

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Preferred Contact:</label>
                                    <div className="input-group">
                                        <input id="mHouse" type="text" className="form-control" placeholder="Preferred Contact" />

                                    </div>
                                </div>

                            </div> */}

                        </div>


                    </div>

                </Modal>

                {/* End Edit Contact Modal*/}
                {/* Edit Profile Modal start */}
                <Modal
                    title="Update Profile"
                    centered
                    visible={editProfileModalVisible}
                    onOk={() => {

                        updateProfile()
                        //   saveApi()
                        // setAddContactVisible(false)
                    }}
                    onCancel={() => setEditProfileModalVisible(false)}
                    //afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                    style={{ top: 10, bottom: 90, overflow: "hidden" }}
                    width={1000}
                >
                    <div className="row" >
                        <div className="col-md-12 mx-auto">

                            {/* Name Section */}
                            <div className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Title</label>
                                    <select id="mTitlte" class="form-control" name="country">
                                        {
                                            titleArray.length > 0 && titleArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }

                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">First Name</label>
                                    <div className="input-group">

                                        {
                                            typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                <input id="mFirstName" value={Profile[0].resource.name[0].given[0]} type="text" className="form-control" placeholder="First Name" />

                                            ) : null
                                        }
                                        {/*  */}

                                    </div>
                                </div>
                            </div>

                            {/* Family Section */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Family Name</label>
                                    <div className="input-group">


                                        {
                                            typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                <input id="mFamilyName" value={Profile[0].resource.name[0].family} type="text" className="form-control" placeholder="Family Name" />


                                            ) : null
                                        }

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Preferred Name</label>
                                    <div className="input-group">
                                        {
                                            typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                <input id="mPreferedName" value={Profile[0].resource.name[0].text} type="text" className="form-control" placeholder="Family Name" />


                                            ) : null
                                        }

                                    </div>
                                </div>

                            </div>

                            {/* Gender  & Ethencity Section */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Gender</label>
                                    <select id="mGender" class="form-control" name="mGender">
                                        {Profile && Profile.length ?
                                            <option value={capitalize(Profile[0].resource.gender)}>{capitalize(Profile[0].resource.gender)}</option>
                                            : null
                                        }

                                        {/* {

                                            Gender.length > 0 && titleArray.map((value, key) => {

                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        } */}

                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Ethnicity</label>
                                    <div className="input-group">

                                        {
                                            typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                <input id="mEthnicity" value={Profile[0].resource.extension[1].valueString} type="text" className="form-control" placeholder="Ethnicity" />


                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* DOB  & Blood Group Section */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">DOB</label>

                                    {
                                        typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                            <input id="mDOB" value={Profile[0].resource.birthDate} type="text" className="form-control" placeholder="DOB" />


                                        ) : null
                                    }

                                    {/* <select id="mTitlte" class="form-control" name="country">
                                        {
                                            titleArray.length > 0 && titleArray.map((value, key) => {
                                                return (
                                                    <option value={value.resource.extension[0].valueString}>{value.resource.extension[1].valueString}</option>
                                                )
                                            })
                                        }

                                    </select> */}
                                </div>

                                {/* <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Blood Group</label>
                                    <div className="input-group">
                                        <select id="mBloodGroup" class="form-control" name="BloodGroup">


                                            {
                                                typeof Profile !== "undefined" && Profile.length && Profile[0].resource.name ? (
                                                    <option value={Profile[0].resource.extension[4].valueString ? Profile[0].resource.extension[4].valueString : "N/A"}>{Profile[0].resource.extension[5].valueString ? Profile[0].resource.extension[5].valueString : "N/A"}</option>
                                                ) : null
                                            }


                                        </select>
                                    </div>
                                </div> */}

                            </div>

                            {/* Email Section */}
                            <div style={{ marginTop: 20 }} className="form-group row mb-0">
                                <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Email</label>

                                    {typeof Profile !== "undefined" && Profile.length && Profile[0].resource.hasOwnProperty('telecom') ? Profile[0].resource.telecom.map((item, index) => {
                                        return (
                                            <>
                                                {item.system === "email" &&
                                                    <input id="mEmail" value={item.value} type="text" className="form-control" placeholder="Email" />
                                                }

                                            </>
                                        )
                                        //setEmail({ value: item.value, error: '' })
                                        // document.getElementById('mEmail').value = item.value;
                                    })
                                        : null}
                                    {/* <input id="mEmail" type="text" className="form-control" placeholder="Email" /> */}

                                </div>
                                {/* <div className="col-md-6">
                                    <label htmlFor="validate-internet-ip-field">Blood Group</label>
                                    <div className="input-group">
                                        <input id="mFirstName" type="text" className="form-control" placeholder="First Name" />

                                    </div>
                                </div> */}
                            </div>





                        </div>


                    </div>

                </Modal>

                {/* End Edit Profile Modal*/}


            </div>
            <Modal
                title="Delete Emergency Contact"
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <p>{"Are you sure you want to delete this emergency contact?"}</p>
            </Modal>
        </div >
    )
}
function mapStateToProps(state) {
    return {
        Profile: state.Profile,
        Practise: state.Practise,

        Balance: state.Balance,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // getProfile:
        getProfile: () => dispatch(fetchProfileFromApi()),
        getPracticeCenterDetail: () => dispatch(fetchPractisefromAPI()),
        getBalance: () => dispatch(fetchBalanceFromApi()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchUserInformation } from "../../shared/actions/AuthActions";
import { View, TouchableOpacity, FlatList, ActivityIndicator } from "react-native-web";
import { emailValidator, getInitials, passwordValidator } from '../../shared/core/utils';
import AsyncStorage from "@react-native-async-storage/async-storage";
import changePasswrodService from '../../shared/services/ChangePasswrod';
import { Redirect, useHistory } from "react-router-dom";

const ResetPassword = props => {
    const dispatch = useDispatch();
    const linkHistory = useHistory();
    const [isResetPassword, setIsResetPassword] = useState(false)

    const resetPasswordFunc = async () => {
        linkHistory.push("/")
        // loadReset()
        // reloadApp()
        // let password = document.getElementById("password").value;
        // let confirmPassword = document.getElementById("confirmPassword").value;

        // const passwordError = passwordValidator(password);
        // const cnfrmPaswrd = passwordValidator(confirmPassword);
        // if (passwordError) {
        //     document.getElementById("errorMessage").innerHTML = "Password cannot be empty "
        //     return;
        // }
        // if (cnfrmPaswrd) {
        //     document.getElementById("errorMessage").innerHTML = "Confirm Password cannot be empty "
        //     return;
        // }
        // if (password !== confirmPassword) {
        //     document.getElementById("errorMessage").innerHTML = "Password can't be matched."
        //     return
        // }
        // //check for lower case
        // if (!password.match(/[a-z]/)) {
        //     alert("Password must contain at least one lower case letter.");
        //     // passForm.passInput.focus();
        //     return;
        // }

        // //Validating length
        // if (password.length < 8) {
        //     alert("Your password has less than 8 characters.");
        //     return;
        // }

        // //check for upper ase
        // if (!password.match(/[A-Z]/)) {
        //     alert("Password must contain at least one upper case letter.");
        //     // passForm.passInput.focus();
        //     return;
        // }
        // var patt = new RegExp("^(?=(.*[a-zA-Z]){1,})(?=(.*[0-9]){2,}).{8,}$");
        // //check for number
        // if (!patt.test(password)) {
        //     alert("Password must contain at least two number.");
        //     // passForm.passInput.focus();
        //     return;
        // }

        // var userInfo = await AsyncStorage.getItem('@userPassordReset') || 'none';
        // // check remining confirm and passweordk
        // const item = JSON.parse(userInfo);
        // const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&Password=' + password
        // //console.log("ResetPasswrod===>" + queryString)
        // changePasswrodService(queryString)
        //     .then(data => data.json())
        //     .then(json => {
        //         // {"StatusCode":1,"StatusMessage":"Password updated successfully."}
        //         if (json.StatusCode == "1") {
        //             //console.log("Enter json==>" + json.StatusCode)
        //             setIsResetPassword(false)
        //             alert(json.StatusMessage)
        //             clearCache()
        //             loadReset()

        //         }
        //         // {"StatusCode":3,"StatusMessage":"three time password error try to some differect passsword"}
        //         if (json.StatusCode == "3") {
        //             //console.log("Enter json==>" + json.StatusCode)
        //             setIsResetPassword(false)
        //             alert(json.StatusMessage)
        //             // clearCache()
        //             // loadReset()

        //         }
        //         //console.log("Json==>" + JSON.stringify(json))
        //         setIsResetPassword(false)
        //         clearCache()
        //         loadReset()

        //     })
        //     .catch(err => {
        //         setIsResetPassword(false)
        //         //console.log("resetPasswrodError==>" + err)
        //     })
    }

    const loadReset = () => {
        linkHistory.push("/")
    }
    const clearCache = async () => {
        try {
            await AsyncStorage.clear()
        } catch (e) {
            // clear error
        }

        //console.log('Done.')
    }
    function value(passForm) {

        /** This function is being used to find out the values input by the user in both the password and confirm password text boxes.
         * The results are fed back to the user using alerts.
         * **/




    };

    return (
        <div className="authentication-theme auth-style_1">
            <div className="row">
                <div className="col-12 logo-section">
                    <a href="#" className="logo">
                        <img src="https://devnhlapp.indici.ie/Images/mainLogo-indici-140X40.svg" alt="logo" />
                    </a>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-5 col-md-7 col-sm-9 col-11 mx-auto">
                    <div className="grid">
                        <div className="grid-body">
                           
                            <div className="row">
                                <div className="col-lg-7 col-md-8 col-sm-9 col-12 mx-auto form-wrapper">

                                    <div className="form-group input-rounded">
                                        <input id="password" type="text" className="form-control" placeholder="Enter password." />
                                    </div>
                                    <div className="form-group input-rounded">
                                        <input id="confirmPassword" type="text" className="form-control" placeholder="Confirm password." />
                                    </div>
                                    {/* <div className="form-group input-rounded">
                                        <input type="password" className="form-control" placeholder="Password" />
                                    </div> */}
                                    {/* <div className="form-inline">
                                        <div className="checkbox">
                                            <label>
                                                <input type="checkbox" className="form-check-input" />Remember me <i className="input-frame" />
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="form-group input-rounded">
                                        <p id="errorMessage" style={{ color: "red" }}></p>
                                    </div>
                                    {/* {
                                        reloadApplication && reloadApp()
                                    } */}
                                    {
                                        isResetPassword && <View style={{ textAlign: 'center', marginBottom: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
                                    }

                                    <button onClick={() => loadReset()} className="btn btn-primary btn-block">Set Password </button>
                                    <div className="signup-link">

                                        <Link to="/">
                                            Sign In
                                        </Link>


                                    </div>
                                </div>
                            </div>
                       
                       
                       
                       
                        </div>
                    </div>
                </div>
            </div>
            <div className="auth_footer">
                <p className="text-muted text-center">© My indici 2021</p>
            </div>
        </div>

    );
}



function mapStateToProps(state) {
    return {
        AuthUser: state.AuthUser
    }
}

function mapDispatchToProps(disptach) {
    return {
        getUserToken: (email, password) => disptach(fetchUserInformation(email, password)),
    }
}


//export default Login;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword)
import React from 'react';


const OuterHtml = props => {
    return (
        <>
            <div style={{ display: 'none' }} dangerouslySetInnerHTML={{ __html: props.commingHtml }}></div>
        </>
    );
}

export default OuterHtml

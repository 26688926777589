import { GET_BALANCE_INFO, GET_BALANCE_INFO_SUCCESS,GET_BALANCE_INFO_FAILED,} from "../constants/Constant";

//inital state
const initalState = {
    isLoadoing: false,
    Balance: [],
    error: false
}

//export default state
export default function balanceReducer(state = initalState, action) {
    switch (action.type) {
        case GET_BALANCE_INFO:
            return {
                ...state,
                isLoadoing: true,
               // Balance: []
            }

        case GET_BALANCE_INFO_SUCCESS:
            return {
                ...state,
                isLoadoing: false,
                Balance: action.data
            }

        case GET_BALANCE_INFO_FAILED:
            return {
                ...state,
                isLoadoing: false,
                error: true
            }

      
        default:
            return state
    }
}

//constant  fro diagnosis
// Reducer file name People.js

export const FETCHING_PEOPLE = 'FETCHING_PEOPLE';
export const FETCHING_PEOPLE_SUCCESS = 'FETCHING_PEOPLE_SUCCESS';
export const FETCHING_PEOPLE_SUCCESS_MORE = 'FETCHING_PEOPLE_SUCCESS_MORE';
export const FETCHING_PEOPLE_FAILURE = 'FETCHING_PEOPLE_FAILURE';

//addiontional for diagnosis Long term
export const FETCHING_LONGTERM_PEOPLE = 'FETCHING_LONGTERM_PEOPLE';
export const FETCHING_LONGTERM_PEOPLE_SUCCESS = 'FETCHING_LONGTERM_PEOPLE_SUCCESS';
export const FETCHING_LONGTERM_PEOPLE_SUCCESS_MORE = 'FETCHING_LONGTERM_PEOPLE_SUCCESS_MORE';
export const FETCHING_LONGTERM_PEOPLE_FAILURE = 'FETCHING_LONGTERM_PEOPLE_FAILURE';
export const FETCHING_LONGTERM_PEOPLE_FINISHED = 'FETCHING_LONGTERM_PEOPLE_FINISHED';

//constant for allergies
export const GETTING_ALLERGIES = 'GETTING_ALLERGIES';
export const GETTING_ALLERGIES_SUCCESS = 'GETTING_ALLERGIES_SUCCESS';
export const GETTING_ALLERGIES_FAILED = 'GETTING_ALLERGIES_FAILED';
export const GETTING_ALLERGIES_MORE_SUCCESS = 'GETTING_ALLERGIES_MORE_SUCCESS';
export const GETTING_ALLERGIES_FINISHED = 'GETTING_ALLERGIES_FINISHED';


//constant for resources
export const GETTING_RESOURCES = 'GETTING_RESOURCES';
export const GETTING_RESOURCES_SUCCESS = 'GETTING_RESOURCES_SUCCESS';
export const GETTING_RESOURCES_FAILED = 'GETTING_RESOURCES_FAILED';
export const GETTING_RESOURCES_MOR_SUCCESS = 'GETTING_RESOURCES_MOR_SUCCESS';
export const GETTING_RESOURCES_FINISHED = 'GETTING_RESOURCES_FINISHED';


//const for medication
export const GETTING_MEDS = 'GETTING_MEDS';
export const GETTING_MEDS_SUCCESS = 'GETTING_MEDS_SUCESSS';
export const GETTING_MEDS_MORE_SUCCESS = 'GETTING_MEDS_MORE_SUCCESS';
export const GETTING_MEDS_FAILED = 'GETTING_MEDS_FAILED'
//Recentmedication
export const GETTING_RECENT_MEDS = 'GETTING_RECENT_MEDS';
export const GETTING_MEDS_RECENT_SUCESSS = 'GETTING_MEDS_RECENT_SUCESSS';
export const GETTING_RECENT_MEDS_MORE_SUCCESS = 'GETTING_RECENT_MEDS_MORE_SUCCESS';
export const GETTING_RECENT_MEDS_FAILED = 'GETTING_RECENT_MEDS_FAILED'

// Repeated Medication Action
export const GETTING_REPEATED_MEDS = 'GETTING_REPEATED_MEDS';
export const GETTING_MEDS_REPEATED_SUCESSS = 'GETTING_MEDS_REPEATED_SUCESSS';
export const GETTING_REPEATED_MEDS_MORE_SUCCESS = 'GETTING_REPEATED_MEDS_MORE_SUCCESS';
export const GETTING_REPEATED_MEDS_FAILED = 'GETTING_REPEATED_MEDS_FAILED'

export const GETTING_LONG_MEDS = 'GETTING_LONG_MEDS';
export const GETTING_LONG_MEDS_SUCCESS = 'GETTING_LONG_MEDS_SUCCESS';
export const GETTING_LONG_MEDS_FAILED = 'GETTING_LONG_MEDS_FAILED'
export const GETTING_LONG_MEDS_MORE = 'GETTING_LONG_MEDS_MORE';
export const GETTING_LONG_MEDS_FINISHED = 'GETTING_LONG_MEDS_FINISHED';

// treamt of choice medication
export const GETTING_TREATMENTOFCHOICE_MEDS = 'GETTING_TREATMENTOFCHOICE_MEDS';
export const GETTING_LONG_TREATMENTOFCHOICE_SUCCESS = 'GETTING_LONG_TREATMENTOFCHOICE_SUCCESS';
export const GETTING_LONG_TREATMENTOFCHOICE_FAILED = 'GETTING_LONG_TREATMENTOFCHOICE_FAILED'
export const GETTING_LONG_TREATMENTOFCHOICE_MORE = 'GETTING_LONG_TREATMENTOFCHOICE_MORE';
export const GETTING_LONG_TREATMENTOFCHOICE_FINISHED = 'GETTING_LONG_TREATMENTOFCHOICE_FINISHED';


//const for authorization
export const GETTING_TOKEN = 'GETTING_TOKEN';
export const GETTING_TOKEN_SUCESS = 'GETTING_TOKEN_SUCCESS';
export const GETTING_TOKEN_FAILED = 'GETTING_TOKEN_FAILED';


//const for user profile
export const GETTING_PROFILE = 'GETTING_PROFILE';
export const GETTING_PROFILE_SUCCESS = 'GETTING_PROFILE_SUCCESS';
export const GETTING_PROFILE_FAILURE = 'GETTING_PROFILE_FAILURE';


//const for user reports
export const GETING_REPORTS = 'GETING_REPORTS';
export const GETING_REPORTS_SUCCESS = 'GETING_REPORTS_SUCCESS';
export const GETING_REPORTS_FAILURE = 'GETING_REPORTS_FAILURE';
export const GETING_REPORTS_MORE_SUCCESS = 'GETING_REPORTS_MORE_SUCCESS';
export const GETING_REPORTS_FINISH = 'GETING_REPORTS_FINISH';

//const for patient timeline
export const GETTING_TIMELINE = 'GETTING_TIMELINE';
export const GETTING_TIMELINE_SUCCESS = 'GETTING_TIMELINE_SUCCESS';
export const GETTING_TIMELINE_SEARCH_SUCCESS = 'GETTING_TIMELINE_SEARCH_SUCCESS';
export const GETTING_TIMELINE_MORE_SUCCESS = 'GETTING_TIMELINE_MORE_SUCCESS';
export const GETTING_TIMELINE_FINISHED = 'GETTING_TIMELINE_FINISHED';
export const GETTING_TIMELINE_FAILED = 'GETTING_TIMELINE_FAILED';


//const for paitent immune 
export const GETTING_IMMUNE = 'GETTING_IMMUNE';
export const GETTING_IMMUNE_SUCCESS = 'GETTING_IMMUNE_SUCCESS';
export const GETTING_IMMUNE_MORE_SUCCESS = 'GETTING_IMMUNE_MORE_SUCCESS';
export const GETTING_IMMUNE_FAILURE = 'GETTING_IMMUNE_FAILURE';
export const GETTING_IMMUNE_FINISHED = 'GETTING_IMMUNE_FINISHED';



//const for paitent immune 
export const GETTING_IMMUNE_OVERDUE = 'GETTING_IMMUNE_OVERDUE';
export const GETTING_IMMUNE_OVERDUE_SUCCESS = 'GETTING_IMMUNE_OVERDUE_SUCCESS';
export const GETTING_IMMUNE_OVERDUE_MORE_SUCCESS = 'GETTING_IMMUNE_OVERDUE_MORE_SUCCESS';
export const GETTING_IMMUNE_OVERDUE_FAILURE = 'GETTING_IMMUNE_OVERDUE_FAILURE';



//const for paitent immune 
export const GETTING_IMMUNE_ADMINISTERED = 'GETTING_IMMUNE_ADMINISTERED';
export const GETTING_IMMUNE_ADMINISTERED_SUCCESS = 'GETTING_IMMUNE_ADMINISTERED_SUCCESS';
export const GETTING_IMMUNE_ADMINISTERED_MORE_SUCCESS = 'GETTING_IMMUNE_ADMINISTERED_MORE_SUCCESS';
export const GETTING_IMMUNE_ADMINISTERED_FAILURE = 'GETTING_IMMUNE_ADMINISTERED_FAILURE';
export const GETTING_IMMUNE_ADMINISTERED_FINISHED = 'GETTING_IMMUNE_ADMINISTERED_FINISHED';


//const for paitent immune BY HASSAN
export const GETTING_IMMUNE_DUE = 'GETTING_IMMUNE_DUE';
export const GETTING_IMMUNE_DUE_SUCCESS = 'GETTING_IMMUNE_DUE_SUCCESS';
export const GETTING_IMMUNE_DUE_MORE_SUCCESS = 'GETTING_IMMUNE_DUE_MORE_SUCCESS';
export const GETTING_IMMUNE_DUE_FAILURE = 'GETTING_IMMUNE_DUE_FAILURE';
export const GETTING_IMMUNE_DUE_FINISHED = 'GETTING_IMMUNE_DUE_FINISHED';

//message screen name
export const MESSAGE_SCREEN_NAME = 'MESSAGE_SCREEN_NAME'

//const for messaging
export const GETTING_MESSAGE = 'GETTING_MESSAGE';
export const GETTING_MESSAGE_SUCCESS = 'GETTING_MESSAGE_SUCCESS';
export const GETTING_MESSAGE_MORE_SUCCESS = 'GETTING_MESSAGE_MORE_SUCCESS';
export const GETTING_MESSAGE_FAIL = 'GETTING_MESSAGE_FAIL';
export const GETTING_MESSAGE_ARRAY_UPDATE = 'GETTING_MESSAGE_ARRAY_UPDATE';

//const for Drafts messaging
export const GETTING_DRAFTS_MESSAGE = 'GETTING_DRAFTS_MESSAGE';
export const GETTING_DRAFTS_MESSAGE_SUCCESS = 'GETTING_DRAFTS_MESSAGE_SUCCESS';
export const GETTING_DRAFTS_MESSAGE_MORE_SUCCESS = 'GETTING_DRAFTS_MESSAGE_MORE_SUCCESS';
export const GETTING_DRAFTS_MESSAGE_FAIL = 'GETTING_DRAFTS_MESSAGE_FAIL';

//const for get message by id.
export const GETTING_MESSAGE_BY_ID = 'GETTING_MESSAGE_BY_ID';
export const GETTING_MESSAGE_BY_ID_SUCCESS = 'GETTING_MESSAGE_BY_ID_SUCCESS';
export const GETTING_MESSAGE_BY_ID_FAILED = 'GETTING_MESSAGE_BY_ID_FAILED';
export const GETTING_MESSAGE_BY_ID_CLEAN = 'GETTING_MESSAGE_BY_ID_CLEAN';

//sent items
export const GETTING_SENT_MESSAGE = 'GETTING_SENT_MESSAGE';
export const GETTING_MESSAGE_SENT_SUCCESS = 'GETTING_MESSAGE_SENT_SUCCESS';
export const GETTING_MESSAGE_SENT_MORE_SUCCESS = 'GETTING_MESSAGE_SENT_MORE_SUCCESS';
export const GETTING_MESSAGE_SENT_FAIL = 'GETTING_MESSAGE_SENT_FAIL';

//set items
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_SET_MESSAGE = 'CLEAR_SET_MESSAGE';
// export const GETTING_MESSAGE_SENT_SUCCESS = 'GETTING_MESSAGE_SENT_SUCCESS';
// export const GETTING_MESSAGE_SENT_MORE_SUCCESS = 'GETTING_MESSAGE_SENT_MORE_SUCCESS';
// export const GETTING_MESSAGE_SENT_FAIL = 'GETTING_MESSAGE_SENT_FAIL';

// Delete message
export const MESSAGE_DELETING = "MESSAGE_DELETING"
export const MESSAGE_DELET = "MESSAGE_DELET"
export const MESSAGE_DELET_ERROR = "MESSAGE_DELET_ERROR"


//const for booking appointment
export const BOOK_APP = 'BOOK_APP';
export const BOOK_APP_SUCCESS = 'BOOK_APP_SUCCESS';
export const BOOK_APP_FAIL = 'BOOK_APP_FAIL';
export const BOOK_APP_CLEANUP = 'BOOK_APP_CLEANUP';

//after booking, adding appointent
export const ADDING_APPOINT = 'ADDING_APPOINT';
export const ADDING_APPOINT_SUCCESS = 'ADDING_APPOINT_SUCCESS';
export const ADDING_APPOINT_FAIL = 'ADDING_APPOINT_FAIL';
export const ADDING_APPOINT_CLEANUP = 'ADDING_APPOINT_CLEANUP';


//get list of messaging receipted
export const GETTING_RECP = 'GETTING_RECP';
export const GETTING_RECP_SUCCESS = 'GETTING_RECP_SUCCESS';
export const GETTING_RECP_FAIL = 'GETTING_RECP_FAIL';



//appointment constants

//future appointments
export const GETTING_APPOINTMENT = 'GETTING_APPOINTMENT';
export const GETTING_APPOINTMENT_SUCCESS = 'GETTING_APPOINTMENT_SUCCESS';
export const GETTING_APPOINTMENT_MORE_SUCCESS = 'GETTING_APPOINTMENT_MORE_SUCCESS';
export const GETTING_APPOINTMENT_FAILURE = 'GETTING_APPOINTMENT_FAILURE';

//completed appointments
export const GETTING_COMPLETED_APPOINTMENT = 'GETTING_COMPLETED_APPOINTMENT';
export const GETTING_COMPLETED_APPOINTMENT_SUCCESS = 'GETTING_COMPLETED_APPOINTMENT_SUCCESS';
export const GETTING_COMPLETED_APPOINTMENT_MORE = 'GETTING_COMPLETED_APPOINTMENT_MORE';
export const GETTING_COMPLETED_APPOINTMENT_FINISHED = 'GETTING_COMPLETED_APPOINTMENT_FINISHED';
export const GETTING_COMPLETED_APPOINTMENTT_FAILURE = 'GETTING_COMPLETED_APPOINTMENTT_FAILURE';

// change Passwrod
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED"
export const CHANGE_PASSWORD_GETTING = "CHANGE_PASSWORD_GETTING"
//Missed appointments
export const GETTING_MISSED_APPOINTMENT = 'GETTING_MISSED_APPOINTMENT';
export const GETTING_MISSED_APPOINTMENT_SUCCESS = 'GETTING_MISSED_APPOINTMENT_SUCCESS';
export const GETTING_MISSED_APPOINTMENT_MORE_SUCCESS = 'GETTING_MISSED_APPOINTMENT_MORE_SUCCESS';
export const GETTING_MISSED_APPOINTMENT_FINISHED = 'GETTING_MISSED_APPOINTMENT_FINISHED';
export const GETTING_MISSED_APPOINTMENTT_FAILURE = 'GETTING_MISSED_APPOINTMENTT_FAILURE';

//cancelled appointments
export const GETTING_CANCEL_APPOINTMENT = 'GETTING_CANCEL_APPOINTMENT';
export const GETTING_CANCEL_APPOINTMENT_SUCCESS = 'GETTING_CANCEL_APPOINTMENT_SUCCESS';
export const GETTING_CANCEL_MORE_APPOINTMENT_SUCCESS = 'GETTING_CANCEL_MORE_APPOINTMENT_SUCCESS';
export const GETTING_CANCEL_APPOINTMENTT_FAILURE = 'GETTING_CANCEL_APPOINTMENTT_FAILURE';
export const GETTING_CANCEL_MORE_APPOINTMENT_FINISHED = 'GETTING_CANCEL_APPOINTMENTT_FINISHED';

//search appointments
export const START_SEARCH_APPOINTMENTN = 'START_SEARCH_APPOINTMENTN';
export const START_SEARCH_APPOINTMENTN_SUCCESS = 'START_SEARCH_APPOINTMENTN_SUCCESS';
export const START_SEARCH_APPOINTMENTN_FAILURE = 'START_SEARCH_APPOINTMENTN_FAILURE';
export const START_SEARCH_APPOINTMENTN_FINISHED = 'START_SEARCH_APPOINTMENTN_FINISHED';



//ACCOUNTS

//pending
export const GET_PENDING_INVOICES = 'GET_PENDING_INVOICES';
export const GET_PENDING_INVOICES_SUCCESS = 'GET_PENDING_INVOICES_SUCCESS';
export const GET_PENDING_INVOICES_MORE_SUCCESS = 'GET_PENDING_INVOICES_MORE_SUCCESS';
export const GET_PENDING_INVOICES_FAIL = 'GET_PENDING_INVOICES_FAIL';

//all
export const GET_ALL_INVOICES = 'GET_ALL_INVOICES';
export const GET_ALL_INVOICES_SUCCESS = 'GET_ALL_INVOICES_SUCCESS';
export const GET_ALL_INVOICES_MORE_SUCCESS = 'GET_ALL_INVOICES_MORE_SUCCESS';
export const GET_ALL_INVOICES_FAIL = 'GET_ALL_INVOICES_FAIL';




//cancel appointment
export const START_APPOINTMENT_CANCELLATION = 'START_APPOINTMENT_CANCELLATION';
export const START_APPOINTMENT_CANCELLATION_SUCESSS = 'START_APPOINTMENT_CANCELLATION_SUCESSS';
export const START_APPOINTMENT_CANCELLATION_FAIL = 'START_APPOINTMENT_CANCELLATION_FAIL';
export const START_APPOINTMENT_CANCELLATION_CLEAN = 'START_APPOINTMENT_CANCELLATION_CLEAN';



//send new message
export const START_SEND_SERVICE = 'START_SEND_SERVICE';
export const START_SEND_SERVICE_SUCCESS = 'START_SEND_SERVICE_SUCCESS';
export const START_SEND_SERVICE_REPLY_SUCCESS = 'START_SEND_SERVICE_REPLY_SUCCESS';
export const START_SEND_SERVICE_FAILED = 'START_SEND_SERVICE_FAILED';
export const START_SEND_SERVICE_CLEANUP = 'START_SEND_SERVICE_CLEANUP';



//Read Message
export const READ_MESSAGE = "READ_MESSAGE";
export const READ_MESSAGE_SUCCESS = "READ_MESSAGE_SUCCESS";
export const READ_MESSAGE_FAILED = "READ_MESSAGE_FAILED";



//lookup
export const START_LOOKUP = 'START_LOOKUP';
export const START_LOOKUP_SUCCESS = 'START_LOOKUP_SUCCESS';
export const START_LOOKUP_FAILED = 'START_LOOKUP_FAILED';



//user login
export const USER_LOGIN = 'USER_LOGIN';
export const USER_AUTH_LOGIN = 'USER_AUTH_LOGIN';
export const USER_RESET_PASSWORD_STATE = 'USER_RESET_PASSWORD_STATE';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_MFA_SUCCESS = 'USER_LOGIN_MFA_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_LOGIN_AUTH_FAILED = 'USER_LOGIN_AUTH_FAILED';
export const USER_LOGIN_END = 'USER_LOGIN_END';
export const USER_RESET_PASSWORD_STATUS = 'USER_RESET_PASSWORD_STATUS';

//validate token
export const START_VALIDATION = 'START_VALIDATION';
export const START_VALIDATION_SUCCESS = 'START_VALIDATION_SUCCESS';
export const START_VALIDATION_FAILED = 'START_VALIDATION_FAILED';



//get letters
export const START_LETTERS = 'START_LETTERS';
export const START_LETTERS_SUCCESS = 'START_LETTERS_SUCCESS';
export const SEARCH_START_LETTERS_SUCCESS = 'SEARCH_START_LETTERS_SUCCESS';
export const START_LETTERS_MORE_SUCCESS = 'START_LETTERS_MORE_SUCCESS';
export const START_LETTERS_FAILED = 'START_LETTERS_FAILED';
export const START_LETTERS_FINISHIED = 'START_LETTERS_FINISHIED';

export const START_LETTERS_BYID = 'START_LETTERS_BYID';
export const START_LETTERS_SUCCESS_BYID = 'START_LETTERS_SUCCESS_BYID';
export const START_LETTERS_FAILED_BYID = 'START_LETTERS_FAILED_BYID';

//get Reminder
export const GET_REMINDER = 'GET_REMINDER';
export const GET_REMINDER_SUCCESS = 'GET_REMINDER_SUCCESS';
export const GET_REMINDER_MORE_SUCCESS = 'GET_REMINDER_MORE_SUCCESS';
export const GET_REMINDER_FAILED = 'GET_REMINDER_FAILED';
export const GET_REMINDER_FINISHIED = 'GET_REMINDER_FINISHIED';


//get Vitals
export const GET_VITAL = 'GET_VITAL';
export const GET_VITAL_SUCCESS = 'GET_VITAL_SUCCESS';
export const GET_VITAL_FAILED = 'GET_VITAL_FAILED';
export const GET_VITAL_WHOLE_ARRAY = 'GET_VITAL_WHOLE_ARRAY';


//get Vitals
export const GET_PROXY_ACCOUNTS = 'GET_PROXY_ACCOUNTS';
export const GET_PROXY_ACCOUNTS_SUCCESS = 'GET_PROXY_ACCOUNTS_SUCCESS';
export const GET_PROXY_ACCOUNTS_FAILED = 'GET_PROXY_ACCOUNTS_FAILED';
export const GET_PROXY_ACCOUNTS_FINISHED = 'GET_PROXY_ACCOUNTS_FINISHED';

//get prcatise
export const GET_PRACTISE = 'GET_PRACTISE';
export const GET_PRACTISE_SUCCESS = 'GET_PRACTISE_SUCCESS';
export const GET_PRACTISE_FAILED = 'GET_PRACTISE_FAILED';


//get prcatise
export const GET_PROXY_MENU = 'GET_PROXY_MENU';
export const GET_PROXY_MENU_SUCCESS = 'GET_PROXY_MENU_SUCCESS';
export const GET_PROXY_MENU_FAILED = 'GET_PROXY_MENU_FAILED';
//get Role 
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILED = 'GET_ROLES_FAILED';

//GET_AVAILABLE_PRACTITIONER
export const GET_AVAILABLE_PRACTITIONER = 'GET_AVAILABLE_PRACTITIONER';
export const GET_AVAILABLE_PRACTITIONER_SUCCESS = 'GET_AVAILABLE_PRACTITIONER_SUCCESS';
export const GET_AVAILABLE_PRACTITIONER_FAILED = 'GET_AVAILABLE_PRACTITIONER_FAILED';
export const GET_AVAILABLE_PRACTITIONER_CLEAN = "GET_AVAILABLE_PRACTITIONER_CLEAN";

//get Balance information
export const GET_BALANCE_INFO = 'GET_BALANCE_INFO';
export const GET_BALANCE_INFO_SUCCESS = 'GET_BALANCE_INFO_SUCCESS';
export const GET_BALANCE_INFO_FAILED = 'GET_BALANCE_INFO_FAILED';

//download proifiles from
export const GETTING_PROFILE_IMAGES = 'GETTING_PROFILE_IMAGES';
export const GETTING_PROFILE_IMAGES_SUCCESS = 'GETTING_PROFILE_IMAGES_SUCCESS';
export const GETTING_PROFILE_IMAGES_FAILED = 'GETTING_PROFILE_IMAGES_FAILED';


//get _COUNTER
export const GET_COUNTER = 'GET_COUNTER';
export const GET_COUNTER_SUCCESS = 'GET_COUNTER_SUCCESS';
export const GET_COUNTER_FAILED = 'GET_COUNTER_FAILED';
//get Pharmacy
export const GET_PHARMACY = 'GET_PHARMACY';
export const GET_PHARMACY_SUCCESS = 'GET_PHARMACY_SUCCESS';
export const GET_PHARMACY_FAILED = 'GET_PHARMACY_FAILED';

//get appointment type
export const GET_APPOINTMENT_TYPE = 'GET_APPOINTMENT_TYPE'
export const GET_APPOINTMENT_TYPE_SUCCESS = 'GET_APPOINTMENT_TYPE_SUCCESS'
export const GET_APPOINTMENT_TYPE_FAILED = 'GET_APPOINTMENT_TYPE_FAILED'

//get Application Settings
export const GET_APPLICATION_SETTINGS = 'GET_APPLICATION_SETTINGS'
export const GET_APPLICATION_SETTINGS_SUCCESS = 'GET_APPLICATION_SETTINGS_SUCCESS'
export const GET_APPLICATION_SETTINGS_FAILED = 'GET_APPLICATION_SETTINGS_FAILED'

//get provider locations
export const GET_PROVIDER_LOCATIONS = 'GET_PROVIDER_LOCATIONS'
export const GET_PROVIDER_LOCATIONS_SUCCESS = 'GET_PROVIDER_LOCATIONS_SUCCESS'
export const GET_PROVIDER_LOCATIONS_FAILED = 'GET_PROVIDER_LOCATIONS_FAILED'


import React from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';


const ReminderHandler = props => {
    const getRandomTag = (value) => {
        // var textArray = [
        //     'card-header text-white bg-danger',
        //     'card-header text-white bg-success',
        //     'card-header text-white bg-primary',
        //     'card-header text-white bg-info',
        //     'card-header text-white bg-warning',
        // ];

        if (value === 'High') {
            return 'card-header text-white bg-danger'
        }

        if (value === 'Normal') {
            return 'card-header text-white bg-success'
        }

        if (value === 'Medium') {
            return 'card-header text-white bg-warning'
        }
        // var randomNumber = Math.floor(Math.random() * textArray.length);
        // return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }
    return (
        <div className="col-lg-6 ">
            <div className="card shadow p-0 mb-3 rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                <div className={getRandomTag(props.color)}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.title}</Text>
                        <Text style={{ fontWeight: '500', color: '#fff' }}>{props.reminderDate}</Text>
                    </View>

                </div>
                <div className="card-body">
                    <h4 className="card-title"><i className="mdi mdi-18px mdi-hospital-building" /> <b>Provider: </b>{props.reminderProvider}</h4>
                    <p className="card-text"><i className="mdi mdi-18px mdi-content-paste" /> <b>Description: </b>{props.reminderDescription}</p>
                    <p className="card-text"><i className="mdi mdi-18px mdi-comment-text" /> <b>Notes: </b>{props.reminderNote}</p>
                </div>
            </div>
        </div>
    );
}

export default ReminderHandler
import React, { useEffect } from 'react'
import getUserId from '../../shared/core/GetToken'
import { Chart, Line } from 'react-chartjs-2';

export default function VitalHandler({
    vitalName,
    vitalLabelsArray,
    vitalDataArray,
    vitalDataArrayB
}) {

    /*
    datasets: [
        {
          label: "First dataset",
          data: [33, 53, 85, 41, 44, 65],
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)"
        },
        {
          label: "Second dataset",
          data: [33, 25, 35, 51, 54, 76],
          fill: false,
          borderColor: "#742774"
        }
      ]*/
    useEffect(() => {
        // //console.log("labels", JSON.stringify(vitalLabelsArray))

        let key = document.getElementById(vitalName).getContext('2d');

        if (vitalName === 'Blood Pressure') {
            console.log(JSON.stringify(vitalDataArray))
            console.log(JSON.stringify(vitalDataArrayB))
            var myChart = new Chart(key, {
                type: 'line',
                data: {
                    labels: vitalLabelsArray,
                    datasets: [{
                        label: "Systolic",
                        data: vitalDataArray,
                        backgroundColor: [
                            'rgba(217, 118, 164, 0.3)'
                        ],
                        pointBackgroundColor: [
                            'rgba(217, 118, 164, 0.3)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',

                        ],
                        borderWidth: 1
                    },
                    {
                        label: "Diastolic",
                        data: vitalDataArrayB,
                        backgroundColor: [
                            'rgba(41, 146, 255, 0.3)'
                        ],
                        pointBackgroundColor: [
                            'rgba(41, 146, 255, 0.3)'
                        ],
                        borderColor: [

                            'rgba(54, 162, 235, 1)',

                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    tooltips: {
                        mode: 'nearest'
                    },
                    scales: {

                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                steps: 10,
                                stepValue: 5,
                                max: 200
                            }
                        }]
                    },
                }
            });
            return
        } else {
            var myChart = new Chart(key, {
                type: 'line',
                data: {
                    labels: vitalLabelsArray,
                    datasets: [{
                        label: vitalName,
                        data: vitalDataArray,
                        backgroundColor: [
                            'rgba(0, 0, 0, 0.1)'
                        ],
                        pointBackgroundColor: [
                            'rgba(0, 0, 0, 0.1)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    tooltips: {
                        mode: 'nearest'
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                offsetGridLines: true
                            }
                        }],
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true,
                                steps: 10,
                                stepValue: 5,
                                max: 200
                            }
                        }]
                       
                    }
                }
            });
        }




    }, [])




    return (
        <div className="col-md-6" >
            <div className="grid">
                <div className="grid-body">
                    <h2 className="grid-title">{vitalName}</h2>
                    <div className="item-wrapper">
                        <canvas id={vitalName}></canvas>
                    </div>
                </div>
            </div>
        </div>
    )
}

import React from 'react'
const addressNodes = ['Address Line 1', 'Address Line 2', 'Address Line 3', 'City/County', 'Country', 'Postal Code']
const Item = ({ titleName, titleValue }) => {
    return (
        <div className="detail-container">
            <label className="detail-label">{titleName}</label>
            <span className="detail-value">{titleValue}</span>
        </div>
    )
}
const ProfileAddress = ({ Profile }) => {
    return (
        <div>
            <div className="address-detail-card-body">
                {
                    typeof (Profile) !== 'undefined' && Profile.length && Profile[0].resource.address !== undefined ? Profile[0].resource.address[0].line.map((item, index) => {
                        return (
                            <Item
                                titleName={addressNodes[index]}
                                titleValue={item}
                            />
                            // <ProfileItemB title={addressNodes[index]} address={item} />

                        )
                    }) : <p>No address found.</p>
                }
            </div>


            {/* <Item
                titleName={"Derrivation Quintile"}
                titleValue={"Miss"}
            />
            <Item
                titleName={"Postal Address"}
                titleValue={"Miss"}
            />
            <Item
                titleName={"Address"}
                titleValue={"Miss"}
            /> */}


        </div>
    )
}
export default ProfileAddress;
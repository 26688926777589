import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Normaize } from "../../shared/core/Normaize";

const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const ProxyAccountWebHandler = props => {
    const [iconColor, setIconColor] = useState(getRandomColor());

    return (
        <View style={styles.listItems}>
            <View style={[styles.circleColumn, props.styles || { backgroundColor: iconColor }]} >
                <Text style={styles.circleColumnItemsDate}>{props.reInitails}</Text>
                {/*       */}
            </View>
            <View style={styles.detailsView}>
                <View style={styles.detailsViewList}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={styles.algTile}>{props.reReceiverName}</Text>
                        <Text>{props.sentDate}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 5 }}>
                        <Text style={{ fontSize: 12 }}>{props.reType}</Text>
                    </View>


                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    listItems: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#D1D1D6',
        paddingBottom: 10,
        paddingTop: 10,
        paddingLeft: 10,
        backgroundColor: '#fff',
        // justifyContent: 'center',
        // alignItems: 'center',
        //marginTop: 3,
        // marginLeft: 8,
        // marginRight: 8,
        //borderRadius: 7,

        // shadowColor: "rgb(69,  91,  99)",
        // shadowOpacity: 0.5,
        // shadowOffset: {
        //     width: 0,
        //     height: 4
        // },
        // shadowRadius: 5,
        // elevation: 5,

        // marginBottom: 2

    },
    circleColumn: {
        width: 45,
        height: 45,
        borderRadius: 50,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 10
    },
    circleColumnItemsDate: {
        fontSize: 18,
        color: '#fff'
    },
    circleColumnItemsMonth: {
        fontSize: 15,
        color: '#fff'
    },
    detailsView: {
        flex: 2,
        marginLeft: 20,
        marginRight: 10,
    },
    detailsViewList: {
        flexDirection: 'column',
    },
    algTile: {
        fontSize: 14,
        fontWeight: "bold",
    },
    algOptions: {
        fontWeight: 'bold',
        fontSize: 16,

    }
});

export default ProxyAccountWebHandler;
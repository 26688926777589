import { GETTING_ALLERGIES, GETTING_ALLERGIES_SUCCESS, GETTING_ALLERGIES_FAILED, GETTING_ALLERGIES_MORE_SUCCESS, GETTING_ALLERGIES_FINISHED } from "../constants/Constant";
import getAllergies from "../services/AllergiesServices";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";

//function will return data
export const fetchAllergyFromApi = (pageNumber, pageSize) => {
    return (disptach) => {
        disptach(getAlg())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = "";

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                // body: raw,
                redirect: 'follow'
            };

            // token = data
            const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber;

            fetch(Settings.apiUrl + "Patient/AllergyIntolerance" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    // //console.log("DATA FOUND: ", json)
                    // disptach(getAlgSuccess(json[0].entry))
                    json[0].hasOwnProperty('entry') ? disptach(getAlgSuccess(json[0].entry)) : disptach(getAlgFailed())
                })
                .catch(err => disptach(getAlgFailed()))

            // getAllergies(queryString)
            //     .then(data => data.json())
            //     .then(json => {
            //         // //console.log("DATA FOUND: ", json)
            //         // disptach(getAlgSuccess(json[0].entry))
            //         json[0].hasOwnProperty('entry') ? disptach(getAlgSuccess(json[0].entry)) : disptach(getAlgFinished())
            //     })
            //     .catch(err => disptach(getAlgFailed(//console.log(err))))
        })

    }
}


// export const fetchMoreAllergyFromApi = (token, pageNumber, pageSize) => {
//     return (disptach) => {
//         disptach(getAlg())
//         getUserId().then((userInfo) => {
//             const item = JSON.parse(userInfo);
//             // token = data
//             const queryString = '?Token=' + item.token + '&PatientID='+item.userid+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber;
//             getAllergies(queryString)
//                 .then(data => data.json())
//                 .then(json => {
//                     ////console.log("DATA FOUND: ", json)

//                     json[0].hasOwnProperty('entry') ? dispatch(getAlgMoreSuccess(json[0].entry)) : dispatch(getAlgFinished())

//                 })
//                 .catch(err => disptach(getAlgFailed(//console.log(err))))
//         })

//     }
// }


//start getting data
const getAlg = () => {
    return {
        type: GETTING_ALLERGIES
    }
}

//date success
const getAlgSuccess = (data) => {
    return {
        type: GETTING_ALLERGIES_SUCCESS,
        data,
    }
}
// //date success
// const getAlgMoreSuccess = (data) => {
//     return {
//         type: GETTING_ALLERGIES_MORE_SUCCESS,
//         data,
//     }
// }

//data faied
const getAlgFailed = () => {
    return {
        type: GETTING_ALLERGIES_FAILED
    }
}
//data faied
const getAlgFinished = () => {
    return {
        type: GETTING_ALLERGIES_FINISHED
    }
}
import { ADDING_APPOINT, ADDING_APPOINT_SUCCESS, ADDING_APPOINT_FAIL, ADDING_APPOINT_CLEANUP } from "../constants/Constant";
import addPatientAppointment from "../services/AddApointmentService";
import getUserId from "../core/GetToken";


export const addPaitentAppoinmenttoAPI = (slotid, reason, PaitentID, typeid) => {
    return async (disptach) => {
        //console.log("Slot in here" + slotid)

        disptach(addAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            // token = data
            var myHeaders = new Headers();

            myHeaders.append("Token", item.token);
            var raw = ""
            let mareezid = PaitentID != '' ? PaitentID : item.userid
            let id = typeid != '' ? '&AppointmentTypeID=' + typeid : ''
            //const queryString = '?Token='+data+'&PatientID=2255013&ScheduleDate='+passingDate;
            const queryString = '?PatientID=' + mareezid + '&SlotID=' + slotid + '&Reason=' + reason + id;
            console.log("here is my string", { queryString })
            addPatientAppointment(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    if (json == "Appointment has been booked successfully")
                        //console.log("DATA FOUND: " + JSON.stringify(json))
                        disptach(addAppointSuccess(json))
                    else {
                        disptach(addAppointFail(json))
                    }
                })
                .catch(err => disptach(addAppointFail(err)))
        })


    }
}

//for state reset
export const clenuplatData = () => {
    return (disptach) => {
        disptach(addAppointClean())
    }
}


const addAppoint = () => {
    return {
        type: ADDING_APPOINT
    }
}

//data success
const addAppointSuccess = (data) => {
    return {
        type: ADDING_APPOINT_SUCCESS,
        data,
    }
}

//data failed
const addAppointFail = (data) => {
    return {
        type: ADDING_APPOINT_FAIL,
        data
    }
}

const addAppointClean = () => {
    return {
        type: ADDING_APPOINT_CLEANUP
    }
}
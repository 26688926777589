import { GETTING_APPOINTMENT, GETTING_APPOINTMENT_SUCCESS, GETTING_APPOINTMENT_MORE_SUCCESS, GETTING_APPOINTMENT_FAILURE } from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";

//to get all diagnosis
export const fetchfAppointmentsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //const queryString = '?Token=' + data + '&PatientID=17308&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            // const queryString = '?Token=' + item.token + '&PatientID='+item.token+'&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=1'

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=1'
            //console.log(queryString);

            // fetch(Settings.apiUrl + "Patient/Appointments" + queryString, requestOptions)
            //     .then(response => response.json())
            //     .then(json => {
            //         //console.log(json)
            //         json[0].hasOwnProperty('entry') ? dispatch(getAppointSucess(json[0].entry)) : dispatch(getAppointFinished())

            //     })
            //     .catch(error => dispatch(getAppointFail(error)));

            getPaitentAppointments(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    //console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getAppointSucess(json[0].entry)) : dispatch(getAppointFinished())
                })
                .catch(err => dispatch(getAppointFail(err)))
        })
    }
}


export const fetchfAppointmentsMoreFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //const queryString = '?Token=' + data + '&PatientID=17308&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            const queryString = '?Token=' + item.token + '&PatientID=' + item.token + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=1'
            getPaitentAppointments(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getAppointMoreSucess(json[0].entry)) : dispatch(getAppointFinished())
                })
                .catch(err => dispatch(getAppointFail(err)))
        })
    }
}

const getAppoint = () => {
    return {
        type: GETTING_APPOINTMENT
    }
}

//data success
const getAppointSucess = (data) => {
    return {
        type: GETTING_APPOINTMENT_SUCCESS,
        data,
    }
}

const getAppointMoreSucess = (data) => {
    return {
        type: GETTING_APPOINTMENT_MORE_SUCCESS,
        data,
    }
}


//data failed
const getAppointFail = () => {
    return {
        type: GETTING_APPOINTMENT_FAILURE
    }
}

const getAppointFinished = () => {
    return "null";
}



import { GET_APPOINTMENT_TYPE, GET_APPOINTMENT_TYPE_SUCCESS, GET_APPOINTMENT_TYPE_FAILED } from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";
import Settings from "../config/Settings";


export const fetchAppointmentsTypesFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getAppointTypes())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=1'
            //console.log(queryString);

            fetch(Settings.apiUrl + "IndiciLookup?TypeID=12&PatientID=" + item.userid + "", requestOptions)
                .then(response => response.json())
                .then(json => {
                    //console.log(json)
                    json[0].hasOwnProperty('entry') ? dispatch(getAppointTypesSucess(json[0].entry)) : dispatch(getAppointTypesFail())
                })
                .catch(err => dispatch(getAppointTypesFail(err)))
            // fetch(Settings.apiUrl + "Patient/Appointments" + queryString, requestOptions)
            //     .then(response => response.json())
            //     .then(json => {
            //         //console.log(json)
            //         json[0].hasOwnProperty('entry') ? dispatch(getAppointSucess(json[0].entry)) : dispatch(getAppointFinished())

            //     })
            //     .catch(error => dispatch(getAppointFail(error)));

            // getPaitentAppointments(queryString, myHeaders)
            //     .then(data => data.json())
            //     .then(json => {
            //         //console.log(json);
            //         json[0].hasOwnProperty('entry') ? dispatch(getAppointSucess(json[0].entry)) : dispatch(getAppointFinished())
            //     })
            //     .catch(err => dispatch(getAppointFail(err)))
        })
    }
}



const getAppointTypes = () => {
    return {
        type: GET_APPOINTMENT_TYPE
    }
}

//data success
const getAppointTypesSucess = (data) => {
    return {
        type: GET_APPOINTMENT_TYPE_SUCCESS,
        data,
    }
}




//data failed
const getAppointTypesFail = () => {
    return {
        type: GET_APPOINTMENT_TYPE_FAILED
    }
}
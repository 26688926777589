import { USER_LOGIN, USER_LOGIN_SUCCESS, USER_LOGIN_FAILED, USER_LOGIN_END, USER_RESET_PASSWORD_STATE } from "../constants/Constant";
const initalState = {
    isLoggedIn: false,
    isLoggin: false,
    AuthUser: [],
    error: false,
    passworderror: '',
    loginFailed: false,
    userPasswordStatus: false
};

export default function userLoginReducer(state = initalState, action) {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                isLoggedIn: false,
                isLoggin: true,
                AuthUser: [],
                userPasswordStatus: false,

            }

        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                isLoggin: false,
                AuthUser: action.data,
                loginFailed: false,
                userPasswordStatus: action.passwordStatus
            }

        case USER_LOGIN_FAILED:
            return {
                ...state,
                isLoggedIn: false,
                isLoggin: false,
                AuthUser: [],
                passworderror: action.msg, //'Your Login details are incorrect',
                loginFailed: true,
                userPasswordStatus: false,

            }

        case USER_LOGIN_END:
            return {
                ...state,
                isLoggedIn: false,
                isLoggin: false,
                AuthUser: [],
                error: false,
                passworderror: '',
                loginFailed: false,
                userPasswordStatus: false,
            }
        case USER_RESET_PASSWORD_STATE:
            return {
                ...state,
                isLoggedIn: false,
                isLoggin: false,
                AuthUser: [],
                error: false,
                passworderror: '',
                loginFailed: false,
                userPasswordStatus: false
            }

        default:
            return state
    }
}
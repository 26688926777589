import React from "react"
import { useLocation } from "react-router-dom"
import Analytics from "../../shared/config/Analytics"


export default function useGoogleAnalytics() {
    const location = useLocation()

    React.useEffect(() => {
        Analytics.init()
    }, [])

    React.useEffect(() => {
        const currentPath = location.pathname + location.search
        Analytics.sendPageview(currentPath)
    }, [location])
}
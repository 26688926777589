import { GET_PROVIDER_LOCATIONS, GET_PROVIDER_LOCATIONS_SUCCESS, GET_PROVIDER_LOCATIONS_FAILED, } from "../constants/Constant";


//current state
const intitalState = {
    ProviderLocation: [],
    defaultLocation: [],
    Colorlist:[],
    isFetchingProvider: false,
    error: false
}

//export requried state
export default function providerLocationReducer(state = intitalState, action) {
    switch (action.type) {
        case GET_PROVIDER_LOCATIONS:
            return {
                ...state,
                isFetchingProvider: true
            }

        case GET_PROVIDER_LOCATIONS_SUCCESS:
            return {
                ...state,
                isFetchingProvider: false,
                ProviderLocation: action.data,
                defaultLocation : action.locations,
                Colorlist: action.arrayofProviderLocation
            }

        case GET_PROVIDER_LOCATIONS_FAILED:
            return {
                ...state,
                isFetchingProvider: false,
                error: true
            }

        default:
            return state
    }
}
import {
    GETTING_IMMUNE_OVERDUE,
    GETTING_IMMUNE_OVERDUE_SUCCESS,
    GETTING_IMMUNE_OVERDUE_MORE_SUCCESS,
    GETTING_IMMUNE_OVERDUE_FAILURE
} from "../constants/Constant";


//inital state
const initalState = {
    OverdueImmune: [],
    isGettingImmuneOverdue: false,
    immuneOverdueError: false
}

//export default state
export default function overdueImmuneReducer(state = initalState, action) {
    switch (action.type) {
        case GETTING_IMMUNE_OVERDUE:
            return {
                ...state,
                isGettingImmuneOverdue: true
            }

        case GETTING_IMMUNE_OVERDUE_SUCCESS:
            return {
                ...state,
                isGettingImmuneOverdue: false,
                OverdueImmune: action.data,     
                //OverdueImmune: [...state.OverdueImmune , ...action.data]
            }

        case GETTING_IMMUNE_OVERDUE_MORE_SUCCESS:
            return {
                ...state,
                isGettingImmuneOverdue: false,
                OverdueImmune: [...state.OverdueImmune , ...action.data]
            }


        case GETTING_IMMUNE_OVERDUE_FAILURE:
            return {
                ...state,
                isGettingImmuneOverdue: false,
                immuneOverdueError: true
            }
        default:
            return state
    }
}
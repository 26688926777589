import React from 'react';
const Iframe = (props) => {
    let iframe_ref = null;
    const writeHTML = (frame) => {
        if (!frame) {
            return;
        }
        iframe_ref = frame;
        let doc = frame.contentDocument;
        doc.open();
        doc.write(props.content);
        doc.close();
        frame.style.width = '930px';
        frame.style.height = '450px'; //`${frame.contentWindow.document.body.scrollHeight}px`;
        frame.style.overflow = 'scroll';
    };
    return (
        <div ref={props.printRef}>
            <iframe src="about:blank" scrolling="yes" frameBorder="0" ref={writeHTML}
            />
        </div>
    );
};
export default Iframe;
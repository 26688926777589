import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, ActivityIndicator, TouchableOpacity, View, Image, Dimensions } from 'react-native';


import { Spin, Switch, Alert } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Modal, Button } from 'antd';
import getUserId from "../../shared/core/GetToken";
import { LoadingOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';

import 'antd/dist/antd.css';
import { capitalize } from '../../shared/core/datehelper';
import Settings from '../../shared/config/Settings';
import renderHTML from "react-render-html";

let tabledata = []
const ReportsHandler = props => {



    const { Column, ColumnGroup } = Table;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    const [visible, setVisible] = useState(false);  //for report pdf view 
    const [tableModalVisible, setTableModalVisible] = useState(false); //for
    const [pdfViewerLoading, setPdfViewerLoading] = useState(false);
    const [isReady, setIsReady] = useState(true);
    const [sloading, setSLoading] = useState(false);
    const getRandomTag = () => {
        var textArray = [
            'card-header text-white bg-danger',
            'card-header text-white bg-success',
            'card-header text-white bg-primary',
            'card-header text-white bg-info',
            'card-header text-white bg-warning',
        ];
        var randomNumber = Math.floor(Math.random() * textArray.length);
        return textArray[randomNumber]
        // audioElement.setAttribute('src', textArray[randomNumber]);
    }


    const loadReport = () => {
        // setVisible(true)
        setSLoading(true);

        getUserId().then((userInfo) => {
            //   setPdfViewerLoading(true)
            //    

            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(Settings.apiUrl + "Patient/LabResultByID?PatientID=" + item.userid + "&ResultID=" + props.labid, requestOptions)
                .then(data => data.json())
                .then(json => {

                    if (json[0].entry[0].resource.content[0].attachment.contentType == "PDF" || json[0].entry[0].resource.content[0].attachment.contentType == "pdf") {
                        setVisible(true)
                        setTimeout(() => {

                        }, 3000);
                        document.getElementById("showPDF").innerHTML = ""
                        //    alert("pdf here")
                        var b64 = json[0].entry[0].resource.content[0].attachment.data;
                        var obj = document.createElement('object');
                        obj.style.width = '100%';
                        obj.style.height = '550px';
                        obj.type = 'application/pdf';
                        obj.data = 'data:application/pdf;base64,' + b64;
                        //document.body.appendChild(obj);
                        document.getElementById("showPDF").appendChild(obj)
                        ///     setPdfViewerLoading(false)
                    }
                    else {
                        //showTable

                        let ObservationText = '';
                        let ObservationValue = "";
                        let AbnormalFlagDesc = ""
                        json[0].entry.map((item, index) => {
                            item.resource.extension.map((value, key) => {
                                if (item.resource.extension[key].url == "ObservationText") {
                                    ObservationText = capitalize(item.resource.extension[key].valueString)
                                } else if (item.resource.extension[key].url == "ObservationValue") {
                                    ObservationValue = capitalize(item.resource.extension[key].valueString)
                                }
                                else if (item.resource.extension[key].url == "AbnormalFlagDesc") {
                                    AbnormalFlagDesc = capitalize(item.resource.extension[key].valueString)
                                }
                            })
                            let ob = {
                                "ObservationText": ObservationText,
                                "ObservationValue": ObservationValue,
                                "AbnormalFlagDesc": AbnormalFlagDesc //capitalize(item.resource.extension[3].valueString) // ObservationValue,
                                // age: 32,
                                // address: 'New York No. 1 Lake Park',
                                // tags: ['nice', 'developer'],
                            }
                            tabledata.push(ob)


                        })
                        setTableModalVisible(true)
                        // setIsReady(!isReady)
                        console.log({ tabledata })
                        setTimeout(() => {

                        }, 3000);

                    }



                    // setVisible(true)
                    setSLoading(false)



                })
                .catch(error => {
                    // setIsLoadingPdf(false)
                    //console.log(error)
                    setSLoading(false)
                    setPdfViewerLoading(false)
                })
                .finally(() => {
                    // setCheckFile(false)
                    // setIsLoadingPdf(false)
                    setSLoading(false)
                    setPdfViewerLoading(false)


                });
        });
    }
    const reportHandler = () => {
        setSLoading(true);
    }

    const columnsLetter = [
        {
            title: "Observation Text",
            dataIndex: "ObservationText",
            key: "ObservationText",
            width: 150
        },
        {
            title: "Observation Value",
            dataIndex: "ObservationValue",
            key: "ObservationValue",
            width: 400,
            render: (text, record, index) => (
                <p style={{display: 'inline-block', width: 700, overflowY: 'scroll'}}> {renderHTML(record.ObservationValue)}</p>
            ),
        },
        {
            title: "Abnormal Flag Desc",
            dataIndex: "AbnormalFlagDesc",
            width: 100,
            key: "AbnormalFlagDesc",
        },
    ];

    return (
        // <Spin spinning={sloading}>
        <>
            <TouchableOpacity onPress={() => loadReport()}>
                <div className="col-lg-12 ">

                    <div className="card shadow-lg rounded" style={{ marginTop: 15, marginBottom: 10 }}>
                        <Spin indicator={antIcon} tip="Loading..." spinning={sloading}>
                            <div className='card-header text-white bg-warning'>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={{ fontWeight: '500', color: '#fff' }}>{props.testName}</Text>
                                    <Text style={{ fontWeight: '500', color: '#fff' }}>{props.rptDate}</Text>
                                </View>
                            </div>
                            <div className="card-body">
                                <h4 className="card-title"><i className="mdi mdi-18px mdi-home-map-marker" />{props.author}</h4>
                                <p className="card-title"><i style={{ marginRight: 4, }} className="mdi mdi-18px mdi-calendar-clock" />{'Laboratory: ' + props.labName}</p>
                                <p className="card-text"><i  style={{ marginRight: 4}} className="mdi mdi-18px mdi-folder" />{'Folder: ' + props.folderName}</p>

           
                                <p className="card-text"><i className="mdi mdi-18px mdi-hospital-marker" />{'Comments: ' + props.comments}</p>
                                {/* <p className="card-text"><i className="mdi mdi-18px mdi-folder" />{'Folder: ' + props.folderName}</p> */}

                                {/* <h4 className="card-title"> <i className="mdi mdi-18px mdi-hospital-marker" />
                        <i className="mdi mdi-18px mdi-file-pdf" /> {props.author}</h4>
                    <h4 className="card-title">
                        <i className="mdi mdi-18px mdi-file-pdf" /> {'Laboratory: ' + props.labName}</h4>
                    <h4 className="card-title">
                        <i className="mdi mdi-18px mdi-file-pdf" /> {'Comments: ' + props.comments}</h4> */}
                            </div>
                        </Spin>
                    </div>



                </div>

            </TouchableOpacity>
            <Modal
                title="PDF File"
                centered
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                afterClose={() => document.getElementById("showPDF").innerHTML = ""}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
            >
                <div className="PDF" id="showPDF">

                </div>
                <p id="hee">
                    {pdfViewerLoading && <View style={{ justifyContent: 'center', alignItems: 'center' }}><ActivityIndicator size="small" color="#00A1DE" /><Text>Loading. Please wait...</Text></View>}
                </p>
            </Modal>
            <Modal
                title="Lab Result"
                centered
                visible={tableModalVisible}
                onOk={() => setTableModalVisible(false)}
                onCancel={() => setTableModalVisible(false)}
                afterClose={() => {
                    tabledata = []
                }}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1200}
            >
                <div id="showTable">

                    {tabledata && tabledata.length ?
                        <>

                            <Table dataSource={tabledata} columns={columnsLetter} />
                            {/* <Table dataSource={tabledata}>
                                <Column title="Observation Text" dataIndex="ObservationText" key="ObservationText" />
                                <Column
                                    title="Observation Value"
                                    dataIndex="ObservationValue"
                                    key="ObservationValue"
                                    width={150}
                                    render={(text, record, index) => {
                                       return  renderHTML(record.ObservationValue)
                                    }}
                                />
                                <Column title="Abnormal Flag Desc" dataIndex="AbnormalFlagDesc" key="AbnormalFlagDesc" />
                            </Table> */}
                        </>
                        : <p>no data is available</p>
                    }

                </div>

            </Modal>


        </>

    );
}

export default ReportsHandler

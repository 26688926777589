import React, { memo, useCallback, useEffect, useState } from 'react';
import { View, Text, StyleSheet, StatusBar, TouchableOpacity, ActivityIndicator, FlatList } from 'react-native-web';
import { connect } from "react-redux";
import { FormatAMPM, GetAppointmentDate, GetMonthName, GetYear } from '../../../shared/core/datehelper'
import { fetchDraftsMessageFromApi, fetchMoreDraftsMessageFromApi } from '../../../shared/actions/DraftsMessageAction';
import { clearSetItemMessage, setItemMessage } from '../../../shared/actions/SetMessageItemAction';
import MessageItem from "../../components/MessageItem";
import { getInitials } from '../../../shared/core/utils';
import FileBase64 from 'react-file-base64';
import getUserId from '../../../shared/core/GetToken';

import "antd/dist/antd.css";
import { Select, notification } from 'antd';
import { msgClenuplatData, sendNewMessagetoAPI } from '../../../shared/actions/NewMessageActions';
// import Settings from '../../config/Settings';
import { Modal, Button } from 'antd';
import Settings from '../../../shared/config/Settings';
import GetAppSettings from '../../../shared/core/GetAppSettings';
import renderHTML from "react-render-html";
import { formatTimeStr } from 'antd/lib/statistic/utils';
const Drafts = props => {


    //const { SentItems, isGetting } = props.SentItems;
    const { DraftsMessaging, isGetting } = props.DraftsMessaging;
    const { SendMessage, isSending, isSendMessageSuccess, error } = props.SendMessage
    const [pageSize, setPageSize] = useState(100);           //state for the page number
    const [pageNumber, setPageNumber] = useState(1);
    const [newMsgModal, setNewMsgModal] = useState(false);           //state for the page number
    useEffect(() => {
        // //console.log(props.testValue)
        props.getDraftsMessage(pageSize, pageNumber)
    }, [])

    React.useEffect(() => {
        // props.tokenVal(history);
        CallcenterList()
    }, [])
    ////////////////////////////////////////////////////////////////  
    const [portalSetting, setPortalSetting] = useState(null);
    const [centerName, setCenterName] = useState('');
    const [centerRef, setCenterRef] = useState('');
    const [centersArray, setCentersArray] = useState([]);
    const [providerArray, setProviderArray] = useState([]);
    const [resourceType, setResourceType] = useState('');
    const [recpName, setRecpName] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');


    const [fileSize, setFileSize] = useState('');
    const [fileType, setfileType] = useState('');
    const [fileBase, setFileBase] = useState('');
    const [selected, setSelected] = useState()
    const [filesList, setFilesList] = useState([]);

    const [loadingMessage, setLoadingMessage] = useState(false);
    const clearSelected = () => {
        // this line will clear the select
        // when you click on the button
        setSelected(null)
    }
    const [selectedCenter, setSelectedCenter] = useState()
    //////////////////////////
    function onChange(value) {
        setResourceType('')
        clearSelected();
        // alert(value)
        setSelectedCenter(centersArray[value].resource.extension[1].valueString)
        //console.log(centersArray[value]);
        let id = centersArray[value].resource.extension[0].valueString;
        // let resourceType = centersArray[value].resource.resourceType;
        setCenterRef(id);
        // setCenterName(centersArray[value].resource.name)
        CallProviderList(id)
    }
    const checkFile = (file) => {
        if (file) {
            for (var i = 0; i < file.length; i++) {
                setFileSize(file[i].size);
                setfileType(file[i].name.split('.').pop())
                setFileBase(file[i].base64)
                let ob = {
                    type: file[i].name.split('.').pop(),
                    size: file[i].size.split(' ')[0] * 1024,
                    base64: file[i].base64.split(',').pop(), //reader.result.split(',').pop(),
                };
                filesList.push(ob);
            }
            //console.log({ filesList })

        }



    }


    function onBlur() {
        //console.log('blur');
    }

    function onFocus() {
        //    CallcenterList()
        //console.log('focus');
    }

    useEffect(() => {
        if (providerArray && providerArray.length) {
            setSelected(providerArray[0].resource.name[0].text)
            setRecpName(providerArray[0].resource.name[0].text);
            setResourceType(providerArray[0].resource.resourceType + '/' + providerArray[0].resource.id);


        }

    }, [providerArray])
    function onChangeProvider(value) {
        setSelected(providerArray[value].resource.name[0].text)
        document.getElementById('errorProvider').innerHTML = ''
        setRecpName(providerArray[value].resource.name[0].text);
        setResourceType(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);


        //console.log(providerArray[value].resource.resourceType + '/' + providerArray[value].resource.id);
        //console.log(providerArray[value].resource.name[0].text)
    }

    function onBlurProvider() {
        //console.log('blur');
    }

    function onFocusProvider() {
        //CallcenterList()
        //console.log('focus provider');
    }

    const [centerLoading, setCenterLoading] = useState(false)
    const CallcenterList = () => {

        setProviderArray([]);
        getUserId().then((userInfo) => {

            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setCenterLoading(true)
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = '?PatientID=' + item.userid + '&TypeID=1';
            //console.log(queryString)
            //console.log(item)
            // document.getElementById('error').innerHTML = ''
            fetch(Settings.apiUrl + "Patient/LoadRoles" + queryString, requestOptions)

                .then(data => data.json())
                .then(json => {
                    setCentersArray(json[0].entry)
                    setCenterLoading(false)
                })
                .catch(err => {
                    //console.log(err)
                    setCenterLoading(false)
                })
        })
    }
    React.useEffect(() => {
        if (centersArray && centersArray.length) {

            CallProviderList(centersArray[0].resource.extension[0].valueString)
            setSelectedCenter(centersArray[0].resource.extension[1].valueString)
            //console.log(centersArray[value]);
            let id = centersArray[0].resource.extension[0].valueString;
            // let resourceType = centersArray[value].resource.resourceType;
            setCenterRef(id);
        }

    }, [centersArray])

    const [providerLoading, setProviderLoading] = useState(false)
    const openNotificationWithIcon = type => {
        notification[type]({
           // message: 'Message Drafted',
            description:
                SendMessage,
        });
    };
    React.useEffect(() => {
        if (isSendMessageSuccess) {
            alert(isSendMessageSuccess)
            setNewMsgModal(false)
            document.getElementById('userSubject').value = ''
            document.getElementById('userMsg').value = ''
            clearSelected()
            setSelectedCenter(null)
            props.cleandata()
            
            openNotificationWithIcon('success')

        }
        // 
    }, [isSendMessageSuccess])
    const CallProviderList = (index) => {
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            setProviderLoading(true)
            myHeaders.append("Token", item.token);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            const queryString = "?PatientID=" + item.userid + "&RoleID=" + index + "&CallType=0";
            // const queryString = '?PatientID=' + item.userid + '&PracticeLocationID=' + index;
            //console.log(queryString)
            fetch(Settings.apiUrl + "Patient/MessagingRecipients" + queryString, requestOptions)
                .then(data => data.json())
                .then(json => {
                    setProviderLoading(false);
                    //console.log(json[0].entry)
                    setProviderArray(json[0].entry);
                })
                .catch(err => {
                    setProviderLoading(false)
                    //console.log(err)
                })
        })

    }




    const sendMessage = () => {
        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            alert("Please select Center");
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            alert("Please select Provider");
            return
        }



        let sub = document.getElementById('userSubject').value
        if (sub === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            alert("Please select subject");
            return
        }

        let msg = document.getElementById('userMsg').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            alert("Please select message");
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(drftid, centerName, centerRef, sub, msg, fileType, fileBase, filesList, resourceType, recpName, 'Send Mail')
    }

    const saveDraft = () => {
        if (centersArray.length === 0) {
            document.getElementById('error').innerHTML = 'Please select Center';
            return
        }

        if (resourceType === '') {
            document.getElementById('errorProvider').innerHTML = 'Please select Provider';
            return
        }



        let sub = document.getElementById('userSubject').value
        if (sub === '') {
            document.getElementById('errorSub').innerHTML = 'Please enter subject';
            return
        }

        let msg = document.getElementById('userMsg').value
        if (msg === '') {
            document.getElementById('errorMsg').innerHTML = 'Please type your message';
            return
        }
        //console.log(sub, msg, centerName, centerRef, recpName, resourceType)

        props.sendData(drftid, centerName, centerRef, sub, msg, fileType, fileBase, fileSize, resourceType, recpName, 'Drafts')

    }

    useEffect(() => {
        GetAppSettings().then((info) => {
            const set = JSON.parse(info)
            setPortalSetting(set)
        })
    }, [])

    ///////////////////////////////////////////

    const [isReady, setIsReady] = useState(false)
    const [drftid, setDraft] = useState(null)
    const getMessageId = msgValue => {
        setNewMsgModal(true);
        setLoadingMessage(true)
        getUserId().then((userInfo) => {
            // setLoading(true)
            // const item = JSON.parse(userInfo);
            const item = userInfo !== "none" && JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(`${Settings.apiUrl}` + "Patient/MessageByID?PatientID=" + item.userid + "&MessageID=" + msgValue.resource.id, requestOptions)
                .then(data => data.json())
                .then(json => {

                    // console.log("Response==>", JSON.stringify(json[0].entry[0]))

                    // setSubject({ value: json[0].entry[0].resource.note[0].text, error: '' })
                    // setUserMessage({ value: json[0].entry[0].resource.extension[0].valueString, error: '' })
                    // setfiles(json[0].entry[0].resource.hasOwnProperty('payload') ? json[0].entry[0].resource.payload : [])
                    json[0].entry[0].resource.hasOwnProperty('payload') &&
                        json[0].entry[0].resource.payload.map((it, index) => {

                            //   base64files.push(item.contentAttachment.data)
                            let ob = {
                                type: it.contentAttachment.contentType,
                                size: it.contentAttachment.size,
                                base64: it.contentAttachment.data, // file[i].base64.split(',').pop(), //reader.result.split(',').pop(),
                            };
                            filesList.push(ob)
                            // alert('files')
                            console.log({ filesList })

                        })
                    setDraft(msgValue.resource.id)
                    setLoadingMessage(false)
                    setTimeout(() => {
                        document.getElementById('userSubject').value = msgValue.resource.note[0].text;
                        document.getElementById('userMsg').value = msgValue.resource.extension[0].valueString

                    }, 2000);
                    setIsReady(!isReady)
                    // DarftId = json[0].entry[0].resource.id
                    // json[0].entry[0].resource.hasOwnProperty('payload') &&
                    //     // setIsSelected(true)
                    //     setIsReady(!isReady)
                    // console.log("for Hassan====>  " + JSON.stringify(item))

                    // setLoading(false)
                })
                .catch(error => {
                    // setLoading(false)
                    console.log('error', error)
                })
        })




        // props.setMessageItem(params, 'Drafts')

        // 
        // setSubject(item.resource.note[0].text)
        // setMessage(item.resource.extension[0].valueString)
    }

    return (
        <div>
            {
                isGetting && <View style={{ textAlign: 'center', marginTop: 5 }}><ActivityIndicator size="small" color="#00A1DE" /></View>
            }
            {
                typeof (DraftsMessaging) !== 'undefined' && DraftsMessaging.length ? (
                    <FlatList

                        data={DraftsMessaging}
                        renderItem={({ item }) => (
                            <TouchableOpacity disabled={portalSetting && !portalSetting.reply}
                                onPress={() => {
                                    getMessageId(item);
                                }}>
                                <MessageItem
                                    initails={getInitials(item.resource.extension[2].valueString)}
                                    messageDate={GetAppointmentDate(item.resource.extension[6].valueDateTime) + ' ' + GetMonthName(item.resource.extension[6].valueDateTime) + " " + GetYear(item.resource.extension[6].valueDateTime)}
                                    messageTime={FormatAMPM(item.resource.extension[6].valueDateTime.split('+')[0])}

                                    sender={item.resource.extension[2].valueString}
                                    messageSubject={item.resource.note[0].text}
                                    messageDescription={item.resource.extension[0].valueString}
                                />
                            </TouchableOpacity>
                        )}
                        keyExtractor={item => item.resource.id}
                        initialNumToRender={10}
                        windowSize={7}

                    />

                ) : !isGetting && <View style={{
                    backgroundColor: '#fff',
                    flexDirection: "column",
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <Text style={{ marginTop: 0, }}>No Records Found</Text>
                </View>
            }

            <Modal
                title="New Message"
                centered
                visible={newMsgModal}
                // onOk={() => {
                //     sendMessage()
                //  //   setNewMsgModal(false);

                // }} 
                // loading={isSending}
                // onCancel={() => setNewMsgModal(false)}
                closable={false}
                style={{ top: 10, bottom: 90, overflow: "hidden" }}
                width={1000}
                footer={[
                    // <div style={{flexDirection:'column', justifyContent:'space-between'}} >

                    isSending ?
                        <ActivityIndicator size="small" color="#00A1DE" />

                        : <>
                            <Button key="back" onClick={() => setNewMsgModal(false)}>
                                Cancel
                            </Button>
                            <Button key="submit" type="secondary" onClick={() => saveDraft()}>
                                Draft
                            </Button>
                            <Button key="submit" type="primary" onClick={() => sendMessage()}>
                                Send
                            </Button>
                        </>




                    // </div>
                ]}
            >
                <div className="row" >
                    <div className="col-md-12 mx-auto">


                        <div class="col-md-12 mx-auto">
                            <div class="alert alert-warning" role="alert">

                                <h6 class="alert-heading">Disclaimer:</h6>
                                {portalSetting &&
                                    portalSetting.PortalMessageTermsandConditions &&
                                    renderHTML(portalSetting.PortalMessageTermsandConditions)

                                }

                            </div>
                        </div>

                        {loadingMessage ? <ActivityIndicator size='small' color="blue" /> :
                            <>
                                <div style={{}} className="form-group row mb-0">
                                    <div className="col-md-6">
                                        {/* <div className="form-group input-recipient"> showcase_content_area */}
                                        <label className="label" style={{ width: 68 }}>To</label>
                                        {/* <div class="col-md-6 "> */}
                                        {centerLoading && <ActivityIndicator size="small" color="#00A1DE" />}
                                        {centersArray && centersArray.length ?
                                            <Select
                                                defaultValue={centersArray[0].resource.extension[1].valueString}
                                                showSearch
                                                style={{ width: '100%' }}
                                                placeholder="Select a person"
                                                optionFilterProp="children"
                                                onChange={onChange}
                                                onFocus={onFocus}
                                                onBlur={onBlur}
                                                value={selectedCenter}

                                            >
                                                {
                                                    centersArray.map((item, index) => <Select.Option key={index}>{item.resource.title}</Select.Option>)
                                                }

                                            </Select>
                                            : !centerLoading &&
                                            <p>No registered Role Available.</p>
                                        }

                                        <p id="error" style={{ color: 'red' }}></p>
                                        {/* </div> */}
                                        {/* </div> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group input-recipient" style={{ paddingBottom: 4 }}>
                                            <label className="label" style={{}}>Select Provider</label>
                                            <div class="showcase_content_area">

                                                {providerLoading && <ActivityIndicator size="small" color="#00A1DE" />
                                                }
                                                {providerArray && providerArray.length ?
                                                    <Select
                                                        showSearch
                                                        defaultValue={providerArray[0].resource.name[0].text}
                                                        style={{ width: '100%' }}
                                                        placeholder="Select Provider"
                                                        optionFilterProp="children"
                                                        onChange={onChangeProvider}
                                                        onFocus={onFocusProvider}
                                                        onBlur={onBlurProvider}
                                                        value={selected}


                                                    >
                                                        {providerArray !== undefined && providerArray.map((item, index) => <Select.Option key={index}>{item.resource.name[0].text}</Select.Option>)}

                                                    </Select>
                                                    : !providerLoading && <p>No registered provider available.</p>

                                                }
                                                <p id="errorProvider" style={{ color: 'red' }}></p>

                                            </div>
                                        </div>


                                    </div>

                                </div>


                                <div style={{}} className="form-group row mb-0">

                                    <div className="form-group col-md-12">
                                        <label className="label" style={{ width: 68 }}>Subject</label>
                                        <input type="text" className="form-control "
                                            placeholder="Subject" id="userSubject" //
                                            onClick={() => document.getElementById('errorSub').innerHTML = ''} />
                                        <p id="errorSub" style={{ color: 'red' }}></p>
                                    </div>
                                </div>
                                <div style={{}} className="form-group row mb-0">
                                    <div className="form-group col-md-12">

                                        <textarea id="userMsg" className="form-control" rows={8} placeholder="Write your message here" defaultValue={""} onClick={() => document.getElementById('errorMsg').innerHTML = ''} />
                                        <p id="errorMsg" style={{ color: 'red' }}></p>
                                    </div>


                                </div>
                                <FileBase64
                                    className="action-buttons"
                                    multiple={true}
                                    onDone={(files) => checkFile(files)} />
                                {/* <div style={{ alignItems: 'flex-end', }} className="form-group row mb-0"> */}

                                <div style={{ minHeight: 30, height: 30 }}>


                                    <div className="email-preview-wrapper" style={{ padding: 0 }}>
                                        <div className="email-container" style={{ margin: 0 }}>
                                            <div className="email-attachments">
                                                {/* <p>jj</p> */}
                                                <div className="attachment-wrapper mt-2" style={{ marginTop: 0, paddingTop: 0 }}>
                                                    {filesList && filesList.length ?
                                                        filesList.map((item, index) => {
                                                            return (
                                                                <div
                                                                    onClick={() => { }}
                                                                    className={
                                                                        item.type ==
                                                                            "PDF"
                                                                            ? "attachment attachment-warning"
                                                                            : "attachment attachment-primary"
                                                                    }>

                                                                    <p>
                                                                        Document.
                                                                        {item.type}
                                                                    </p>
                                                                    <span>Attachment {index + 1}</span>
                                                                    <div className="icon-wrapper">
                                                                        {item.type ==
                                                                            "PDF" ? (
                                                                            <i className="mdi mdi mdi-file-pdf" />
                                                                        ) : (
                                                                            <i className="mdi mdi mdi-file-image" />
                                                                        )}
                                                                    </div>
                                                                    {/* </TouchableOpacity> */}
                                                                </div>
                                                            )
                                                        })
                                                        : null
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </>
                        }
                    </div>


                </div>

            </Modal >


        </div >
    );
}





function mapStateToProps(state) {
    return {
        //SentItems: state.SentItems,
        DraftsMessaging: state.DraftsMessaging,
        SendMessage: state.SendMessage,
        //SetMessageItem: state.SetMessageItem,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendData: (draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType) => dispatch(sendNewMessagetoAPI(draftMessageId, centerName, centerReference, subject, emailText, fileType, fileBase, fileSize, resourceType, msgRecp, msgType)),

        getDraftsMessage: (pageSize, pageNumber) => dispatch(fetchDraftsMessageFromApi(pageSize, pageNumber)),
        setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
        // getMoreDraftsMessageMoreSuccess: (pageSize, pageNumber) => dispatch(fetchMoreDraftsMessageFromApi(pageSize, pageNumber)),
        //setMessageItem: (item, screenName) => dispatch(setItemMessage(item, screenName)),
        // setScreenName: (screenName) => dispatch(setScreenName(screenName)),
        //clearSetMessage: () => dispatch(clearSetItemMessage()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Drafts)

//export default Drafts

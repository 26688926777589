import { GETTING_MISSED_APPOINTMENT, GETTING_MISSED_APPOINTMENT_SUCCESS, GETTING_MISSED_APPOINTMENTT_FAILURE, GETTING_MISSED_APPOINTMENT_MORE_SUCCESS, GETTING_MISSED_APPOINTMENT_FINISHED } from "../constants/Constant";
import getPaitentAppointments from "../services/AppointmentServices";
import getUserId from "../core/GetToken";

//to get all diagnosis
export const fetchmAppointmentsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getMissedAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);

            var myHeaders = new Headers();
            myHeaders.append("Token", item.token);
            //const queryString = '?Token=' + data + '&PatientID=17308&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            const queryString = '?PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=2'
            getPaitentAppointments(queryString, myHeaders)
                .then(data => data.json())
                .then(json => {
                    //console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getMissedAppointSucess(json[0].entry)) : dispatch(getMissedAppointFinished())
                })
                .catch(err => dispatch(getMissedAppointFail(err)))
        })
    }
}

//to get all diagnosis
export const fetchMoreAppointmentsFromApi = (pageSize, pageNumber) => {
    return (dispatch) => {
        dispatch(getMissedAppoint())
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            //const queryString = '?Token=' + data + '&PatientID=17308&PageSize=' + pageSize + '&PageNumber=' + pageNumber
            const queryString = '?Token=' + item.token + '&PatientID=' + item.userid + '&PageSize=' + pageSize + '&PageNumber=' + pageNumber + '&AppointmentType=2'
            getPaitentAppointments(queryString)
                .then(data => data.json())
                .then(json => {
                    //console.log(json);
                    json[0].hasOwnProperty('entry') ? dispatch(getMoreMissedAppointSucess(json[0].entry)) : dispatch(getMissedAppointFinished())
                })
                .catch(err => dispatch(getMissedAppointFail(err)))
        })
    }
}





const getMissedAppoint = () => {
    return {
        type: GETTING_MISSED_APPOINTMENT
    }
}

//data success
const getMissedAppointSucess = (data) => {
    return {
        type: GETTING_MISSED_APPOINTMENT_SUCCESS,
        data,
    }
}
//data more success
const getMoreMissedAppointSucess = (data) => {
    return {
        type: GETTING_MISSED_APPOINTMENT_MORE_SUCCESS,
        data
    }
}
//data failed
const getMissedAppointFail = () => {
    return {
        type: GETTING_MISSED_APPOINTMENTT_FAILURE
    }
}
const getMissedAppointFinished = () => {
    return {
        type: GETTING_MISSED_APPOINTMENT_FINISHED
    }
}


